@for (circle of circles(); track $index) {
    <div class="d-inline-block">
        <div
            class="tier-selection-circle circle-{{ $index + 1 }}"
            [ngClass]="{ selected: circle.selected }"
            (click)="selectCircle($index)"
            role="button"
        >
            {{ $index + 1 }}
        </div>
    </div>
}
