import { Injectable } from '@angular/core';
import { ArtifactIntelligenceActions, ArtifactIntelligenceRequestActions } from './artifact-intelligence.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, last, concatAll, concatMap } from 'rxjs/operators';
import {
    ArtifactService,
    ControlValidationDetectionType,
    ControlValidationStatus,
    SourceType,
} from '../../../entities/artifact';
import { EMPTY, from, of } from 'rxjs';
import { NOOP } from '@shared/redux/actions';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';

@Injectable()
export class ArtifactIntelligenceEffects {
    updateArtifactValidationStatusRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ArtifactIntelligenceRequestActions.updateArtifactValidationStatusRequest),
            switchMap(({ artifactId, status }) =>
                this._artifactService.updateArtifactValidationStatus(artifactId, status).pipe(
                    map(() => ArtifactIntelligenceRequestActions.updateArtifactValidationStatusRequestSuccess()),
                    catchError((error) =>
                        of(ArtifactIntelligenceRequestActions.updateArtifactValidationStatusRequestFailed({ error })),
                    ),
                ),
            ),
        ),
    );

    updateArtifactStaticValidationTypeRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ArtifactIntelligenceRequestActions.updateArtifactStaticValidationTypeRequest),
            switchMap(({ artifactId, staticValidationType }) =>
                this._artifactService.updateStaticValidation(artifactId, staticValidationType).pipe(
                    map(() => ArtifactIntelligenceRequestActions.updateArtifactStaticValidationTypeRequestSuccess()),
                    catchError((error) =>
                        of(
                            ArtifactIntelligenceRequestActions.updateArtifactStaticValidationTypeRequestFailed({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    updateArtifactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ArtifactIntelligenceRequestActions.updateArtifactRequest),
            switchMap(({ artifact }) =>
                this._artifactService.update(artifact).pipe(
                    map(() => ArtifactIntelligenceRequestActions.updateArtifactRequestSuccess()),
                    catchError((error) =>
                        of(ArtifactIntelligenceRequestActions.updateArtifactRequestFailed({ error })),
                    ),
                ),
            ),
        ),
    );

    createArtifactValidationRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ArtifactIntelligenceRequestActions.createArtifactValidationRequest),
            switchMap(({ artifactId }) =>
                this._artifactService.createArtifactValidation(artifactId).pipe(
                    map(() =>
                        ArtifactIntelligenceRequestActions.createArtifactValidationRequestSuccess({ artifactId }),
                    ),
                    catchError((error) =>
                        of(ArtifactIntelligenceRequestActions.createArtifactValidationRequestFailed({ error })),
                    ),
                ),
            ),
        ),
    );

    createAllArtifactControlValidationsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ArtifactIntelligenceRequestActions.createAllArtifactControlValidationsRequest),
            switchMap(({ artifact, controlDomains }) => {
                const relevantControlDomains = controlDomains.filter(
                    (control) => !control.artifactControlsValidated && !control.disabled && control.relevant,
                );

                if (relevantControlDomains.length === 0) {
                    return of(NOOP());
                }

                return from([
                    ...relevantControlDomains.map((control) => {
                        return this._artifactService.addControlValidation(artifact.id, {
                            controlDomainId: control.controlId,
                            controlId: null,
                            controlValidationStatus: ControlValidationStatus.PRESENT,
                            artifactValidationId: artifact.validation.id,
                            sourceType: SourceType.AUDITOR,
                            validationDetections: [
                                {
                                    type: ControlValidationDetectionType.NORMAL,
                                    notes: 'notes',
                                    sourceType: SourceType.AUDITOR,
                                },
                            ],
                        });
                    }),
                ]).pipe(
                    concatAll(),
                    last(),
                    map(() => ArtifactIntelligenceRequestActions.createAllArtifactControlValidationsRequestSuccess()),
                    catchError(() => EMPTY),
                );
            }),
        ),
    );

    createArtifactControlValidationRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ArtifactIntelligenceRequestActions.createArtifactControlValidationRequest),
            concatMap(({ artifactId, controlValidation }) =>
                this._artifactService.addControlValidation(artifactId, controlValidation).pipe(
                    map(() => ArtifactIntelligenceRequestActions.createArtifactControlValidationRequestSuccess()),
                    catchError((error) =>
                        of(
                            ArtifactIntelligenceRequestActions.createArtifactControlValidationRequestFailed({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    updateArtifactControlValidationRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ArtifactIntelligenceRequestActions.updateArtifactControlValidationRequest),
            concatMap(({ artifactId, controlValidationId, controlValidation }) =>
                this._artifactService.updateControlValidation(artifactId, controlValidationId, controlValidation).pipe(
                    map(() => ArtifactIntelligenceRequestActions.updateArtifactControlValidationRequestSuccess()),
                    catchError((error) =>
                        of(ArtifactIntelligenceRequestActions.updateArtifactControlValidationRequestFailed({ error })),
                    ),
                ),
            ),
        ),
    );

    deleteRequestArtifactControlValidationRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ArtifactIntelligenceRequestActions.deleteArtifactControlValidationRequest),
            switchMap(({ artifactId, controlValidationId, disableConfirmation }) =>
                (disableConfirmation
                    ? of(true)
                    : this._matConfirmDialogService.confirm({
                          title: 'Delete Validation',
                          message: 'Are you sure you want to delete this validation and all its detections?',
                          confirmLabel: 'Yes, delete it',
                          confirmButtonColor: 'warn',
                      })
                ).pipe(
                    switchMap((result) =>
                        result
                            ? this._artifactService.deleteControlValidation(artifactId, controlValidationId).pipe(
                                  map(() =>
                                      ArtifactIntelligenceRequestActions.deleteArtifactControlValidationRequestSuccess(),
                                  ),
                                  catchError((error) =>
                                      of(
                                          ArtifactIntelligenceRequestActions.deleteArtifactControlValidationRequestFailed(
                                              { error },
                                          ),
                                      ),
                                  ),
                              )
                            : of(ArtifactIntelligenceActions.deleteControlConfirmationModalDismissed()),
                    ),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _artifactService: ArtifactService,
        private _matConfirmDialogService: MatConfirmDialogService,
    ) {}
}
