<section class="sign-up-container" [ngClass]="submitSuccess ? 'success-feedback' : ''">
    <section class="registration-container">
        <div class="registration-header-logo">
            <img src="content/img/viso_logo_white.png" />
        </div>
        <div class="registration-panel">
            <app-registration-panel> </app-registration-panel>
        </div>
    </section>
    <section class="registration-form">
        <div class="registration-form-container" [ngClass]="{ 'success-feedback': submitSuccess }">
            <div *ngIf="!submitSuccess" class="dark form-title">Create your account</div>
            <div *ngIf="!submitSuccess" class="dark form-subtitle">
                This is the first step to assessing your third parties for free
            </div>
            <div *ngIf="submitSuccess" class="dark form-title text-center" data-pw="emailVerificationSentHeader">
                Email verification link sent!
            </div>

            <ng-template #successFeedback>
                <div class="success-feedback">
                    <div class="body-2 body-text">Thank you for creating an account.</div>
                    <div class="body-2 body-text">
                        Please check your email to verify your email address and complete your registration.
                    </div>
                </div>
            </ng-template>

            <form
                *ngIf="!submitSuccess; else successFeedback"
                name="userRegistrationForm"
                class="mt-4"
                [formGroup]="userRegistrationForm"
                (ngSubmit)="registerUser()"
            >
                <div class="row">
                    <div class="col-6">
                        <button
                            class="social-login-button social-login-google"
                            data-idp="google"
                            (click)="registerWithSocialLogin($event)"
                        >
                            Sign up with Google
                        </button>
                    </div>
                    <div class="col-6">
                        <button
                            class="social-login-button social-login-microsoft"
                            data-idp="microsoft"
                            (click)="registerWithSocialLogin($event)"
                        >
                            Sign up with Microsoft
                        </button>
                    </div>
                </div>
                <div class="row mx-4 my-4">
                    <span class="or-divider">or</span>
                </div>
                <div class="row">
                    <div class="col-12 form-group">
                        <label for="email">Username<span class="required-indicator">*</span></label>
                        <input
                            id="email"
                            type="text"
                            class="form-control"
                            formControlName="email"
                            placeholder="Provide a valid email address"
                            required
                            data-pw="emailInput"
                        />
                        <div class="text-error" *ngIf="showRequiredEmailError">Username is required.</div>
                        <div class="text-error" *ngIf="showInvalidEmailError">This is not a valid email address.</div>
                        <div class="text-error" *ngIf="showEmailMaxLengthError">
                            Email max length is 100 characters.
                        </div>
                    </div>
                    <div class="col-12 form-group">
                        <label for="password">Password<span class="required-indicator">*</span></label>
                        <span class="password-visibility">
                            <mat-icon
                                *ngIf="passwordVisibility"
                                (click)="passwordVisibility = !passwordVisibility"
                                fontSet="material-icons-outlined"
                                >visibility</mat-icon
                            >
                            <mat-icon
                                *ngIf="!passwordVisibility"
                                (click)="passwordVisibility = !passwordVisibility"
                                fontSet="material-icons-outlined"
                                >visibility_off</mat-icon
                            >
                        </span>

                        <input
                            id="password"
                            [type]="passwordVisibility ? 'text' : 'password'"
                            class="form-control"
                            formControlName="password"
                            placeholder="Create a password *"
                            required
                            data-pw="passwordInput"
                        />
                        <div
                            class="password-requirements"
                            [ngClass]="{
                                'password-requirements-not-met': showPasswordRequirementsError,
                                'password-requirements-met': hasPasswordRequirementsSuccess,
                            }"
                        >
                            <span [ngClass]="{ 'password-requirement-checked': hasPasswordMinLength }">
                                12+ characters
                            </span>
                            with 1 of each:
                            <span [ngClass]="{ 'password-requirement-checked': hasPasswordLowerCaseLetters }">
                                lowercase,</span
                            >
                            <span [ngClass]="{ 'password-requirement-checked': hasPasswordUpperCaseLetters }">
                                uppercase,</span
                            >
                            <span [ngClass]="{ 'password-requirement-checked': hasPasswordAtLeastOneNumber }">
                                number,</span
                            >
                            <span [ngClass]="{ 'password-requirement-checked': hasPasswordAtLeastOneSymbol }">
                                symbol
                            </span>
                        </div>
                        <div class="text-error" *ngIf="showRequiredPasswordError">Password is required.</div>
                        <div class="text-error" *ngIf="showEmailIncludedOnPasswordError">
                            Password cannot contain part of username.
                        </div>
                    </div>
                </div>

                <div class="text-error" *ngIf="showAccountCreationError" data-pw="accountCreationErrorMsg">
                    {{ accountCreationError }}
                </div>

                <button
                    type="submit"
                    mat-button
                    visoButton="primary"
                    color="primary"
                    class="full-width btn-lg mt-2 create-account"
                    data-pw="createAccountButton"
                    [disabled]="!isButtonEnabled || creatingAccount"
                    (mousedown)="registerUser()"
                >
                    <ng-container *ngIf="creatingAccount; else createAccount">
                        <div class="sk-three-bounce mx-auto my-0">
                            <div class="sk-child sk-bounce1"></div>
                            <div class="sk-child sk-bounce2"></div>
                            <div class="sk-child sk-bounce3"></div>
                        </div>
                    </ng-container>
                    <ng-template #createAccount>Sign Up</ng-template>
                </button>

                <div class="mt-3">
                    By proceeding, you agree to the
                    <a class="registration-link" href="https://visotrust.com/softwarelicenseagreement" target="_blank"
                        >Terms of Service</a
                    >
                    and
                    <a class="registration-link" href="https://visotrust.com/privacy-policy" target="_blank"
                        >Privacy Policy</a
                    >, and will opt in to receive communications from VISO TRUST.
                </div>

                <div class="mt-3">
                    Already have an account? <a class="registration-link" href="{{ environment.baseUrl }}">Log In</a>
                </div>
            </form>
        </div>
    </section>
</section>
