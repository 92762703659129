import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ArtifactFilePageLink,
    ArtifactType,
    ControlValidationDetectionType,
    OpenArtifactFileParams,
} from '../../../../../entities/artifact';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../../../dynamic-content/dynamic-content-injector';

import { Detection } from '../../../models/detection.model';
import { AssuranceLevels } from '../../../../../entities/relationship/models/security-control-domain';
import { AuditReportTypeName } from '../../../../../entities/audit-report';
import { openArtifactFile } from '../../../../../routes/request/redux/actions/artifacts.actions';
import { FrameworkMappingType } from '@entities/framework/models/framework-mapping.model';
import { ArtifactPageLinkViewType } from '../artifact-page-link/artifact-page-link.component';

@Component({
    selector: 'app-detection-card',
    templateUrl: './detection-card.component.html',
    styleUrls: ['./detection-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'cursor-pointer',
        '(click)': 'onDetectionClick.emit(detection, controlName, frameworkType)',
    },
})
export class DetectionCardComponent {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    @Input() detection: Detection;

    @Input() individualControlName: string;

    @Input() frameworkType: FrameworkMappingType;

    @Input() selected: boolean;

    @Output()
    onDetectionClick = new EventEmitter<Detection>();

    AssuranceLevels = AssuranceLevels;
    ArtifactType = ArtifactType;
    AuditReportType = AuditReportTypeName;
    DetectionType = ControlValidationDetectionType;
    ArtifactPageLinkViewType = ArtifactPageLinkViewType;

    constructor(private _store$: Store) {}

    openArtifactPage(event: any, artifact: OpenArtifactFileParams): void {
        event.stopPropagation();
        this._store$.dispatch(openArtifactFile(artifact));
    }

    getPages(pageList: string): ArtifactFilePageLink[] {
        if (!pageList) {
            return null;
        }
        const pageNumbers = pageList.replace(/, +/g, ',').split(',');

        return pageNumbers.map((page) => {
            if (isNaN(Number(page)) && page.includes('-')) {
                const pageRange = page.split('-');
                return {
                    initialPage: +pageRange[0],
                    finalPage: +pageRange[1],
                };
            } else if (typeof page === 'number') {
                return page;
            } else {
                return page;
            }
        });
    }
}
