import { Injectable } from '@angular/core';
import { Risk } from '../risk-assessment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    RelationshipRiskToleranceView,
    RiskTolerance,
    UpdateRiskToleranceSettingsRequest,
} from '@entities/risk-tolerance/risk-tolerance.model';
import { RiskThresholdData } from '../../routes/risk-model-settings/redux/risk-model-settings.selectors';

export function getRiskLevelFromScore(
    score: number,
    riskTolerance: RiskTolerance,
    riskToleranceThresholds: Map<RiskTolerance, Map<Risk, RiskThresholdData>>,
    currentRiskLevel?: Risk,
): Risk {
    if (currentRiskLevel === Risk.NO_CONTEXT) {
        return Risk.NO_CONTEXT;
    }
    let newRiskLevel: Risk = Risk.EXTREME;
    let riskToleranceRiskLevelThresholds: Map<Risk, RiskThresholdData> = riskToleranceThresholds.get(riskTolerance);
    for (const key of Object.keys(Risk)) {
        let currentRiskLevel: Risk = Risk[key];
        if (score < riskToleranceRiskLevelThresholds.get(currentRiskLevel)?.score) {
            newRiskLevel = currentRiskLevel;
            break;
        }
    }
    return newRiskLevel;
}

@Injectable({
    providedIn: 'root',
})
export class RiskToleranceService {
    getRiskLevelFromScore = getRiskLevelFromScore;

    private resourceUrl = 'api/risk-tolerance';

    constructor(private http: HttpClient) {}

    getRiskToleranceThresholds(): Observable<Map<RiskTolerance, Map<Risk, number>>> {
        return this.http.get<Map<RiskTolerance, Map<Risk, number>>>(`${this.resourceUrl}/thresholds`);
    }

    getRelationshipRiskDistribution(): Observable<Map<Risk, RelationshipRiskToleranceView[]>> {
        return this.http.get<Map<Risk, RelationshipRiskToleranceView[]>>(`${this.resourceUrl}/distribution`);
    }

    updateRiskToleranceSettings(request: UpdateRiskToleranceSettingsRequest): Observable<void> {
        return this.http.put<void>(`${this.resourceUrl}/risk-tolerance-settings`, request);
    }
}
