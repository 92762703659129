<div ng2FileDrop class="dropzone" [uploader]="uploader" [ngClass]="{ 'on-drag': (onDrag$ | async) }">
    <div class="on-drag-area border border-primary text-primary rounded justify-content-center align-items-center">
        <p>Drop files here.</p>
    </div>
    <div class="modal-header">
        <h4 class="modal-title">{{ titleText }}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="close()"></button>
    </div>
    <div class="modal-body py-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col border-end py-3">
                    <ng-container *ngIf="!invokedForArtifactIntelligence; else artifactIntelligenceLanguage">
                        <p>
                            Please provide documents that describe the security program as it relates to this
                            engagement.
                        </p>
                        <p>
                            Third party audit documents provide a high level of assurance and are preferred.
                            Certificates alone are insufficient.
                        </p>
                        <p>If a password is required to access the file, provide it by selecting the lock icon.</p>
                    </ng-container>
                    <ng-template #artifactIntelligenceLanguage>
                        <p>
                            Upload artifacts that describe your trust and security program(s). Include any required
                            passwords by selecting the key icon.
                        </p>
                    </ng-template>
                    <tabset class="viso-tabset" [justified]="true">
                        <tab heading="Third Party Audits">
                            <div class="helper-message py-2 px-3 my-3">
                                <em class="fa fa-lightbulb me-2"></em>
                                These provide a high level of assurance and are recommended.
                            </div>
                            <ul class="doc-types-list">
                                <li class="d-flex mb-3">
                                    <img
                                        src="content/img/artifact-types/csa-star-1.png"
                                        alt="csa-star-1"
                                        class="doc-type"
                                    />
                                    <div class="ps-3">
                                        CSA STAR Level 2 Certification/Attestation Report
                                        <ul>
                                            <li>Include associated CAIQ and below mentioned ISO/SOC documents</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="d-flex mb-3">
                                    <img src="content/img/artifact-types/iso.png" alt="iso-27001" class="doc-type" />
                                    <div class="ps-3">
                                        ISO 27001 Cert and Statement of Applicability
                                        <ul>
                                            <li>
                                                Provide a surveillance audit if certificates have been issued over 365
                                                days ago
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="d-flex mb-3">
                                    <img src="content/img/artifact-types/soc2.png" alt="soc-2" class="doc-type" />
                                    <div class="ps-3">
                                        SOC 2 Type 1/2 Report
                                        <ul>
                                            <li>Include a bridge letter if this was issued over 365 days ago</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="mb-3">
                                    <img src="content/img/artifact-types/pci.png" alt="soc-2" class="doc-type me-3" />
                                    PCI DSS Cert and Attestation of Compliance (On-site)
                                </li>
                                <li class="mb-3">
                                    <img
                                        src="content/img/artifact-types/hitrust.png"
                                        alt="soc-2"
                                        class="doc-type me-3"
                                    />
                                    HITRUST Certification/Validation Report
                                </li>
                            </ul>
                        </tab>
                        <tab heading="Penetration Tests">
                            <ul class="mt-3 doc-types-list">
                                <li class="mb-3">
                                    <span class="py-1 px-2 bg-light me-2">
                                        <em class="fas fa-clipboard-check text-muted"></em>
                                    </span>
                                    External, Internal and Application Penetration Test Reports
                                </li>
                                <li class="mb-3">
                                    <span class="py-1 px-2 bg-light me-2">
                                        <em class="fas fa-clipboard-check text-muted"></em>
                                    </span>
                                    Application Security Assessment Reports
                                </li>
                                <li class="mb-3">
                                    <span class="py-1 px-2 bg-light me-2">
                                        <em class="fas fa-clipboard-check text-muted"></em>
                                    </span>
                                    Executive summaries or redacted reports are acceptable
                                </li>
                            </ul>
                        </tab>
                        <tab heading="Other Attestations">
                            <ul class="mt-3 doc-types-list">
                                <li class="mb-3">
                                    <span class="py-1 px-2 bg-light me-2">
                                        <em class="fas fa-file-invoice text-muted"></em>
                                    </span>
                                    Published security policies/standards and table of contents
                                </li>
                                <li class="mb-3">
                                    <span class="py-1 px-2 bg-light me-2">
                                        <em class="fas fa-file-invoice text-muted"></em>
                                    </span>
                                    CSA STAR, Shared Assessments, PCI DSS, HITRUST or VSA self assessment
                                </li>
                                <li class="mb-3">
                                    <span class="py-1 px-2 bg-light me-2">
                                        <em class="fas fa-file-invoice text-muted"></em>
                                    </span>
                                    Security Overview Document
                                </li>
                            </ul>
                        </tab>
                    </tabset>
                </div>
                <div class="col py-3">
                    <div class="row">
                        <div class="col">
                            <button
                                class="btn btn-block p-3 mb-2 drag-drop-message-container"
                                (click)="fileInput.click()"
                            >
                                <span class="text-dark">
                                    Drag and Drop or <span class="text-bold text-primary">Browse</span>
                                </span>
                            </button>
                            <input #fileInput type="file" ng2FileSelect multiple class="d-none" [uploader]="uploader" />
                        </div>
                    </div>
                    <ng-container *jhiHasAnyAuthority="Roles.Auditor">
                        <div class="row mt-3" *ngIf="isThereArtifactQueue">
                            <div class="col">
                                <form [formGroup]="artifactOwnershipFormGroup" class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="artifactOwnership">Permissions</label>
                                            <ngx-select
                                                optionValueField="enumValue"
                                                optionTextField="name"
                                                formControlName="artifactOwnership"
                                                name="artifactOwnership"
                                                [items]="ownershipLevels$ | async"
                                            >
                                            </ngx-select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group" *ngIf="showThirdPartyEmailField">
                                            <label for="thirdPartyEmail">Contact Email Address (required)</label>
                                            <input
                                                id="thirdPartyEmail"
                                                type="email"
                                                class="form-control"
                                                formControlName="thirdPartyEmail"
                                                required
                                            />
                                        </div>
                                        <div class="form-group" *ngIf="showSourceField">
                                            <label for="source">Source/Notes (required)</label>
                                            <input
                                                id="source"
                                                type="text"
                                                class="form-control"
                                                formControlName="source"
                                                required
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row mt-3">
                        <div class="col">
                            <ng-container *ngFor="let item of uploader.queue">
                                <app-document-file
                                    class="mb-2"
                                    [documentFileName]="item?.file?.name"
                                    [disabled]="uploadingFiles$ | async"
                                    [includePassword]="includePassword"
                                    (onPasswordSaved)="setArtifactPassword($event, item)"
                                    (onFileRemoved)="removeItem(item)"
                                ></app-document-file>
                            </ng-container>
                        </div>
                    </div>
                    <ng-container *ngIf="showAddURLArtifactSection$ | async">
                        <div class="row mt-3">
                            <div class="col">
                                <hr />
                            </div>
                            <div class="col-auto">
                                <span class="text-muted d-inline-block mx-4 mt-1"> or </span>
                            </div>
                            <div class="col">
                                <hr />
                            </div>
                        </div>
                        <div class="row mt-2 mb-2">
                            <div class="col">
                                <div class="form-group bg-light">
                                    <label class="d-none" for="addUrl">Add URL to a webpage</label>
                                    <div class="input-group mb-3">
                                        <input
                                            id="addUrl"
                                            class="form-control border-0 bg-light"
                                            placeholder="Add URL to a webpage"
                                            type="text"
                                            [formControl]="addURLArtifactFormControl"
                                        />
                                        <div class="input-group-append">
                                            <button
                                                type="button"
                                                mat-button
                                                visoButton="text"
                                                color="primary"
                                                [disabled]="disabledAddURLArtifactFormControl"
                                                (click)="addUrlArtifact()"
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row">
                        <div class="col">
                            <ng-container *ngFor="let urlArtifact of urlArtifacts; let index = index">
                                <app-document-file
                                    class="mb-2"
                                    [documentFileName]="urlArtifact"
                                    [isUrl]="true"
                                    [disabled]="uploadingFiles$ | async"
                                    [includePassword]="false"
                                    (onFileRemoved)="removeUrlArtifact(index)"
                                ></app-document-file>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row" *ngIf="!isThereArtifactQueue">
                        <div class="col">No artifacts uploaded yet.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer text-end">
        <p *ngIf="footerMessage$ | async as footerMessage" class="me-4 mb-0">{{ footerMessage }}</p>
        <button color="primary" class="btn btn-primary" [disabled]="uploadDisabled$ | async" (click)="upload()">
            Upload
        </button>
    </div>
</div>
