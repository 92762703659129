import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { WizardComponent } from '@viso-trust/angular-archwizard';
import { isEqual } from 'lodash-es';
import { Relationship } from '@entities/relationship';
import { ContextType } from '@entities/context-type';
import { DataType, DataTypeCategory } from '@entities/data-type';
import { Risk } from '@entities/risk-assessment';
import { OrgDataSensitivityConfig } from '../../../../admin/client-profile/client-profile.model';
import { getUserAccount } from '../../../session/redux/session.selectors';
import {
    calculateInherentRisk,
    calculateInherentRiskSuccess,
    updateRelationshipFailed,
    updateRelationshipSuccess,
    updateVendorRelationship,
    updateVendorRelationshipCancel,
} from '../../redux/actions/relationship.actions';
import { ItemsFormGroup, RelationshipContextFormGroup } from '../models/relationship-context-form';
import { DataTypesSensitivityHash } from '../models/hashes';

@Component({
    selector: 'app-relationship-context-modal',
    templateUrl: './relationship-context-modal.component.html',
    styleUrls: ['./relationship-context-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipContextModalComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    relationship: Relationship;

    @Input({ required: true })
    businessCases: ContextType[];

    @Input({ required: true })
    dataTypes: DataType[];

    @Input({ required: true })
    profileDataSensitivityLevels: OrgDataSensitivityConfig[];

    relationshipContextFormGroup: FormGroup<RelationshipContextFormGroup>;
    inherentRisk$ = new BehaviorSubject<Risk>(null);
    dataTypesRegistered = false;
    hideCustomerDataTab: boolean;
    currentUserOrgName$: Observable<string>;
    submitting$: Observable<boolean>;

    @ViewChild(WizardComponent)
    private _wizard: WizardComponent;
    private _organizationDataTypes: DataType[];
    private _customerDataTypes: DataType[];
    private _unsub$ = new Subject<void>();

    constructor(
        private _activeModal: NgbActiveModal,
        private _store$: Store,
        private _actions$: Actions,
        private _fb: FormBuilder,
    ) {}

    get currentStep(): number {
        return this._wizard?.currentStepIndex || 0;
    }

    get isFirstStep(): boolean {
        return this.currentStep === 0;
    }

    get isLastStep(): boolean {
        return this.currentStep + 1 === this._wizard?.wizardSteps.length;
    }

    get businessCasesFormGroup(): FormGroup<ItemsFormGroup<ContextType>> {
        return this.relationshipContextFormGroup.controls.businessCases;
    }

    get customerDataTypesFormGroup(): FormGroup<ItemsFormGroup<DataType>> {
        return this.relationshipContextFormGroup.controls.customerDataTypes;
    }

    get organizationDataTypesFormGroup(): FormGroup<ItemsFormGroup<DataType>> {
        return this.relationshipContextFormGroup.controls.organizationDataTypes;
    }

    get organizationDataTypes(): DataType[] {
        return this._organizationDataTypes;
    }

    get customerDataTypes(): DataType[] {
        return this._customerDataTypes;
    }

    get thirdPartyOrgName(): string {
        return this.relationship.vendorName;
    }

    ngOnInit(): void {
        this._organizationDataTypes = this.dataTypes.filter(
            (dataType) => dataType.category === DataTypeCategory.ORGANIZATION,
        );
        this._customerDataTypes = this.dataTypes.filter(
            (dataType) => dataType.category === DataTypeCategory.CUSTOMER_OR_PARTNER,
        );
        this.relationshipContextFormGroup = this._fb.group({
            businessCases: this._fb.group({
                items: this._fb.array(
                    this.businessCases.map((businessCase) =>
                        this._fb.group({
                            ref: this._fb.control(businessCase),
                            checked: this._fb.control(
                                !!this.relationship.contextTypes?.find((ct) => ct.id === businessCase.id),
                            ),
                        }),
                    ),
                ),
            }),
            customerDataTypes: this._fb.group({
                items: this._fb.array(
                    this._customerDataTypes.map((dataType) =>
                        this._fb.group({
                            ref: this._fb.control(dataType),
                            checked: this._fb.control(
                                !!this.relationship.dataTypes?.find((ct) => ct.id === dataType.id),
                            ),
                        }),
                    ),
                ),
            }),
            organizationDataTypes: this._fb.group({
                items: this._fb.array(
                    this._organizationDataTypes.map((dataType) =>
                        this._fb.group({
                            ref: this._fb.control(dataType),
                            checked: this._fb.control(
                                !!this.relationship.dataTypes?.find((ct) => ct.id === dataType.id),
                            ),
                        }),
                    ),
                ),
            }),
        });

        this.currentUserOrgName$ = this._store$.select(getUserAccount).pipe(map((account) => account.orgName));

        this.submitting$ = merge(
            this._actions$.pipe(
                ofType(updateVendorRelationship),
                map(() => true),
            ),
            this._actions$.pipe(
                ofType(updateVendorRelationshipCancel, updateRelationshipSuccess, updateRelationshipFailed),
                map(() => false),
            ),
        );

        this.hideCustomerDataTab = !this._customerDataTypes.length;

        this.inherentRisk$.next(this.relationship?.latestRiskAssessment?.inherentRisk);

        if (
            !!this.relationship?.latestRiskAssessment?.inherentRisk &&
            this.relationship?.latestRiskAssessment?.inherentRisk !== Risk.NO_CONTEXT
        ) {
            this.dataTypesRegistered = true;
        }

        merge(
            this.businessCasesFormGroup.valueChanges,
            this.organizationDataTypesFormGroup.valueChanges,
            this.customerDataTypesFormGroup.valueChanges,
        )
            .pipe(
                debounceTime(500),
                map(() => ({
                    contextTypes: this.getBusinessCasesPayload().map((x) => x.id),
                    dataTypes: this.getDataTypesPayload().map((x) => x.id),
                })),
                distinctUntilChanged((a, b) => isEqual(a, b)),
                takeUntil(this._unsub$),
            )
            .subscribe((initialRiskPayload) => {
                this.dataTypesRegistered = initialRiskPayload.dataTypes.length > 0;
                this._store$.dispatch(calculateInherentRisk(initialRiskPayload));
            });

        this._actions$
            .pipe(ofType(calculateInherentRiskSuccess), takeUntil(this._unsub$))
            .subscribe((action) => this.inherentRisk$.next(action.data));

        this._actions$.pipe(ofType(updateRelationshipSuccess)).subscribe(() => this.close());
    }

    saveContext(): void {
        const relationship = this.getSaveContextPayload();
        this._store$.dispatch(updateVendorRelationship({ relationship }));
    }

    next(): void {
        this._wizard.goToNextStep();
    }

    previous(): void {
        this._wizard.goToPreviousStep();
    }

    close(): void {
        this._activeModal.close();
    }

    sortDataTypesPerSensitivity(dataTypes: DataType[]): DataTypesSensitivityHash {
        return dataTypes.reduce((acc, dataType, currentIndex) => {
            const tempDataType = {
                index: currentIndex,
                dataType,
            };
            return {
                ...acc,
                ...(acc[dataType.sensitivityLevel]
                    ? { [dataType.sensitivityLevel]: [...acc[dataType.sensitivityLevel], tempDataType] }
                    : { [dataType.sensitivityLevel]: [tempDataType] }),
            };
        }, {} as DataTypesSensitivityHash);
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private getSaveContextPayload(): Relationship {
        const contextTypes = this.getBusinessCasesPayload();
        const dataTypes = this.getDataTypesPayload();
        return {
            ...this.relationship,
            contextTypes,
            dataTypes,
        };
    }

    private getBusinessCasesPayload(): ContextType[] {
        const items = this.businessCasesFormGroup.value?.items;
        return items.filter((item) => item.checked).map((item) => item.ref);
    }

    private getDataTypesPayload(): DataType[] {
        const customerDataTypes = this.customerDataTypesFormGroup.value;
        const organizationDataTypes = this.organizationDataTypesFormGroup.value;
        return [...customerDataTypes.items, ...organizationDataTypes.items]
            .filter((item) => item.checked)
            .map((item) => item.ref);
    }
}
