import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NetworkExposureRiskAdvisory, RiskAdvisoryTypeLabels } from '@entities/risk-advisory';
import { NavigationExtras } from '@angular/router';

@Component({
    selector: 'app-risk-advisory-details',
    templateUrl: './risk-advisory-details.component.html',
    styleUrls: ['./risk-advisory-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskAdvisoryDetailsComponent implements OnInit {
    riskAdvisory: NetworkExposureRiskAdvisory;
    RiskAdvisoryTypeLabels = RiskAdvisoryTypeLabels;

    private readonly _relationshipSearchDefaultQueryParams: string = 'drStatus:[NOT_ONBOARDED|ONBOARDED]';

    get viewRelationshipUrl(): { commands: any[]; extras?: NavigationExtras } {
        if (!!this.riskAdvisory.uniqueRelationshipId) {
            return { commands: ['/requests', this.riskAdvisory.uniqueRelationshipId] };
        } else {
            return {
                commands: ['/relationships'],
                extras: {
                    queryParams: {
                        search: `${this._relationshipSearchDefaultQueryParams},vendorOrgId:${this.riskAdvisory.orgId}`,
                    },
                },
            };
        }
    }

    get viewNetworkGraphUrl(): { commands: any[]; extras?: NavigationExtras } {
        if (this.riskAdvisory.isVendor) {
            return {
                commands: ['/relationships'],
                extras: {
                    queryParams: {
                        search: this._relationshipSearchDefaultQueryParams,
                        view: 'graph',
                        networkExposureOrgId: this.riskAdvisory.orgId,
                    },
                },
            };
        } else {
            return {
                commands: ['/relationships'],
                extras: {
                    queryParams: {
                        search: this._relationshipSearchDefaultQueryParams,
                        view: 'graph',
                        networkExposureOrgId: this.riskAdvisory.orgId,
                    },
                },
            };
        }
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: NetworkExposureRiskAdvisory) {}

    ngOnInit(): void {
        this.riskAdvisory = this.data;
    }

    getDescription(riskAdvisory: NetworkExposureRiskAdvisory): string {
        if (riskAdvisory.isVendor && !riskAdvisory.description) {
            return `You have a relationship with this vendor.
                 Find the details of this advisory in the vendor breach statement or view your relationship with this vendor.`;
        }

        if (riskAdvisory.isNthParty && !riskAdvisory.description) {
            return `This vendor is a part of your network.
                Find the details of this advisory in the vendor breach statement or review in your risk network.`;
        }

        if (!riskAdvisory.description) {
            return 'You do not have a relationship with this vendor, but you can find the details of this advisory in the vendor breach statement.';
        }

        return riskAdvisory.description;
    }
}
