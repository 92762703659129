import { LongRunningTaskName } from '../../enums/long-running-task';

export const longRunningTaskFeatureKey = 'longRunningTask';

export interface LongRunningTaskState {
    runningTasksForOrg: LongRunningTaskName[];
}

export const initialState: LongRunningTaskState = {
    runningTasksForOrg: [],
};
