<app-custom-stepper *ngIf="!!recommendations.length">
    @for (recommendation of recommendations; track recommendation.id; let i = $index) {
        <cdk-step [id]="i">
            <app-full-page-file-drop (onFileDropped)="emitUpload(recommendation.id, $event[0])" />
            <div class="container-top-half">
                <div class="row">
                    <div class="col-xl-4 col fixed-height">
                        <p>Additional questions ({{ i + 1 }} of {{ recommendations.length }})</p>
                        <h1 class="assessment-collection-step-title mb-3">{{ recommendation.title }}</h1>
                        @if (!!recommendation.subtitle?.length) {
                            <p class="mb-3" [innerHtml]="recommendation.subtitle | safeHtml"></p>
                        }
                        @if (!!recommendation.expectedArtifactsLabels?.length) {
                            <div class="mb-3">
                                <p class="text-bold">What artifacts are we looking for?</p>
                                <ul class="ps-4">
                                    @for (
                                        expectedArtifact of recommendation.expectedArtifactsLabels;
                                        track expectedArtifact
                                    ) {
                                        <li>{{ expectedArtifact }}</li>
                                    }
                                </ul>
                            </div>
                        }
                    </div>

                    <div class="col-auto ps-0 pb-4">
                        <mat-divider class="h-100" [vertical]="true"></mat-divider>
                    </div>

                    <input
                        #fileInput
                        data-pw="fileInput"
                        type="file"
                        ng2FileSelect
                        class="d-none"
                        [uploader]="uploader"
                        (onFileSelected)="emitUpload(recommendation.id, $event[0])"
                    />

                    <div class="col p-3 fixed-height">
                        <div class="row pb-5">
                            <div class="col">
                                <div class="mb-3">
                                    <strong> Upload an artifact... </strong>
                                </div>
                                <app-artifact-list
                                    [rtpFileArtifacts]="getAttestationArtifactAsArray(recommendation)"
                                    (setPassword)="setPassword.emit($event)"
                                    (uploadClicked)="fileInput.click()"
                                    (removeArtifact)="removeArtifact.emit($event)"
                                />
                                @if (classificationIsIncorrect(recommendation.attestationArtifact)) {
                                    <p class="text-error text-bold">
                                        {{ getIncorrectClassificationLabel(recommendation) }}
                                    </p>
                                }
                            </div>
                        </div>
                        <div class="row pb-4">
                            <div class="col">
                                <strong [ngClass]="{ 'text-muted': !!recommendation.attestationArtifact }">
                                    ...or attest to one of the following:
                                </strong>
                                <mat-radio-group
                                    [formControl]="currentAttestationValueControl"
                                    [value]="recommendation.attestationValue"
                                    class="d-flex flex-column"
                                    color="primary"
                                    data-pw="attestationRadioButtonGroup"
                                >
                                    @for (
                                        attestationOption of getAttestationOptions(recommendation);
                                        track attestationOption.value
                                    ) {
                                        <mat-radio-button
                                            class="mt-2"
                                            [value]="attestationOption.value"
                                            data-pw="dontHaveAttestationOption"
                                        >
                                            {{ attestationOption.label }}
                                        </mat-radio-button>
                                    }
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end container-bottom-half">
                <button
                    mat-stroked-button
                    color="primary"
                    class="me-auto"
                    [disabled]="backDisabled(recommendation)"
                    (click)="goBack(recommendation)"
                >
                    Go back
                </button>
                <button
                    mat-flat-button
                    color="primary"
                    [disabled]="continueDisabled(recommendation)"
                    (click)="continue(recommendation)"
                    data-pw="continueButton"
                >
                    Continue
                </button>
            </div>
        </cdk-step>
    }
</app-custom-stepper>
