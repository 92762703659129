<form [formGroup]="contactForm" (ngSubmit)="save()">
    <div class="modal-header">
        <h4 class="modal-title" id="myContactLabel">User Details</h4>
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>

    <div class="modal-body p-4">
        <div class="form-group required">
            <label for="field_first_name">First Name</label>
            <input
                type="text"
                class="form-control"
                name="firstName"
                id="field_first_name"
                data-pw="firstNameInput"
                formControlName="firstName"
                placeholder="Enter first name"
                required
            />
        </div>
        <div class="form-group required">
            <label for="field_last_name">Last Name</label>
            <input
                type="text"
                class="form-control"
                name="lastName"
                id="field_last_name"
                data-pw="lastNameInput"
                formControlName="lastName"
                placeholder="Enter last name"
                required
            />
        </div>
        <div class="form-group required">
            <label for="field_email">Email Address</label>
            <input
                type="text"
                class="form-control"
                name="email"
                id="field_email"
                data-pw="emailInput"
                formControlName="email"
                placeholder="Enter email"
                required
            />
            <span class="text-error" *ngIf="showContactAlreadyExistsError"
                >A user with this email address already exists.</span
            >
            <span class="text-error" *ngIf="showInvalidEmailDomainError"
                >The users domain is invalid. Valid domains: <span>{{ currentUser?.orgDomains?.join(',') }}</span></span
            >
            <span
                class="text-error"
                *ngIf="!showContactAlreadyExistsError && !showInvalidEmailDomainError && showInvalidEmailSyntaxError"
            >
                Invalid email address format.
            </span>
        </div>
        <div class="form-group required">
            <label for="field_businessUnit">Business Unit</label>
            <ngx-select
                optionValueField="id"
                optionTextField="name"
                formControlName="businessUnitId"
                [items]="businessUnits$ | async"
                [isFocused]="false"
                placeholder="Select business unit"
                data-pw="selectBusinessUnitInput"
            >
            </ngx-select>
        </div>
        <div class="form-group required" *ngIf="isUserManagedByIdp && isUserOrgAdminOrOrgUserOrReadOnly">
            <label for="field_Role">Role</label>
            <ngx-select
                optionValueField="enumValue"
                optionTextField="name"
                formControlName="authorities"
                [items]="roles"
                [isFocused]="false"
                placeholder="Select user role"
                data-pw="selectUserRoleInput"
            >
            </ngx-select>
        </div>
        <div class="form-group required">
            <mat-checkbox
                [disabled]="!isUserOrgAdmin"
                formControlName="defaultSubscriber"
                id="field_defaultSubscriber"
                data-pw="defaultSubscriberCheckbox"
                color="primary"
            >
                <span>Default Subscriber</span>
            </mat-checkbox>
        </div>
    </div>

    <div class="modal-footer d-flex justify-content-end main-action">
        <ng-container *jhiHasAnyAuthority="Roles.OrgAdmin">
            <button
                *ngIf="contact.id && !contact.managedByIdp"
                type="button"
                mat-button
                visoButton="secondary"
                color="warn"
                class="me-auto"
                data-dismiss="modal"
                (click)="deleteContact()"
            >
                <span class="text-bold">Delete User</span>
            </button>
        </ng-container>
        <button
            type="button"
            mat-button
            visoButton="secondary"
            class="float-end"
            data-dismiss="modal"
            (click)="clear()"
        >
            <span>Cancel</span>
        </button>
        <button
            type="submit"
            [disabled]="contactForm.invalid"
            toggleDisableOnActions
            [disablingActions]="[ContactActions.UpdateContactRequest, ContactActions.CreateContactRequest]"
            [enablingActions]="[
                ContactActions.CreateContactRequestFailed,
                ContactActions.UpdateContactRequestFailed,
                ContactActions.DeleteContactRequestFailed,
                ContactActions.CreateContactRequestSuccess,
                ContactActions.UpdateContactRequestSuccess,
                ContactActions.DeleteContactRequestSuccess,
            ]"
            (click)="save()"
            class="float-end"
            mat-button
            visoButton="primary"
            data-pw="saveContactButton"
        >
            <span *ngIf="!contact.id">Create User</span>
            <span *ngIf="contact.id">Save</span>
        </button>
    </div>
</form>
