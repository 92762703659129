import { BaseEntity } from './../../shared';

export enum VisoUserRole {
    Admin = 'ROLE_ADMIN',
    Auditor = 'ROLE_VISO_AUDITOR',
    OrgAdmin = 'ROLE_ORG_ADMIN',
    Support = 'ROLE_VISO_SUPPORT',
    OrgUser = 'ROLE_ORG_USER',
    ReadOnly = 'ROLE_ORG_READ_ONLY',
}

export const enum UserType {
    'LEGAL',
    'RISK',
    'AUDITOR',
    'SECURITY',
    'ENGINEER',
    'ADMIN',
    'VENDOR',
    'CLIENT',
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum VisoUserGroups {
    SUBSCRIBERS,
    AUDITORS,
    THIRD_PARTY_PRIMARY,
    BUSINESS_OWNER,
}

export enum VisoUserGroupLabels {
    SUBSCRIBERS = 'Subscribers',
    AUDITORS = 'Auditors',
    THIRD_PARTY_PRIMARY = '3p',
    BUSINESS_OWNER = 'Business Owner',
}

export class VisoUser implements BaseEntity {
    constructor(
        public id?: number,
        public firstName?: string,
        public lastName?: string,
        public authorities?: VisoUserRole[],
        public langKey?: string,
        public type?: UserType,
        public avatarUrl?: string,
        public email?: string,
        public phone?: string,
        public bio?: string,
        public status?: UserStatus,
        public orgId?: number,
        public orgName?: string,
        public orgDomains?: string[],
        public createdDate?: Date,
        public updatedDate?: Date,
        public clientLicense?: ClientLicenseView,
        public businessUnitId?: number,
        public businessUnitName?: string,
        public managedByIdp?: boolean,
        public subscriptionCount?: number,
        public businessOwnerCount?: number,
        public defaultSubscriber?: boolean,
    ) {}
}

export interface VisoUserMetadata {
    firstName: string;
    lastName: string;
    orgName: string;
}

export interface ClientLicenseView {
    id: number;
    licenseType: LicenseType;
    maxRelationshipsAssessed: number;
    maxRelationshipsCreated: number;
    relationshipsAssessedCount: number;
    relationshipsCreatedCount: number;
    maxRelationshipsOnboarded: number;
    relationshipsOnboardedCount: number;
    maxRelationshipsWithLifecycleManagement: number;
    relationshipsWithLifecycleManagementCount: number;
    maxConciergeAssessments: number;
    conciergeAssessmentCount: number;
    maxSupplementalQuestionnaireQuestions: number;
    supplementalQuestionnaireQuestionCount: number;
    maxTrustQuestionnaires: number;
    trustQuestionnairesCount: number;
    maxTrustAiQa: number;
    maxTprmAiQa: number;
    tprmAiQaCount: number;
    trustAiQaCount: number;
    contractStartDate: Date;
    contractEndDate: Date;
}

export interface ClientLicenseDefaultView {
    licenseType: LicenseType;
    maxRelationshipsAssessed: number;
    maxRelationshipsCreated: number;
    maxRelationshipsOnboarded: number;
    maxRelationshipsWithLifecycleManagement: number;
    maxSupplementalQuestionnaireQuestions: number;
    maxTrustQuestionnaires: number;
    maxTrustAiQa: number;
    maxTprmAiQa: number;
    contractStartDate: Date;
}

export enum LicenseType {
    ENTERPRISE = 'ENTERPRISE',
    TRIAL = 'TRIAL',
    NO_LOGIN = 'NO_LOGIN',
    PROD_TRIAL = 'PROD_TRIAL',
    STARTER = 'STARTER',
}

export enum LicenseTypeLabel {
    ENTERPRISE = 'Enterprise',
    TRIAL = 'Trial',
    NO_LOGIN = 'No Login',
    PROD_TRIAL = 'Prod Trial',
    STARTER = 'Starter',
}

export interface UserProfile {
    visoUserId?: number;
    showFirstAssessmentCreatedMessage?: boolean;
    showFirstLastNameForm?: boolean;
    showOrganizationNameForm?: boolean;
    showWelcomeMessage?: boolean;
    showVendorTierWalkthrough?: boolean;
    showInstantAnalysisRLPWalkthrough?: boolean;
    showInstantAnalysisRDPWalkthrough?: boolean;
    showRiskAnalysisWalkthrough?: boolean;
}
