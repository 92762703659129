import { Route } from '@angular/router';
import { PublicSearchComponent } from './public-search.component';

export const publicSearchRoute: Route = {
    path: 'public-search',
    component: PublicSearchComponent,
    data: {
        pageTitle: 'Public Info Search',
    },
};
