import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    VendorDetailsResponse,
    VendorDetailsStatsResponse,
} from '../../../../../routes/request/models/vendor-details.model';
import { RelationshipVendorDetailsStatsResponse } from '@entities/relationship';
import { AuditReportTypeName } from '@entities/audit-report';

@Component({
    selector: 'app-vendor-artifacts',
    templateUrl: './vendor-artifacts.component.html',
    styleUrl: './vendor-artifacts.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorArtifactsComponent {
    @Input({ required: true })
    vendorDetails: VendorDetailsResponse;

    @Input({ required: true })
    set vendorDetailsStats(value: VendorDetailsStatsResponse) {
        this.relationshipVendorDetailsStats = value as RelationshipVendorDetailsStatsResponse;
    }

    relationshipVendorDetailsStats: RelationshipVendorDetailsStatsResponse;

    protected readonly AuditReportTypeName = AuditReportTypeName;
}
