import { GuidedTour, Orientation } from 'ngx-guided-tour';

export const vendorTieringTour = (): GuidedTour => {
    return {
        tourId: 'vendor-tiering',
        steps: [
            {
                title: 'Managing tiers',
                content: "Scale your tiers up or down at any time by clicking on 'Manage tiers'.",
                selector: '#manage-tiers-button',
                orientation: Orientation.Left,
                action: () => {
                    const checkbox = document.getElementById('relationship-checkbox') as HTMLInputElement;
                    if (!checkbox.checked) {
                        checkbox.click();
                    }
                },
            },
            {
                title: 'Assigning tiers',
                content: 'To assign a vendor to a tier, first select one or more relationships.',
                selector: '#relationships-table',
                orientation: Orientation.TopLeft,
            },
            {
                title: 'Assigning tiers',
                content: "Then, choose 'Assign to' and select the tier of your choice.",
                selector: '#mat-menu-panel-2 > div',
                orientation: Orientation.Left,
                action: () => {
                    document.getElementById('assign-vendor-tier-button').click();
                    // Hack to refresh the button highlight after graph loads.
                    setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
                },
                closeAction: () => {
                    document.getElementById('assign-vendor-tier-button').click();
                },
            },
            {
                title: 'Navigating between tiers',
                content: 'Click on any of the tier tabs at the top of the page to navigate between tiers.',
                selector: '#vendor-tiers-nav',
                orientation: Orientation.BottomLeft,
            },
        ],
    };
};
