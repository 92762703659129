import { createAction, props } from '@ngrx/store';
import { LongRunningTaskName } from '../../enums/long-running-task';

export enum LongRunningTaskActions {
    GET_IN_PROGRESS_LONG_RUNNING_TASKS_REQUEST = '[Long Running Task] Get In Progress Long Running Tasks Request',
    GET_IN_PROGRESS_LONG_RUNNING_TASKS_REQUEST_SUCCESS = '[Long Running Task] Get In Progress Long Running Tasks Request Success',
    GET_IN_PROGRESS_LONG_RUNNING_TASKS_REQUEST_FAILED = '[Long Running Task] Get In Progress Long Running Tasks Request Failed',
}

export const getInProgressLongRunningTasksRequest = createAction(
    LongRunningTaskActions.GET_IN_PROGRESS_LONG_RUNNING_TASKS_REQUEST,
);

export const getInProgressLongRunningTasksRequestSuccess = createAction(
    LongRunningTaskActions.GET_IN_PROGRESS_LONG_RUNNING_TASKS_REQUEST_SUCCESS,
    props<{ tasks: LongRunningTaskName[] }>(),
);

export const getInProgressLongRunningTasksRequestFailed = createAction(
    LongRunningTaskActions.GET_IN_PROGRESS_LONG_RUNNING_TASKS_REQUEST_FAILED,
);
