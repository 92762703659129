<form name="editForm" role="form" novalidate (ngSubmit)="update()" #editForm="ngForm">
    <div class="modal-header">
        <h4 class="modal-title" id="myOrgLabel">Edit Public Profile</h4>
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>
    <div class="modal-body d-flex row">
        <div class="col col-lg-6">
            <div class="form-group">
                <label class="form-control-label" for="field_name">Name</label>
                <input type="text" class="form-control" name="name" id="field_name" [(ngModel)]="org.name" required />
                <div [hidden]="!(editForm.controls.name?.dirty && editForm.controls.name?.invalid)">
                    <small class="form-text text-error" [hidden]="!editForm.controls.name?.errors?.required">
                        This field is required.
                    </small>
                </div>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="field_legalName">Legal Name</label>
                <input
                    type="text"
                    class="form-control"
                    name="legalName"
                    id="field_legalName"
                    [(ngModel)]="org.legalName"
                />
            </div>
            <div class="form-group">
                <label class="form-control-label" for="field_homepage">Homepage</label>
                <input
                    type="text"
                    class="form-control"
                    name="homepage"
                    id="field_homepage"
                    [(ngModel)]="org.homepage"
                    urlValidation
                />
                <div [hidden]="!(editForm.controls.homepage?.dirty && editForm.controls.homepage?.invalid)">
                    <small class="form-text text-error" [hidden]="!editForm.controls.homepage?.errors?.pattern">
                        Please provide a valid URL
                    </small>
                </div>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="field_description">Description</label>
                <textarea
                    type="text"
                    class="form-control"
                    name="description"
                    id="field_description"
                    [(ngModel)]="org.description"
                ></textarea>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="field_industry">Industry</label>
                <input
                    type="text"
                    class="form-control"
                    name="industry"
                    id="field_industry"
                    [(ngModel)]="org.industry"
                />
            </div>
            <div class="form-group">
                <label class="form-control-label">Company Size</label>
                <select class="form-control" name="orgSize" id="orgSize" [(ngModel)]="org.orgSize">
                    <option value="" disabled selected>Select Your Company's Size</option>
                    <option *ngFor="let orgSize of OrgSizeLabels | enumToArray: OrgSizeLabels" [value]="orgSize.value">
                        {{ orgSize.label }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="field_privacyPolicy">Privacy Policy</label>
                <input
                    type="text"
                    class="form-control"
                    name="privacyPolicy"
                    id="field_privacyPolicy"
                    [(ngModel)]="org.privacyPolicyUrl"
                    urlValidation
                />
                <div [hidden]="!(editForm.controls.privacyPolicy?.dirty && editForm.controls.privacyPolicy?.invalid)">
                    <small class="form-text text-error" [hidden]="!editForm.controls.privacyPolicy?.errors?.pattern">
                        Please provide a valid URL
                    </small>
                </div>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="field_domains">Domains</label>
                <input
                    type="text"
                    class="form-control"
                    name="domains"
                    id="field_domains"
                    [ngModel]="org.domains"
                    required
                    disabled
                />
                <div [hidden]="!(editForm.controls.domain?.dirty && editForm.controls.domain?.invalid)">
                    <small class="form-text text-error" [hidden]="!editForm.controls.domain?.errors?.required">
                        This field is required.
                    </small>
                </div>
            </div>
        </div>
        <div class="col col-lg-6">
            <div class="form-group">
                <label class="form-control-label" for="field_streetAddress">Street Address</label>
                <input
                    type="text"
                    class="form-control"
                    name="streetAddress"
                    id="field_streetAddress"
                    [(ngModel)]="org.address"
                />
            </div>
            <div class="form-group">
                <label class="form-control-label" for="field_city">City</label>
                <input type="text" class="form-control" name="city" id="field_city" [(ngModel)]="org.city" />
            </div>
            <div class="form-group">
                <label class="form-control-label" for="field_state">State</label>
                <input type="text" class="form-control" name="state" id="field_state" [(ngModel)]="org.region" />
            </div>
            <div class="form-group">
                <label class="form-control-label" for="field_zip">ZIP or postal code</label>
                <input type="text" class="form-control" name="zip" id="field_zip" [(ngModel)]="org.postalCode" />
            </div>
            <div class="form-group">
                <label class="form-control-label">Country or region</label>
                <select class="form-control" name="country" id="country" [(ngModel)]="org.countryCode">
                    <option value="" disabled selected>Select a Country or region</option>
                    <option *ngFor="let country of countries" [value]="country.code3">{{ country.name }}</option>
                </select>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="field_support">Support Email</label>
                <input
                    type="text"
                    class="form-control"
                    name="support"
                    id="field_support"
                    [(ngModel)]="org.emailAddress"
                />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" mat-button visoButton="secondary" data-dismiss="modal" (click)="clear()">Cancel</button>
        <button type="submit" [disabled]="editForm.form.invalid || isSaving" mat-button visoButton="primary">
            Save
        </button>
    </div>
    <jhi-your-organization-logo-cropper></jhi-your-organization-logo-cropper>
</form>
