import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../shared/redux/state';
import {
    ArtifactIntelligenceState,
    artifactIntelligenceStateKey,
    DetectionDetailsDrawerData,
} from './artifact-intelligence.state';

export const getArtifactIntelligenceState: MemoizedSelector<AppState, ArtifactIntelligenceState> =
    createFeatureSelector<ArtifactIntelligenceState>(artifactIntelligenceStateKey);

export const getIsDetectionDetailsDrawerOpen: MemoizedSelector<AppState, boolean> = createSelector(
    getArtifactIntelligenceState,
    (state) => state.isDetectionDetailsDrawerOpen,
);

export const getDetectionDetailsDrawerData: MemoizedSelector<AppState, DetectionDetailsDrawerData | null> =
    createSelector(getArtifactIntelligenceState, (state) => state.detectionDetailsDrawerData);
