<mat-card class="card-container">
    <mat-card-header class="mb-3 d-block">
        <h4>Links</h4>
        <p>These links may contain useful information about the vendor</p>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col">
                @for (link of links$ | async; track $index) {
                    <div class="mt-3">
                        <p class="text-muted fw-bold mb-0">{{ link.name }}</p>
                        @if (!!link.url) {
                            <a
                                class="link-text"
                                [ngClass]="{ 'text-decoration-underline': !!link.url }"
                                [externalLink]="link.url"
                                target="_blank"
                                appMiddleTruncate
                                [truncateShowTooltip]="true"
                            >
                                {{ stripLinkProtocol(link.url) }}
                            </a>
                        } @else {
                            <span>-</span>
                        }

                        @if (
                            link.name === trustCenterLinkName &&
                            urlIsFromSafeBase((vendorDetails$ | async)?.trustCenter)
                        ) {
                            <div class="safebase-disclaimer d-flex align-items-center">
                                <mat-icon class="ms-2" svgIcon="safebase" [inline]="true"></mat-icon>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    </mat-card-content>
</mat-card>
