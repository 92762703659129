<div class="modal-header">
    <h4 class="modal-title">Relationship Context</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="close()"></button>
</div>
<div class="modal-body">
    <aw-wizard [awNavigationMode] navigateForward="allow">
        <aw-wizard-step stepTitle="Business Case">
            <step-header-with-risk-preview [inherentRisk]="inherentRisk$ | async" [defaultState]="dataTypesRegistered">
                <h2>Define Business Case</h2>
                <p>
                    The business case determines the attack surface profile and the relevant security controls required
                    to reduce the likelihood of data loss.
                </p>
            </step-header-with-risk-preview>
            <app-business-cases-selection
                [businessCasesFormGroup]="businessCasesFormGroup"
                [clientOrgName]="currentUserOrgName$ | async"
                [thirdPartyOrgName]="thirdPartyOrgName"
            ></app-business-cases-selection>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Organization Data Types">
            <step-header-with-risk-preview [inherentRisk]="inherentRisk$ | async" [defaultState]="dataTypesRegistered">
                <h2>Select Shared Organization Data Types</h2>
                <p>
                    The overall sensitivity of data types in scope, along with the attack surface profile, determine the
                    inherent risk of this relationship.
                    <br /><br />
                    Hover over each item to get more information on the data type.
                </p>
            </step-header-with-risk-preview>
            <app-data-types-selection
                [dataTypesFormGroup]="organizationDataTypesFormGroup"
                [dataTypesBySensitivity]="organizationDataTypes | callback: sortDataTypesPerSensitivity"
                [profileDataSensitivityLevels]="profileDataSensitivityLevels"
            ></app-data-types-selection>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Customer Data Types" *ngIf="!hideCustomerDataTab">
            <step-header-with-risk-preview [inherentRisk]="inherentRisk$ | async" [defaultState]="dataTypesRegistered">
                <h2>Select Shared Customer or Partner Data Types</h2>
                <p>
                    The overall sensitivity of data types in scope, along with the attack surface profile, determine the
                    inherent risk of this relationship.
                    <br /><br />
                    Hover over each item to get more information on the data type.
                </p>
            </step-header-with-risk-preview>
            <app-data-types-selection
                [dataTypesFormGroup]="customerDataTypesFormGroup"
                [dataTypesBySensitivity]="customerDataTypes | callback: sortDataTypesPerSensitivity"
                [profileDataSensitivityLevels]="profileDataSensitivityLevels"
            ></app-data-types-selection>
        </aw-wizard-step>
    </aw-wizard>
</div>
<div class="modal-footer justify-content-end">
    <ng-container *ngIf="{ submitting: submitting$ | async } as ctx">
        <button class="me-auto text-primary" mat-button visoButton="link" (click)="close()" [disabled]="ctx.submitting">
            Cancel
        </button>
        <button
            *ngIf="!isFirstStep"
            class="me-2"
            mat-button
            visoButton="secondary"
            (click)="previous()"
            [disabled]="ctx.submitting"
        >
            Back
        </button>
        <button
            *ngIf="!isLastStep"
            mat-button
            visoButton="primary"
            (click)="next()"
            [disabled]="ctx.submitting"
            data-pw="relationshipContextModalNext"
        >
            Next
        </button>
        <button
            *ngIf="isLastStep"
            type="button"
            mat-button
            visoButton="primary"
            (click)="saveContext()"
            [disabled]="ctx.submitting"
            data-pw="relationshipContextModalSubmit"
        >
            Submit
        </button>
    </ng-container>
</div>
