<mat-card class="card-container">
    <mat-card-header class="mb-4 d-block">
        <div class="d-flex align-items-center">
            <h4 class="mb-0">Risk dimensions</h4>
            <mat-icon
                class="ms-2 text-primary"
                svgIcon="viso"
                matTooltip="Predicted scope is estimated based on anonymized data. Actual scope is determined by the context of your relationship with {{
                    vendorDetails?.name
                }}."
            ></mat-icon>
        </div>
        <p class="mt-2">
            Assessments with this vendor are likely to produce information about the following risk dimensions.
        </p>
    </mat-card-header>
    <mat-card-content>
        @if (sortedControlDomains.length > 0) {
            <div class="d-flex p-3 pt-0">
                <span class="text-muted label-spacing text-bold">Dimension</span>
                <span class="text-end text-muted d-flex align-items-center gap-1 text-bold">
                    Assurance
                    <mat-icon
                        [inline]="true"
                        color="primary"
                        matTooltip="Artifacts uploaded to the platform are evaluated for rigor, control density, exception rate, program design, and audit period length (if relevant) and are dynamically assigned an assurance score."
                        >info</mat-icon
                    >
                </span>
            </div>

            <mat-list>
                @for (entry of sortedControlDomains; track entry.key) {
                    <mat-list-item>
                        <span matListItemTitle>{{ ControlDomainTypeLabels[entry.key] }}</span>
                        <app-assurance-level-meter
                            matListItemMeta
                            [direction]="'vertical'"
                            [assuranceLevel]="entry.value"
                        ></app-assurance-level-meter>
                    </mat-list-item>
                }
            </mat-list>
        }

        @if (unknownControlDomains.length > 0) {
            <strong>Unknown</strong>
            <p>The vendor has not provided enough information to infer insights about these risk dimensions.</p>
            <mat-list>
                <mat-list-item *ngFor="let unknownControlDomainType of unknownControlDomains">
                    <div matListItemTitle class="d-flex align-items-center gap-3">
                        <mat-icon class="text-muted">help_outline</mat-icon>
                        <span>{{ ControlDomainTypeLabels[unknownControlDomainType] }}</span>
                    </div>
                </mat-list-item>
            </mat-list>
        }
    </mat-card-content>
</mat-card>
