<div mat-dialog-title>
    Request remediation
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="row mb-3">
        <div class="col">
            <app-recommendations-list [recommendations]="recommendations" />
        </div>
    </div>
    <form [formGroup]="remediationFormGroup" role="form" novalidate>
        <div class="row mb-3">
            <div class="col">
                <p class="mb-2">
                    <strong
                        >This comment will be sent to the primary third party contact and posted to this relationship as
                        a comment.</strong
                    >
                </p>
                <mat-form-field class="w-100">
                    <mat-label>Add comment (optional)</mat-label>
                    <textarea
                        [formControl]="remediationCommentControl"
                        matInput
                        id="comment"
                        name="comment"
                        rows="3"
                    ></textarea>
                </mat-form-field>
                <mat-hint [ngClass]="{ 'text-error': commentLength >= 250 }"
                    ><small>{{ commentLength }}/250 characters</small></mat-hint
                >
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h4 class="mb-0">
                    <strong>Target date for remediation</strong>
                </h4>
                <p class="py-3">
                    Please provide a target date for remediation. First, VISO TRUST will send the remediation request.
                    30 days before your target date, VISO TRUST will automatically send a request to collect artifacts
                    for the remediation.
                </p>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col">
                <mat-form-field>
                    <mat-label>Date</mat-label>
                    <input
                        [formControl]="targetDateControl"
                        [matDatepicker]="targetDatePicker"
                        matInput
                        type="date"
                        name="target-date"
                        id="target-date"
                        [min]="today"
                    />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="targetDatePicker" />
                    <mat-datepicker #targetDatePicker />
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col">
                <h4 class="mb-0">This remediation request will go to...</h4>
                <ul>
                    <li>
                        <strong
                            >{{ primaryContact.firstName }} {{ primaryContact.lastName }} ({{
                                primaryContact.email
                            }})</strong
                        >, the third-party contact for this relationship.
                    </li>
                </ul>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col">
                <h4 class="mb-0">A copy of the request will go to...</h4>
                <ul>
                    <li *ngIf="currentUser.email === businessOwner.email; else boAndCurrentUserDifferent">
                        <strong>{{ currentUser.firstName }} {{ currentUser.lastName }} ({{ currentUser.email }})</strong
                        >, the requester of the remediation and business owner for this relationship.
                    </li>
                    <ng-template #boAndCurrentUserDifferent>
                        <li>
                            <strong
                                >{{ currentUser.firstName }} {{ currentUser.lastName }} ({{
                                    currentUser.email
                                }})</strong
                            >, the requester of the remediation.
                        </li>
                        <li>
                            <strong
                                >{{ businessOwner.firstName }} {{ businessOwner.lastName }} ({{
                                    businessOwner.email
                                }})</strong
                            >, the business owner for this relationship.
                        </li>
                    </ng-template>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-checkbox class="d-flex" [formControl]="sendRemediationToSubscribersControl" color="primary">
                    <p class="mb-0 pt-3">
                        Also send remediation to subscribers
                        <br />
                        <span class="text-muted">
                            Anyone in your organization who subscribes to this relationship will receive a copy of the
                            remediation request.
                        </span>
                    </p>
                </mat-checkbox>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
    <button type="button" mat-stroked-button color="primary" (click)="navigateBack()">
        <mat-icon>chevron_left</mat-icon>
        Back to risk review
    </button>
    <button type="button" mat-flat-button color="primary" [disabled]="remediationFormGroup.invalid" (click)="confirm()">
        Send request
    </button>
</mat-dialog-actions>
