import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import { SupplementalQuestionnairesDialogComponent } from '../../relationship-tab/supplemental-questionnaires/supplemental-questionnaires-dialog/supplemental-questionnaires-dialog.component';
import { catchError, of, switchMap } from 'rxjs';
import { RelationshipService } from '@entities/relationship';
import { OrgService } from '@entities/org';
import {
    deleteRelationshipSupplementalQuestionnaireConfigRequest,
    deleteRelationshipSupplementalQuestionnaireConfigRequestFailed,
    deleteRelationshipSupplementalQuestionnaireConfigRequestSuccess,
    downloadRelationshipSupplementalQuestionnaireRequestFailed,
    downloadRelationshipSupplementalQuestionnaireRequest,
    getOrgSupplementalQuestionnaireConfigRequest,
    getOrgSupplementalQuestionnaireConfigRequestFailed,
    getOrgSupplementalQuestionnaireConfigRequestSuccess,
    getRelationshipSupplementalQuestionnaireConfigRequest,
    getRelationshipSupplementalQuestionnaireConfigRequestFailed,
    getRelationshipSupplementalQuestionnaireConfigRequestSuccess,
    updateRelationshipSupplementalQuestionnaireConfigRequest,
    updateRelationshipSupplementalQuestionnaireConfigRequestFailed,
    updateRelationshipSupplementalQuestionnaireConfigRequestSuccess,
} from '../actions/supplemental-questionnaire.actions';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { saveFileFromResBlob } from '@shared/file-download/redux/actions';
import {
    reprocessSupplementalQuestionnaireRequest,
    reprocessSupplementalQuestionnaireRequestFailed,
    reprocessSupplementalQuestionnaireRequestSuccess,
} from '../../../assessment-details/assessment-details-common/redux/assessment-details.actions';

@Injectable()
export class SupplementalQuestionnaireEffects {
    openRelationshipSupplementalQuestionnairesDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                filter(
                    (route) =>
                        !!route &&
                        route.routeConfig.path === 'relationships/:relationshipId/supplemental-questionnaires',
                ),
                map((route) => route.params.relationshipId),
                tap((relationshipId) =>
                    this._dialogService.open(SupplementalQuestionnairesDialogComponent, {
                        inputs: {
                            relationshipId,
                        },
                        config: {
                            disableClose: true,
                        },
                    }),
                ),
            ),
        { dispatch: false },
    );

    getOrgSupplementalQuestionnaireConfigRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getOrgSupplementalQuestionnaireConfigRequest),
            switchMap(({ clientId }) =>
                this._orgService.getOrgSupplementalQuestionnaireConfig(clientId).pipe(
                    map((orgSupplementalQuestionnaireConfigResponse) =>
                        getOrgSupplementalQuestionnaireConfigRequestSuccess({
                            orgSupplementalQuestionnaireConfig: orgSupplementalQuestionnaireConfigResponse,
                        }),
                    ),
                    catchError(() => of(getOrgSupplementalQuestionnaireConfigRequestFailed())),
                ),
            ),
        ),
    );

    getRelationshipSupplementalQuestionnaireConfigRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationshipSupplementalQuestionnaireConfigRequest),
            switchMap(({ relationshipId }) =>
                this._relationshipService.getRelationshipSupplementalQuestionnaireConfig(relationshipId).pipe(
                    map((relationshipSupplementalQuestionnaireConfigResponse) =>
                        getRelationshipSupplementalQuestionnaireConfigRequestSuccess({
                            relationshipSupplementalQuestionnaireConfigResponse,
                        }),
                    ),
                    catchError(() => of(getRelationshipSupplementalQuestionnaireConfigRequestFailed())),
                ),
            ),
        ),
    );

    updateRelationshipSupplementalQuestionnaireConfigRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateRelationshipSupplementalQuestionnaireConfigRequest),
            switchMap(({ relationshipId, updateRequest }) =>
                this._relationshipService
                    .updateRelationshipSupplementalQuestionnaireConfig(relationshipId, updateRequest)
                    .pipe(
                        tap(() =>
                            this._snackbarService.success(
                                'Successfully updated the supplemental questionnaire configuration.',
                            ),
                        ),
                        map(() => updateRelationshipSupplementalQuestionnaireConfigRequestSuccess()),
                        catchError(() => of(updateRelationshipSupplementalQuestionnaireConfigRequestFailed())),
                    ),
            ),
        ),
    );

    deleteRelationshipSupplementalQuestionnaireConfigRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteRelationshipSupplementalQuestionnaireConfigRequest),
            switchMap(({ relationshipId }) =>
                this._relationshipService.deleteRelationshipSupplementalQuestionnaireConfig(relationshipId).pipe(
                    tap(() =>
                        this._snackbarService.success(
                            'Supplemental questionnaire configuration reset for this relationship.',
                        ),
                    ),
                    map(() => deleteRelationshipSupplementalQuestionnaireConfigRequestSuccess()),
                    catchError(() => of(deleteRelationshipSupplementalQuestionnaireConfigRequestFailed())),
                ),
            ),
        ),
    );

    downloadRelationshipSupplementalQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadRelationshipSupplementalQuestionnaireRequest),
            switchMap(({ relationshipId, suppQId }) =>
                this._relationshipService.downloadSupplementalQuestionnaire(relationshipId, suppQId).pipe(
                    map((fileResponseBlob) => saveFileFromResBlob({ fileResponseBlob })),
                    catchError(() => of(downloadRelationshipSupplementalQuestionnaireRequestFailed())),
                ),
            ),
        ),
    );

    reprocessSupplementalQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(reprocessSupplementalQuestionnaireRequest),
            switchMap(({ relationshipId, suppQId }) =>
                this._relationshipService.reprocessSupplementalQuestionnaire(relationshipId, suppQId).pipe(
                    map((artifactsToReprocess) =>
                        reprocessSupplementalQuestionnaireRequestSuccess({ artifactsToReprocess }),
                    ),
                    catchError((error) => of(reprocessSupplementalQuestionnaireRequestFailed(error))),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _dialogService: MatDialogWrapperService,
        private _relationshipService: RelationshipService,
        private _orgService: OrgService,
        private _snackbarService: SnackbarService,
    ) {}
}
