<div class="content-heading">
    <div>
        Public Info Search
        <small>Admin controls for importing/scraping public info search</small>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="card card-default">
            <div class="card-header">
                <p class="card-title">Import SEC 8K Filings</p>
            </div>
            <div class="card-body">
                <div class="card-message">Currently this function starts the import using local data only.</div>
                <button
                    class="mb-2 ms-2"
                    mat-button
                    visoButton="primary"
                    (click)="startSec8kImportProcess()"
                    [disabled]="importStarted"
                >
                    Start import process
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="card card-default">
            <div class="card-header">
                <p class="card-title">Start public info search for all vendors with a relationship</p>
            </div>
            <div class="card-body">
                <div class="card-message text-danger">
                    Be cautious, this process triggers quite a few expensive calls on the ML side.
                </div>
                <button
                    class="mb-2 ms-2"
                    mat-button
                    visoButton="primary"
                    (click)="startPublicInfoSearchProcess()"
                    [disabled]="importStarted"
                >
                    Start public search
                </button>
            </div>
        </div>
    </div>
</div>
