<app-risk-analysis-navigation-menu
    [riskAnalysisNavigationMenu]="riskAnalysisNavigationMenu"
    [selectedNavigationItem]="selectedNavigationItem"
    [relationship]="request"
    [businessOwner]="businessOwner"
    (onSelectedItem)="onNavigationChange($event)"
/>

<app-security-control-domains
    *ngIf="selectedControlGroup"
    [supplementalQuestionnaireReprocessingState]="supplementalQuestionnaireReprocessingState"
    [relationshipControlDomainAssessments]="relationshipControlDomainAssessments"
    [controls]="selectedControlGroup.controlDomains"
    [artifactSupersession]="artifactSupersession"
    [frameworkMappings]="frameworkMappings$ | async"
    [isStatusRiskBased]="selectedControlGroup.isStatusRiskBased"
    [isStatusCompliantBased]="selectedControlGroup.isStatusCompliantBased"
    [isStatusInformationBased]="selectedControlGroup.isStatusInformationBased"
    [supplementalQuestionnaireId]="selectedControlGroup.supplementalQuestionnaireId"
    [isOutOfScope]="selectedControlGroup.allControlsOutOfScope"
    [isNotEnabled]="selectedControlGroup.allControlsDisabled"
    [controlDomainTypeLabel]="selectedControlGroup.controlDomainTypeLabel"
    [showOutOfScopeDomains]="selectedControlGroup.allControlsOutOfScope || selectedControlGroup.allControlsDisabled"
    [relationship]="request"
    [latestAssessmentStatus]="latestAssessmentStatus"
    [latestAssessmentFollowUpQuestionnaireCreatedDate]="latestAssessmentFollowUpQuestionnaireCreatedDate"
    [missingInformationWasRequested]="missingInformationWasRequested"
    [assessmentSentToEmail]="(latestAssessment$ | async)?.sentToEmail"
    [isLatestAssessmentNonDocumentsOnly]="isLatestAssessmentNonDocumentsOnly"
    [selectedFramework]="selectedFramework"
>
    <ng-container no-controls-in-scope-message *ngIf="!selectedControlGroup.hideNoControlsInScopeMessage">
        <p class="m-0">
            No
            {{ selectedControlGroup.controlDomainTypeLabel }}
            control domains in scope.
        </p>
    </ng-container>

    @if (!selectedControlGroup.supplementalQuestionnaireId) {
        <mat-form-field framework-selection-dropdown>
            <mat-label>Display framework detections</mat-label>
            <mat-select
                class="framework-select"
                (selectionChange)="onFrameworkChange($event)"
                [value]="selectedFramework"
            >
                <mat-option></mat-option>
                <mat-option
                    *ngFor="let frameworkMappingType of FrameworkMappingType | enumToArray: FrameworkMappingTypeLabels"
                    [value]="frameworkMappingType.value"
                >
                    {{ frameworkMappingType.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    }
</app-security-control-domains>
