import { Org, OrgDetailsResponse } from './org.model';
import { storedCountries } from '@shared/model/countries';
import { CompleteVendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';

export class VendorSearchUtils {
    static getVendorFormattedAddress(org: Org | OrgDetailsResponse | CompleteVendorSearchResult): string {
        if (!org) {
            return '';
        }

        const country = storedCountries.find((country) => country.code3 === org.countryCode);
        if (!!org.city && !!org.countryCode) {
            if (!!org.region) {
                return `${org.city}, ${org.region}, ${country?.name}`;
            } else {
                return `${org.city}, ${country?.name}`;
            }
        } else {
            return null;
        }
    }
}
