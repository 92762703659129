import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, merge } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { Relationship, RequestStatus } from '../../entities/relationship';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import {
    getRelationshipRequest,
    getRelationshipRequestFailed,
    getRelationshipRequestSuccess,
} from './redux/actions/relationship.actions';
import { resetRelationshipState } from './redux/request.actions';

@Injectable()
export class RelationshipResolver {
    constructor(
        private _router: Router,
        private _store$: Store,
        private _actions$: Actions,
        private _snackbar: SnackbarService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const requestId = +route.paramMap.get('id');
        return of(true).pipe(
            tap(() => this._store$.dispatch(getRelationshipRequest({ requestId }))),
            tap(() => this._store$.dispatch(resetRelationshipState())),
            mergeMap(() =>
                merge(
                    this._actions$.pipe(
                        ofType(getRelationshipRequestSuccess),
                        map(({ relationship }) => relationship),
                        take(1),
                    ),
                    this._actions$.pipe(
                        ofType(getRelationshipRequestFailed),
                        map(({ error }) => error),
                        take(1),
                    ),
                ).pipe(take(1)),
            ),
            tap((response) => {
                if (response instanceof HttpErrorResponse) {
                    throw response;
                }
                if (response.status === RequestStatus.ARCHIVED) {
                    throw new Error('Archived Relationship');
                }
            }),
            map((relationship: Relationship) => relationship),
            catchError((err: Error) => {
                this._snackbar.error(
                    `Cannot Access the archived Relationship (Id: ${requestId}).\nPlease restore it first.`,
                );
                let url: string;
                if (window.history.length) {
                    url = '..';
                } else {
                    url = '/';
                }
                this._router.navigate([url]);

                return EMPTY;
            }),
        );
    }
}
