<mat-card data-spotlight-tour="vendor-prediction">
    <mat-card-header [ngClass]="{ 'mb-3': isRelationshipDetails || vendorDetailsStats?.assessmentsCompleted > 0 }">
        <div class="row">
            <div class="col-auto pe-0">
                <img
                    *ngIf="vendorFaviconUrl"
                    [src]="vendorFaviconUrl"
                    [appImageFallback]="vendorImageFallback"
                    class="rounded-circle me-2 org-icon"
                    onImageErrorCssClass="no-organization-icon"
                    alt="Organization Icon"
                />
            </div>
            <div class="col">
                <h3 class="text-normal mb-0">{{ vendorName }}</h3>
                @if (!!industry) {
                    <p class="text-light">{{ industry }}</p>
                }
            </div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col" [ngClass]="{ 'd-flex flex-grow-1': !isRelationshipDetails }">
                <app-vendor-risk-details
                    class="w-100"
                    [latestRiskAssessment]="latestRiskAssessment"
                    [vendorDetails]="vendorDetails"
                    [vendorDetailsStats]="vendorDetailsStats"
                    [isRelationshipDetails]="isRelationshipDetails"
                ></app-vendor-risk-details>
            </div>
        </div>
        @if (!isRelationshipDetails) {
            <div
                class="row justify-content-end mt-3"
                *jhiHasNotAuthority="[Roles.Auditor, Roles.Admin, Roles.Support, Roles.ReadOnly]"
            >
                <div class="col-auto">
                    <button
                        *disableAddRelationshipOnRules
                        type="button"
                        mat-flat-button
                        color="primary"
                        (click)="onAddRelationshipClicked.emit()"
                        data-spotlight-tour="add-relationship"
                    >
                        <mat-icon>add</mat-icon>
                        Add relationship
                    </button>
                </div>
            </div>
        }
    </mat-card-content>
</mat-card>
