<ng-container *ngIf="(artifacts$ | async)?.length || showEmptyTable">
    <div class="row">
        <div class="col">
            <ng-container [dynamicContainer]="artifactActionsContainerToken"></ng-container>
        </div>
    </div>
    <div class="row mt-0">
        <div class="col">
            <div class="artifact-list-container">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    [matSortActive]="sortByColumnName"
                    [matSortDirection]="sortDirection"
                    matSortDisableClear
                    matSortStart="desc"
                    [trackBy]="trackByArtifactId"
                >
                    <ng-container matColumnDef="artifactType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.artifactType }}</th>
                        <td mat-cell *matCellDef="let row">
                            <ng-container *jhiHasAnyAuthority="Roles.Auditor">
                                <ng-container *ngIf="auditable; else notAuditable">
                                    <a class="audit-link" href="javascript:;" (click)="onSelect(row)">{{
                                        row | callback: getArtifactDisplayName
                                    }}</a>
                                </ng-container>
                                <ng-template #notAuditable>
                                    {{ row | callback: getArtifactDisplayName }}
                                </ng-template>
                            </ng-container>
                            <ng-container *jhiHasAnyAuthority="Roles.ReadOnly">
                                {{ row | callback: getArtifactDisplayName }}
                            </ng-container>
                            <ng-container *jhiHasNotAuthority="[Roles.Auditor, Roles.ReadOnly]">
                                <div class="d-flex align-items-center">
                                    <div
                                        [tooltip]="row | callback: getSourceTooltipText"
                                        placement="top"
                                        class="text-center me-2"
                                    >
                                        <mat-icon fontSet="material-icons" class="sourceIcon">
                                            {{
                                                row.ownership?.ownershipType === ArtifactOwnershipLevel.PUBLIC
                                                    ? 'public'
                                                    : 'lock'
                                            }}
                                        </mat-icon>
                                    </div>

                                    <span>
                                        {{ row | callback: getArtifactDisplayName }}
                                    </span>
                                </div>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="artifactName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.artifactName }}</th>
                        <td mat-cell *matCellDef="let row">
                            <ng-container [ngSwitch]="row.type">
                                <a *ngSwitchCase="ArtifactType.URL_ARTIFACT" class="viso-link" [externalLink]="row.url">
                                    {{ row.url }}
                                </a>
                                <a
                                    *ngSwitchCase="ArtifactType.QUESTIONNAIRE_ARTIFACT"
                                    class="viso-link"
                                    [routerLink]="['/requests', request?.id, 'questionnaire', row.id]"
                                    [routerLinkActive]="['router-link-active']"
                                >
                                    {{ row.fileName }}
                                </a>
                                <a
                                    data-pw="fileArtifactNameColumn"
                                    *ngSwitchCase="ArtifactType.FILE_ARTIFACT"
                                    class="viso-link"
                                    (click)="openArtifactFile(row.id, row.fileName)"
                                >
                                    {{ row.fileName }}
                                </a>
                                <a
                                    *ngSwitchCase="ArtifactType.ATTESTATION_ARTIFACT"
                                    class="viso-link"
                                    [routerLink]="[
                                        '/requests',
                                        request?.id,
                                        'assessments',
                                        assessmentId,
                                        'attestations',
                                        row.id,
                                    ]"
                                    [routerLinkActive]="['router-link-active']"
                                >
                                    {{ row.fileName }}
                                </a>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="source">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                            [width]="50"
                            class="justify-content-center text-center"
                        >
                            {{ columnHeaders.source }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div [tooltip]="row | callback: getSourceTooltipText" placement="right" class="text-center">
                                <mat-icon fontSet="material-icons" class="sourceIcon">
                                    {{
                                        row.ownership?.ownershipType === ArtifactOwnershipLevel.PUBLIC
                                            ? 'public'
                                            : 'lock'
                                    }}
                                </mat-icon>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="association">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.association }}</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.association }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="assuranceLevel">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.assuranceLevel }}</th>
                        <td mat-cell *matCellDef="let row">
                            <app-assurance-level-meter
                                class="d-inline-block"
                                [hideTooltip]="true"
                                [hideLabel]="true"
                                [assuranceLevel]="row.validation?.auditReportAssuranceLevel"
                            ></app-assurance-level-meter>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="superseding">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.superseding }}</th>
                        <td mat-cell *matCellDef="let row">
                            <ng-container *ngIf="!!row.supersededArtifact; else blankSupersedes">
                                <a href="javascript:;" (click)="onSupersedesSelect(row.supersededArtifact.id)">
                                    {{ row.supersededArtifact.fileName }}
                                </a>
                            </ng-container>
                            <ng-template #blankSupersedes> -</ng-template>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="pdfStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.pdfStatus }}</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row | callback: getUrlConversionStatusLabel }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expirationDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.expirationDate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <em
                                *ngIf="isArtifactExpiringSoonOrExpired(row)"
                                class="fa fa-exclamation-circle text-error"
                            ></em>
                            <span
                                [ngClass]="{
                                    'fw-bold': isArtifactExpiringSoonOrExpired(row),
                                    'text-muted': isArtifactStatusNotRequired(row),
                                }"
                                [ngbTooltip]="getArtifactExpireTooltip(row)"
                                placement="right"
                                container="body"
                            >
                                {{
                                    row.validation?.expirationDate
                                        ? (row.validation?.expirationDate | date: 'mediumDate')
                                        : '-'
                                }}
                            </span>
                            <mat-icon
                                class="ms-1"
                                *ngIf="row | callback: getExpirationExplanation as artifactTypeValidationMsg"
                                [inline]="true"
                                [matTooltip]="artifactTypeValidationMsg"
                                [matTooltipClass]="'tooltip-indicator'"
                                matTooltipPosition="right"
                                >info</mat-icon
                            >
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="updatedDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.updatedDate }}</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.updatedDate | date: 'mediumDate' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="uploadedDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.uploadedDate }}</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.uploadedDate | date: 'mediumDate' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="validationStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.validationStatus }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span class="d-none" data-pw="artifactListItemId">{{ row.id }}</span>
                            <span *ngIf="!row.validation" class="d-none" data-pw="unvalidatedArtifactListItem"></span>
                            <ng-container
                                *ngIf="{
                                    isHumanReviewed: row.validation?.isHumanReviewed,
                                    status: row.validation?.status || ArtifactValidationStatus.NOT_VALIDATED,
                                } as statusCtx"
                            >
                                <span
                                    [ngClass]="{
                                        'fw-bold': statusCtx.status === ArtifactValidationStatus.COMPLETE,
                                        'text-muted': statusCtx.status === ArtifactValidationStatus.NOT_REQUIRED,
                                    }"
                                    class="statusContainer"
                                >
                                    <span
                                        [ngClass]="{
                                            'text-primary':
                                                (row | callback: getRtpStatus) ===
                                                ArtifactRtpValidationStatus.ANALYZING,
                                        }"
                                        class="status"
                                        >{{ row | callback: getValidationStatusLabel }}</span
                                    >
                                    <span
                                        [tooltip]="statusCtx.isHumanReviewed ? 'Reviewed by an auditor' : 'Automated'"
                                        placement="right"
                                    >
                                        <ng-container *ngIf="row.validation || !!(row | callback: getRtpStatus)">
                                            <mat-icon fontSet="material-icons" class="statusIcon">
                                                {{
                                                    (row | callback: showAutomationStatusIcon)
                                                        ? 'auto_fix_high'
                                                        : 'person'
                                                }}
                                            </mat-icon>
                                        </ng-container>
                                    </span>
                                </span>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="auditingBody">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.auditingBody }}</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.validation?.auditingBodyName || '-' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="controlsValidatedCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ columnHeaders.controlsValidatedCount }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.controlsValidatedCount || '-' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="subservicer">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders.subservicer }}</th>
                        <td mat-cell *matCellDef="let row">
                            {{ (row.validation?.subservicer && row.validation?.subservicerName) || '-' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions" stickyEnd [sticky]="true">
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                        <td mat-cell *matCellDef="let row">
                            <div
                                class="additional-actions d-flex justify-content-center"
                                placement="bottom left"
                                dropdown
                                container="body"
                            >
                                <button
                                    dropdownToggle
                                    type="button"
                                    class="btn-icon float-end"
                                    aria-controls="dropdown-container"
                                >
                                    <em class="fa fa-ellipsis-v text-primary"></em>
                                </button>
                                <ul
                                    id="dropdown-container"
                                    *dropdownMenu
                                    class="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="button-container"
                                >
                                    <li role="menuitem">
                                        <a
                                            class="dropdown-item text-error"
                                            [ngClass]="{
                                                disabled: !(currentAccount | callback: canDeleteArtifact : row),
                                            }"
                                            (click)="deleteArtifact.emit(row)"
                                        >
                                            Delete Artifact
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container *ngIf="columnDefinitions$ | async as columnDefinition">
                        <tr mat-header-row *matHeaderRowDef="columnDefinition; sticky: true"></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: columnDefinition"
                            [ngClass]="{ 'inactive-row': row | callback: getRowInactiveClass }"
                        ></tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</ng-container>
