<h2 mat-dialog-title>Manage supplemental questionnaires</h2>
<mat-dialog-content>
    @if (loadingConfiguration) {
        <div class="d-flex align-items-center justify-content-center">
            <div class="sk-spinner sk-spinner-pulse"></div>
        </div>
    } @else {
        <div class="row">
            <div class="col">
                <p>
                    We'll add these questionnaires to assessments for this relationship. We'll attempt to answer these
                    automatically using your vendor's artifacts. If there is a 3rd party contact associated with the
                    assessment, you can initiate a follow up for manual response to any remaining questions. Responses
                    to these questions will not impact the residual or inherent risk for this relationship.
                </p>
                <app-supplemental-questionnaires-config-form
                    [hasRelationshipSupplementalQuestionnaireConfig]="false"
                    [supplementalQuestionnaireConfig]="supplementalQuestionnaireConfig$ | async"
                    (onQuestionnaireConfigChanges)="onQuestionnaireConfigChanges($event)"
                />
            </div>
        </div>
    }
</mat-dialog-content>
<mat-dialog-actions
    [ngClass]="
        (usingRelationshipSupplementalQuestionnaireConfig$ | async) ? 'justify-content-between' : 'align-self-end'
    "
>
    @if (usingRelationshipSupplementalQuestionnaireConfig$ | async) {
        <button
            mat-flat-button
            color="warn"
            class="me-2"
            type="button"
            (click)="deleteRelationshipSupplementalQuestionnaireConfig()"
            mat-dialog-close
        >
            Restore org defaults
        </button>
    }
    <div>
        @if ((supplementalQuestionnaireConfig$ | async)?.length > 0) {
            <button mat-button color="primary" class="me-2" type="button" mat-dialog-close>Cancel</button>
            <button
                mat-flat-button
                color="primary"
                [disabled]="!supplementalQuestionnaireConfig"
                (click)="saveSupplementalQuestionnaireConfig()"
                mat-dialog-close
            >
                Save
            </button>
        } @else {
            <button mat-flat-button color="primary" mat-dialog-close>Close</button>
        }
    </div>
</mat-dialog-actions>
