<div mat-dialog-title>
    Let's customize your domain
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="customize-domains-dialog">
    Please note, you may only configure one option at a time.
    <mat-stepper #domainStepper linear (selectedIndexChange)="onStepperIndexChange($event)">
        <mat-step>
            <form [formGroup]="typeFormGroup">
                <button
                    class="popover-button"
                    mat-button
                    color="primary"
                    [appPopover]="domainDifference"
                    popoverTheme="dark"
                    popoverContainerClass="domain-difference-popover-container"
                >
                    What's the difference?
                </button>
                <mat-radio-group formControlName="type" required color="primary">
                    <div class="domain-type-options">
                        <div>
                            <mat-radio-button id="subdomain" [value]="DomainType.SUBDOMAIN">
                                <strong>Use a subdomain</strong><br />
                                Instant update, adds your customization to a "visotrust.com" address
                            </mat-radio-button>
                        </div>
                        <div>
                            <mat-radio-button [value]="DomainType.VANITY">
                                <strong>Use my organization's own domain</strong><br />
                                Takes time, requires verification via your organization's domain settings
                            </mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
            </form>
        </mat-step>
        <mat-step>
            <div class="subsequent-step">
                <form
                    [formGroup]="subdomainFormGroup"
                    *ngIf="(domainType$ | async) == DomainType.SUBDOMAIN; else vanityDomainStep1"
                >
                    <small>Subdomain preview</small>
                    <div class="domain-preview">
                        <h4>
                            <span [ngClass]="{ 'opacity-50': !subdomainInput.value }">{{
                                subdomainInput.value || 'subdomain'
                            }}</span
                            >.{{ domain }}
                        </h4>
                    </div>
                    <mat-form-field class="w-100">
                        <mat-label>Subdomain name</mat-label>
                        <input
                            #subdomainInput
                            type="text"
                            matInput
                            class="form-control mt-2"
                            formControlName="subdomain"
                            placeholder="Subdomain name"
                        />
                    </mat-form-field>
                </form>

                <ng-template #vanityDomainStep1>
                    <form [formGroup]="vanityDomainFormGroup">
                        <small>URL preview</small>
                        <div class="domain-preview">
                            <h4>
                                <span [ngClass]="{ 'opacity-50': !vanityDomainInput.value }">{{
                                    vanityDomainInput.value || 'vanity'
                                }}</span
                                >.<span [ngClass]="{ 'opacity-50': !domainInput.value }">{{
                                    domainInput.value || 'domain.com'
                                }}</span>
                            </h4>
                        </div>

                        <mat-form-field class="mb-4 w-100">
                            <mat-label>Vanity domain</mat-label>

                            <input
                                #vanityDomainInput
                                formControlName="vanityDomain"
                                type="text"
                                matInput
                                class="form-control mt-2"
                                placeholder="Vanity domain"
                            />
                        </mat-form-field>
                        <mat-form-field class="w-100 mb-4">
                            <mat-label>Registered domains</mat-label>
                            <mat-select
                                #domainInput
                                class="select"
                                formControlName="domain"
                                placeholder="Select a registered domain"
                            >
                                <mat-option class="select-item" *ngFor="let domain of orgDomains" [value]="domain">
                                    {{ domain }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-checkbox formControlName="cnameExists" color="primary"
                            >By checking this box, I confirm that a CNAME record for <strong>{{ cname }}</strong> exists
                            in my organization's domain configuration.</mat-checkbox
                        >
                    </form>
                </ng-template>
            </div>
        </mat-step>
    </mat-stepper>

    <ng-template #domainDifference>
        <div class="domain-difference-popover">
            <div>
                <strong>VISO TRUST offers two different options. Please note, you can only use one at a time.</strong>
            </div>
            <h6>Subdomains</h6>
            <div>
                <strong>Adds your custom name to a VISO TRUST address.</strong><br />
                For example, https://acmecorp.visotrust.com/assessments/1234
            </div>
            <h6>Custom domain</h6>

            <div>
                <strong>Uses your custom domain, without any VISO TRUST presence.</strong><br />
                For example, https://tprm.acmecorp.com/assessments/1234
            </div>
        </div>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
    <button
        mat-button
        color="warn"
        class="cancel-button"
        (click)="cancel()"
        *ngIf="(step$ | async) == 0; else prevButton"
    >
        Cancel
    </button>
    <ng-template #prevButton>
        <button mat-stroked-button class="prev-button" (click)="prev()">Go back</button>
    </ng-template>
    <button
        [disabled]="currentFormGroup.invalid"
        mat-flat-button
        color="primary"
        class="next-button"
        (click)="next(stepper?.selectedIndex)"
        *ngIf="(step$ | async) == 0; else saveButton"
    >
        Continue
    </button>
    <ng-template #saveButton>
        <button
            [disabled]="currentFormGroup.invalid"
            mat-flat-button
            class="next-button"
            color="primary"
            (click)="save()"
            toggleDisableOnActions
            [disablingActions]="[
                OrgSubdomainActions.SetOrgSubdomainRequest,
                VanityDomainActions.CreateVanityDomainRequest,
            ]"
            [enablingActions]="[
                OrgSubdomainActions.SetOrgSubdomainRequestSuccess,
                OrgSubdomainActions.SetOrgSubdomainRequestFailure,
                VanityDomainActions.CreateVanityDomainRequestSuccess,
                VanityDomainActions.CreateVanityDomainRequestFailure,
            ]"
        >
            <ng-container *ngIf="(domainType$ | async) == DomainType.SUBDOMAIN; else vanityDomainLabel">
                Save subdomain
            </ng-container>
            <ng-template #vanityDomainLabel>Save domain</ng-template>
        </button>
    </ng-template>
</mat-dialog-actions>
