<section>
    <ngx-datatable
        #dataSensitivityLevelsConfigTable
        class="bootstrap"
        [rows]="dataSensitivityLevels"
        [columnMode]="'force'"
        [rowHeight]="35"
        [headerHeight]="30"
        [scrollbarV]="true"
        [count]="dataSensitivityLevels.length"
    >
        <ngx-datatable-column name="Viso Data Sensitivity Level" prop="dataSensitivityLevel">
            <ng-template let-row="row" ngx-datatable-cell-template>{{ row.dataSensitivityLevel }} </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Custom Data Sensitivity Level" prop="displayName">
            <ng-template let-row="row" ngx-datatable-cell-template>{{ row.displayName }} </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="40" [resizable]="false" [canAutoResize]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="additional-actions" placement="bottom left" dropdown container="body">
                    <button
                        id="button-container"
                        dropdownToggle
                        type="button"
                        class="btn-icon float-end"
                        aria-controls="dropdown-container"
                    >
                        <em class="fa fa-ellipsis-v text-primary"></em>
                    </button>
                    <ul
                        id="dropdown-container"
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-start translate-x"
                        aria-labelledby="button-container"
                        style="--data-translate-value: -160px"
                    >
                        <li>
                            <a
                                class="dropdown-item"
                                (click)="
                                    openEditDataSensitivityLevelConfigModal(manageDataSensitivityLevelConfigModal, row)
                                "
                                >Edit</a
                            >
                        </li>
                    </ul>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</section>

<ng-template #manageDataSensitivityLevelConfigModal let-modal>
    <form name="editDataSensitivityLevelForm" [formGroup]="editDataSensitivityLevelForm">
        <div class="modal-header">
            <div class="modal-title title-text">Edit Data Sensitivity Level</div>
        </div>
        <div class="modal-body modal-text">
            <section *ngIf="currentDataSensitivityLevel">
                <div class="form-group">
                    <label for="dataSensitivityLevel"><strong>Viso Data Sensitivity Level</strong></label>
                    <div>{{ currentDataSensitivityLevel.dataSensitivity }}</div>
                </div>
                <div class="form-group">
                    <label for="displayName"><strong>Custom Data Sensitivity Level</strong></label>
                    <input
                        id="displayName"
                        type="text"
                        class="form-control"
                        formControlName="displayName"
                        [disabled]="savingDataSensitivityLevel"
                    />
                </div>
            </section>
        </div>
        <div class="modal-footer d-flex justify-content-between">
            <button
                type="button"
                mat-button
                visoButton="warning"
                [disabled]="savingDataSensitivityLevel"
                (click)="cancel()"
            >
                Cancel
            </button>
            <div>
                <button
                    type="button"
                    class="me-2"
                    mat-button
                    visoButton="primary"
                    [disabled]="savingDataSensitivityLevel"
                    (click)="saveDataSensitivityLevel({ resetToDefault: true })"
                >
                    Reset to Default
                </button>
                <button
                    *ngIf="currentDataSensitivityLevel"
                    type="button"
                    mat-button
                    visoButton="primary"
                    [disabled]="editDataSensitivityLevelForm.invalid || savingDataSensitivityLevel"
                    (click)="saveDataSensitivityLevel({ resetToDefault: false })"
                >
                    {{ savingDataSensitivityLevel ? 'Saving...' : 'Submit' }}
                </button>
            </div>
        </div>
    </form>
</ng-template>
