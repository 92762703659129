import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-artifact-added-modal',
    templateUrl: './artifact-added-modal.component.html',
    styleUrl: './artifact-added-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtifactAddedModalComponent {
    submittedAnimationOptions: AnimationOptions = {
        path: 'content/animations/lottie/assessment-collection/submitted.json',
        loop: true,
        autoplay: true,
    };

    @Input()
    assessmentId: number;

    constructor(private dialogRef: MatDialogRef<ArtifactAddedModalComponent>) {}

    close() {
        this.dialogRef.close();
    }

    proceedWithAvailableData(): void {
        this.dialogRef.close(this.assessmentId);
    }
}
