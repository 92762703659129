import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AssessmentSubmission, PublicAssessment } from '@entities/assessment';
import { FileArtifact, FileArtifactType, QuestionnaireArtifact, UploadFileArtifactRequest } from '@entities/artifact';
import { PublicAuditReport } from '@entities/audit-report';
import { AssessmentRecommendation } from '@entities/recommendation';
import { RelationshipControlDomainAssessments } from '@entities/relationship/models/security-control-domain';
import {
    AuthenticationFailedReasons,
    BasePublicAssessmentPayload,
    SetArtifactPasswordPayload,
    VendorDetails,
} from '../../models';
import { OrgBranding } from '../../../../../branding';

export enum AssessmentCollectionCommonRequestActions {
    GetPublicAssessmentRequest = '[Assessment Collection Common Request] Get Public Assessment Request',
    GetPublicAssessmentRequestSuccess = '[Assessment Collection Common Request] Get Public Assessment Request Success',
    GetPublicAssessmentRequestFailed = '[Assessment Collection Common Request] Get Public Assessment Request Failed',

    GetPublicAssessmentClientBrandingRequest = '[Assessment Collection Common Request] Get Public Assessment Client Branding Request',
    GetPublicAssessmentClientBrandingRequestSuccess = '[Assessment Collection Common Request] Get Public Assessment Client Branding Request Success',
    GetPublicAssessmentClientBrandingRequestFailed = '[Assessment Collection Common Request] Get Public Assessment Client Branding Request Failed',

    SetPublicAssessment = '[Assessment Collection Common] Set Public Assessment',

    CancelAssessmentRequest = '[Assessment Collection Common Request] Cancel Assessment Request',
    CancelAssessmentRequestSuccess = '[Assessment Collection Common Request] Cancel Assessment Request Success',
    CancelAssessmentRequestFailed = '[Assessment Collection Common Request] Cancel Assessment Request Failed',

    ExtendAssessmentExpirationRequest = '[Assessment Collection Common Request] Extend Assessment Expiration Request',
    ExtendAssessmentExpirationRequestSuccess = '[Assessment Collection Common Request] Extend Assessment Expiration Request Success',
    ExtendAssessmentExpirationRequestFailed = '[Assessment Collection Common Request] Extend Assessment Expiration Request Failed',

    ForwardRequestRequest = '[Assessment Collection Common Request] Forward Request Request',
    ForwardRequestRequestSuccess = '[Assessment Collection Common Request] Forward Request Request Success',
    ForwardRequestRequestFailed = '[Assessment Collection Common Request] Forward Request Request Failed',

    DeclineRemediationRequest = '[Assessment Collection Common Request] Decline Remediation Request',
    DeclineRemediationRequestSuccess = '[Assessment Collection Common Request] Decline Remediation Request Success',
    DeclineRemediationRequestFailed = '[Assessment Collection Common Request] Decline Remediation Request Failed',

    UploadFilesRequest = '[Assessment Collection Common Request] Upload Files Request',
    UploadFilesRequestSuccess = '[Assessment Collection Common Request] Upload Files Request Success',
    UploadFilesRequestFailed = '[Assessment Collection Common Request] Upload Files Request Failed',

    GetRecommendationsRequest = '[Assessment Collection Common Request] Get Recommendations Request',
    GetRecommendationsRequestSuccess = '[Assessment Collection Common Request] Get Recommendations Request Success',
    GetRecommendationsRequestFailed = '[Assessment Collection Common Request] Get Recommendations Request Failed',

    GetAuditReportsRequest = '[Assessment Collection Common Request] Get Audit Reports Request',
    GetAuditReportsRequestSuccess = '[Assessment Collection Common Request] Get Audit Reports Request Success',
    GetAuditReportsRequestFailed = '[Assessment Collection Common Request] Get Audit Reports Request Failed',

    SetPasswordForArtifactRequest = '[Assessment Collection Common Request] Set Password For Artifact Request',
    SetPasswordForArtifactRequestSuccess = '[Assessment Collection Common Request] Set Password For Artifact Request Success',
    SetPasswordForArtifactRequestFailed = '[Assessment Collection Common Request] Set Password For Artifact Request Failed',

    GetSecurityControlDomainRequest = '[Assessment Collection Common Request] Get Security Control Domain Request',
    GetSecurityControlDomainRequestSuccess = '[Assessment Collection Common Request] Get Security Control Domain Request Success',
    GetSecurityControlDomainRequestFailed = '[Assessment Collection Common Request] Get Security Control Domain Request Failed',

    RemoveArtifactRequest = '[Assessment Collection Common Request] Remove Artifact Request',
    RemoveArtifactRequestSuccess = '[Assessment Collection Common Request] Remove Artifact Request Success',
    RemoveArtifactRequestFailed = '[Assessment Collection Common Request] Remove Artifact Request Failed',

    CreateQuestionnaireRequest = '[Assessment Collection Common Request] Create Questionnaire Request',
    CreateQuestionnaireRequestSuccess = '[Assessment Collection Common Request] Create Questionnaire Request Success',
    CreateQuestionnaireRequestFailed = '[Assessment Collection Common Request] Create Questionnaire Request Failed',

    SaveQuestionnaireRequest = '[Assessment Collection Common Request] Save Questionnaire Request',
    SaveQuestionnaireRequestSuccess = '[Assessment Collection Common Request] Save Questionnaire Request Success',
    SaveQuestionnaireRequestFailed = '[Assessment Collection Common Request] Save Questionnaire Request Failed',

    SubmitAssessmentRequest = '[Assessment Collection Common Request] Submit Assessment Request',
    SubmitAssessmentRequestSuccess = '[Assessment Collection Common Request] Submit Assessment Request Success',
    SubmitAssessmentRequestFailed = '[Assessment Collection Common Request] Submit Assessment Request Failed',
}

export const getPublicAssessmentRequest = createAction(
    AssessmentCollectionCommonRequestActions.GetPublicAssessmentRequest,
    props<BasePublicAssessmentPayload>(),
);

export const getPublicAssessmentRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.GetPublicAssessmentRequestSuccess,
    props<{ publicAssessment: PublicAssessment }>(),
);

export const getPublicAssessmentRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.GetPublicAssessmentRequestFailed,
    props<{ authenticationFailedReason: AuthenticationFailedReasons; error: HttpErrorResponse }>(),
);

export const getPublicAssessmentClientBrandingRequest = createAction(
    AssessmentCollectionCommonRequestActions.GetPublicAssessmentClientBrandingRequest,
);

export const getPublicAssessmentClientBrandingRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.GetPublicAssessmentClientBrandingRequestSuccess,
    props<{ branding: OrgBranding }>(),
);

export const getPublicAssessmentClientBrandingRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.GetPublicAssessmentClientBrandingRequestFailed,
);

export const setPublicAssessment = createAction(
    AssessmentCollectionCommonRequestActions.SetPublicAssessment,
    props<{ publicAssessment: PublicAssessment }>(),
);

export const cancelAssessmentRequest = createAction(
    AssessmentCollectionCommonRequestActions.CancelAssessmentRequest,
    props<BasePublicAssessmentPayload & { vendorDetails: VendorDetails }>(),
);

export const cancelAssessmentRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.CancelAssessmentRequestSuccess,
);

export const cancelAssessmentRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.CancelAssessmentRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const extendAssessmentExpirationRequest = createAction(
    AssessmentCollectionCommonRequestActions.ExtendAssessmentExpirationRequest,
    props<BasePublicAssessmentPayload>(),
);

export const extendAssessmentExpirationRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.ExtendAssessmentExpirationRequestSuccess,
);

export const extendAssessmentExpirationRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.ExtendAssessmentExpirationRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const forwardRequestRequest = createAction(
    AssessmentCollectionCommonRequestActions.ForwardRequestRequest,
    props<BasePublicAssessmentPayload & { vendorDetails: VendorDetails }>(),
);

export const forwardRequestRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.ForwardRequestRequestSuccess,
);

export const forwardRequestRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.ForwardRequestRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const declineRemediationRequest = createAction(
    AssessmentCollectionCommonRequestActions.DeclineRemediationRequest,
    props<BasePublicAssessmentPayload & { vendorDetails: VendorDetails }>(),
);

export const declineRemediationRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.DeclineRemediationRequestSuccess,
);

export const declineRemediationRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.DeclineRemediationRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const uploadFilesRequest = createAction(
    AssessmentCollectionCommonRequestActions.UploadFilesRequest,
    props<
        BasePublicAssessmentPayload & {
            uploadFileArtifactRequests: UploadFileArtifactRequest[];
            replacedArtifactId?: number;
        }
    >(),
);

export const uploadFilesRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.UploadFilesRequestSuccess,
    props<{
        uploadFileArtifactRequests: UploadFileArtifactRequest[];
        successfullyUploadedArtifacts: FileArtifact[];
        duplicateArtifactFileNames: string[];
        replacedArtifactId?: number;
    }>(),
);

export const uploadFilesRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.UploadFilesRequestFailed,
    props<{ error: HttpErrorResponse; uploadFileArtifactRequests: UploadFileArtifactRequest[] }>(),
);

export const getRecommendationsRequest = createAction(
    AssessmentCollectionCommonRequestActions.GetRecommendationsRequest,
    props<BasePublicAssessmentPayload>(),
);

export const getRecommendationsRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.GetRecommendationsRequestSuccess,
    props<{ recommendations: AssessmentRecommendation[] }>(),
);

export const getRecommendationsRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.GetRecommendationsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getAuditReportsRequest = createAction(AssessmentCollectionCommonRequestActions.GetAuditReportsRequest);

export const getAuditReportsRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.GetAuditReportsRequestSuccess,
    props<{ auditReports: PublicAuditReport[] }>(),
);

export const getAuditReportsRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.GetAuditReportsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const setPasswordForArtifactRequest = createAction(
    AssessmentCollectionCommonRequestActions.SetPasswordForArtifactRequest,
    props<BasePublicAssessmentPayload & SetArtifactPasswordPayload>(),
);

export const setPasswordForArtifactRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.SetPasswordForArtifactRequestSuccess,
);

export const setPasswordForArtifactRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.SetPasswordForArtifactRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getSecurityControlDomainRequest = createAction(
    AssessmentCollectionCommonRequestActions.GetSecurityControlDomainRequest,
    props<BasePublicAssessmentPayload>(),
);

export const getSecurityControlDomainRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.GetSecurityControlDomainRequestSuccess,
    props<{ securityControlDomain: RelationshipControlDomainAssessments }>(),
);

export const getSecurityControlDomainRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.GetSecurityControlDomainRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const removeArtifactRequest = createAction(
    AssessmentCollectionCommonRequestActions.RemoveArtifactRequest,
    props<BasePublicAssessmentPayload & { artifactId: number; artifactType: FileArtifactType }>(),
);

export const removeArtifactRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.RemoveArtifactRequestSuccess,
    props<{ artifactType: FileArtifactType; artifactId: number }>(),
);

export const removeArtifactRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.RemoveArtifactRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const createQuestionnaireRequest = createAction(
    AssessmentCollectionCommonRequestActions.CreateQuestionnaireRequest,
    props<BasePublicAssessmentPayload>(),
);

export const createQuestionnaireRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.CreateQuestionnaireRequestSuccess,
    props<{ questionnaire: QuestionnaireArtifact }>(),
);

export const createQuestionnaireRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.CreateQuestionnaireRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const saveQuestionnaireRequest = createAction(
    AssessmentCollectionCommonRequestActions.SaveQuestionnaireRequest,
    props<BasePublicAssessmentPayload & { questionnaire: QuestionnaireArtifact }>(),
);

export const saveQuestionnaireRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.SaveQuestionnaireRequestSuccess,
);

export const saveQuestionnaireRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.SaveQuestionnaireRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const submitAssessmentRequest = createAction(
    AssessmentCollectionCommonRequestActions.SubmitAssessmentRequest,
    props<{ payload: AssessmentSubmission }>(),
);

export const submitAssessmentRequestSuccess = createAction(
    AssessmentCollectionCommonRequestActions.SubmitAssessmentRequestSuccess,
);

export const submitAssessmentRequestFailed = createAction(
    AssessmentCollectionCommonRequestActions.SubmitAssessmentRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
