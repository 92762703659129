import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { VendorDetailsResponse, VendorDetailsStatsResponse } from '../../../routes/request/models/vendor-details.model';
import { NetworkExposureRiskAdvisory, RiskAdvisory } from '@entities/risk-advisory';
import { SortDirection } from '@angular/material/sort';
import { VisoUserRole } from '@entities/viso-user';
import { RiskAssessment } from '@entities/risk-assessment';
import { ContextType } from '@entities/context-type';
import { DataType } from '@entities/data-type';
import { ControlDomainType } from '@entities/control-domain';

@Component({
    selector: 'app-vendor-details',
    templateUrl: './vendor-details.component.html',
    styleUrls: ['./vendor-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorDetailsComponent {
    @Input()
    latestRiskAssessment?: RiskAssessment;

    @Input()
    contextTypes?: ContextType[];

    @Input()
    dataTypes?: DataType[];

    @Input()
    disabledControlDomainTypes?: ControlDomainType[];

    @Input()
    inScopeControlDomainTypes?: ControlDomainType[];

    @Input({ required: true })
    vendorDetails: VendorDetailsResponse;

    @Input({ required: true })
    vendorDetailsStats: VendorDetailsStatsResponse;

    @Input({ required: true })
    riskAdvisories!: NetworkExposureRiskAdvisory[];

    @Input({ required: true })
    totalRiskAdvisories!: number;

    @Input({ required: true })
    showRefreshDetailsButton!: boolean;

    @Input({ required: true })
    refreshingVendorDetails!: boolean;

    @Input({ required: true })
    userCanEdit!: boolean;

    @Input({ required: true })
    isRelationshipDetails!: boolean;

    @Output()
    onAddRelationshipClicked = new EventEmitter<void>();

    @Output()
    onEditClicked = new EventEmitter<void>();

    @Output()
    onRefreshDetailsClicked = new EventEmitter<void>();

    @Output() onRiskAdvisorySortChange: EventEmitter<{ column: string; direction: SortDirection }> = new EventEmitter();

    @Output() onRiskAdvisoryPageChange: EventEmitter<{ page: number; pageSize: number }> = new EventEmitter();

    @Output() onRiskAdvisorySearchTextChange: EventEmitter<string> = new EventEmitter();

    @Output() deleteRiskAdvisory: EventEmitter<{ riskAdvisory: RiskAdvisory }> = new EventEmitter();

    protected readonly Roles = VisoUserRole;

    get showVendorLinks(): boolean {
        return (
            Object.keys(this.vendorDetails?.urls || {}).length > 0 ||
            !!this.vendorDetails?.trustCenter?.url ||
            !!this.vendorDetails?.homepage
        );
    }

    get showVendorLeadership(): boolean {
        return this.vendorDetails?.leadership?.length > 0;
    }

    get updatedDate(): Date {
        return this.vendorDetails?.updatedDate;
    }
}
