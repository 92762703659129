<div class="row mb-2" *ngIf="!!lastUpdated && !!updatedByFullName?.length">
    <div class="col">
        <p>Last updated {{ lastUpdated | date }} by {{ updatedByFullName }}.</p>
    </div>
</div>
<div class="row">
    <div class="col-auto">
        <h5 class="text-muted">Inherent risk</h5>
        <h4 *ngIf="hasInherentRisk; else emptyRisk" [riskTextColor]="inherentRisk">
            {{ (RiskLabels[inherentRisk] | riskLevel | async) || '-' }}
        </h4>
    </div>
    <div class="col-auto">
        <h5 class="text-muted">Residual risk</h5>
        <h4 *ngIf="hasResidualRisk; else emptyRisk" [riskTextColor]="residualRisk">
            {{ (RiskLabels[residualRisk] | riskLevel | async) || '-' }}
        </h4>
    </div>
    <div class="col-auto">
        <h5 class="text-muted d-flex align-items-center">
            Risk override
            <mat-icon
                class="text-primary ms-2"
                [inline]="true"
                [appPopover]="riskOverrideExplanationPopover"
                [popoverTheme]="'dark'"
                >info</mat-icon
            >
        </h5>
        <h4 *ngIf="hasRiskOverride; else emptyRisk" [riskTextColor]="riskOverrideControl.value">
            {{ (RiskLabels[riskOverrideControl.value] | riskLevel | async) || '-' }}
        </h4>
    </div>
</div>
<ng-template #emptyRisk>
    <h4 class="text-muted">-</h4>
</ng-template>
<div class="row mt-2" *ngIf="allowChanges">
    <div class="col">
        <button
            *ngIf="!riskOverrideSelectVisible; else riskOverrideSelect"
            (click)="riskOverrideSelectVisible = !riskOverrideSelectVisible"
            visoButton="secondary"
            color="primary"
            mat-button
        >
            <mat-icon>tune</mat-icon>
            Override risk
        </button>
        <ng-template #riskOverrideSelect>
            <p>Select the risk override for this relationship.</p>
            <div class="d-flex align-items-center">
                <mat-form-field>
                    <mat-select class="select" [formControl]="riskOverrideControl" placeholder="Select risk level">
                        <mat-option
                            class="select-item"
                            *ngFor="let riskLevel of RiskWithContext | enumToArray: RiskLabels | reverse"
                            [value]="riskLevel.value"
                        >
                            {{ riskLevel.label | riskLevel | async }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button class="ms-2" mat-button visoButton="link" color="warn" (click)="clearOverride()">
                    <mat-icon>close</mat-icon>
                    Clear override
                </button>
            </div>
        </ng-template>
    </div>
</div>
<ng-template #riskOverrideExplanationPopover>
    <div class="p-2 popover-width-limit">
        Risk overrides allow you to assign the risk for this relationship.
        <br /><br />
        <strong>
            This override is only a label. Overrides will not affect the risk analysis or the result of the assessment.
        </strong>
    </div>
</ng-template>
