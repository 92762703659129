<progressbar [value]="loadingProgress" [animate]="true" *ngIf="loading"></progressbar>
<div id="riskNetworkContainer" #riskNetworkContainer></div>

<div class="risk-network-disclosure">
    <p>Nth Party data is derived from publicly available information and Document Intelligence.</p>
    <p>There may be some inaccuracies.</p>
</div>

<div class="network-controls">
    <button mat-raised-button [disabled]="stabilizeOnProgress" (click)="toggleShownNodes()">
        <mat-icon class="me-2">
            {{ allNodesSelected ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        {{ allNodesSelected ? 'Hide All 4th Parties' : 'Show All 4th Parties' }}
    </button>
    <button mat-raised-button (click)="toggleGraphSize()">
        <em class="fa me-2" [ngClass]="{ 'fa-expand': !isGraphExpanded, 'fa-compress': isGraphExpanded }"></em>
        {{ isGraphExpanded ? 'Collapse Graph' : 'Expand Graph' }}
    </button>
    <div class="zoom-controls">
        <button mat-raised-button class="hugh-content p-2" (click)="zoom($event, 'in')">
            <em class="custom-icon custom-icon-zoom-in"></em>
        </button>
        <button mat-raised-button class="hugh-content p-2" (click)="zoom($event, 'out')">
            <em class="custom-icon custom-icon-zoom-out"></em>
        </button>
    </div>
</div>

<div class="nth-party-information">
    <app-vendor-tile
        *ngIf="selectedNthParty$ | async as vendor"
        [vendor]="vendor"
        (addRelationship)="addRelationshipClicked($event)"
        (viewProfile)="viewProfile($event)"
    />
</div>
