import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
    CreateRelationshipRequest,
    LifecycleManagementUpdateRequest,
    Relationship,
    RelationshipAssessmentSummarySectionConfigResponse,
    RelationshipStatusUpdatedResponse as RelationshipStatusUpdateView,
    RelationshipSupplementalQuestionnaireConfigResponse,
    RelationshipVendorDetailsStatsResponse,
    UpdateRelationshipSupplementalQuestionnaireConfigRequest,
} from './relationship.model';
import { RelationshipComment } from '../relationship-comment';
import { VisoUser } from '../viso-user';
import { PrimaryVendorContact } from '../../routes/primary-vendor-contact';
import { DateUtilsService } from '@shared/utils/date-utils.service';
import { RelationshipControlDomainAssessments } from './models/security-control-domain';
import { AssessmentView, FollowupType } from '@entities/assessment';
import { Risk } from '../risk-assessment';
import { Tag } from '../tag';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';
import { InteractiveIqrResponse } from '../../routes/request/rdp-ai-question-answering/rdp-ai-question-answering.model';
import { OnboardRequest } from '../../routes/request/models/onboarding.model';
import moment from 'moment';
import { Apollo, gql } from 'apollo-angular';
import { ArtifactService } from '../artifact';
import { RTPArtifactEvent, RTPEvent, RTPEventType } from '@entities/rtp';
import {
    AssessmentSummarySection,
    UpdateAssessmentSummarySectionConfigRequest,
} from '@shared/assessment-components/components/assessment-summary-config/model/assessment-summary-config-models';
import { OrgAssessmentSummarySections } from '../../admin/client-profile/client-profile.model';
import { createRequestOption, PaginationParams } from '../../shared';
import {
    RelationshipWithActiveAssessment,
    RelationshipWithActiveAssessmentRequest,
} from '../../routes/assessments-overview/models/assessment-overview.model';
import { VendorTierStats } from '@entities/vendor-tier';
import { BulkCancelAssessmentsResponse, BulkRelationshipUpdateResponse, BulkStartAssessmentResponse } from './models';
import { SuppQReprocessingState } from '@entities/supplemental-questionnaire';

export type EntityResponseType = HttpResponse<Relationship>;

@Injectable({
    providedIn: 'root',
})
export class RelationshipService {
    private _resourceUrl = 'api/relationships';

    constructor(
        private _http: HttpClient,
        private _dateUtilsService: DateUtilsService,
        private _apollo: Apollo,
        private _artifactService: ArtifactService,
    ) {}

    create(createRelationshipPayload: CreateRelationshipRequest): Observable<EntityResponseType> {
        return this._http
            .post<Relationship>(this._resourceUrl, createRelationshipPayload, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(relationship: Relationship): Observable<EntityResponseType> {
        const copy = this.convert(relationship);
        return this._http
            .put<Relationship>(this._resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    onboard(relationshipId: number, onboardRequest?: OnboardRequest): Observable<RelationshipStatusUpdateView> {
        return this._http.put<RelationshipStatusUpdateView>(
            `${this._resourceUrl}/${relationshipId}/onboard`,
            onboardRequest,
        );
    }

    offboard(relationshipId: number): Observable<RelationshipStatusUpdateView> {
        return this._http.put<RelationshipStatusUpdateView>(`${this._resourceUrl}/${relationshipId}/offboard`, null);
    }

    find(id: number): Observable<EntityResponseType> {
        return this._http
            .get<Relationship>(`${this._resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    addComment(id: number, comment: string): Observable<HttpResponse<RelationshipComment>> {
        return this._http
            .post<RelationshipComment>(`${this._resourceUrl}/${id}/comments`, comment, { observe: 'response' })
            .pipe(map((res: HttpResponse<RelationshipComment>) => res));
    }

    getComments(id: number): Observable<HttpResponse<RelationshipComment[]>> {
        return this._http
            .get<RelationshipComment[]>(`${this._resourceUrl}/${id}/comments`, { observe: 'response' })
            .pipe(map((res: HttpResponse<RelationshipComment[]>) => res));
    }

    getMentionableUsers(id: number): Observable<HttpResponse<VisoUser[]>> {
        return this._http
            .get<VisoUser[]>(`${this._resourceUrl}/${id}/mentionable-users`, { observe: 'response' })
            .pipe(map((res: HttpResponse<VisoUser[]>) => res));
    }

    archive(id: number): Observable<void> {
        return this._http.put<void>(`${this._resourceUrl}/${id}/archive`, null);
    }

    bulkArchive(
        ids: number[],
        countToEagerlyProcess: number,
    ): Observable<HttpResponse<BulkRelationshipUpdateResponse>> {
        return this._http.put<BulkRelationshipUpdateResponse>(`${this._resourceUrl}/bulk/status/archive`, ids, {
            params: createRequestOption({ eager: countToEagerlyProcess }),
            observe: 'response',
        });
    }

    bulkStartAssessments(
        ids: number[],
        countToEagerProcess: number,
    ): Observable<HttpResponse<BulkStartAssessmentResponse>> {
        return this._http.put<BulkStartAssessmentResponse>(`${this._resourceUrl}/bulk/assessments/start`, ids, {
            params: createRequestOption({ eager: countToEagerProcess }),
            observe: 'response',
        });
    }

    bulkCancelAssessments(
        ids: number[],
        countToEagerProcess: number,
    ): Observable<HttpResponse<BulkCancelAssessmentsResponse>> {
        return this._http.put<BulkCancelAssessmentsResponse>(`${this._resourceUrl}/bulk/assessments/cancel`, ids, {
            params: { eager: countToEagerProcess },
            observe: 'response',
        });
    }

    bulkDisableArtifactUpdates(ids: number[]): Observable<BulkRelationshipUpdateResponse> {
        return this._http.put<BulkRelationshipUpdateResponse>(
            `${this._resourceUrl}/bulk/artifact-updates/disable`,
            ids,
        );
    }

    bulkEnableArtifactUpdates(ids: number[]): Observable<BulkRelationshipUpdateResponse> {
        return this._http.put<BulkRelationshipUpdateResponse>(`${this._resourceUrl}/bulk/artifact-updates/enable`, ids);
    }

    bulkOffboard(
        ids: number[],
        countToEagerlyProcess: number,
    ): Observable<HttpResponse<BulkRelationshipUpdateResponse>> {
        return this._http.put<BulkRelationshipUpdateResponse>(`${this._resourceUrl}/bulk/status/offboard`, ids, {
            params: createRequestOption({ eager: countToEagerlyProcess }),
            observe: 'response',
        });
    }

    getSubscribers(id: number): Observable<HttpResponse<VisoUser[]>> {
        return this._http
            .get<VisoUser[]>(`${this._resourceUrl}/${id}/subscribers`, { observe: 'response' })
            .pipe(map((res: HttpResponse<VisoUser[]>) => res));
    }

    getPrimaryVendorContact(id: number): Observable<HttpResponse<PrimaryVendorContact>> {
        return this._http
            .get<PrimaryVendorContact>(`${this._resourceUrl}/${id}/primary-contact`, { observe: 'response' })
            .pipe(map((res: HttpResponse<PrimaryVendorContact>) => res));
    }

    getBusinessOwner(id: number): Observable<HttpResponse<VisoUser>> {
        return this._http.get<VisoUser>(`${this._resourceUrl}/${id}/business-owner`, { observe: 'response' });
    }

    assignBusinessOwner(id: number, businessOwner: VisoUser): Observable<HttpResponse<string>> {
        return this._http.post<string>(`${this._resourceUrl}/${id}/business-owner`, businessOwner, {
            observe: 'response',
        });
    }

    getSecurityControlDomain(id: number): Observable<RelationshipControlDomainAssessments> {
        return this._http.get<RelationshipControlDomainAssessments>(
            `${this._resourceUrl}/${id}/security-control-domain`,
        );
    }

    getLatestAssessment(id: number): Observable<AssessmentView> {
        return this._http.get<AssessmentView>(`${this._resourceUrl}/${id}/latest-assessment`, {
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { statuses: [404] }),
        });
    }

    cancelLatestAssessment(id: number): Observable<void> {
        return this._http.put<void>(`${this._resourceUrl}/${id}/latest-assessment/cancel`, {});
    }

    calculateInherentRisk(dataTypes: number[], contextTypes: number[]): Observable<Risk> {
        return this._http.post<Risk>(
            `${this._resourceUrl}/calculate-inherent-risk`,
            { dataTypes, contextTypes },
            {
                context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
            },
        );
    }

    getTags(id: number): Observable<Tag[]> {
        return this._http.get<Tag[]>(`${this._resourceUrl}/${id}/tags`);
    }

    askQuestion(id: number, question: string): Observable<InteractiveIqrResponse> {
        return this._http
            .post<InteractiveIqrResponse>(`${this._resourceUrl}/${id}/ask-iqr`, { question })
            .pipe(map((response) => ({ ...response, question })));
    }

    findSupersessionForRelationship(relationshipId: number): Observable<Map<number, number>> {
        return this._http
            .get(`${this._resourceUrl}/${relationshipId}/artifact-supersession`)
            .pipe(map((response) => new Map(Object.keys(response).map((key) => [+key, +response[key]]))));
    }

    updateAssessmentSummarySectionConfigForRelationship(
        relationshipId: number,
        updateAssessmentSummarySectionConfigRequest: UpdateAssessmentSummarySectionConfigRequest,
    ) {
        return this._http.put(
            `${this._resourceUrl}/${relationshipId}/assessment-summary-config`,
            updateAssessmentSummarySectionConfigRequest,
        );
    }

    deleteAssessmentSummarySectionConfigForRelationship(relationshipId: number) {
        return this._http.delete(`${this._resourceUrl}/${relationshipId}/assessment-summary-config`);
    }

    findAssessmentSummarySectionConfigForRelationship(
        relationshipId: number,
    ): Observable<OrgAssessmentSummarySections[]> {
        return this._http
            .get<RelationshipAssessmentSummarySectionConfigResponse>(
                `${this._resourceUrl}/${relationshipId}/assessment-summary-config`,
            )
            .pipe(
                map((response) => {
                    return Object.keys(response?.assessmentSummarySections).map((section) => ({
                        section: section as AssessmentSummarySection,
                        visible: response?.assessmentSummarySections[section],
                    }));
                }),
            );
    }

    updateRelationshipArtifactSupersession(
        relationshipId: number,
        supersedingArtifactId: number,
        supersededArtifactId?: number,
    ): Observable<any> {
        return this._http.put(`${this._resourceUrl}/${relationshipId}/artifact-supersession`, {
            supersedingArtifactId,
            supersededArtifactId,
        });
    }

    updateLifecycleManagement(relationshipId: number, request: LifecycleManagementUpdateRequest): Observable<void> {
        return this._http.put<void>(`${this._resourceUrl}/${relationshipId}/lifecycle-management`, request);
    }

    getRelationshipForAssessmentList(
        request: RelationshipWithActiveAssessmentRequest,
        req?: PaginationParams,
    ): Observable<HttpResponse<RelationshipWithActiveAssessment[]>> {
        const options = createRequestOption(req);
        return this._http.post<RelationshipWithActiveAssessment[]>(`${this._resourceUrl}/active-assessments`, request, {
            params: options,
            observe: 'response',
        });
    }

    getRelationshipsWithCompletedAssessmentsAwaitingRiskAcceptance(
        req?: PaginationParams,
    ): Observable<HttpResponse<RelationshipWithActiveAssessment[]>> {
        const options = createRequestOption(req);
        return this._http.post<RelationshipWithActiveAssessment[]>(
            `${this._resourceUrl}/active-assessments/awaiting-risk-acceptance`,
            null,
            {
                params: options,
                observe: 'response',
            },
        );
    }

    onRelationshipRtpEvent(relationshipId: number): Observable<RTPEvent> {
        const ON_RELATIONSHIP_RTP_EVENT = gql`
            subscription ON_RELATIONSHIP_RTP_EVENT($relationshipId: Long!) {
                onRelationshipRtpEvent(relationshipId: $relationshipId) {
                    id
                    source
                    specversion
                    type
                    datacontenttype
                    dataschema
                    subject
                    time
                    data
                    additionalProperties
                }
            }
        `;

        return this._apollo
            .subscribe<RTPEvent>({
                query: ON_RELATIONSHIP_RTP_EVENT,
                variables: {
                    relationshipId,
                },
            })
            .pipe(
                map((response) => Object.entries(response.data)[0][1].data as RTPEvent),
                map((response) => {
                    if (
                        [RTPEventType.RTP_ARTIFACT_CLASSIFIED, RTPEventType.RTP_PUBLISH_DATE_SET].includes(
                            response.eventType,
                        )
                    ) {
                        return {
                            ...response,
                            artifact: this._artifactService.convertItemFromServer(
                                (response as RTPArtifactEvent).artifact,
                            ),
                        };
                    }
                    return response;
                }),
            );
    }

    startRemediation(
        id: number,
        targetDate: Date,
        sendRemediationRequestToSubscribers: boolean,
        riskReviewNote?: string,
        remediationComment?: string,
    ): Observable<void> {
        return this._http.post<void>(`${this._resourceUrl}/${id}/remediation/start`, {
            riskReviewNote,
            remediationComment,
            targetDate,
            sendRemediationRequestToSubscribers,
        });
    }

    cancelRemediation(id: number, riskReviewNote: string): Observable<void> {
        return this._http.post<void>(`${this._resourceUrl}/${id}/remediation/cancel`, { riskReviewNote });
    }

    acceptRisk(id: number, riskReviewNote?: string, riskOverride?: Risk): Observable<void> {
        return this._http.put<void>(`${this._resourceUrl}/${id}/accept-risk`, { riskReviewNote, riskOverride });
    }

    revokeRiskAcceptance(id: number, riskReviewNote?: string): Observable<void> {
        return this._http.put<void>(`${this._resourceUrl}/${id}/revoke-risk-acceptance`, { riskReviewNote });
    }

    completeLatestAssessment(id: number): Observable<void> {
        return this._http.post<void>(`${this._resourceUrl}/${id}/latest-assessment/complete`, null);
    }

    sendLatestAssessmentFollowup(id: number): Observable<void> {
        return this._http.post<void>(`${this._resourceUrl}/${id}/latest-assessment/follow-up`, null);
    }

    updateLatestAssessmentFollowupMethod(
        id: number,
        followupType: FollowupType,
        followupRiskThreshold?: Risk,
    ): Observable<void> {
        return this._http.post<void>(`${this._resourceUrl}/${id}/latest-assessment/follow-up/edit`, {
            followupType,
            followupRiskThreshold,
        });
    }

    updateRelationshipSupplementalQuestionnaireConfig(
        id: number,
        updateRequest: UpdateRelationshipSupplementalQuestionnaireConfigRequest,
    ): Observable<void> {
        return this._http.put<void>(`${this._resourceUrl}/${id}/supplemental-questionnaire-config`, updateRequest);
    }

    getRelationshipSupplementalQuestionnaireConfig(
        id: number,
    ): Observable<RelationshipSupplementalQuestionnaireConfigResponse> {
        return this._http.get<RelationshipSupplementalQuestionnaireConfigResponse>(
            `${this._resourceUrl}/${id}/supplemental-questionnaire-config`,
        );
    }

    deleteRelationshipSupplementalQuestionnaireConfig(
        id: number,
    ): Observable<RelationshipSupplementalQuestionnaireConfigResponse> {
        return this._http.delete<RelationshipSupplementalQuestionnaireConfigResponse>(
            `${this._resourceUrl}/${id}/supplemental-questionnaire-config`,
        );
    }

    downloadSupplementalQuestionnaire(relationshipId: number, suppQId: string): Observable<HttpResponse<Blob>> {
        return this._http.get(`${this._resourceUrl}/${relationshipId}/supplemental-questionnaire/${suppQId}`, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    reprocessSupplementalQuestionnaire(relationshipId: number, suppQId: string): Observable<number> {
        return this._http.post<number>(
            `${this._resourceUrl}/${relationshipId}/supplemental-questionnaire/${suppQId}/reprocess`,
            {},
        );
    }

    getStatsByVendorTierId(vendorTierId?: number): Observable<VendorTierStats> {
        return this._http.get<VendorTierStats>(`${this._resourceUrl}/vendor-tier/stats`, {
            params: createRequestOption({ vendorTierId }),
        });
    }

    assignAllToTier(relationshipIds: number[], tierId: number): Observable<void> {
        return this._http.put<void>(`${this._resourceUrl}/vendor-tier`, { relationshipIds, tierId });
    }

    getRelationshipVendorDetailsStats(relationshipId: number): Observable<RelationshipVendorDetailsStatsResponse> {
        return this._http.get<RelationshipVendorDetailsStatsResponse>(
            `${this._resourceUrl}/${relationshipId}/vendor-details/stats`,
        );
    }

    getSupplementalQuestionnaireReprocessingStates(
        relationshipId: number,
    ): Observable<Record<string, SuppQReprocessingState>> {
        return this._http.get<Record<string, SuppQReprocessingState>>(
            `${this._resourceUrl}/${relationshipId}/supplemental-questionnaire/reprocessing-state`,
        );
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Relationship = this.convertItemFromServer(res.body);
        return res.clone({ body });
    }

    /**
     * Convert a returned JSON object to Relationship.
     */
    private convertItemFromServer(relationship: Relationship): Relationship {
        const copy: Relationship = Object.assign({}, relationship);
        copy.startDate = this._dateUtilsService.convertDateTimeFromServer(relationship.startDate);
        copy.endDate = this._dateUtilsService.convertDateTimeFromServer(relationship.endDate);
        copy.createdDate = this._dateUtilsService.convertDateTimeFromServer(relationship.createdDate);
        copy.updatedDate = this._dateUtilsService.convertDateTimeFromServer(relationship.updatedDate);

        const summarySections = relationship.assessmentSummarySections;
        if (!!summarySections) {
            // leave this
            copy.assessmentSummarySections = Object.keys(summarySections).map((section) => ({
                section: section as AssessmentSummarySection,
                visible: summarySections[section],
            }));
        }
        return copy;
    }

    /**
     * Convert a Relationship to a JSON which can be sent to the server.
     */
    private convert(relationship: Relationship): Relationship {
        const copy: Relationship = Object.assign({}, relationship);

        if (relationship.startDate instanceof Date) {
            copy.startDate = relationship.startDate;
        } else if (relationship.startDate === undefined) {
            copy.startDate = undefined;
        } else {
            copy.startDate = moment(relationship.startDate).toDate();
        }

        if (relationship.recertificationDate instanceof Date) {
            copy.recertificationDate = relationship.recertificationDate;
        } else if (relationship.recertificationDate === undefined) {
            copy.recertificationDate = undefined;
        } else {
            copy.recertificationDate = moment(relationship.recertificationDate).toDate();
        }

        copy.endDate = this._dateUtilsService.toDate(relationship.endDate);
        copy.createdDate = undefined;

        if (relationship.updatedDate instanceof Date) {
            copy.updatedDate = relationship.updatedDate;
        } else if (relationship.updatedDate === undefined) {
            copy.updatedDate = undefined;
        } else {
            copy.updatedDate = moment(relationship.updatedDate).toDate();
        }
        return copy;
    }
}
