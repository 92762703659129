import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompleteVendorSearchResult } from '../../../../shared/vendor-components/models/vendor-search-result';

export enum OrganizationActions {
    SearchOrganizationsRequest = '[Request] Search Organizations Request',
    SearchOrganizationsRequestSuccess = '[Request] Search Organizations Request Success',
    SearchOrganizationsRequestFailed = '[Request] Search Organizations Request Failed',

    GetOrgDomainsRequest = '[Request] Get Org Domains Request',
    GetOrgDomainsRequestSuccess = '[Request] Get Org Domains Request Success',
    GetOrgDomainsRequestFailed = '[Request] Get Org Domains Request Failed',
}

export const getOrgDomainsRequest = createAction(OrganizationActions.GetOrgDomainsRequest, props<{ orgId: number }>());

export const getOrgDomainsRequestSuccess = createAction(
    OrganizationActions.GetOrgDomainsRequestSuccess,
    props<{ orgDomains: string[] }>(),
);

export const getOrgDomainsRequestFailed = createAction(
    OrganizationActions.GetOrgDomainsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const searchOrganizationsRequest = createAction(
    OrganizationActions.SearchOrganizationsRequest,
    props<{ searchText: string; componentId?: string; onlyClients?: boolean }>(),
);

export const searchOrganizationsRequestSuccess = createAction(
    OrganizationActions.SearchOrganizationsRequestSuccess,
    props<{ organizations: CompleteVendorSearchResult[]; searchText: string; componentId?: string }>(),
);

export const searchOrganizationsRequestFailed = createAction(
    OrganizationActions.SearchOrganizationsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
