<mat-card class="card-container">
    <mat-card-header class="d-block">
        <h4>Security leadership</h4>
        <p>
            VISO TRUST monitors publicly available information to capture risk-relevant insight about this vendor.
        </p></mat-card-header
    >
    <mat-card-content>
        <div class="row">
            <div class="col">
                <mat-list>
                    <mat-list-item *ngFor="let leader of leadership" class="mb-2">
                        <span matListItemTitle class="text-sm">{{ leader.title }}</span>
                        <span matListItemLine class="text-normal">{{ leader.firstName + ' ' + leader.lastName }}</span>
                        <span matListItemLine class="text-light text-sm d-flex align-items-center">{{
                            leader.email || 'No email address found'
                        }}</span>
                        <div matListItemMeta>
                            <a [externalLink]="leader.linkedinUrl" target="_blank">
                                <img
                                    style="max-width: 20px"
                                    class="url-button-icon"
                                    src="content/svg/linkedin.svg"
                                    alt="LinkedIn Icon"
                                />
                                View profile
                            </a>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </mat-card-content>
</mat-card>
