<h2 mat-dialog-title>Decline remediation</h2>
<mat-dialog-content>
    <p class="mb-4">
        Are you sure you want to decline the remediation request from {{ clientName }}? To proceed, confirm your
        information below.
    </p>
    <form [formGroup]="declineRemediationFormGroup" role="form" novalidate>
        <mat-form-field class="mb-4 w-100">
            <mat-label>First name</mat-label>
            <input type="text" matInput formControlName="firstName" placeholder="Enter first name" />
        </mat-form-field>

        <mat-form-field class="mb-4 w-100">
            <mat-label>Last name</mat-label>
            <input type="text" matInput formControlName="lastName" placeholder="Enter last name" />
        </mat-form-field>

        <mat-form-field class="mb-4 w-100">
            <mat-label>Email address</mat-label>
            <input type="email" matInput formControlName="email" placeholder="Enter email" />
        </mat-form-field>
    </form>
    <p class="text-bg-warning">
        This action cannot be undone. If this is a mistake, {{ clientName }} will need to send another request for
        remediation.
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close color="primary">Cancel</button>
    <button mat-flat-button color="warn" (click)="confirm()" [disabled]="declineRemediationFormGroup.invalid">
        Decline remediation
    </button>
</mat-dialog-actions>
