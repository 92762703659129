import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PublicSearchService {
    private resourceUrl = 'api/public-search';

    constructor(private http: HttpClient) {}

    startPublicInfoSearchProcess(): Observable<string> {
        return this.http.post(
            this.resourceUrl,
            {},
            {
                responseType: 'text',
            },
        );
    }
}
