import { ChangeDetectionStrategy, Component, Signal, computed, input } from '@angular/core';
import { VendorDetailsResponse } from '../../../../../routes/request/models/vendor-details.model';
import { DataType } from '@entities/data-type';
import { ContextType } from '@entities/context-type';
import { ControlDomainType, ControlDomainTypeLabels } from '@entities/control-domain';

@Component({
    selector: 'app-vendor-context',
    templateUrl: './vendor-context.component.html',
    styleUrl: './vendor-context.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorContextComponent {
    vendorDetails = input.required<VendorDetailsResponse>();
    contextTypes = input.required<ContextType[]>();
    dataTypes = input.required<DataType[]>();
    disabledControlDomainTypes = input.required<ControlDomainType[]>();
    inScopeControlDomainTypes = input.required<ControlDomainType[]>();

    ControlDomainTypeLabels = ControlDomainTypeLabels;

    relevantRiskDimensions: Signal<ControlDomainType[]>;
    allRiskDimensions = Object.keys(ControlDomainType)
        .filter(
            (controlDomainType) =>
                controlDomainType !== ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE &&
                controlDomainType !== ControlDomainType.SUBSERVICERS,
        )
        .map((controlDomainType) => ControlDomainType[controlDomainType]);

    constructor() {
        this.relevantRiskDimensions = computed(() =>
            this.inScopeControlDomainTypes()?.filter(
                (controlDomainType) =>
                    controlDomainType !== ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE &&
                    controlDomainType !== ControlDomainType.SUBSERVICERS,
            ),
        );
    }

    isControlDomainTypeDisabled(controlDomainType: ControlDomainType): boolean {
        return this.disabledControlDomainTypes()?.includes(controlDomainType);
    }

    isControlDomainTypeOutOfScope(controlDomainType: ControlDomainType) {
        return !this.inScopeControlDomainTypes()?.includes(controlDomainType);
    }
}
