import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ClientProfile } from '../../../admin/client-profile/client-profile.model';
import { UserProfile, VisoUser, VisoUserMetadata } from '@entities/viso-user';

export enum SessionActions {
    InitSession = '[Session] Init Session',
    LoginRequest = '[Session] Login Request',
    GetServerSessionRequest = '[Session] Get Current Session Request',
    GetServerSessionCompleted = '[Session] Get Current Session Completed',
    LoadSession = '[Session] Load Session',
    LogoutRequest = '[Session] Logout Request',
    LogoutSuccess = '[Session] Logout Success',
    LogoutFailed = '[Session] Logout Failed',
    GetCurrentClientProfileRequest = '[Session] Get Client Profile Request',
    LoadClientProfile = '[Session] Load Client Profile',
    GetUserProfileRequest = '[Session] Get User Profile Request',
    GetUserProfileRequestSuccess = '[Session] Get User Profile Request Success',
    UpdateUserProfileRequest = '[Session] Update User Request Profile',
    UpdateUserProfileRequestSuccess = '[Session] Update User Profile Request Success',
    UpdateVisoUserMetadata = '[Session] Update Viso User Metadata',
    UpdateVisoUserMetadataSuccess = '[Session] Update Viso User Metadata Success',
    UpdateVisoUserMetadataFailed = '[Session] Update Viso User Failed',
}

export const initSession = createAction(SessionActions.InitSession);

export const loginRequest = createAction(SessionActions.LoginRequest);

export const getServerSessionRequest = createAction(SessionActions.GetServerSessionRequest);

export const getServerSessionCompleted = createAction(
    SessionActions.GetServerSessionCompleted,
    props<{ error?: HttpErrorResponse }>(),
);

export const loadSession = createAction(SessionActions.LoadSession, props<{ account: VisoUser }>());

export const logoutRequest = createAction(SessionActions.LogoutRequest);

export const logoutSuccess = createAction(SessionActions.LogoutSuccess);

export const logoutFailed = createAction(SessionActions.LogoutFailed);

export const getCurrentClientProfileRequest = createAction(SessionActions.GetCurrentClientProfileRequest);

export const loadClientProfile = createAction(SessionActions.LoadClientProfile, props<{ profile: ClientProfile }>());

export const getUserProfileRequest = createAction(SessionActions.GetUserProfileRequest);

export const getUserProfileRequestSuccess = createAction(
    SessionActions.GetUserProfileRequestSuccess,
    props<{ userProfile: UserProfile }>(),
);

export const updateUserProfileRequest = createAction(
    SessionActions.UpdateUserProfileRequest,
    props<{ userProfile: UserProfile }>(),
);

export const updateUserProfileRequestSuccess = createAction(
    SessionActions.UpdateUserProfileRequestSuccess,
    props<{ userProfile: UserProfile }>(),
);

export const updateVisoUserMetadata = createAction(
    SessionActions.UpdateVisoUserMetadata,
    props<{ request: VisoUserMetadata }>(),
);

export const updateVisoUserMetadataSuccess = createAction(SessionActions.UpdateVisoUserMetadataSuccess);

export const updateVisoUserMetadataFailed = createAction(
    SessionActions.UpdateVisoUserMetadataFailed,
    props<{ error: HttpErrorResponse }>(),
);
