import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-vendor-tier-management-dialog',
    templateUrl: './vendor-tier-management-dialog.component.html',
    styleUrl: './vendor-tier-management-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorTierManagementDialogComponent {
    @Input({ required: true })
    currentNumberOfTiers: number;

    selectedTiers: number;

    get reducingTiers(): boolean {
        return this.currentNumberOfTiers > this.selectedTiers;
    }

    constructor(private _dialogRef: MatDialogRef<number>) {}

    saveChanges(): void {
        this._dialogRef.close(this.selectedTiers);
    }

    generateUnassignedWarning(): string {
        if (this.selectedTiers === 0 && this.selectedTiers < this.currentNumberOfTiers) {
            return 'Vendors in all tiers will be marked unassigned.';
        }
        if (this.selectedTiers === this.currentNumberOfTiers - 1) {
            return `Vendors in tier ${this.selectedTiers + 1} will be marked unassigned.`;
        }
        const numbers = [];
        for (let i = this.selectedTiers + 1; i <= this.currentNumberOfTiers; i++) {
            numbers.push(i);
        }
        return `Vendors in tiers ${numbers.join(', ').replace(/, (\d+)$/, ' and $1')} will be marked unassigned.`;
    }
}
