import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { first, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import {
    addDomainToVendor,
    addDomainToVendorRequest,
    addDomainToVendorRequestFailed,
    addDomainToVendorRequestSuccess,
    deleteVendorDetailsTabRiskAdvisory,
    deleteVendorDetailsTabRiskAdvisoryCancel,
    deleteVendorDetailsTabRiskAdvisoryRequest,
    deleteVendorDetailsTabRiskAdvisoryRequestFailed,
    deleteVendorDetailsTabRiskAdvisoryRequestSuccess,
    getVendorDetailsRequest,
    getVendorDetailsRequestFailed,
    getVendorDetailsRequestSuccess,
    getVendorDetailsStatsRequest,
    getVendorDetailsStatsRequestFailed,
    getVendorDetailsStatsRequestSuccess,
    getVendorDetailsTabRiskAdvisoriesRequest,
    getVendorDetailsTabRiskAdvisoriesRequestFailed,
    getVendorDetailsTabRiskAdvisoriesRequestSuccess,
    refreshVendorDetailsVendorDetailsTabRequest,
    refreshVendorDetailsVendorDetailsTabRequestFailed,
    refreshVendorDetailsVendorDetailsTabRequestSuccess,
    updateVendorDetailsRequest,
    updateVendorDetailsRequestFailed,
    updateVendorDetailsRequestSuccess,
} from '../actions/vendor-details-tab.actions';
import { OrgService } from '@entities/org';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { RiskAdvisoryService } from '@entities/risk-advisory/risk-advisory.service';
import { RelationshipService } from '@entities/relationship';

@Injectable()
export class VendorDetailsTabEffects {
    getVendorDetailsStatsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getVendorDetailsStatsRequest),
            switchMap(({ relationshipId }) =>
                this._relationshipService.getRelationshipVendorDetailsStats(relationshipId).pipe(
                    map((relationshipVendorDetailsStats) =>
                        getVendorDetailsStatsRequestSuccess({
                            relationshipVendorDetailsStats,
                        }),
                    ),
                    catchError((error) => of(getVendorDetailsStatsRequestFailed(error))),
                ),
            ),
        ),
    );

    getVendorDetailsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getVendorDetailsRequest),
            switchMap(({ orgId }) =>
                this._orgService.getVendorDetails(orgId).pipe(
                    map((vendorDetails) =>
                        getVendorDetailsRequestSuccess({
                            vendorDetails,
                        }),
                    ),
                    catchError((error) => of(getVendorDetailsRequestFailed(error))),
                ),
            ),
        ),
    );

    refreshVendorDetailsVendorDetailsTabRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshVendorDetailsVendorDetailsTabRequest),
            switchMap(({ vendorId, relationshipId }) =>
                this._orgService.refreshVendorDetails(vendorId, relationshipId).pipe(
                    map(() => {
                        this._snackbarService.success('Vendor details refreshed.');
                        return refreshVendorDetailsVendorDetailsTabRequestSuccess();
                    }),
                    catchError((error) => {
                        this._snackbarService.error('Vendor detail refresh failed.');
                        return of(refreshVendorDetailsVendorDetailsTabRequestFailed({ error }));
                    }),
                ),
            ),
        ),
    );

    updateVendorDetailsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateVendorDetailsRequest),
            switchMap(({ updateRequest, isCurrentUserAdmin }) =>
                this._orgService.updateVendorDetails(updateRequest, isCurrentUserAdmin).pipe(
                    map(() => updateVendorDetailsRequestSuccess()),
                    catchError((error) => of(updateVendorDetailsRequestFailed({ error }))),
                ),
            ),
        ),
    );

    addDomainToVendor$ = createEffect(() =>
        this._actions$.pipe(
            ofType(addDomainToVendor),
            switchMap(({ vendorDetails, newDomain }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Confirm Domain Assignment',
                        message: `Org: <strong>${vendorDetails.name}</strong>\nDomain to add: <strong>${newDomain}</strong>\n
                              <div class="text-error"><strong>Note:</strong> This operation cannot be undone without Engineering intervention.</div>`,
                        confirmLabel: 'Assign domain',
                    })
                    .pipe(
                        first(),
                        filter(Boolean),
                        map(() => addDomainToVendorRequest({ vendorDetails, newDomain })),
                    ),
            ),
        ),
    );

    addDomainToVendorRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(addDomainToVendorRequest),
            switchMap(({ vendorDetails, newDomain }) =>
                this._orgService.addDomain(vendorDetails.id, newDomain).pipe(
                    map(() => addDomainToVendorRequestSuccess({ orgName: vendorDetails.name })),
                    catchError((error) => of(addDomainToVendorRequestFailed({ error }))),
                ),
            ),
        ),
    );

    addDomainToVendorRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(addDomainToVendorRequestSuccess),
                tap(({ orgName }) => this._snackbarService.success(`Domain successfully added to ${orgName}!`)),
            ),
        { dispatch: false },
    );

    getVendorDetailsTabRiskAdvisoriesRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getVendorDetailsTabRiskAdvisoriesRequest),
            switchMap(({ params, vendorId }) =>
                this._riskAdvisoryService.getAllRiskAdvisoriesForVendor(vendorId, params).pipe(
                    map((response) =>
                        getVendorDetailsTabRiskAdvisoriesRequestSuccess({
                            riskAdvisories: response.body,
                            httpHeaders: response.headers,
                        }),
                    ),
                    catchError((error) => of(getVendorDetailsTabRiskAdvisoriesRequestFailed(error))),
                ),
            ),
        ),
    );

    deleteVendorDetailsTabRiskAdvisory$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteVendorDetailsTabRiskAdvisory),
            switchMap(({ riskAdvisory }) =>
                this._confirmDialogService
                    .confirm({
                        title: `Delete Risk Advisory: ${riskAdvisory.title}`,
                        message:
                            'Deleting this Risk Advisory means <strong class="mat-text-warn">permanently deleting it</strong>.',
                        confirmLabel: 'Delete Risk Advisory',
                        confirmButtonStyle: 'warning',
                    })
                    .pipe(
                        map((confirmed) =>
                            confirmed
                                ? deleteVendorDetailsTabRiskAdvisoryRequest({ riskAdvisory: riskAdvisory })
                                : deleteVendorDetailsTabRiskAdvisoryCancel(),
                        ),
                    ),
            ),
        ),
    );

    deleteVendorDetailsTabRiskAdvisoryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteVendorDetailsTabRiskAdvisoryRequest),
            switchMap(({ riskAdvisory }) =>
                this._riskAdvisoryService.deleteRiskAdvisory(riskAdvisory.id).pipe(
                    map(() => deleteVendorDetailsTabRiskAdvisoryRequestSuccess()),
                    catchError((error) => of(deleteVendorDetailsTabRiskAdvisoryRequestFailed(error))),
                ),
            ),
        ),
    );

    deleteVendorDetailsTabRiskAdvisoryRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(deleteVendorDetailsTabRiskAdvisoryRequestSuccess),
                tap(() => {
                    this._snackbarService.success(`Risk advisory deleted.`);
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _orgService: OrgService,
        private _confirmDialogService: MatConfirmDialogService,
        private _snackbarService: SnackbarService,
        private _riskAdvisoryService: RiskAdvisoryService,
        private _relationshipService: RelationshipService,
    ) {}
}
