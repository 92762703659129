<app-custom-stepper #stepper>
    <cdk-step>
        <mat-dialog-content>
            <div class="d-flex flex-column align-items-center justify-content-center mb-2">
                <div class="fs-2 mt-4 mb-4">
                    <mat-icon fontSet="material-symbols-outlined">celebration</mat-icon>
                </div>
                <h3 class="fw-normal">New! Organize your vendors in tiers!</h3>
            </div>
            <p class="light-text">
                Now you can group vendors into tiers, making it easier to prioritize oversight and see risk insights
                specific to each tier.
            </p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            @if (isOrgAdmin) {
                <button data-pw="tiering-tutorial-skip" mat-flat-button type="button" (click)="close(true)">
                    Do this later
                </button>
                <button
                    mat-flat-button
                    color="primary"
                    type="button"
                    (click)="orgAlreadyHasTiers ? close(false) : stepper.next()"
                >
                    Get started
                </button>
            } @else {
                <button mat-flat-button color="primary" type="button" (click)="close(true)">Get started</button>
            }
        </mat-dialog-actions>
    </cdk-step>
    <cdk-step>
        <mat-dialog-content>
            <div class="text-center mt-4 mb-2">
                <h3 class="fw-normal">First, how many tiers do you have?</h3>
            </div>
            <p class="light-text">
                You can change this later. Remember, you can also group vendors with tags within a tier.
            </p>
            <div class="d-flex justify-content-center my-4">
                <app-vendor-tier-selection
                    (numberOfTiersSelected)="numberOfTiersSelected = $event"
                    [allowNone]="false"
                />
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-flat-button type="button" (click)="close(true)">Cancel</button>
            <button mat-flat-button color="primary" type="button" (click)="createTiers()">Create tiers</button>
        </mat-dialog-actions>
    </cdk-step>
</app-custom-stepper>
