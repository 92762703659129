import { Action, createReducer, on } from '@ngrx/store';
import { initialState, RelationshipsState } from './relationships-state';
import {
    clearAllFilters,
    clearAllUnpaginatedRelationshipIds,
    clearVendorTierStats,
    getAllRelationshipIdsQuerySuccess,
    getRelationshipCountsRequestSuccess,
    getVendorTierStatsRequestSuccess,
    setAdditionalFiltersState,
    setColumnsState,
    expandNthGraph,
    collapseNthGraph,
    openVendorTierDescriptionDialog,
    closeVendorTierDescriptionDialog,
    setSelectedTierId,
} from './relationships.actions';

const reducer = createReducer(
    initialState,
    on(setColumnsState, (state, { results }) => ({
        ...state,
        columnsGroups: [...results],
    })),
    on(clearAllFilters, (state) => ({
        ...state,
        additionalFilters: null,
    })),
    on(setAdditionalFiltersState, (state, { filters }) => ({
        ...state,
        additionalFilters: { ...filters },
    })),
    on(getVendorTierStatsRequestSuccess, (state, { vendorTierStats }) => {
        return {
            ...state,
            vendorTierStats: [...state.vendorTierStats, vendorTierStats],
        };
    }),
    on(clearVendorTierStats, (state) => ({
        ...state,
        vendorTierStats: [],
    })),
    on(getRelationshipCountsRequestSuccess, (state, { relationshipCounts }) => ({
        ...state,
        relationshipCounts: {
            total: relationshipCounts.total,
            byTier: Object.fromEntries(relationshipCounts.byTier),
        },
    })),
    on(getAllRelationshipIdsQuerySuccess, (state, { relationshipIds }) => ({
        ...state,
        unpaginatedIdsForCurrentFilter: relationshipIds,
    })),
    on(clearAllUnpaginatedRelationshipIds, (state) => ({
        ...state,
        unpaginatedIdsForCurrentFilter: [],
    })),
    on(expandNthGraph, (state) => ({
        ...state,
        isGraphExpanded: true,
    })),
    on(collapseNthGraph, (state) => ({
        ...state,
        isGraphExpanded: false,
    })),
    on(openVendorTierDescriptionDialog, (state) => ({
        ...state,
        editingTierDescription: true,
    })),
    on(closeVendorTierDescriptionDialog, (state) => ({
        ...state,
        editingTierDescription: false,
    })),
    on(setSelectedTierId, (state, { selectedTierId }) => ({
        ...state,
        selectedTierId,
    })),
);

export function relationshipsReducer(state: RelationshipsState = initialState, action: Action): RelationshipsState {
    return reducer(state, action);
}
