import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export type VendorTierWalkthroughDialogResult = {
    skipSpotlightTour: boolean;
    tiersToCreate: number;
};

@Component({
    selector: 'app-vendor-tier-walkthrough-dialog',
    templateUrl: './vendor-tier-walkthrough-dialog.component.html',
    styleUrl: './vendor-tier-walkthrough-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorTierWalkthroughDialogComponent {
    @Input({ required: true })
    isOrgAdmin: boolean;

    @Input({ required: true })
    orgAlreadyHasTiers: boolean;

    numberOfTiersSelected = 1;

    constructor(private _matDialogRef: MatDialogRef<VendorTierWalkthroughDialogResult>) {}

    close(skipSpotlightTour: boolean): void {
        this._matDialogRef.close({
            skipSpotlightTour,
            tiersToCreate: undefined,
        });
    }

    createTiers(): void {
        return this._matDialogRef.close({
            showWalkthrough: true,
            tiersToCreate: this.numberOfTiersSelected,
        });
    }
}
