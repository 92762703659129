import { Component, Input } from '@angular/core';
import { TagColorEnum } from '@entities/tag';
import { CompleteVendorSearchResult } from './vendor-search-result';
import { VendorSearchUtils } from '@entities/org/vendor-search.utils';

@Component({
    selector: 'app-base-vendor',
    template: '',
})
export class BaseVendorComponent {
    @Input({ required: true })
    vendor: CompleteVendorSearchResult;

    TagColor = TagColorEnum.COLOR_01;

    get vendorId(): number {
        return this.vendor?.id;
    }

    get vendorName(): string {
        return this.vendor?.name;
    }

    get vendorAddress(): string {
        return !!this.vendor && VendorSearchUtils.getVendorFormattedAddress(this.vendor);
    }

    get vendorHomepage(): string {
        return this.vendor?.homepage;
    }

    get vendorFavicon(): string {
        return this.vendor?.faviconUrl || 'content/img/no-vendor-logo.png';
    }

    get vendorFaviconAlt(): string {
        return !!this.vendorName ? `${this.vendorName}_logo` : '';
    }

    get vendorDescription(): string {
        return this.vendor?.description;
    }

    get vendorIndustries(): string[] {
        return this.vendor?.industry?.split(',').filter((x) => !!x);
    }

    get existingRelationshipCount(): number {
        return this.vendor?.existingRelationshipCount;
    }

    prettifyUrl = (url: string) => {
        return url.replace(/http(s)?:\/\/|www\.|\/.*/g, '');
    };
}
