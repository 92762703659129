import { GuidedTour } from 'ngx-guided-tour';
import {
    instantAnalysisRDPTour,
    instantAnalysisRLPTour,
    relationshipsListTour,
    vendorTieringTour,
    welcomeTour,
    riskAnalysisListTour,
} from '@shared/spotlight-tour/tours';
import { Router } from '@angular/router';

export enum SpotlightTourType {
    WELCOME = 'WELCOME',
    RLP = 'RLP',
    VENDOR_TIERING = 'VENDOR_TIERING',
    INSTANT_ANALYSIS_RLP = 'INSTANT_ANALYSIS_RLP',
    INSTANT_ANALYSIS_RDP = 'INSTANT_ANALYSIS_RDP',
    RISK_ANALYSIS_PAGE = 'RISK_ANALYSIS_PAGE',
}

export const getToursByType = (router?: Router): Record<SpotlightTourType, GuidedTour> => ({
    WELCOME: welcomeTour(),
    RLP: relationshipsListTour(),
    VENDOR_TIERING: vendorTieringTour(),
    INSTANT_ANALYSIS_RLP: instantAnalysisRLPTour(router),
    INSTANT_ANALYSIS_RDP: instantAnalysisRDPTour(),
    RISK_ANALYSIS_PAGE: riskAnalysisListTour(),
});
