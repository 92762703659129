import { GuidedTour, Orientation } from 'ngx-guided-tour';

export const riskAnalysisListTour = (): GuidedTour => {
    return {
        tourId: 'riskAnalysis-page',
        steps: [
            {
                title: 'Welcome to the New & Improved Risk Analysis!',
                content: 'We’ve redesigned the layout to make it easier to navigate—same data, better experience.',
            },
            {
                title: 'Risk dimensions & custom controls at a glance',
                content:
                    'Quickly assess risk with a clear view of your risk dimensions and custom controls—no extra digging required.',
                selector: 'app-risk-analysis-navigation-menu',
                orientation: Orientation.Right,
            },
            {
                title: 'Expanded detection details',
                content: 'Click on a detection to see metadata and extra context in a new side panel.',
                selector: 'app-detection-card',
                orientation: Orientation.Top,
                action: () => {
                    const controlGroupComponent = document.querySelector('app-control-group');
                    const firstExpansionPanel =
                        controlGroupComponent?.querySelector<HTMLElement>('mat-expansion-panel-header');
                    firstExpansionPanel?.click();
                },
            },
            {
                title: 'Artifacts have moved',
                content: 'Find the Artifacts table in its own tab for a cleaner, more organized view.',
                selector: '.artifacts-tab-header',
                orientation: Orientation.Right,
            },
        ],
    };
};
