import { UploadURLArtifactRequest } from '../artifact';

export enum RiskAdvisoryType {
    SECURITY = 'SECURITY',
    COMPLIANCE = 'COMPLIANCE',
    VULNERABILITY = 'VULNERABILITY',
    SEC_8K = 'SEC_8K',
    LEGAL = 'LEGAL',
    GEOPOLITICAL = 'GEOPOLITICAL',
    FINANCIAL = 'FINANCIAL',
    OPERATIONAL = 'OPERATIONAL',
}

export enum RiskAdvisoryTypeLabels {
    SECURITY = 'Security',
    COMPLIANCE = 'Compliance',
    VULNERABILITY = 'Vulnerability',
    SEC_8K = 'SEC 8K Filing',
    LEGAL = 'Legal',
    GEOPOLITICAL = 'Geopolitical',
    FINANCIAL = 'Financial',
    OPERATIONAL = 'Operational',
}

export interface CreateUpdateRiskAdvisoryRequest {
    id: string;
    orgId: number;
    type: RiskAdvisoryType;
    publishedDate: Date;
    title: string;
    description?: string;
    material?: boolean;
    provisional?: boolean;
    urlArtifactSaveRequest: UploadURLArtifactRequest;
}

export interface RiskAdvisory {
    id: string;
    orgId: number;
    orgName: string;
    type: RiskAdvisoryType;
    url: string;
    title: string;
    description: string;
    createdDate: Date;
    updatedDate: Date;
    publishedDate: Date;
    material: boolean;
    provisional: boolean;
}

export interface NetworkExposureRiskAdvisory extends RiskAdvisory {
    networkExposureCount: number;
    isNthParty: boolean;
    isVendor: boolean;
    material: boolean;
    provisional: boolean;
    uniqueRelationshipId: number;
}

export interface SummaryResponse {
    title: string;
    date: string;
    summary: string;
}

export interface CheckDuplicateRiskAdvisoryRequest {
    orgId: number;
    url: string;
}
