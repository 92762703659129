<form [formGroup]="subscriberFormGroup">
    <div class="modal-header d-flex flex-column">
        <div class="d-flex w-100 align-items-center mb-3">
            <legend class="modal-title">Subscribe a Contact</legend>
            <button
                mat-icon-button
                type="button"
                class="btn-close shrink"
                data-dismiss="modal"
                aria-hidden="true"
                (click)="close()"
            ></button>
        </div>
        <p class="mb-4 w-100">Subscribers will receive email notifications on updates to this relationship.</p>
        <app-radiobutton-group
            class="w-100"
            [type]="'toggle'"
            [direction]="'row'"
            [radiobuttonGroupName]="'selectContactRadiobuttonGroup'"
            [formControl]="selectContactFormControl"
        >
            <ng-template radiobutton-group-item [value]="true" let-forItem="forItem">
                <label [for]="forItem">Subscribe an existing contact</label>
            </ng-template>

            <ng-template radiobutton-group-item [value]="false" let-forItem="forItem">
                <label [for]="forItem">Create a new contact and subscribe</label>
            </ng-template>
        </app-radiobutton-group>
    </div>
    <div class="modal-body">
        <div class="form-group required mb-4 mt-2" *ngIf="showContactList">
            <label>Contact</label>
            <ngx-select
                optionValueField="ref"
                optionTextField="name"
                formControlName="contact"
                [items]="contacts$ | async"
                [isFocused]="false"
                placeholder="Select an existing contact"
            >
                <ng-template ngx-select-option let-option>
                    <div [ngClass]="{ 'border-bottom pb-1': option.value.email === currentUser?.email }">
                        <span>{{ option.value.firstName }} {{ option.value.lastName }} • {{ option.value.email }}</span>
                        <span *ngIf="option.value.email === currentUser?.email"> • (you)</span>
                        <span class="d-block">{{ option.value.businessUnitName }}</span>
                    </div>
                </ng-template>
            </ngx-select>
        </div>

        <ng-container *ngIf="!showContactList">
            <div class="form-group required">
                <label for="field_first_name">First Name</label>
                <input
                    type="text"
                    class="form-control"
                    name="firstName"
                    id="field_first_name"
                    data-pw="firstNameInput"
                    formControlName="firstName"
                    placeholder="Enter first name"
                    required
                />
                <span class="text-error" *ngIf="showFirstNameLengthError">Maximum Length: 50</span>
            </div>
            <div class="form-group required">
                <label for="field_last_name">Last Name</label>
                <input
                    type="text"
                    class="form-control"
                    name="lastName"
                    id="field_last_name"
                    data-pw="lastNameInput"
                    formControlName="lastName"
                    placeholder="Enter last name"
                    required
                />
                <span class="text-error" *ngIf="showLastNameLengthError">Maximum Length: 50</span>
            </div>

            <div class="form-group required">
                <label for="field_email">Email Address</label>
                <input
                    type="text"
                    class="form-control"
                    name="email"
                    id="field_email"
                    data-pw="emailInput"
                    formControlName="email"
                    placeholder="Enter email"
                    required
                />
                <span class="text-error" *ngIf="showEmailLengthError">Maximum Length: 100<br /></span>
                <span class="text-error" *ngIf="showContactAlreadyExistsError"
                    >A contact with this email address already exists.</span
                >
                <span class="text-error" *ngIf="showInvalidEmailDomainError"
                    >The contacts domain is invalid. Valid domains: <span>{{ orgDomains.join(',') }}</span></span
                >
                <span class="text-error" *ngIf="showInvalidEmailSyntaxError">
                    The username length is invalid. Maximum size for username 64 characters.
                </span>
            </div>
            <div class="form-group required mb-0">
                <label for="field_businessUnit">Business Unit</label>
                <ngx-select
                    optionValueField="ref"
                    optionTextField="name"
                    formControlName="businessUnit"
                    [items]="businessUnits$ | async"
                    [isFocused]="false"
                    placeholder="Select business unit"
                    data-pw="selectBusinessUnitInput"
                >
                </ngx-select>
            </div>
        </ng-container>
    </div>
</form>
<div class="modal-footer">
    <button mat-button visoButton="secondary" class="me-2" type="button" (click)="close()">Cancel</button>
    <button
        mat-button
        visoButton="primary"
        type="button"
        [disabled]="subscriberFormGroup.invalid"
        toggleDisableOnActions
        [enablingActions]="[
            RequestActions.SubscribeContactRequestSuccess,
            RequestActions.SubscribeContactRequestFailed,
            SubscriberActions.CreateAndSubscribeContactRequestFailed,
        ]"
        [disablingActions]="[
            RequestActions.SubscribeContactRequest,
            SubscriberActions.CreateAndSubscribeContactRequest,
        ]"
        (click)="save()"
    >
        Save
    </button>
</div>
