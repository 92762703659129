import { FrameworkMappingType } from '@entities/framework/models/framework-mapping.model';
import * as fromRoot from '../../../shared/redux/state';
import { Detection } from '../models/detection.model';

export const artifactIntelligenceStateKey: string = 'artifact-intelligence';

export interface ArtifactIntelligenceState {
    isDetectionDetailsDrawerOpen: boolean;
    detectionDetailsDrawerData: DetectionDetailsDrawerData | null;
}

export interface DetectionDetailsDrawerData {
    detection: Detection;
    controlName: string;
    domainControlName: string;
    frameworkType: FrameworkMappingType;
}

export const initialState: ArtifactIntelligenceState = {
    isDetectionDetailsDrawerOpen: false,
    detectionDetailsDrawerData: null,
};

export interface AppState extends fromRoot.AppState {
    artifactIntelligence: ArtifactIntelligenceState;
}
