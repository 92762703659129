import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { closeVendorTierDescriptionDialog } from '../redux/relationships.actions';

@Component({
    selector: 'app-vendor-tier-description-dialog',
    templateUrl: './vendor-tier-description-dialog.component.html',
    styleUrl: './vendor-tier-description-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorTierDescriptionDialogComponent implements OnInit {
    @Input({ required: true })
    tierName: string;

    @Input({ required: true })
    currentDescription: string;

    newDescriptionControl: FormControl<string>;

    constructor(
        private _dialogRef: MatDialogRef<VendorTierDescriptionDialogComponent>,
        private _store$: Store,
    ) {}

    ngOnInit(): void {
        this.newDescriptionControl = new FormControl(this.currentDescription);
        this._dialogRef.afterClosed().subscribe(() => {
            this._store$.dispatch(closeVendorTierDescriptionDialog());
        });
    }

    close(description: string): void {
        event.stopPropagation();
        this._dialogRef.close(description);
    }
}
