<div class="row mb-2">
    <div class="col">
        <h3 class="lead">Tier</h3>
    </div>
    <div class="col-auto">
        <a
            *jhiHasAnyAuthority="Roles.OrgAdmin"
            exportRemovableAction
            class="text-primary"
            role="button"
            [routerLink]="editLink"
            [replaceUrl]="true"
        >
            Move
        </a>
    </div>
</div>
<div class="row">
    <div class="col">
        <p class="mb-0" [ngClass]="{ 'text-muted': !currentTier }">
            {{ currentTier?.name ?? 'Unassigned' }}
        </p>
    </div>
</div>
