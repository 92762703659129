<header class="content-heading">
    <section>
        Client Profile
        <small>Manage Client Profile for Orgs</small>
    </section>
</header>

<main>
    <section class="pb-4 vendor-search" [formGroup]="clientProfileForm">
        <app-vendor-search formControlName="vendorRef" [customOption]="false" [onlyClients]="true" />
    </section>

    <section>
        <div class="card card-default">
            <div class="card-body">
                <tabset [justified]="true" class="min-height">
                    <tab>
                        <ng-template tabHeading>Risk Levels</ng-template>

                        @if (!getOrgIdValue) {
                            <div class="no-org-selected mt-4">
                                <i class="fa fa-info-circle"></i>
                                <p>Please select an organization</p>
                            </div>
                        } @else if (!loadingProfile && !clientProfileId) {
                            <div class="no-org-selected mt-4">
                                <i class="fa fa-info-circle"></i>
                                <p>{{ orgName }} does not have a Client Profile</p>
                            </div>
                        }

                        <section *ngIf="loadingProfile">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="sk-spinner sk-spinner-pulse"></div>
                            </div>
                        </section>

                        <app-risk-levels-config
                            *ngIf="!!clientProfileId && !loadingProfile"
                            [clientProfileId]="clientProfileId"
                            [riskLevels]="riskLevelsConfig"
                        ></app-risk-levels-config>
                    </tab>
                    <tab>
                        <ng-template tabHeading>Data Sensitivity Levels</ng-template>
                        @if (!getOrgIdValue) {
                            <div class="no-org-selected mt-4">
                                <i class="fa fa-info-circle"></i>
                                <p>Please select an organization</p>
                            </div>
                        } @else if (!loadingProfile && !clientProfileId) {
                            <div class="no-org-selected mt-4">
                                <i class="fa fa-info-circle"></i>
                                <p>{{ orgName }} does not have a Client Profile</p>
                            </div>
                        }

                        <section *ngIf="loadingProfile">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="sk-spinner sk-spinner-pulse"></div>
                            </div>
                        </section>

                        <app-data-sensitivity-levels-config
                            *ngIf="!!clientProfileId && !loadingProfile"
                            [clientProfileId]="clientProfileId"
                            [dataSensitivityLevels]="dataSensitivityLevels"
                            (refreshClientProfile)="refreshClientProfile()"
                        ></app-data-sensitivity-levels-config>
                    </tab>
                    <tab>
                        <ng-template tabHeading>Data Types</ng-template>
                        @if (!getOrgIdValue) {
                            <div class="no-org-selected mt-4">
                                <i class="fa fa-info-circle"></i>
                                <p>Please select an organization</p>
                            </div>
                        } @else if (!loadingProfile && !clientProfileId) {
                            <div class="no-org-selected mt-4">
                                <i class="fa fa-info-circle"></i>
                                <p>{{ orgName }} does not have a Client Profile</p>
                            </div>
                        }

                        <section *ngIf="loadingProfile">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="sk-spinner sk-spinner-pulse"></div>
                            </div>
                        </section>

                        <app-data-types-config
                            *ngIf="!!clientProfileId && !loadingProfile"
                            [clientProfileId]="clientProfileId"
                            [dataTypes]="dataTypesConfig"
                            [orgId]="getOrgIdValue"
                            [profileControls]="controlsConfig"
                            (dataTypeSubmitted)="updateClientProfileData()"
                        ></app-data-types-config>
                    </tab>
                    <tab>
                        <ng-template tabHeading>Org Controls</ng-template>

                        @if (!getOrgIdValue) {
                            <div class="no-org-selected mt-4">
                                <i class="fa fa-info-circle"></i>
                                <p>Please select an organization</p>
                            </div>
                        } @else if (!loadingProfile && !clientProfileId) {
                            <div class="no-org-selected mt-4">
                                <i class="fa fa-info-circle"></i>
                                <p>{{ orgName }} does not have a Client Profile</p>
                            </div>
                        }

                        <section *ngIf="loadingProfile">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="sk-spinner sk-spinner-pulse"></div>
                            </div>
                        </section>

                        <app-controls-config
                            *ngIf="!!clientProfileId"
                            [clientProfileId]="clientProfileId"
                            [orgId]="getOrgIdValue"
                            [profileControls]="controlsConfig"
                            (controlStatusChange)="refreshClientProfile()"
                        ></app-controls-config>
                    </tab>
                </tabset>
            </div>
        </div>
    </section>
</main>
