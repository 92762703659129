import { BaseEntity } from './../../shared';
import { ContextType } from '../context-type';

export class ControlDomain implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public influence?: number,
        public longDescription?: string,
        public relevantLinks?: string,
        public orgId?: number,
        public contextTypes?: ContextType[],
        public controls?: Control[],
        public coverage?: number,
        public controlDomainType?: ControlDomainType,
        // For frontend Use only
        public disabled?: boolean,
        public relevant?: boolean,
        public hasDetections?: boolean,
        public missingInformation?: boolean,
    ) {}
}

export enum ControlDomainType {
    SECURITY = 'SECURITY',
    PRIVACY = 'PRIVACY',
    RESILIENCE = 'RESILIENCE',
    ARTIFICIAL_INTELLIGENCE = 'ARTIFICIAL_INTELLIGENCE',
    PRODUCT_SECURITY = 'PRODUCT_SECURITY',
    CYBER_INSURANCE = 'CYBER_INSURANCE',
    SERVICE_LOCATIONS = 'SERVICE_LOCATIONS',
    SUBSERVICERS = 'SUBSERVICERS',
    SUPPLEMENTAL_QUESTIONNAIRE = 'SUPPLEMENTAL_QUESTIONNAIRE',
}

export enum ControlDomainTypeLabels {
    SECURITY = 'Security',
    PRIVACY = 'Privacy',
    RESILIENCE = 'Resilience',
    ARTIFICIAL_INTELLIGENCE = 'Artificial Intelligence',
    PRODUCT_SECURITY = 'Product Security',
    CYBER_INSURANCE = 'Cyber Insurance',
    SERVICE_LOCATIONS = 'Service Locations',
    SUBSERVICERS = 'Subservicers',
    SUPPLEMENTAL_QUESTIONNAIRE = 'Supplemental Questionnaires',
}

export class Control implements BaseEntity {
    id?: number;
    name?: string;
    coverage?: number;
    description?: string;
    controlDomainId?: number;
    controlDomainInfluence?: number;
    controlDomainName?: string;
    controlDomainDescription?: string;
}
