import { BaseEntity } from './../../shared';

export enum NotificationStatus {
    NEW = 'NEW',
    READ = 'READ',
    DISMISSED = 'DISMISSED',
}

export enum NotificationType {
    EVENT_NOTIFICATION = 'event',
    LINK_NOTIFICATION = 'link',
    TIP_NOTIFICATION = 'tip',
    RISK_ADVISORY_NOTIFICATION = 'risk-advisory',
}

export enum EventNotificationType {
    ASSESSMENT_ARTIFACT_UPDATE_COMPLETED = 'ASSESSMENT_ARTIFACT_UPDATE_COMPLETED',
    ASSESSMENT_COMPLETE = 'ASSESSMENT_COMPLETE',
    ASSESSMENT_CANCELLED_AUTOMATICALLY = 'ASSESSMENT_CANCELLED_AUTOMATICALLY',
    UPCOMING_RECERTIFICATION = 'UPCOMING_RECERTIFICATION',
    AUTOMATIC_RECERTIFICATION = 'AUTOMATIC_RECERTIFICATION',
    RECERTIFICATION_OVERDUE = 'RECERTIFICATION_OVERDUE',
    ASSESSMENT_CANCELLED = 'ASSESSMENT_CANCELLED',
    ARTIFACT_EXPIRING = 'ARTIFACT_EXPIRING',
    ARTIFACT_EXPIRED = 'ARTIFACT_EXPIRED',
    DATA_TYPE_CHANGED = 'DATA_TYPE_CHANGED',
    FILE_HASH_MANAGEMENT_SUCCESS = 'FILE_HASH_MANAGEMENT_SUCCESS',
    FILE_HASH_MANAGEMENT_FAILED = 'FILE_HASH_MANAGEMENT_FAILED',
    RISK_MODEL_REFRESH_SUCCESS = 'RISK_MODEL_REFRESH_SUCCESS',
    RISK_MODEL_REFRESH_FAILED = 'RISK_MODEL_REFRESH_FAILED',
    ASSESSMENT_RECOMMENDATION_REFRESH_SUCCESS = 'ASSESSMENT_RECOMMENDATION_REFRESH_SUCCESS',
    ASSESSMENT_RECOMMENDATION_REFRESH_FAILED = 'ASSESSMENT_RECOMMENDATION_REFRESH_FAILED',
    SUPPLEMENTAL_QUESTIONNAIRES_ANSWERED_AUTOMATICALLY = 'SUPPLEMENTAL_QUESTIONNAIRES_ANSWERED_AUTOMATICALLY',
    SUPPLEMENTAL_QUESTIONNAIRE_REPROCESSING_COMPLETED = 'SUPPLEMENTAL_QUESTIONNAIRE_REPROCESSING_COMPLETED',
    ARTIFACT_INTELLIGENCE_AVAILABLE = 'ARTIFACT_INTELLIGENCE_AVAILABLE',
    ARTIFACTS_TRANSFERRED_FROM_TRUST_PROFILE = 'ARTIFACTS_TRANSFERRED_FROM_TRUST_PROFILE',
    ORG_PUBLIC_SEARCH_INFO_COMPLETED = 'ORG_PUBLIC_SEARCH_INFO_COMPLETED',
    ARTIFACT_ANTIVIRUS_SCAN_SUCCESS = 'ARTIFACT_ANTIVIRUS_SCAN_SUCCESS',
    ARTIFACT_ANTIVIRUS_SCAN_FAILED = 'ARTIFACT_ANTIVIRUS_SCAN_FAILED',
    BULK_RELATIONSHIP_ASSESSMENTS_CREATED = 'BULK_RELATIONSHIP_ASSESSMENTS_CREATED',
    BULK_RELATIONSHIP_ASSESSMENTS_CANCELLED = 'BULK_RELATIONSHIP_ASSESSMENTS_CANCELLED',
    BULK_RELATIONSHIP_MARKED_NOT_ONBOARDED = 'BULK_RELATIONSHIP_MARKED_NOT_ONBOARDED',
    BULK_RELATIONSHIP_ARCHIVED = 'BULK_RELATIONSHIP_ARCHIVED',
}

export enum TipNotificationType {
    ADD_LOGO = 'ADD_LOGO',
    ADD_RELATIONSHIP = 'ADD_RELATIONSHIP',
    EXPLORE_RELATIONSHIP = 'EXPLORE_RELATIONSHIP',
    VISO_RELATIONSHIP = 'VISO_RELATIONSHIP',
}

export interface NotificationConnection {
    edges: [NotificationEdge];
    pageInfo: PageInfo;
    totalCount: number;
}

export interface NotificationEdge {
    node: Notification;
    cursor: String;
}

export interface PageInfo {
    startCursor: String;
    endCursor: String;
    hasNextPage: Boolean;
    hasPreviousPage: Boolean;
}

export interface BaseNotification extends BaseEntity {
    id?: number;
    notificationType: NotificationType;
    visoUserId?: number;
    notificationText?: string;
    status?: NotificationStatus;
    createdDate?: any;
    humanizedCreatedDate?: string;
    modifiedDate?: any;
}

export interface EventNotification extends BaseNotification {
    notificationType: NotificationType.EVENT_NOTIFICATION;
    data: any;
    eventNotificationType: EventNotificationType;
}

export interface LinkNotification extends BaseNotification {
    notificationType: NotificationType.LINK_NOTIFICATION;
    description: string;
    anchorText: string;
    url: string;
}

export interface TipNotification extends BaseNotification {
    notificationType: NotificationType.TIP_NOTIFICATION;
    tipType: TipNotificationType;

    // Frontend use only
    relationshipId?: number;
}

export interface RiskAdvisoryNotification extends LinkNotification {}

export interface CreateLinkNotificationPayload
    extends Pick<LinkNotification, 'description' | 'anchorText' | 'url' | 'notificationType'> {
    orgId?: number;
}

export type Notification = EventNotification | LinkNotification | TipNotification | RiskAdvisoryNotification;
