import {
    Artifact,
    ControlValidation,
    ControlValidationDetection,
    ControlValidationStatus,
    FileArtifact,
    QuestionnaireArtifact,
} from '../../artifact';
import { ControlAssessment } from '../../risk-assessment';

export interface RelationshipControlDomainAssessments {
    artifacts: ControlDomainFileArtifact[] | ControlDomainQuestionnaireArtifact[];
    controlDomainAssessments: ControlDomainAssessment[];
}

export interface ControlDomainArtifactValidation {
    id: number;
    controlId: number;
    controlDomainId: number;
    detectedControls: ControlDomainControlValidation[];
    expirationDate: string;
    expired: boolean;
    validationMessage: string;
    auditReportType: string;
    subservicer: boolean;
    subservicerName: string;
    auditReportAssurance: number;
    auditReportAssuranceLevel: AssuranceLevels;
}

export interface ControlDomainArtifact extends Pick<Artifact, 'id' | 'type' | 'validation'> {}

export interface ControlDomainFileArtifact extends ControlDomainArtifact, Pick<FileArtifact, 'fileName'> {}

export interface ControlDomainQuestionnaireArtifact
    extends ControlDomainArtifact,
        Pick<QuestionnaireArtifact, 'fileName' | 'supplemental' | 'answers'> {}

export interface ControlDomainControlValidation
    extends Pick<ControlValidation, 'id' | 'controlDomainId' | 'controlId' | 'sourceType' | 'validationDetections'> {
    validationDetections: ControlDomainControlValidationDetection[];
}

export interface ControlDomainControlValidationDetection
    extends Omit<ControlValidationDetection, 'controlValidationId' | 'createdBy' | 'updatedBy'> {}

export interface ControlsWithDetections {
    controlId: number;
    controlName: string;
    detections?: ControlDomainControlValidationDetectionWithArtifact[];
    detectionsCount?: number;
}

export enum ControlDomainAssessmentStatus {
    DISABLED = 'DISABLED',
    OUT_OF_SCOPE = 'OUT_OF_SCOPE',
}

export type ControlDomainAssessmentValidationStatus = ControlValidationStatus | ControlDomainAssessmentStatus;

export interface ControlDomainAssessment {
    id: number;
    controlDomainId: number;
    controlName: string; // Frontend only
    answer: string; // Frontend only
    coverage: number;
    longDescription: string;
    status: ControlDomainAssessmentValidationStatus;
    assuranceLevel: AssuranceLevels;
    validated: boolean;
    relevant: boolean;
    followupNeeded: boolean;
    controlAssessments: ControlAssessment[];
    individualControls: ControlsWithDetections[]; // Frontend only
    forSuppQ: boolean; // Frontend only
    atLeastOneDetectionIsTested?: boolean; // Frontend only
}

export const defaultAssuranceLevel = 0.75;

export enum AssuranceLevels {
    LIMITED = 'LIMITED',
    MODERATE = 'MODERATE',
    STANDARD = 'STANDARD',
    ADVANCED = 'ADVANCED',
}

export interface ControlDomainControlValidationDetectionWithArtifact extends ControlDomainControlValidationDetection {
    artifact: ControlDomainFileArtifact &
        Pick<
            ControlDomainArtifactValidation,
            | 'expirationDate'
            | 'expired'
            | 'validationMessage'
            | 'subservicer'
            | 'subservicerName'
            | 'auditReportAssurance'
            | 'auditReportAssuranceLevel'
        >;
    auditReportType: string;
    controlId: number;
    controlDomainId: number;
    frameworkMapping: string;
}

export interface PopulatedControlDomainControlAssessment extends ControlDomainAssessment {
    sharedResponsibility: boolean;
    controlName: string;
    exceptionsCount?: number;
    sharedResponsibilityCount?: number;
    cuecCount?: number;
    subservicerCount?: number;

    detections: ControlDomainControlValidationDetectionWithArtifact[];
    detectionsTotalCount?: number;
}

export interface GroupedSecurityControlDomain {
    controlValidationStatus: SecurityControlDomainGroupStatus;
    controlDomainAssessments: PopulatedControlDomainControlAssessment[];
    groupName?: string;
    supplementalQuestionnaireGroupName?: string;
}

export enum SecurityControlDomainGroupStatus {
    MISSING_INFORMATION = 'MISSING_INFORMATION',
    PRESENT = 'PRESENT',
    INSUFFICIENT = 'INSUFFICIENT',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    UNVALIDATED = 'UNVALIDATED',
    NO_INFORMATION = 'NO_INFORMATION',
    OUT_OF_SCOPE = 'OUT_OF_SCOPE',
    DISABLED = 'DISABLED',
}

export enum SecurityControlDomainGroupStatusLabels {
    MISSING_INFORMATION = 'Follow Up Needed',
    PRESENT = 'Present',
    INSUFFICIENT = 'Insufficient',
    NOT_APPLICABLE = 'Not Applicable',
    UNVALIDATED = 'Unvalidated',
    NO_INFORMATION = 'No Info',
    OUT_OF_SCOPE = 'Out of Scope',
    DISABLED = 'Disabled',
}
