import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '@shared/redux/state';
import {
    getCurrentClientProfileRequest,
    getServerSessionCompleted,
    getServerSessionRequest,
    getUserProfileRequest,
    getUserProfileRequestSuccess,
    initSession,
    loadClientProfile,
    loadSession,
    loginRequest,
    logoutFailed,
    logoutRequest,
    logoutSuccess,
    updateUserProfileRequest,
    updateUserProfileRequestSuccess,
    updateVisoUserMetadata,
    updateVisoUserMetadataFailed,
    updateVisoUserMetadataSuccess,
} from './session.actions';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, fromEvent, of } from 'rxjs';
import { getUserAccount, getUserProfile } from './session.selectors';
import { AccountService, AuthServerProvider } from '../../../shared';
import { ClientProfileService } from '../../../admin';
import { sessionStateKey } from './session.state';
import { currentUserRefreshActions } from './current-user-refresh-actions';
import { UserProfile, VisoUserService } from '@entities/viso-user';
import { Router } from '@angular/router';

const homeUrl = '/home';

@Injectable()
export class SessionEffects implements OnInitEffects {
    ngrxOnInitEffects(): Action {
        return initSession();
    }

    /**
     * Executes immediately
     * Seeks session on store and if there is not any it calls `getServerSessionRequest` action
     */
    initSession$ = createEffect(() =>
        this._actions$.pipe(
            ofType(initSession),
            withLatestFrom(this._store$.pipe(select(getUserAccount))),
            switchMap(([, account]) => {
                if (!account && !location.href.includes(homeUrl)) {
                    return of(getServerSessionRequest());
                }

                return EMPTY;
            }),
        ),
    );

    /**
     * SignIn Effect
     * Triggers when `LoginRequest` gets dispatched
     * It will show a Spring Security generated login page with links to configured OIDC providers.
     */
    signIn$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(loginRequest),
                tap(() => {
                    let hashlessExternalUrl = this._location
                        .prepareExternalUrl('oauth2/authorization/oidc')
                        .replace('#', '');
                    location.href = `${location.origin}${hashlessExternalUrl}`;
                }),
            ),
        { dispatch: false },
    );

    /**
     * Get Server Session Effect
     * Triggers when `GetServerSessionRequest` gets dispatched
     * It executes a request to get the server session
     */
    getServerSession$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getServerSessionRequest),
            switchMap(() =>
                this._accountService.fetch().pipe(
                    mergeMap((response) => {
                        localStorage.setItem(sessionStateKey, 'authenticated');
                        window['dataLayer'] = window?.['dataLayer'] || [];
                        window['dataLayer'].push({
                            event: 'loginSuccess', // Custom event name for successful login
                            currentDate: new Date(), // Pushing the current date to the data layer
                        });
                        return of(
                            loadSession({ account: response }),
                            getCurrentClientProfileRequest(),
                            getUserProfileRequest(),
                            getServerSessionCompleted({ error: null }),
                        );
                    }),
                    catchError((error) => {
                        return of(getServerSessionCompleted({ error }));
                    }),
                ),
            ),
        ),
    );

    getUserProfileRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getUserProfileRequest),
            switchMap(() =>
                this._visoUserService.getUserProfile().pipe(
                    map((userProfile) => getUserProfileRequestSuccess({ userProfile })),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );

    showWelcomeDialogWhenProfileNeedsInfo$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(getUserProfileRequestSuccess),
                filter(
                    ({ userProfile }) =>
                        userProfile.showWelcomeMessage ||
                        userProfile.showFirstLastNameForm ||
                        userProfile.showOrganizationNameForm,
                ),
                tap(() =>
                    this._router.navigate(
                        [
                            {
                                outlets: {
                                    popup: 'new-user',
                                },
                            },
                        ],
                        {
                            replaceUrl: true,
                        },
                    ),
                ),
            ),
        { dispatch: false },
    );

    updateUserProfileRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateUserProfileRequest),
            withLatestFrom(this._store$.select(getUserProfile)),
            switchMap(([{ userProfile }, storedUserProfile]) => {
                return this._visoUserService
                    .updateUserProfile({ ...storedUserProfile, ...(userProfile as UserProfile) })
                    .pipe(
                        map((result) => updateUserProfileRequestSuccess({ userProfile: result })),
                        catchError(() => EMPTY),
                    );
            }),
        ),
    );

    updateVisoUserMetadata$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateVisoUserMetadata),
            switchMap(({ request }) =>
                this._visoUserService.updateVisoUserMetadata(request).pipe(
                    map((response) => updateVisoUserMetadataSuccess()),
                    catchError((error) => of(updateVisoUserMetadataFailed({ error }))),
                ),
            ),
        ),
    );

    refreshCurrentUser$ = createEffect(() =>
        this._actions$.pipe(
            ofType(...currentUserRefreshActions),
            withLatestFrom(this._store$.pipe(select(getUserAccount))),
            map(() => getServerSessionRequest()),
        ),
    );

    logoutFailed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(logoutFailed),
                tap(() => {
                    this._store$.dispatch(loginRequest());
                }),
            ),
        { dispatch: false },
    );

    /**
     * Get Client Profile Request Effect
     * Triggers when `GetCurrentClientProfileRequest` gets dispatched
     * It executes a request to get the client profile from the current session
     */
    getCurrentClientProfileRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getCurrentClientProfileRequest),
            switchMap(() =>
                this._clientProfileService.getForCurrentSession().pipe(
                    map((response) => loadClientProfile({ profile: response })),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );

    /**
     * SignOut Effect
     * Triggers when `LogoutRequest` gets dispatched
     * Executes `AuthServerProvider.logout()` to logout from server
     */
    signOut$ = createEffect(() =>
        this._actions$.pipe(
            ofType(logoutRequest),
            switchMap(() =>
                this._authSessionService.logout().pipe(
                    tap((response) => {
                        const data = response.body;
                        let logoutUrl = data.logoutUrl;
                        const redirectUri = `${location.origin}`;

                        // if Keycloak, uri has protocol/openid-connect/token
                        if (logoutUrl.indexOf('/protocol') > -1) {
                            logoutUrl = logoutUrl + '?redirect_uri=' + redirectUri;
                        } else {
                            // Okta
                            logoutUrl =
                                logoutUrl +
                                '?id_token_hint=' +
                                data.idToken +
                                '&post_logout_redirect_uri=' +
                                redirectUri;
                        }
                        window.location.href = logoutUrl;
                    }),
                    mergeMap(() => {
                        localStorage.removeItem(sessionStateKey);
                        return of(logoutSuccess());
                    }),
                    catchError(() => of(logoutFailed())),
                ),
            ),
        ),
    );

    /**
     * On Storage Change Effect
     * Triggers when `StorageEvent` gets fired
     * Cleans the state after logout and redirects to login
     */
    onStorageChange$ = createEffect(() =>
        fromEvent<StorageEvent>(window, 'storage').pipe(
            filter((evt) => evt.key === sessionStateKey),
            filter((evt) => !evt.newValue),
            concatMap(() => of(logoutSuccess(), loginRequest())),
        ),
    );

    constructor(
        private _store$: Store<AppState>,
        private _actions$: Actions,
        private _location: Location,
        private _accountService: AccountService,
        private _authSessionService: AuthServerProvider,
        private _clientProfileService: ClientProfileService,
        private _visoUserService: VisoUserService,
        private _router: Router,
    ) {}
}
