import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { filter, map, of, withLatestFrom } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { AssessmentSubmissionContext, AdditionalInformationSubSteps, StepIds } from '../../models';
import {
    expiredDocumentsScreenContinued,
    expiredDocumentsScreenSkipped,
    navigateToQuestionnaire,
    setCurrentCollectArtifactsSubStep,
    setCurrentStep,
    setSubmissionContext,
    submitPasscodeSuccess,
} from '../actions';
import {
    areThereUploadedArtifacts,
    getIsFollowupRequested,
    getShouldExpiredDocumentsScreenBePresented,
} from '../selectors';

@Injectable()
export class CollectArtifactsSubStepsNavigationEffects {
    initiallySetExpiredDocumentsSubStepWhenRequired$ = createEffect(() =>
        this._actions$.pipe(
            ofType(submitPasscodeSuccess),
            withLatestFrom(this._store$.select(getShouldExpiredDocumentsScreenBePresented)),
            filter(([, shouldExpiredDocumentsScreenBePresented]) => shouldExpiredDocumentsScreenBePresented),
            map(() =>
                setCurrentCollectArtifactsSubStep({
                    collectArtifactsSubStep: AdditionalInformationSubSteps.EXPIRED_DOCUMENTS,
                }),
            ),
        ),
    );

    navigateForwardFromExpiredDocuments$ = createEffect(() =>
        this._actions$.pipe(
            ofType(expiredDocumentsScreenContinued, expiredDocumentsScreenSkipped),
            map(() =>
                setCurrentCollectArtifactsSubStep({
                    collectArtifactsSubStep: AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS,
                }),
            ),
        ),
    );

    navigateToQuestionnaire$ = createEffect(() =>
        this._actions$.pipe(
            ofType(navigateToQuestionnaire),
            withLatestFrom(this._store$.select(areThereUploadedArtifacts), this._store$.select(getIsFollowupRequested)),
            switchMap(([, areThereArtifacts, isFollowupRequested]) =>
                !isFollowupRequested && areThereArtifacts
                    ? this._confirmDialogService.confirm({
                          title: 'Proceed to Questionnaire?',
                          message:
                              'One or more artifacts have already been uploaded for this assessment. If you choose to answer a questionnaire instead, we will discard these artifacts.',
                          confirmLabel: 'Yes, continue',
                          confirmButtonColor: 'warn',
                          cancelButtonColor: 'primary',
                      })
                    : of(true),
            ),
            filter(Boolean),
            mergeMap(() => [
                setSubmissionContext({ context: AssessmentSubmissionContext.QUESTIONNAIRE }),
                setCurrentStep({ stepId: StepIds.ADDITIONAL_INFORMATION }),
                setCurrentCollectArtifactsSubStep({
                    collectArtifactsSubStep: AdditionalInformationSubSteps.ANSWER_QUESTIONNAIRE,
                }),
            ]),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _confirmDialogService: MatConfirmDialogService,
    ) {}
}
