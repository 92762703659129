<div class="assurance-level" [ngClass]="direction === 'vertical' && 'flex-column'">
    <span class="assurance-level-title" *ngIf="!hideLabel">{{ assuranceLevel | titlecase }}</span>
    <div
        class="assurance-level-meter-wrapper d-flex"
        [ngClass]="assuranceLevelClass"
        [ngbTooltip]="!hideTooltip && assuranceLevel && (assuranceLevel | titlecase) + ' Assurance'"
    >
        <span class="assurance-level-dot mat-bg mat-border"></span>
        <span class="assurance-level-dot mat-bg mat-border"></span>
        <span class="assurance-level-dot mat-bg mat-border"></span>
        <span class="assurance-level-dot mat-bg mat-border"></span>
    </div>
</div>
