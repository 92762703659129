import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { OrgDetailsResponse, OrgService, OrgSize, OrgSizeLabels, OrgUrlType } from '@entities/org';
import { ClientLicenseView, VisoUserRole } from '@entities/viso-user';
import { Actions, ofType } from '@ngrx/effects';
import { uploadCompanyIconRequestSuccess, uploadCompanyLogoRequestSuccess } from './redux/your-organization.actions';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { YourOrganizationHelperService } from './your-organization-helper.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Country, storedCountries } from '@shared/model/countries';
import { BREADCRUMB_CONTAINER_TOKEN } from '@shared/dynamic-content/dynamic-content-injector';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { select, Store } from '@ngrx/store';
import { getClientLicense, getUserAuthority } from '../session/redux/session.selectors';
import {
    deleteBrandingColorRequestSuccess,
    deleteCompanyImagesRequestSuccess,
    saveBrandingColorRequestSuccess,
} from './branding/redux/branding.actions';
import { VendorSearchUtils } from '@entities/org/vendor-search.utils';
import { ControlDomainType } from '@entities/control-domain';
import { getEnabledControlDomainTypes } from '../controls/redux/controls.selectors';

export enum YourOrganizationTabsOrder {
    PROFILE,
    BRANDING,
    MANAGE_DOMAINS,
    LICENSE,
}

@Component({
    selector: 'app-your-organization',
    templateUrl: './your-organization.component.html',
    styleUrls: ['./your-organization.component.scss'],
})
export class YourOrganizationComponent implements OnInit, AfterViewInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    Roles = VisoUserRole;
    OrgSizeLabels = OrgSizeLabels;
    OrgSize = OrgSize;

    org: OrgDetailsResponse;

    countries: Country[] = storedCountries;
    headquartersLocation$ = new BehaviorSubject<string>('-');
    tabContentTrigger$ = new BehaviorSubject<YourOrganizationTabsOrder>(null);

    hasDomainsBrandingEnabled$: Observable<boolean>;
    hasLicenseInfoEnabled$: Observable<boolean>;
    clientLicense$: Observable<ClientLicenseView>;
    enabledControlDomainTypes$: Observable<ControlDomainType[]>;

    isCurrentUserOrgAdmin$: Observable<boolean>;

    YourOrganizationTabsOrder = YourOrganizationTabsOrder;

    @ViewChildren(TabDirective)
    private _orgTabs: QueryList<TabDirective>;

    private _unsub$: Subject<void> = new Subject<void>();

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _orgService: OrgService,
        private _yourOrgHelper: YourOrganizationHelperService,
        private _featureFlagsService: FeatureFlagService,
        private _cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.clientLicense$ = this._store$.select(getClientLicense);
        this.enabledControlDomainTypes$ = this._store$.select(getEnabledControlDomainTypes);

        this.hasDomainsBrandingEnabled$ = this._featureFlagsService.flagsLoaded.pipe(
            map((flagset) => flagset[FeatureFlags.DOMAINS_BRANDING] ?? false),
        );

        this.hasLicenseInfoEnabled$ = this._featureFlagsService.flagsLoaded.pipe(
            map((flags) => flags[FeatureFlags.LICENSE_INFO] ?? false),
        );

        this._actions$
            .pipe(
                ofType(
                    uploadCompanyLogoRequestSuccess,
                    uploadCompanyIconRequestSuccess,
                    saveBrandingColorRequestSuccess,
                    deleteBrandingColorRequestSuccess,
                    deleteCompanyImagesRequestSuccess,
                ),
                tap(() => {
                    this.loadOrgData();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.tabContentTrigger$
            .pipe(
                map((tabIndex) => {
                    switch (tabIndex) {
                        case YourOrganizationTabsOrder.PROFILE:
                            return () => {
                                if (!this.headquartersLocation$.value || !this.org) {
                                    this.loadOrgData();
                                }
                            };
                        default:
                            return;
                    }
                }),
                filter((fn) => !!fn),
                takeUntil(this._unsub$),
            )
            .subscribe((fn) => fn());

        this.loadOrgData();
        this.registerChangeInYourOrg();

        this.isCurrentUserOrgAdmin$ = this._store$.pipe(
            select(getUserAuthority(VisoUserRole.OrgAdmin)),
            takeUntil(this._unsub$),
        );
    }

    ngAfterViewInit(): void {
        this._orgTabs.changes.pipe(takeUntil(this._unsub$)).subscribe((tabs) => {
            tabs.toArray().forEach((tab, index) => {
                tab.selectTab
                    .pipe(
                        map(() => index as YourOrganizationTabsOrder),
                        takeUntil(this._unsub$),
                    )
                    .subscribe((tabIndex) => this.tabContentTrigger$.next(tabIndex));
                if (tab.active) {
                    this.tabContentTrigger$.next(index as YourOrganizationTabsOrder);
                }

                this._cdr.detectChanges();
            });
        });
    }

    loadOrgData() {
        this._orgService.getCurrentOrg().subscribe((orgResponse: HttpResponse<OrgDetailsResponse>) => {
            this.org = orgResponse.body;
            this.org.privacyPolicyUrl = orgResponse.body.urls[OrgUrlType.PRIVACY_POLICY];

            this.headquartersLocation$.next(VendorSearchUtils.getVendorFormattedAddress(this.org) ?? '-');
        });
    }

    registerChangeInYourOrg() {
        this._yourOrgHelper.yourOrgChanged$.pipe(takeUntil(this._unsub$)).subscribe(() => this.loadOrgData());
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
