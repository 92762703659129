<h2 mat-dialog-title>Customize risk tolerance</h2>
<mat-dialog-content>
    <p>
        Adjust your risk tolerance and set custom labels to customize how we display your third party risk. These
        settings will apply retroactively to all relationships.
    </p>
    <div class="mt-4">
        <form [formGroup]="riskToleranceFormGroup">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <p>Risk tolerance</p>
                            <p>
                                How much risk is your organization willing to tolerate? This will map your risk labels
                                to new thresholds.
                            </p>
                            <mat-slider
                                class="risk-tolerance-slider"
                                [max]="2"
                                [min]="0"
                                [step]="1"
                                [showTickMarks]="true"
                            >
                                <input
                                    matSliderThumb
                                    #slider
                                    (valueChange)="onToleranceChanged($event)"
                                    [value]="setInitialTolerance()"
                                />
                            </mat-slider>
                            <div class="slider-labels">
                                <div class="slider-label text-left text-light text-sm">Minimal (default)</div>
                                <div class="slider-label text-center text-light text-sm">Moderate</div>
                                <div class="slider-label text-end text-light text-sm">Significant</div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-4">
                                    <p class="text-light text-sm">Default label</p>
                                </div>
                                <div class="col">
                                    <p class="text-light text-sm">Custom label</p>
                                </div>
                            </div>
                            <ng-container formArrayName="riskLevelDisplayNames">
                                <ng-container
                                    [formGroup]="getRiskDisplayNameFormGroup(riskLevel)"
                                    *ngFor="let riskLevel of riskOrderReverse; let i = index"
                                >
                                    <div class="row mt-2">
                                        <div class="col-4 align-content-center mb-0">
                                            <p
                                                [ngClass]="{
                                                    'no-context': riskLevel === Risk.NO_CONTEXT,
                                                    'low-risk': riskLevel === Risk.LOW,
                                                    'medium-risk': riskLevel === Risk.MEDIUM,
                                                    'high-risk': riskLevel === Risk.HIGH,
                                                    'extreme-risk': riskLevel === Risk.EXTREME,
                                                }"
                                                class="text-bold"
                                            >
                                                {{ RiskLabels[riskLevel] }}
                                            </p>
                                        </div>
                                        <div class="col">
                                            <mat-form-field class="w-100">
                                                <input
                                                    type="text"
                                                    matInput
                                                    placeholder="Add custom label"
                                                    formControlName="displayName"
                                                    maxlength="45"
                                                />
                                            </mat-form-field>
                                            <button
                                                *ngIf="i === riskOrderReverse.length - 1"
                                                mat-button
                                                color="primary"
                                                class="text-button"
                                                (click)="resetAllRiskLabels()"
                                            >
                                                Reset all
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <mat-card appearance="outlined" class="risk-tolerance-preview">
                        <p class="text-bold ms-3 mt-2 mb-0">Preview</p>
                        <mat-card-header>
                            <mat-card-title
                                >Your risk tolerance is {{ RiskToleranceLabels[getSelectedRiskTolerance] | lowercase }}
                                {{ getSelectedRiskTolerance === RiskTolerance.MINIMAL ? '(default)' : '' }}
                            </mat-card-title>
                            <mat-card-subtitle>
                                <p [ngClass]="{ 'mb-0': showReadMore }">
                                    {{ getSelectedRiskTolerance | callback: getRiskToleranceSummary }}
                                </p>
                                <p [ngClass]="{ 'd-none': showReadMore }" class="mb-0">
                                    {{ getSelectedRiskTolerance | callback: getRiskToleranceDescription }}
                                </p>
                                <button
                                    mat-button
                                    color="primary"
                                    class="text-button"
                                    (click)="showReadMore = !showReadMore"
                                >
                                    {{ showReadMore ? 'Read more' : 'Read less' }}
                                </button>
                            </mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row mt-4">
                                <div class="col">
                                    <div class="col">
                                        <app-risk-thresholds
                                            [riskLevelDisplayNames]="previewRiskLevelDisplayNames"
                                            [riskToleranceThresholds]="riskToleranceThresholds"
                                            [activeRiskTolerance]="getSelectedRiskTolerance"
                                        ></app-risk-thresholds>
                                    </div>

                                    <div class="row ms-1 my-4">
                                        <div class="col">
                                            <mat-divider />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <app-risk-distribution
                                                class="d-block"
                                                [riskToleranceThresholds]="riskToleranceThresholds"
                                                [activeRiskTolerance]="getSelectedRiskTolerance"
                                                [riskLevelDisplayNames]="previewRiskLevelDisplayNames"
                                                [relationshipRiskDistribution]="relationshipRiskDistribution"
                                            ></app-risk-distribution>
                                        </div>
                                    </div>

                                    <div class="row ms-1 my-4">
                                        <div class="col">
                                            <mat-divider />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <p class="text-bold">Example relationship</p>
                                            <p>
                                                Select one of your recently assessed vendors to see how this change will
                                                affect how we display their risk.
                                            </p>

                                            <mat-form-field class="w-100">
                                                <input
                                                    type="text"
                                                    placeholder="Search for a relationship"
                                                    matInput
                                                    formControlName="selectedExampleRelationship"
                                                    [matAutocomplete]="auto"
                                                    (input)="filterExampleRelationships()"
                                                />
                                                <mat-autocomplete
                                                    autoActiveFirstOption
                                                    #auto="matAutocomplete"
                                                    [displayWith]="displayWithFn"
                                                >
                                                    <mat-option
                                                        *ngFor="
                                                            let previewRelationshipRiskTolerance of filteredPreviewRelationshipRiskDistribution
                                                        "
                                                        [value]="previewRelationshipRiskTolerance"
                                                        >{{
                                                            previewRelationshipRiskTolerance
                                                                | callback: getRelationshipName
                                                        }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>

                                            <div class="row mt-3">
                                                <div class="col">
                                                    <div class="row">
                                                        <div class="col">
                                                            <p class="text-light text-sm">Risk type</p>
                                                        </div>
                                                        <div class="col">
                                                            <p class="text-light text-sm">Risk score</p>
                                                        </div>
                                                        <div class="col">
                                                            <p class="text-light text-sm">Current label</p>
                                                        </div>
                                                        <div class="col">
                                                            <p class="text-light text-sm">New label</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <p>Inherent risk</p>
                                                        </div>
                                                        <div class="col">
                                                            <p>{{ getInherentRiskScorePercentage }}</p>
                                                        </div>
                                                        <div class="col">
                                                            <p>
                                                                {{
                                                                    getExistingRiskLevelDisplayName(
                                                                        getSelectedExampleInherentRiskLevel,
                                                                        getInherentRiskScore
                                                                    ) || '-'
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div class="col">
                                                            <p>
                                                                {{
                                                                    getPreviewRiskLevelDisplayName(
                                                                        getSelectedExampleInherentRiskLevel,
                                                                        getInherentRiskScore
                                                                    ) || '-'
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <p>Residual risk</p>
                                                        </div>
                                                        <div class="col">
                                                            <p>{{ getResidualRiskScorePercentage }}</p>
                                                        </div>
                                                        <div class="col">
                                                            <p>
                                                                {{
                                                                    getExistingRiskLevelDisplayName(
                                                                        getSelectedExampleResidualRiskLevel,
                                                                        getResidualRiskScore
                                                                    ) || '-'
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div class="col">
                                                            <p>
                                                                {{
                                                                    getPreviewRiskLevelDisplayName(
                                                                        getSelectedExampleResidualRiskLevel,
                                                                        getResidualRiskScore
                                                                    ) || '-'
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex" align="end">
    <button mat-button visoButton="secondary" class="me-2" type="button" mat-dialog-close>Cancel</button>
    <button
        mat-button
        visoButton="primary"
        type="button"
        mat-dialog-close
        (click)="updateRiskToleranceSettings()"
        [disabled]="riskToleranceFormGroup.invalid"
    >
        Save changes
    </button>
</mat-dialog-actions>
