import { Injectable } from '@angular/core';
import { LongRunningTaskService } from '../long-running-task.service';
import {
    getInProgressLongRunningTasksRequest,
    getInProgressLongRunningTasksRequestFailed,
    getInProgressLongRunningTasksRequestSuccess,
} from './long-running-task.actions';
import { catchError, map, mergeMap, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

@Injectable()
export class LongRunningTaskEffects {
    getInProgressLongRunningTasksRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getInProgressLongRunningTasksRequest),
            mergeMap(() =>
                this._longRunningTaskService.getAllRunningTasks().pipe(
                    map((tasks) => getInProgressLongRunningTasksRequestSuccess({ tasks })),
                    catchError(() => of(getInProgressLongRunningTasksRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _longRunningTaskService: LongRunningTaskService,
    ) {}
}
