<form [formGroup]="businessUnitReassignFormGroup">
    <div class="modal-header">
        <h4 class="modal-title mb-0">Delete Business Unit</h4>
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>

    <div *ngIf="businessUnit.userCount > 0">
        <div class="modal-body">
            <p>
                You have {{ businessUnit.userCount }} users listed under "<strong>{{ businessUnit.name }}</strong
                >". Please reassign them and confirm deletion.
            </p>
            <div class="form-group">
                <label for="field_businessUnit">Reassign to</label>
                <ngx-select
                    optionValueField="id"
                    optionTextField="name"
                    formControlName="reassignToId"
                    [allowClear]="true"
                    [items]="businessUnitSelectOptions$ | async"
                    [isFocused]="false"
                    placeholder="Select business unit"
                    data-pw="businessOwnerSelectBusinessOwnerControl"
                    required
                >
                </ngx-select>
            </div>
        </div>
    </div>

    <div *ngIf="businessUnit.userCount === 0">
        <div class="modal-body">
            <p>
                Are you sure you want to delete "<strong>{{ businessUnit.name }}</strong
                >"?
            </p>
        </div>
    </div>

    <div class="modal-footer d-flex justify-content-end main-action">
        <div class="btn-wrapper">
            <button
                type="button"
                mat-button
                visoButton="secondary"
                color="primary"
                data-dismiss="modal"
                (click)="clear()"
            >
                Cancel
            </button>
            <button
                mat-button
                visoButton="primary"
                (click)="confirmDelete()"
                [disabled]="deleting || (businessUnitReassignFormGroup.invalid && businessUnit.userCount !== 0)"
            >
                Delete Business Unit
            </button>
        </div>
    </div>
</form>
