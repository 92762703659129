<div class="table-responsive">
    <table class="table table-hover table-bordered table-striped">
        <thead>
            <tr>
                <th>Artifact Type</th>
                <th>Artifact Name</th>
                <th>Assurance Level</th>
                <th>Valid Until</th>
                <th>Last Updated</th>
                <th>Uploaded</th>
                <th>Validation Status</th>
                <th>Domains Validated</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let artifact of artifacts">
                <td>{{ artifact.artifactTypeName }}</td>
                <td>{{ artifact.fileName }}</td>
                <td>
                    <app-assurance-level-meter
                        class="d-inline-block"
                        [hideTooltip]="true"
                        [hideLabel]="true"
                        [assuranceLevel]="artifact.validation?.auditReportAssuranceLevel"
                    ></app-assurance-level-meter>
                </td>
                <td>
                    <em
                        *ngIf="isArtifactExpiringSoonOrExpired(artifact)"
                        class="fa fa-exclamation-circle text-error"
                    ></em>
                    <span
                        [ngClass]="{
                            'fw-bold': isArtifactExpiringSoonOrExpired(artifact),
                            'text-muted': isArtifactStatusNotRequired(artifact),
                        }"
                    >
                        {{
                            artifact.validation?.expirationDate
                                ? (artifact.validation?.expirationDate | date: 'mediumDate')
                                : '-'
                        }}
                    </span>
                </td>
                <td>
                    {{ artifact.updatedDate | date: 'mediumDate' }}
                </td>
                <td>
                    {{ artifact.uploadedDate ? (artifact.uploadedDate | date: 'mediumDate') : '' }}
                </td>
                <td>
                    <ng-container
                        *ngIf="artifact.validation?.status || ArtifactValidationStatus.NOT_VALIDATED as status"
                    >
                        <span
                            [ngClass]="{
                                'fw-bold': status === ArtifactValidationStatus.COMPLETE,
                                'text-muted': status === ArtifactValidationStatus.NOT_REQUIRED,
                            }"
                        >
                            {{ ArtifactValidationStatusLabels[status] }}
                        </span>
                    </ng-container>
                </td>
                <td>
                    {{ artifact.validation?.detectedControls?.length || 0 }}
                </td>
            </tr>
        </tbody>
    </table>
</div>
