import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { VisoUser, VisoUserRole } from '../../entities/viso-user';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../shared/dynamic-content/dynamic-content-injector';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    generateApiTokenRequest,
    generateApiTokenRequestSuccess,
    getApiTokenDetailsRequest,
    getApiTokenDetailsRequestSuccess,
    getVisoUserRequest,
    getVisoUserRequestSuccess,
    openAccessTokenModal,
    openRevokeAccessTokenModal,
    revokeApiTokenRequestSuccess,
    UserProfileActions,
} from './redux/user-profile.actions';
import { filter, map, startWith, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { getUserAccount } from '../session/redux/session.selectors';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { EnumSelectOption } from '../../shared/model/select-options';
import {
    ActiveJwtView,
    CreateJwtPayload,
    JwtExpirationPeriodLabels,
    JwtExpirationPeriods,
} from '../../admin/jwt-management/jwt-management.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface ApiAccessFormGroup {
    jwtExpirationPeriod: FormControl<JwtExpirationPeriods>;
    visoUserId: FormControl<number>;
    customExpirationDate: FormControl<Date>;
}

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;

    isLoggedInUser$ = new BehaviorSubject<boolean>(false);
    user$ = new BehaviorSubject<VisoUser>(null);
    activeApiTokenDetails$ = new BehaviorSubject<ActiveJwtView>(null);
    hasActiveApiToken: boolean;

    apiKeyExpirationPeriods$: Observable<EnumSelectOption[]> = of([
        {
            enumValue: JwtExpirationPeriods.THIRTY_DAYS,
            name: JwtExpirationPeriodLabels.THIRTY_DAYS,
        },
        {
            enumValue: JwtExpirationPeriods.SIXTY_DAYS,
            name: JwtExpirationPeriodLabels.SIXTY_DAYS,
        },
        {
            enumValue: JwtExpirationPeriods.NINETY_DAYS,
            name: JwtExpirationPeriodLabels.NINETY_DAYS,
        },
        {
            enumValue: JwtExpirationPeriods.SIX_MONTHS,
            name: JwtExpirationPeriodLabels.SIX_MONTHS,
        },
        {
            enumValue: JwtExpirationPeriods.ONE_YEAR,
            name: JwtExpirationPeriodLabels.ONE_YEAR,
        },
        {
            enumValue: JwtExpirationPeriods.CUSTOM,
            name: JwtExpirationPeriodLabels.CUSTOM,
        },
    ]);
    showCustomDateField$: Observable<boolean>;
    today = new Date();
    apiAccessFormGroup: FormGroup<ApiAccessFormGroup>;

    UserProfileActions = UserProfileActions;
    VisoUserRole = VisoUserRole;

    private _unsub$ = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private _actions$: Actions,
        private _store$: Store,
        private _fb: FormBuilder,
    ) {}

    ngOnInit() {
        this.apiAccessFormGroup = this._fb.group({
            jwtExpirationPeriod: this._fb.control<JwtExpirationPeriods | null>(null, {
                validators: Validators.required,
            }),
            visoUserId: this._fb.control<number | null>(null, {
                validators: Validators.required,
            }),
            customExpirationDate: this._fb.control<Date | null>(null),
        });

        this.route.params.subscribe((params) => {
            const paramName = 'id';
            const visoUserId: number = params[paramName];
            this._store$.dispatch(getVisoUserRequest({ visoUserId }));
        });

        this._actions$
            .pipe(
                ofType(getVisoUserRequestSuccess),
                withLatestFrom(this._store$.pipe(select(getUserAccount))),
                map(([{ visoUser }, currentAccount]) => {
                    if (visoUser?.id === currentAccount?.id) {
                        this.isLoggedInUser$.next(true);
                    }
                    this.apiAccessFormGroup.controls.visoUserId.setValue(visoUser?.id);
                    return visoUser;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe((visoUser) => this.user$.next(visoUser));

        this.isLoggedInUser$
            .pipe(
                filter((isLoggedInUser) => !!isLoggedInUser),
                take(1),
            )
            .subscribe(() => this._store$.dispatch(getApiTokenDetailsRequest()));

        this._actions$
            .pipe(ofType(getApiTokenDetailsRequestSuccess), takeUntil(this._unsub$))
            .subscribe(({ activeJwtDetails }) => {
                this.activeApiTokenDetails$.next(activeJwtDetails);
                this.hasActiveApiToken = true;
            });

        const controls = this.apiAccessFormGroup.controls;

        this.showCustomDateField$ = controls.jwtExpirationPeriod.valueChanges.pipe(
            map((expirationDate) => expirationDate === JwtExpirationPeriods.CUSTOM),
            startWith(false),
        );

        controls.jwtExpirationPeriod.valueChanges.pipe(takeUntil(this._unsub$)).subscribe(() => {
            controls.customExpirationDate.reset(null);
        });

        this._actions$
            .pipe(ofType(generateApiTokenRequestSuccess), takeUntil(this._unsub$))
            .subscribe(({ apiTokenResponse }) => {
                this.apiAccessFormGroup.setValue({
                    customExpirationDate: null,
                    jwtExpirationPeriod: null,
                    visoUserId: this.user$.getValue().id,
                });
                this._store$.dispatch(getApiTokenDetailsRequest());
                this._store$.dispatch(openAccessTokenModal({ accessToken: apiTokenResponse.accessToken }));
            });

        this._actions$
            .pipe(
                ofType(revokeApiTokenRequestSuccess),
                tap(() => (this.hasActiveApiToken = false)),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    generateApiToken(): void {
        const payload = this.apiAccessFormGroup.getRawValue() as CreateJwtPayload;
        this._store$.dispatch(generateApiTokenRequest({ payload }));
    }

    revokeApiToken() {
        this._store$.dispatch(openRevokeAccessTokenModal());
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
