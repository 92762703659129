<div class="modal-header d-flex w-100 justify-content-between mb-2">
    <h4 class="modal-title">Move relationship to tier</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="close()"></button>
</div>
<div class="modal-body">
    <p>Group this vendor with others like it to streamline oversight and view relevant risk insights.</p>
    <mat-form-field class="w-100">
        <mat-select [formControl]="newTierIdControl">
            <mat-option [value]="-1"> Unassigned </mat-option>
            @for (vendorTier of allVendorTiers; track vendorTier.id) {
                <mat-option [value]="vendorTier.id">
                    {{ vendorTier.name }}
                </mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>
<div class="modal-footer">
    <button mat-flat-button (click)="close()">Close</button>
    <button mat-flat-button color="primary" (click)="save()">Move</button>
</div>
