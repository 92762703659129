import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VendorTier } from '@entities/vendor-tier/vendor-tier.model';

@Injectable({
    providedIn: 'root',
})
export class VendorTierService {
    private _resourceUrl = 'api/vendor-tier';

    constructor(private _http: HttpClient) {}

    updateVendorTiersCount(numberOfTiers: number): Observable<VendorTier[]> {
        return this._http.put<VendorTier[]>(`${this._resourceUrl}`, {
            count: numberOfTiers,
        });
    }

    updateVendorTierDescription(vendorTierId: number, description: string): Observable<VendorTier> {
        return this._http.put<VendorTier>(`${this._resourceUrl}/description`, {
            vendorTierId,
            description,
        });
    }
}
