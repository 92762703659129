<p mat-dialog-title>{{ title }}</p>
<p class="subtitle" *ngIf="!!subtitle">{{ subtitle }}</p>
<mat-dialog-content>
    <p [innerHTML]="message | safeHtml"></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        *ngIf="!onlyConfirmButton"
        type="button"
        [visoButton]="cancelButtonStyle"
        [color]="cancelButtonColor"
        mat-button
        (click)="cancel()"
    >
        {{ cancelLabel }}
        <mat-icon *ngIf="!!cancelLabelIcon" iconPositionEnd>{{ cancelLabelIcon }}</mat-icon>
    </button>
    <button mat-button type="button" [visoButton]="confirmButtonStyle" [color]="confirmButtonColor" (click)="confirm()">
        <mat-icon *ngIf="!!confirmLabelIcon">{{ confirmLabelIcon }}</mat-icon>
        {{ confirmLabel }}
    </button>
</mat-dialog-actions>
