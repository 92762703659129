import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map, Observable, ReplaySubject } from 'rxjs';

// This should probably come from a global config
export type Screens = { [key: string]: string };
// Currently matching Bootstrap 5 breakpoints
const SCREENS: Screens = {
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
};

/**
 * MediaWatcherService monitors screen size changes using Angular's BreakpointObserver.
 * It provides an observable stream of active media queries based on a mobile-first approach,
 * meaning breakpoints are included as the screen gets larger.
 *
 * **Usage:**
 * To check if `'lg'` is active, subscribe to `onMediaChange$` and look for `'lg'` in `matchingAliases`,
 * since the source will contain `['sm', 'md', 'lg']` when `'lg'` is reached.
 */
@Injectable()
export class MediaWatcherService {
    private _onMediaChange: ReplaySubject<{ matchingAliases: string[]; matchingQueries: any }> = new ReplaySubject<{
        matchingAliases: string[];
        matchingQueries: any;
    }>(1);

    constructor(private _breakpointObserver: BreakpointObserver) {
        const mediaQueries = Object.entries(SCREENS).reduce(
            (acc, [alias, screen]) => {
                acc[alias] = `(min-width: ${screen})`;
                return acc;
            },
            {} as Record<string, string>,
        );

        this._breakpointObserver
            .observe(Object.values(mediaQueries))
            .pipe(
                map((state) => {
                    const matchingAliases: string[] = [];
                    const matchingQueries: Record<string, string> = {};

                    for (const [query, matches] of Object.entries(state.breakpoints)) {
                        if (matches) {
                            const matchingAlias = Object.keys(mediaQueries).find(
                                (alias) => mediaQueries[alias] === query,
                            );
                            if (matchingAlias) {
                                matchingAliases.push(matchingAlias);
                                matchingQueries[matchingAlias] = query;
                            }
                        }
                    }

                    this._onMediaChange.next({
                        matchingAliases,
                        matchingQueries,
                    });
                }),
            )
            .subscribe();
    }

    get onMediaChange$(): Observable<{ matchingAliases: string[]; matchingQueries: any }> {
        return this._onMediaChange.asObservable();
    }

    onMediaQueryChange$(query: string | string[]): Observable<BreakpointState> {
        return this._breakpointObserver.observe(query);
    }
}
