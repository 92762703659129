<strong>What artifacts are we looking for?</strong>
@for (
    inScopeFileType of uploadedStatusesOfInScopeFileTypes | callback: getUploadedInScopeFileTypeEntries;
    track inScopeFileType.fileArtifactType
) {
    <div class="mt-2 d-flex align-items-center">
        <div class="check-container">
            @if (inScopeFileType.hasBeenUploaded) {
                <div class="check-circle checked" @fade></div>
            } @else if (classificationInProgress) {
                <mat-icon class="text-muted pulse">auto_awesome</mat-icon>
            } @else {
                <div class="check-circle"></div>
            }
        </div>
        <strong
            class="ms-1 text-primary cursor-help"
            [appPopover]="guidancePopover"
            [popoverContext]="inScopeFileType.fileArtifactType"
            popoverTheme="dark"
        >
            {{ FileArtifactTypeLabels[inScopeFileType.fileArtifactType] }}
        </strong>
    </div>
}

<ng-template #guidancePopover let-fileArtifactType>
    <div class="p-3 text-nowrap" *ngIf="artifactExamples[fileArtifactType] as artifactExample">
        <div class="mb-2">
            <strong>{{ artifactExample.title }}</strong>
        </div>
        <li *ngFor="let example of artifactExample.examples">
            {{ example }}
        </li>
    </div>
</ng-template>
