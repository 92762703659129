<div class="disclaimer-container" [ngClass]="disclaimerTypeClass">
    <div class="row">
        <div class="col-auto pe-0">
            <mat-icon class="material-icons-outlined header-icon">{{ !!icon ? icon : type }}</mat-icon>
        </div>
        <div class="col ps-2">
            <p class="header-text mb-0">{{ disclaimerTitle }}</p>
            <ng-content></ng-content>
        </div>
    </div>
</div>
