import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    VendorDetailsResponse,
    VendorDetailsStatsResponse,
} from '../../../../../routes/request/models/vendor-details.model';
import { RelationshipVendorDetailsStatsResponse } from '@entities/relationship';
import { ControlDomainType, ControlDomainTypeLabels } from '@entities/control-domain';
import { KeyValue } from '@angular/common';
import { AssuranceLevels } from '@entities/relationship/models/security-control-domain';

@Component({
    selector: 'app-vendor-risk-domains',
    templateUrl: './vendor-risk-domains.component.html',
    styleUrl: './vendor-risk-domains.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorRiskDomainsComponent {
    @Input({ required: true })
    vendorDetails: VendorDetailsResponse;

    @Input({ required: true })
    set vendorDetailsStats(value: VendorDetailsStatsResponse) {
        this.relationshipVendorDetailsStats = value as RelationshipVendorDetailsStatsResponse;
    }

    relationshipVendorDetailsStats: RelationshipVendorDetailsStatsResponse;

    readonly ControlDomainTypeLabels = ControlDomainTypeLabels;

    get unknownControlDomains(): ControlDomainType[] {
        const assuranceLevels = this.relationshipVendorDetailsStats?.controlDomainTypeAssuranceLevels ?? {};
        return Object.keys(ControlDomainType)
            .filter(
                (key) =>
                    !Object.keys(assuranceLevels).includes(ControlDomainType[key]) &&
                    key !== ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE &&
                    key !== ControlDomainType.SUBSERVICERS,
            )
            .map((key) => ControlDomainType[key]);
    }

    get sortedControlDomains(): KeyValue<ControlDomainType, AssuranceLevels>[] {
        return Object.entries(this.relationshipVendorDetailsStats?.controlDomainTypeAssuranceLevels ?? {})
            .filter(
                ([key]) =>
                    ![ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE, ControlDomainType.SUBSERVICERS].includes(
                        key as ControlDomainType,
                    ),
            )
            .map(([key, value]) => ({ key: key as ControlDomainType, value }))
            .sort(
                (a, b) => Object.keys(ControlDomainType).indexOf(a.key) - Object.keys(ControlDomainType).indexOf(b.key),
            );
    }
}
