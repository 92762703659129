import { ChangeDetectionStrategy, Component, EventEmitter, input, OnInit, Output, signal } from '@angular/core';

@Component({
    selector: 'app-vendor-tier-selection',
    templateUrl: './vendor-tier-selection.component.html',
    styleUrl: './vendor-tier-selection.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorTierSelectionComponent implements OnInit {
    initialSelection = input<number>(1);
    allowNone = input<boolean>(false);

    @Output()
    numberOfTiersSelected = new EventEmitter<number>();

    circles = signal<{ selected: boolean }[]>(Array.from({ length: 5 }, () => ({ selected: false })));

    private _lastIndexSelected = -1;

    ngOnInit(): void {
        this.selectCircle(this.initialSelection() - 1);
    }

    selectCircle(index: number): void {
        if (this.allowNone() && index === this._lastIndexSelected) {
            this.circles.update((circles) =>
                circles.map(() => ({
                    selected: false,
                })),
            );
            this.numberOfTiersSelected.emit(0);
            this._lastIndexSelected = -1;
        } else {
            this.circles.update((circles) =>
                circles.map((_, i) => ({
                    selected: i <= index,
                })),
            );
            this.numberOfTiersSelected.emit(index + 1);
            this._lastIndexSelected = index;
        }
    }
}
