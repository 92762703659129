import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ConfirmDialogService } from '../../../../shared/components/confirm-dialog/confirm-dialog.service';
import { RelationshipService } from '../../../../entities/relationship';
import {
    markRequestAsArchived,
    markRequestAsArchivedRequest,
    markRequestAsArchivedRequestFailed,
    markRequestAsArchivedRequestSuccess,
} from '../actions/mark.actions';
import { SnackbarService } from '../../../../shared/components/snackbar/snackbar.service';

@Injectable()
export class MarkEffects {
    markRequestAsArchived$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markRequestAsArchived),
            switchMap(({ requestId }) => {
                return this._confirmService
                    .confirm({
                        title: 'Archive Relationship',
                        message: `Are you sure you want to archive this relationship? Any assessments in progress will be cancelled.`,
                        confirmLabel: 'Yes, archive',
                    })
                    .pipe(mergeMap((result) => (result ? of(markRequestAsArchivedRequest({ requestId })) : EMPTY)));
            }),
        ),
    );

    markRequestAsArchivedRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markRequestAsArchivedRequest),
            switchMap(({ requestId }) =>
                this._relationshipService.archive(requestId).pipe(
                    map(() => markRequestAsArchivedRequestSuccess()),
                    catchError(() => of(markRequestAsArchivedRequestFailed())),
                ),
            ),
        ),
    );

    markRequestAsArchivedRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(markRequestAsArchivedRequest),
                delay(1000),
                tap(() => {
                    this._snackbarService.success('Relationship Archived');
                    this._router.navigate(['/']);
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private _router: Router,
        private _actions$: Actions,
        private _confirmService: ConfirmDialogService,
        private _relationshipService: RelationshipService,
        private _snackbarService: SnackbarService,
    ) {}
}
