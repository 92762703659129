<h2 mat-dialog-title>Forward Assessment</h2>
<mat-dialog-content>
    <p class="mb-4">
        Please fill out the information of the contact you would like us to forward this assessment to. This person will
        be responsible for completing the assessment collection.
    </p>
    <form [formGroup]="forwardFormGroup" role="form" novalidate>
        <mat-form-field class="mb-4 w-100">
            <mat-label>First name</mat-label>
            <input type="text" matInput formControlName="firstName" placeholder="Enter first name" />
        </mat-form-field>

        <mat-form-field class="mb-4 w-100">
            <mat-label>Last name</mat-label>
            <input type="text" matInput formControlName="lastName" placeholder="Enter last name" />
        </mat-form-field>

        <mat-form-field class="mb-4 w-100">
            <mat-label>Email address</mat-label>
            <input type="email" matInput formControlName="email" placeholder="Enter email" />
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close color="primary">Cancel</button>
    <button mat-flat-button color="primary" (click)="confirm()" [disabled]="forwardFormGroup.invalid">
        Forward assessment
    </button>
</mat-dialog-actions>
