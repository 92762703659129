export interface SupplementalQuestionnaireResponse {
    id: string;
    createdDate: Date;
    questionnaireName: string;
    questionCount: number;
    questions: SupplementalQuestionnaireQuestion[];
    enabled: boolean;
}

export interface SupplementalQuestionnaireWithQuestions {
    id: string;
    questionnaireName: string;
    questions: SupplementalQuestionnaireQuestion[];
    enabled: boolean;
}

export interface SupplementalQuestionnaireQuestion {
    id: number;
    question: string;
}

export enum SuppQReprocessingState {
    CAN_REPROCESS = 'CAN_REPROCESS',
    PROCESSING = 'PROCESSING',
    UP_TO_DATE = 'UP_TO_DATE',
}
