<mat-dialog-content>
    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column">
        <ng-lottie width="150px" [options]="submittedAnimationOptions"></ng-lottie>
        <p class="text-md">Artifacts added to the assessment</p>
    </div>
    <div class="mt-2">
        <p class="text-bold">Ready for the next step?</p>
        <p>
            We can proceed with the assessment based on the artifacts uploaded so far.
            <br />If you proceed, this vendor <b>will not</b> be able to provide additional artifacts.
        </p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="close()">Resume collection</button>
    <button mat-flat-button color="primary" (click)="proceedWithAvailableData()">
        <mat-icon color="white">fast_forward</mat-icon>
        Proceed with available artifacts
    </button>
</mat-dialog-actions>
