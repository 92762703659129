<div class="row mb-3">
    <div class="col">
        <h3 class="lead">Subscribers</h3>
    </div>
    <div class="col-auto">
        <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
            <a
                *jhiHasNotAuthority="[Roles.Auditor, Roles.Admin, Roles.Support, Roles.ReadOnly]"
                exportRemovableAction
                class="text-primary"
                [routerLink]="addLink"
                [replaceUrl]="true"
            >
                Add
            </a>
        </ng-container>
    </div>
</div>
<div class="row">
    @if (relationshipSubscribers$ | async; as relationshipSubscribers) {
        <div class="col">
            @for (subscriber of relationshipSubscribers; track subscriber.id) {
                <div class="subscriber-row">
                    <div
                        class="d-flex align-items-center subscriber-info-container"
                        (mouseenter)="onMouseEnter(subscriber.id.toString())"
                        (mouseleave)="onMouseLeave()"
                    >
                        <div class="d-flex flex-column flex-grow-1 subscriber-info">
                            @if (subscriber.defaultSubscriber) {
                                <span class="text-xs" style="font-weight: bold"> Default subscriber </span>
                            }
                            <p class="mb-0">{{ subscriber.fullname }}</p>
                            <small class="mb-0">{{ subscriber.email }}</small>
                            <small class="mb-0 text-muted">{{ subscriber.businessUnitName }}</small>
                        </div>
                        @if (!subscriber.defaultSubscriber && isHovered(subscriber.id.toString())) {
                            <div class="ml-3">
                                <a
                                    *jhiHasNotAuthority="[Roles.Support, Roles.ReadOnly]"
                                    class="cursor-pointer"
                                    exportRemovableAction
                                    (click)="unsubscribeSubscriber.emit(subscriber.id)"
                                >
                                    Unsubscribe
                                </a>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    } @else {
        <ng-template #noSubscribers>
            <p class="text-muted">No subscribers added yet.</p>
        </ng-template>
    }
</div>
