import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Detection } from '../models/detection.model';
import { FrameworkMappingType } from '@entities/framework/models/framework-mapping.model';
import { Artifact, ArtifactValidationStatus, ControlValidation } from '@entities/artifact';
import { HttpErrorResponse } from '@angular/common/http';
import { StaticValidationType } from '@entities/static-validation/static-validation.model';
import { ArtifactControlDomain } from '../models';

export const ArtifactIntelligenceActions = createActionGroup({
    source: 'Artifact Intelligence',
    events: {
        OpenDetectionDetailsDrawer: props<{
            detection: Detection;
            controlName: string;
            domainControlName: string;
            frameworkType: FrameworkMappingType;
        }>(),
        CloseDetectionDetailsDrawer: emptyProps(),
        'Delete Control Confirmation Modal Dismissed': emptyProps(),
    },
});

export const ArtifactIntelligenceRequestActions = createActionGroup({
    source: 'Artifact Intelligence Request',
    events: {
        'Update Artifact Validation Status Request': props<{ artifactId: number; status: ArtifactValidationStatus }>(),
        'Update Artifact Validation Status Request Success': emptyProps(),
        'Update Artifact Validation Status Request Failed': props<{ error: HttpErrorResponse }>(),
        'Update Artifact Static Validation Type Request': props<{
            artifactId: number;
            staticValidationType: StaticValidationType;
        }>(),
        'Update Artifact Static Validation Type Request Success': emptyProps(),
        'Update Artifact Static Validation Type Request Failed': props<{ error: HttpErrorResponse }>(),
        'Update Artifact Request': props<{ artifact: Artifact }>(),
        'Update Artifact Request Success': emptyProps(),
        'Update Artifact Request Failed': props<{ error: HttpErrorResponse }>(),
        'Create Artifact Validation Request': props<{ artifactId: number }>(),
        'Create Artifact Validation Request Success': props<{ artifactId: number }>(),
        'Create Artifact Validation Request Failed': props<{ error: HttpErrorResponse }>(),
        'Create All Artifact Control Validations Request': props<{
            artifact: Artifact;
            controlDomains: ArtifactControlDomain[];
        }>(),
        'Create All Artifact Control Validations Request Success': emptyProps(),
        'Create All Artifact Control Validations Request Failed': props<{ error: HttpErrorResponse }>(),
        'Create Artifact Control Validation Request': props<{
            artifactId: number;
            controlValidation: ControlValidation;
        }>(),
        'Create Artifact Control Validation Request Success': emptyProps(),
        'Create Artifact Control Validation Request Failed': props<{ error: HttpErrorResponse }>(),
        'Update Artifact Control Validation Request': props<{
            artifactId: number;
            controlValidationId: number;
            controlValidation: ControlValidation;
        }>(),
        'Update Artifact Control Validation Request Success': emptyProps(),
        'Update Artifact Control Validation Request Failed': props<{ error: HttpErrorResponse }>(),
        'Delete Artifact Control Validation Request': props<{
            artifactId: number;
            controlValidationId: number;
            disableConfirmation: boolean;
        }>(),
        'Delete Artifact Control Validation Request Success': emptyProps(),
        'Delete Artifact Control Validation Request Failed': props<{ error: HttpErrorResponse }>(),
    },
});
