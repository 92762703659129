import { initialState, longRunningTaskFeatureKey } from './long-running-task.state';
import { getInProgressLongRunningTasksRequestSuccess } from './long-running-task.actions';

import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

export const reducer = createReducer(
    initialState,
    on(getInProgressLongRunningTasksRequestSuccess, (state, { tasks }) => ({
        ...state,
        runningTasksForOrg: tasks,
    })),
);

export const longRunningTaskFeature = createFeature({
    name: longRunningTaskFeatureKey,
    reducer,
    extraSelectors: ({ selectRunningTasksForOrg }) => ({
        longRunningTaskInProgressForOrg: createSelector(selectRunningTasksForOrg, (tasks) => tasks.length > 0),
    }),
});
