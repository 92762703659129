<section>
    <ngx-datatable
        #riskLevelsConfigTable
        class="bootstrap"
        [rows]="riskLevels"
        [columnMode]="'force'"
        [rowHeight]="35"
        [headerHeight]="30"
        [scrollbarV]="true"
        [count]="riskLevels.length"
    >
        <ngx-datatable-column name="Viso Risk Level" prop="riskLevel">
            <ng-template let-row="row" ngx-datatable-cell-template>{{ row.riskLevel }} </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Custom Risk Level" prop="displayName">
            <ng-template let-row="row" ngx-datatable-cell-template>{{ row.displayName }} </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="40" [resizable]="false" [canAutoResize]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="additional-actions" placement="bottom left" dropdown container="body">
                    <button
                        id="button-container"
                        dropdownToggle
                        type="button"
                        class="btn-icon float-end"
                        aria-controls="dropdown-container"
                    >
                        <em class="fa fa-ellipsis-v text-primary"></em>
                    </button>
                    <ul
                        id="dropdown-container"
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-start translate-x"
                        aria-labelledby="button-container"
                        style="--data-translate-value: -160px"
                    >
                        <li>
                            <a
                                class="dropdown-item"
                                (click)="openEditRiskLevelConfigModal(manageRiskLevelConfigModal, row)"
                                >Edit</a
                            >
                        </li>
                    </ul>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</section>

<ng-template #manageRiskLevelConfigModal let-modal>
    <div class="modal-header">
        <div class="modal-title title-text">Edit RiskLevel</div>
    </div>
    <div class="modal-body modal-text">
        <section *ngIf="currentRiskLevel">
            <div class="form-group">
                <label for="riskLevel"><strong>Viso Risk Level</strong></label>
                <div>{{ currentRiskLevel.riskLevel }}</div>
            </div>
            <div class="form-group">
                <label for="displayName"><strong>Custom Risk Level</strong></label>
                <input
                    id="displayName"
                    type="text"
                    class="form-control"
                    [attr.maxlength]="45"
                    [(ngModel)]="currentRiskLevel.displayName"
                    [disabled]="savingRiskLevel"
                />
            </div>
        </section>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <button type="button" mat-button visoButton="warning" [disabled]="savingRiskLevel" (click)="cancel()">
            Cancel
        </button>
        <div>
            <button
                type="button"
                mat-button
                visoButton="primary"
                class="me-2"
                [disabled]="savingRiskLevel"
                (click)="saveRiskLevel({ resetToDefault: true })"
            >
                Reset to Default
            </button>
            <button
                *ngIf="currentRiskLevel"
                type="button"
                mat-button
                visoButton="primary"
                [disabled]="!currentRiskLevel.displayName || savingRiskLevel"
                (click)="saveRiskLevel({ resetToDefault: false })"
            >
                {{ savingRiskLevel ? 'Saving...' : 'Submit' }}
            </button>
        </div>
    </div>
</ng-template>
