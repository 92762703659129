import { HttpHeaders } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Org } from '@entities/org';
import { VisoUser } from '@entities/viso-user';
import { RelationshipAdditionalFilters } from '../models/relationship-additional-filters.model';
import { RelationshipColumnGroup } from '../models/relationship-column.model';
import { RelationshipQueryRequest } from '../models/relationship-query-request.model';
import { RelationshipCounts, VendorRelationship } from '../models/relationship.model';
import { RiskNetworkGraphNode } from '../models/risk-network-graph-node';
import { CompleteVendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';
import { VendorTier, VendorTierStats } from '@entities/vendor-tier';
import {
    BulkCancelAssessmentsResponse,
    BulkRelationshipUpdateResponse,
    BulkStartAssessmentResponse,
} from '@entities/relationship/models';

export enum RelationshipsActions {
    RelationshipsQueryRequest = '[Relationships] Relationships Query Request',
    RelationshipsQuerySuccess = '[Relationships] Relationships Query Success',
    RelationshipsQueryFailed = '[Relationships] Relationships Query Failed',

    QueryRelationshipsForCsvRequest = '[Relationships] Query Relationships For CSV Request',
    QueryRelationshipsForCsvRequestSuccess = '[Relationships] Query Relationships For CSV Request Success',
    QueryRelationshipsForCsvRequestFailed = '[Relationships] Query Relationships For CSV Request Failed',

    SetColumnsState = '[Relationships] Set Columns State',
    SetAdditionalFiltersState = '[Relationships] Set Filters State',
    ClearAllFilters = '[Relationships] Clear All Filters',

    BulkStartAssessmentsRequest = '[Relationships] Bulk Start Assessments Request',
    BulkStartAssessmentsRequestSuccess = '[Relationships] Bulk Start Assessments Request Success',
    BulkStartAssessmentsRequestAccepted = '[Relationships] Bulk Start Assessments Request Accepted',
    BulkStartAssessmentsRequestFailed = '[Relationships] Bulk Start Assessments Request Failed',

    BulkCancelAssessmentsRequest = '[Relationships] Bulk Cancel Assessments Request',
    BulkCancelAssessmentsRequestSuccess = '[Relationships] Bulk Cancel Assessments Request Success',
    BulkCancelAssessmentsRequestAccepted = '[Relationships] Bulk Cancel Assessments Request Accepted',
    BulkCancelAssessmentsRequestFailed = '[Relationships] Bulk Cancel Assessments Request Failed',

    BulkDisableArtifactUpdatesRequest = '[Relationships] Bulk Disable Artifact Updates Request',
    BulkDisableArtifactUpdatesRequestSuccess = '[Relationships] Bulk Disable Artifact Updates Request Success',
    BulkDisableArtifactUpdatesRequestFailed = '[Relationships] Bulk Disable Artifact Updates Request Failed',

    BulkEnableArtifactUpdatesRequest = '[Relationships] Bulk Enable Artifact Updates Request',
    BulkEnableArtifactUpdatesRequestSuccess = '[Relationships] Bulk Enable Artifact Updates Request Success',
    BulkEnableArtifactUpdatesRequestFailed = '[Relationships] Bulk Enable Artifact Updates Request Failed',

    GetRiskNetworkGraphRequest = '[Relationships] Get Risk Network Graph Request',
    GetRiskNetworkGraphRequestSuccess = '[Relationships] Get Risk Network Graph Request Success',
    GetRiskNetworkGraphRequestFailed = '[Relationships] Get Risk Network Graph Request Failed',

    VisoUsersQueryRequest = '[Relationships] Viso Users Query Request',
    VisoUsersQuerySuccess = '[Relationships] Viso Users Query Success',
    OrgClientsQueryRequest = '[Relationships] Org Clients Query Request',
    OrgClientsQuerySuccess = '[Relationships] Org Clients Query Success',

    GetVendorByIdRequest = '[Relationships] Get Vendor By Id',
    GetVendorByIdRequestSuccess = '[Relationships] Get Vendor By Id Success',
    GetVendorByIdRequestFailed = '[Relationships] Get Vendor By Id Failed',

    GetVendorTierStatsRequest = '[Relationships] Get Vendor Tier Stats Request',
    GetVendorTierStatsRequestSuccess = '[Relationships] Get Vendor Tier Stats Request Success',
    GetVendorTierStatsRequestFailed = '[Relationships] Get Vendor Tier Stats Request Failed',

    ClearVendorTierStats = '[Relationships] Clear Vendor Tier Stats',

    TryShowVendorTierWalkthrough = '[Relationships] Try Show Vendor Tier Walkthrough',

    UpdateVendorTiersCountRequest = '[Relationships] Update Vendor Tiers Count Request',
    UpdateVendorTiersCountSuccess = '[Relationships] Update Vendor Tiers Count Request Success',
    UpdateVendorTiersCountFailed = '[Relationships] Update Vendor Tiers Count Request Failed',

    OpenManageTiersDialog = '[Relationships] Open Manage Tiers Dialog',

    OpenAssignRelationshipsToTierDialog = '[Relationships] Open Assign Relationships to Tier Dialog',

    AssignRelationshipsToTierRequest = '[Relationships] Assign Relationships to Tier Request',
    AssignRelationshipsToTierRequestSuccess = '[Relationships] Assign Relationships to Tier Request Success',
    AssignRelationshipsToTierRequestFailed = '[Relationships] Assign Relationships to Tier Request Failed',

    OpenVendorTierDescriptionDialog = '[Relationships] Open Vendor Tier Description Dialog',

    UpdateVendorTierDescriptionRequest = '[Relationships] Update Vendor Tier Description Request',
    UpdateVendorTierDescriptionRequestSuccess = '[Relationships] Update Vendor Tier Description Request Success',
    UpdateVendorTierDescriptionRequestFailed = '[Relationships] Update Vendor Tier Description Request Failed',

    GetRelationshipCountsRequest = '[Relationships] Get Relationships Counts Request',
    GetRelationshipCountsRequestSuccess = '[Relationships] Get Relationships Counts Request Success',
    GetRelationshipCountsRequestFailed = '[Relationships] Get Relationships Counts Request Failed',

    BulkArchiveRelationshipsRequest = '[Relationships] Bulk Archive Relationships Request',
    BulkArchiveRelationshipsRequestSuccess = '[Relationships] Bulk Archive Relationships Request Success',
    BulkArchiveRelationshipsRequestAccepted = '[Relationships] Bulk Archive Relationships Request Accepted',
    BulkArchiveRelationshipsRequestFailed = '[Relationships] Bulk Archive Relationships Request Failed',

    BulkOffboardRelationshipsRequest = '[Relationships] Bulk Offboard Relationships Request',
    BulkOffboardRelationshipsRequestSuccess = '[Relationships] Bulk Offboard Relationships Request Success',
    BulkOffboardRelationshipsRequestAccepted = '[Relationships] Bulk Offboard Relationships Request Accepted',
    BulkOffboardRelationshipsRequestFailed = '[Relationships] Bulk Offboard Relationships Request Failed',

    GetRiskThresholdsForVendorTierStats = '[Relationships] Get Risk Thresholds For Vendor Tier Stats',

    GetAllRelationshipIdsQueryRequest = '[Relationships] Get All Relationship Ids Query Request',
    GetAllRelationshipIdsQuerySuccess = '[Relationships] Get All Relationship Ids Query Success',
    GetAllRelationshipIdsQueryFailed = '[Relationships] Get All Relationship Ids Query Failed',

    ClearAllUnpaginatedRelationshipIds = '[Relationships] Clear All Unpaginated Relationship Ids',

    RestoreRelationshipRequest = '[Relationships] Restore Relationship Request',
    RestoreRelationshipRequestSuccess = '[Relationships] Restore Relationship Request Success',
    RestoreRelationshipRequestFailed = '[Relationships] Restore Relationship Request Failed',

    ExpandNthGraph = '[Relationships] Expand Nth Graph',
    CollapseNthGraph = '[Relationships] Collapse Nth Graph',

    OpenVendorTierDescriptionDialogClosed = '[Relationships] Open Vendor Tier Description Dialog Closed',
    CloseVendorTierDescriptionDialog = '[Relationships] Close Vendor Tier Description Dialog',

    SetSelectedTierId = '[Relationships] Set Selected Tier Id',
}

export const relationshipsQueryRequest = createAction(
    RelationshipsActions.RelationshipsQueryRequest,
    props<{ params: RelationshipQueryRequest }>(),
);

export const relationshipsQuerySuccess = createAction(
    RelationshipsActions.RelationshipsQuerySuccess,
    props<{ results: VendorRelationship[]; headers: HttpHeaders }>(),
);

export const relationshipsQueryFailed = createAction(RelationshipsActions.RelationshipsQueryFailed);

export const queryRelationshipsForCsvRequest = createAction(
    RelationshipsActions.QueryRelationshipsForCsvRequest,
    props<{ params: RelationshipQueryRequest }>(),
);

export const queryRelationshipsForCsvRequestSuccess = createAction(
    RelationshipsActions.QueryRelationshipsForCsvRequestSuccess,
    props<{ results: VendorRelationship[] }>(),
);

export const queryRelationshipsForCsvRequestFailed = createAction(
    RelationshipsActions.QueryRelationshipsForCsvRequestFailed,
);

/**
 * Set Filters State Redux Action
 * Gets dispatched when filters state change is triggered.
 */
export const setAdditionalFiltersState = createAction(
    RelationshipsActions.SetAdditionalFiltersState,
    props<{ filters: RelationshipAdditionalFilters }>(),
);

/**
 * Set Columns State Redux Action
 * Gets dispatched when a columns state change is triggered.
 */
export const setColumnsState = createAction(
    RelationshipsActions.SetColumnsState,
    props<{ results: RelationshipColumnGroup[] }>(),
);

/**
 * Clear All Filters Redux Action
 * Gets dispatched when clear all filters request is triggered inside relationships view.
 */
export const clearAllFilters = createAction(RelationshipsActions.ClearAllFilters);

/**
 * Viso Users Query Request Redux Action
 * Gets dispatched when viso users query request is triggered.
 */
export const visoUsersQueryRequest = createAction(RelationshipsActions.VisoUsersQueryRequest);

/**
 * Viso Users Query Success Redux Action
 * Gets dispatched when a `VisoUsersQueryRequest` is successful.
 */
export const visoUsersQuerySuccess = createAction(
    RelationshipsActions.VisoUsersQuerySuccess,
    props<{ results: VisoUser[] }>(),
);

/**
 * Org Clients Query Request Redux Action
 * Gets dispatched when org clients query request is triggered
 */
export const orgClientsQueryRequest = createAction(RelationshipsActions.OrgClientsQueryRequest);

/**
 * Org Clients Query Success Redux Action
 * Gets dispatched when a `OrgClientsQueryRequest` is successful.
 */
export const orgClientsQuerySuccess = createAction(
    RelationshipsActions.OrgClientsQuerySuccess,
    props<{ results: Org[] }>(),
);

export const bulkStartAssessmentsRequest = createAction(
    RelationshipsActions.BulkStartAssessmentsRequest,
    props<{ ids: number[]; assessmentsInProgress: boolean; countToEagerlyProcess: number }>(),
);

export const bulkStartAssessmentsRequestSuccess = createAction(
    RelationshipsActions.BulkStartAssessmentsRequestSuccess,
    props<{ ids: number[]; response: BulkStartAssessmentResponse }>(),
);

export const bulkStartAssessmentsRequestAccepted = createAction(
    RelationshipsActions.BulkStartAssessmentsRequestAccepted,
    props<{ ids: number[]; response: BulkStartAssessmentResponse }>(),
);

export const bulkStartAssessmentsRequestFailed = createAction(RelationshipsActions.BulkStartAssessmentsRequestFailed);

export const bulkCancelAssessmentsRequest = createAction(
    RelationshipsActions.BulkCancelAssessmentsRequest,
    props<{ ids: number[]; showConfirmationModal: boolean; countToEagerlyProcess: number }>(),
);

export const bulkCancelAssessmentsRequestSuccess = createAction(
    RelationshipsActions.BulkCancelAssessmentsRequestSuccess,
    props<{ ids: number[]; response: BulkCancelAssessmentsResponse }>(),
);

export const bulkCancelAssessmentsRequestAccepted = createAction(
    RelationshipsActions.BulkCancelAssessmentsRequestAccepted,
    props<{ ids: number[]; response: BulkCancelAssessmentsResponse }>(),
);

export const bulkCancelAssessmentsRequestFailed = createAction(RelationshipsActions.BulkCancelAssessmentsRequestFailed);

export const bulkDisableArtifactUpdatesRequest = createAction(
    RelationshipsActions.BulkDisableArtifactUpdatesRequest,
    props<{ ids: number[] }>(),
);

export const bulkDisableArtifactUpdatesRequestSuccess = createAction(
    RelationshipsActions.BulkDisableArtifactUpdatesRequestSuccess,
    props<{ ids: number[]; response: BulkRelationshipUpdateResponse }>(),
);

export const bulkDisableArtifactUpdatesRequestFailed = createAction(
    RelationshipsActions.BulkDisableArtifactUpdatesRequestFailed,
);

export const bulkEnableArtifactUpdatesRequest = createAction(
    RelationshipsActions.BulkEnableArtifactUpdatesRequest,
    props<{ ids: number[] }>(),
);

export const bulkEnableArtifactUpdatesRequestSuccess = createAction(
    RelationshipsActions.BulkEnableArtifactUpdatesRequestSuccess,
    props<{ ids: number[]; response: BulkRelationshipUpdateResponse }>(),
);

export const bulkEnableArtifactUpdatesRequestFailed = createAction(
    RelationshipsActions.BulkEnableArtifactUpdatesRequestFailed,
);

export const getRiskNetworkGraphRequest = createAction(
    RelationshipsActions.GetRiskNetworkGraphRequest,
    props<{ searchString?: string; networkExposureOrgId?: number }>(),
);

export const getRiskNetworkGraphRequestSuccess = createAction(
    RelationshipsActions.GetRiskNetworkGraphRequestSuccess,
    props<{ riskNetworkGraphNodes: RiskNetworkGraphNode[] }>(),
);

export const getRiskNetworkGraphRequestFailed = createAction(RelationshipsActions.GetRiskNetworkGraphRequestFailed);

export const getVendorByIdRequest = createAction(
    RelationshipsActions.GetVendorByIdRequest,
    props<{ vendorId: number }>(),
);

export const getVendorByIdRequestSuccess = createAction(
    RelationshipsActions.GetVendorByIdRequestSuccess,
    props<{ vendor: CompleteVendorSearchResult }>(),
);

export const getVendorByIdRequestFailed = createAction(RelationshipsActions.GetVendorByIdRequestFailed);

export const getVendorTierStatsRequest = createAction(
    RelationshipsActions.GetVendorTierStatsRequest,
    props<{ vendorTierId: number }>(),
);

export const getVendorTierStatsRequestSuccess = createAction(
    RelationshipsActions.GetVendorTierStatsRequestSuccess,
    props<{ vendorTierStats: VendorTierStats }>(),
);

export const getVendorTierStatsRequestFailed = createAction(RelationshipsActions.GetVendorTierStatsRequestFailed);

export const clearVendorTierStats = createAction(RelationshipsActions.ClearVendorTierStats);

export const tryShowVendorTierWalkthrough = createAction(RelationshipsActions.TryShowVendorTierWalkthrough);

export const updateVendorTiersCountRequest = createAction(
    RelationshipsActions.UpdateVendorTiersCountRequest,
    props<{ numberOfTiers: number }>(),
);

export const updateVendorTiersCountRequestSuccess = createAction(
    RelationshipsActions.UpdateVendorTiersCountSuccess,
    props<{ vendorTiers: VendorTier[] }>(),
);

export const updateVendorTiersCountRequestFailed = createAction(RelationshipsActions.UpdateVendorTiersCountFailed);

export const openManageTiersDialog = createAction(RelationshipsActions.OpenManageTiersDialog);

export const openAssignRelationshipsToTierDialog = createAction(
    RelationshipsActions.OpenAssignRelationshipsToTierDialog,
    props<{
        relationshipIds: number[];
        tier?: VendorTier;
        relationshipsAlreadyAssignedToTiers: boolean;
        forUnpaginatedRelationships: boolean;
    }>(),
);

export const assignRelationshipsToTierRequest = createAction(
    RelationshipsActions.AssignRelationshipsToTierRequest,
    props<{ relationshipIds: number[]; tier?: VendorTier }>(),
);

export const assignRelationshipsToTierRequestSuccess = createAction(
    RelationshipsActions.AssignRelationshipsToTierRequestSuccess,
);

export const assignRelationshipsToTierRequestFailed = createAction(
    RelationshipsActions.AssignRelationshipsToTierRequestFailed,
);

export const openVendorTierDescriptionDialog = createAction(RelationshipsActions.OpenVendorTierDescriptionDialog);

export const updateVendorTierDescriptionRequest = createAction(
    RelationshipsActions.UpdateVendorTierDescriptionRequest,
    props<{ vendorTierId: number; description: string }>(),
);

export const updateVendorTierDescriptionRequestSuccess = createAction(
    RelationshipsActions.UpdateVendorTierDescriptionRequestSuccess,
    props<{ vendorTier: VendorTier }>(),
);

export const updateVendorTierDescriptionRequestFailed = createAction(
    RelationshipsActions.UpdateVendorTierDescriptionRequestFailed,
);

export const getRelationshipCountsRequest = createAction(RelationshipsActions.GetRelationshipCountsRequest);

export const getRelationshipCountsRequestSuccess = createAction(
    RelationshipsActions.GetRelationshipCountsRequestSuccess,
    props<{ relationshipCounts: RelationshipCounts }>(),
);

export const getRelationshipCountsRequestFailed = createAction(RelationshipsActions.GetRelationshipCountsRequestFailed);

export const bulkArchiveRelationshipsRequest = createAction(
    RelationshipsActions.BulkArchiveRelationshipsRequest,
    props<{ relationshipIds: number[]; countToEagerlyProcess: number }>(),
);

export const bulkArchiveRelationshipsRequestSuccess = createAction(
    RelationshipsActions.BulkArchiveRelationshipsRequestSuccess,
    props<{ response: BulkRelationshipUpdateResponse }>(),
);

export const bulkArchiveRelationshipsRequestAccepted = createAction(
    RelationshipsActions.BulkArchiveRelationshipsRequestAccepted,
    props<{ response: BulkRelationshipUpdateResponse }>(),
);

export const bulkArchiveRelationshipsRequestFailed = createAction(
    RelationshipsActions.BulkArchiveRelationshipsRequestFailed,
);

export const bulkOffboardRelationshipsRequest = createAction(
    RelationshipsActions.BulkOffboardRelationshipsRequest,
    props<{ relationshipIds: number[]; countToEagerlyProcess: number }>(),
);

export const bulkOffboardRelationshipsRequestSuccess = createAction(
    RelationshipsActions.BulkOffboardRelationshipsRequestSuccess,
    props<{ response: BulkRelationshipUpdateResponse }>(),
);

export const bulkOffboardRelationshipsRequestAccepted = createAction(
    RelationshipsActions.BulkOffboardRelationshipsRequestAccepted,
    props<{ response: BulkRelationshipUpdateResponse }>(),
);

export const bulkOffboardRelationshipsRequestFailed = createAction(
    RelationshipsActions.BulkOffboardRelationshipsRequestFailed,
);

export const getRiskThresholdsForVendorTierStats = createAction(
    RelationshipsActions.GetRiskThresholdsForVendorTierStats,
);

export const getAllRelationshipIdsQueryRequest = createAction(
    RelationshipsActions.GetAllRelationshipIdsQueryRequest,
    props<{ search: String }>(),
);

export const getAllRelationshipIdsQuerySuccess = createAction(
    RelationshipsActions.GetAllRelationshipIdsQuerySuccess,
    props<{ relationshipIds: number[] }>(),
);

export const getAllRelationshipIdsQueryFailed = createAction(RelationshipsActions.GetAllRelationshipIdsQueryFailed);

export const clearAllUnpaginatedRelationshipIds = createAction(RelationshipsActions.ClearAllUnpaginatedRelationshipIds);

export const restoreRelationshipRequest = createAction(
    RelationshipsActions.RestoreRelationshipRequest,
    props<{ relationshipId: number }>(),
);

export const restoreRelationshipRequestSuccess = createAction(RelationshipsActions.RestoreRelationshipRequestSuccess);

export const restoreRelationshipRequestFailed = createAction(RelationshipsActions.RestoreRelationshipRequestFailed);

export const expandNthGraph = createAction(RelationshipsActions.ExpandNthGraph);

export const collapseNthGraph = createAction(RelationshipsActions.CollapseNthGraph);

export const openVendorTierDescriptionDialogClosed = createAction(
    RelationshipsActions.OpenVendorTierDescriptionDialogClosed,
);

export const closeVendorTierDescriptionDialog = createAction(RelationshipsActions.CloseVendorTierDescriptionDialog);

export const setSelectedTierId = createAction(RelationshipsActions.SetSelectedTierId, (selectedTierId: number) => ({
    selectedTierId,
}));
