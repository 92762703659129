import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import { OrgService } from 'src/main/webapp/app/entities/org';
import {
    searchOrganizationsRequestFailed,
    searchOrganizationsRequestSuccess,
    searchOrganizationsRequest,
    getOrgDomainsRequest,
    getOrgDomainsRequestSuccess,
    getOrgDomainsRequestFailed,
} from '../actions/organization.actions';

@Injectable()
export class OrganizationEffects {
    searchOrganizationsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(searchOrganizationsRequest),
            throttleTime(500, undefined, { leading: true, trailing: true }),
            switchMap(({ searchText, componentId, onlyClients }) =>
                this._orgService.searchByKeyword(searchText?.toLowerCase(), onlyClients).pipe(
                    map((organizations) =>
                        searchOrganizationsRequestSuccess({ organizations, searchText, componentId }),
                    ),
                    catchError((error: HttpErrorResponse) => of(searchOrganizationsRequestFailed({ error }))),
                ),
            ),
        ),
    );

    getOrgDomainsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getOrgDomainsRequest),
            switchMap(({ orgId }) =>
                this._orgService.findAllDomains(orgId).pipe(
                    map((orgDomains) => getOrgDomainsRequestSuccess({ orgDomains })),
                    catchError((error: HttpErrorResponse) => of(getOrgDomainsRequestFailed({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _orgService: OrgService,
    ) {}
}
