import { GuidedTour, Orientation } from 'ngx-guided-tour';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

export const instantAnalysisRLPTour = (router: Router): GuidedTour => {
    return {
        tourId: 'instant-analysis-rlp',
        steps: [
            {
                title: 'New! Instant analysis!',
                content:
                    'Check out new risk insights about vendors in the directory. \n\n Get instantly predicted inherent and residual risk to help your team move securely at the speed of business.',
                closeAction: () => router.navigate(['/directory']),
            },
            {
                title: 'Find enhanced vendor profiles in the vendor directory.',
                content: '',
                closeAction: () => {
                    const googleDirectoryId = environment.googleDirectoryId;
                    router.navigate(['/directory', googleDirectoryId]);
                },
            },
            {
                title: "We've updated the layout of the directory, and added new information.",
                content: '',
                orientation: Orientation.Bottom,
            },
            {
                title: 'Dynamic metrics keep you updated on vendor activity with VISO TRUST.',
                content: '',
                orientation: Orientation.Bottom,
                selector: '[data-spotlight-tour="vendor-overview-dynamic-metrics"]',
            },
            {
                title: 'Know what to expect before you fully assess.',
                content: '',
                orientation: Orientation.Top,
                selector: '[data-spotlight-tour="risk-details-prediction"]',
            },
            {
                title: 'Add a relationship for more detailed insights and predicted risk values.',
                content: '',
                orientation: Orientation.Left,
                selector: '[data-spotlight-tour="add-relationship"]',
            },
        ],
    };
};
