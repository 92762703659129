<div class="d-flex flex-column w-100 pt-3">
    <mat-card appearance="outlined" class="mb-4 card-container">
        <mat-card-content>
            <h4 class="text-bold mb-4">{{ LicenseTypeLabel[clientLicense.licenseType] }} license</h4>
            <div class="d-flex flex-column gap-2">
                <div class="row">
                    <div class="col-4">Custom control usage</div>
                    <div class="col">
                        {{
                            formatLicenseField(
                                clientLicense.supplementalQuestionnaireQuestionCount,
                                clientLicense.maxSupplementalQuestionnaireQuestions
                            )
                        }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">Custom domain</div>
                    <div class="col">{{ hasDomainsBrandingEnabled ? 'Enabled' : 'Disabled' }}</div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="mb-4 card-container">
        <mat-card-content>
            <h4 class="text-bold mb-4">Assessment utilization</h4>
            <div class="d-flex flex-column gap-2">
                <div class="row">
                    <div class="col-4">Assessed vendors</div>
                    <div class="col">
                        {{
                            formatLicenseField(
                                clientLicense.relationshipsAssessedCount,
                                clientLicense.maxRelationshipsAssessed
                            )
                        }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">Managed vendors</div>
                    <div class="col">
                        {{
                            formatLicenseField(
                                clientLicense.relationshipsWithLifecycleManagementCount,
                                clientLicense.maxRelationshipsWithLifecycleManagement
                            )
                        }}
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-4">Risk dimensions</div>
                    <div class="col">
                        <div class="d-flex flex-column gap-2">
                            <div
                                *ngFor="let domain of licensedRiskDimensions"
                                class="d-flex align-items-center gap-2"
                                [ngClass]="{ 'text-muted': !enabledRiskDimensions?.includes(domain) }"
                            >
                                <mat-icon fontSet="material-symbols-outlined">{{
                                    enabledRiskDimensions?.includes(domain) ? 'check_circle_outline' : 'lock'
                                }}</mat-icon>
                                <span>{{ ControlDomainTypeLabels[domain] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
