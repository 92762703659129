<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Control Domains</li>
    </ol>
</div>
<div class="content-heading">
    <div>Control Domains</div>
</div>
<div class="row">
    <div class="col-xl-12">
        <div class="card card-default">
            <div class="card-header">
                <a
                    class="strong"
                    (click)="
                        openHelp(
                            'The presence of controls lowers the risk of a relationship.',
                            'Depending on the business case you define we will automatically suggest controls, which if present at the vendor would reduce risk.\nIf you would like to create custom controls that you deem to be risk-lowering, please reach out to VISO TRUST support. Every control is associated with an influence factor, which determines how impactful the presence of that control would be.'
                        )
                    "
                    >What are Control Domains<em class="fa fa-question-circle text-primary ms-2"></em
                ></a>
            </div>
            <tabset class="viso-tabset">
                <ng-container *ngFor="let controlGroup of groupedControls$ | async">
                    <tab *ngIf="!!controlGroup.controls.length" [heading]="controlGroup.label">
                        <div class="card-body">
                            <app-controls-list [controlDomains]="controlGroup.controls"> </app-controls-list>
                        </div>
                    </tab>
                </ng-container>
            </tabset>
        </div>
    </div>
</div>
