import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Relationship } from '../../../entities/relationship';

enum BannerCases {
    NoBusinessCases,
    NoDataTypes,
    NoContextAtAll,
    NoAssessment,
}

@Component({
    selector: 'app-in-line-callout',
    templateUrl: './in-line-callout.component.html',
    styleUrls: ['./in-line-callout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'd-block',
    },
})
export class InLineCalloutComponent implements OnInit, OnDestroy {
    @Input()
    set relationship(value: Relationship) {
        this._relationship$.next(value);
    }

    @Output()
    startAssessment = new EventEmitter<void>();

    bannerDetails$: Observable<{
        bannerCase: BannerCases;
        message: string;
        actionLabel: string;
        navigationRoute?: any;
        actionDelegate?: () => void;
    }>;
    isCollapsed$ = new BehaviorSubject<boolean>(false);

    BannerCases = BannerCases;

    get relationship(): Relationship {
        return this._relationship$.value;
    }

    private _relationship$ = new BehaviorSubject<Relationship>(null);
    private _unsub$ = new Subject<void>();

    get addContextLink() {
        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this._relationship$.value?.id}/context`,
                },
            },
        ];
    }

    get assessmentDetailsLink() {
        return ['/assessments/', this._relationship$.value?.latestAssessmentId];
    }

    ngOnInit(): void {
        const bannerCase$ = this._relationship$.pipe(
            map((relationship) => {
                const [noDataTypes, noBusinessCases, latestAssessmentStatus] = [
                    !relationship.dataTypes.length,
                    !relationship.contextTypes.length,
                    relationship.latestAssessmentStatus,
                ];
                if (noBusinessCases && noDataTypes) {
                    return BannerCases.NoContextAtAll;
                }
                if (noBusinessCases) {
                    return BannerCases.NoBusinessCases;
                }
                if (noDataTypes) {
                    return BannerCases.NoDataTypes;
                }
                if (!latestAssessmentStatus) {
                    return BannerCases.NoAssessment;
                }
            }),
        );

        this.bannerDetails$ = bannerCase$.pipe(
            map((bannerCase) => {
                let message, actionLabel: string;
                let navigationRoute: any;
                let actionDelegate: () => void;
                switch (bannerCase) {
                    case BannerCases.NoBusinessCases:
                        message = 'In order to assess this relationship, finish defining the context.';
                        actionLabel = 'Add Business Case';
                        navigationRoute = this.addContextLink;
                        break;
                    case BannerCases.NoDataTypes:
                        message = 'In order to assess this relationship, finish defining the context.';
                        actionLabel = 'Add Shared Data Types';
                        navigationRoute = this.addContextLink;
                        break;
                    case BannerCases.NoContextAtAll:
                        message = 'In order to assess this relationship, first define the context.';
                        actionLabel = 'Add Context';
                        navigationRoute = this.addContextLink;
                        break;
                    case BannerCases.NoAssessment:
                        message = 'Get residual risk and and other insights powered by Artifact Intelligence.';
                        actionLabel = 'Start Assessment';
                        actionDelegate = () => this.startAssessment.emit();
                        break;
                }
                return {
                    bannerCase,
                    message,
                    actionLabel,
                    navigationRoute,
                    actionDelegate,
                };
            }),
        );

        bannerCase$
            .pipe(distinctUntilChanged(), takeUntil(this._unsub$))
            .subscribe((bannerCase) => (!!bannerCase ? this.showBanner() : this.dismissBanner()));
    }

    showBanner(): void {
        this.isCollapsed$.next(false);
    }

    dismissBanner(): void {
        this.isCollapsed$.next(true);
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
