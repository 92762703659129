import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VisoUserRole } from '@entities/viso-user';
import { VendorTier } from '@entities/vendor-tier';

@Component({
    selector: 'app-move-tier',
    templateUrl: './move-tier.component.html',
    styleUrl: './move-tier.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveTierComponent {
    @Input({ required: true })
    relationshipId: number;

    @Input({ required: true })
    currentTier: VendorTier;

    @Input({ required: true })
    availableTiers: VendorTier[];

    Roles = VisoUserRole;

    get editLink() {
        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this.relationshipId}/vendor-tier`,
                },
            },
        ];
    }
}
