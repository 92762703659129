<div class="row h-100">
    <div class="col-4">
        <div class="row mb-3">
            <div class="col">
                <h3 class="text-normal mt-3">Import questionnaire</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>Download template and configure your questionnaire.</p>
                <p>Limit {{ maxSupplementalQuestionnaireQuestions }} per organization.</p>
                <p>You have {{ availableQuestions }} questions remaining.</p>
                <a type="button" [href]="IMPORT_QUESTIONNAIRE_TEMPLATE_URL" mat-stroked-button color="primary">
                    <mat-icon>download</mat-icon>
                    Download
                </a>
            </div>
        </div>
    </div>
    <div class="col-auto">
        <mat-divider class="h-100 d-flex" [vertical]="true"></mat-divider>
    </div>
    <div class="col-7 mt-3">
        <div class="row mb-3">
            <div class="col">
                <p>Share your configured questionnaire with us</p>
                <ng-template #showImportQuestionnaire>
                    <div
                        appFileDragAndDrop
                        class="mt-3 drag-drop-message-container"
                        (onFileDropped)="importQuestionnaire($event)"
                    >
                        <div class="row">
                            <div class="col">
                                <div class="display-2 text-primary">
                                    <mat-icon [inline]="true">upload_file</mat-icon>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="mb-0">
                                    Drag and drop or
                                    <a
                                        class="text-primary text-decoration-underline"
                                        href="javascript:void(0)"
                                        (click)="fileInput.click()"
                                        >select your questionnaire</a
                                    >.
                                </p>
                                <p class="text-sm">Accepted file type: .csv</p>
                            </div>
                        </div>
                        <input
                            #fileInput
                            class="d-none"
                            type="file"
                            ng2FileSelect
                            [uploader]="uploader"
                            (onFileSelected)="importQuestionnaire($event)"
                        />
                    </div>
                </ng-template>

                <div
                    *ngIf="
                        !!importFileName && !importErrors?.length && importPercentUploaded === 100;
                        else showImportQuestionnaire
                    "
                    class="drag-drop-message-container p-2 text-center"
                >
                    {{ importFileName }}
                </div>

                <ng-container *ngIf="!importErrors?.length; else errorsList">
                    <div class="d-flex p-0 mt-3">
                        <progressbar
                            *ngIf="!!importPercentUploaded"
                            class="progress-bar-dull progress-bar-resize"
                            [value]="importPercentUploaded"
                            type="info"
                        ></progressbar>
                    </div>
                    <p *ngIf="importPercentUploaded === 100" class="text-bold mt-3">
                        Successfully parsed the uploaded questionnaire
                    </p>
                </ng-container>

                <ng-template #errorsList>
                    <div class="p-0 mt-3">
                        <div class="d-flex">
                            <progressbar
                                class="progress-bar-resize"
                                [value]="importPercentUploaded"
                                type="danger"
                            ></progressbar>
                        </div>

                        <div class="mt-3">
                            <p class="text-bold">Please address the errors and upload your file again.</p>
                            <p>
                                Review the notes below and refer to our
                                <a
                                    href="https://support.visotrust.com/article/r3vtvrvpie-importing-relationship"
                                    target="_blank"
                                    >help documentation</a
                                >
                                for more details.
                            </p>
                        </div>

                        <div class="mt-3 error-list border border-danger p-3">
                            <table>
                                <tr>
                                    <th>Error</th>
                                    <th>Row</th>
                                </tr>
                                <tr *ngFor="let error of importErrors">
                                    <td class="pe-5">{{ error.key }}</td>
                                    <td class="ms-2">{{ error.value }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="mt-3 mb-3">
                        <span
                            >If you are unable to resolve the issue, please contact
                            <a href="mailto:support@visotrust.com">support&#64;visotrust.com</a> and share the ID:
                            <span class="text-bold">{{ supportId }}</span> for reference.</span
                        >
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
