import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Relationship } from '../../../../entities/relationship';
import { Risk } from '../../../../entities/risk-assessment';
import { Tag } from '../../../../entities/tag';

export enum RelationshipActions {
    UpdateVendorRelationship = '[Request] Update Vendor Relationship',
    UpdateVendorRelationshipCancel = '[Request] Update Vendor Relationship Cancel',
    UpdateRelationship = '[Request] Update Relationship',
    UpdateRelationshipSuccess = '[Request] Update Relationship Success',
    UpdateRelationshipFailed = '[Request] Update Relationship Failed',
    GetRelationshipRequest = '[Request] Get Relationship Request',
    GetRelationshipRequestSuccess = '[Request] Get Relationship Request Success',
    GetRelationshipRequestFailed = '[Request] Get Relationship Request Failed',
    GetRequestTags = '[Request] Get Relationship Tags',
    GetRequestTagsSuccess = '[Request] Get Relationship Tags Success',
    GetRequestTagsFailed = '[Request] Get Relationship Tags Failed',
    CalculateInherentRisk = '[Request] Calculate Inherent Risk',
    CalculateInherentRiskSuccess = '[Request] Calculate Inherent Risk Success',
    CalculateInherentRiskFailed = '[Request] Calculate Inherent Risk Failed',
}

export const updateVendorRelationship = createAction(
    RelationshipActions.UpdateVendorRelationship,
    props<{ relationship: Relationship }>(),
);

export const updateVendorRelationshipCancel = createAction(RelationshipActions.UpdateVendorRelationshipCancel);

export const updateRelationship = createAction(
    RelationshipActions.UpdateRelationship,
    props<{ relationship: Relationship }>(),
);

export const updateRelationshipSuccess = createAction(
    RelationshipActions.UpdateRelationshipSuccess,
    props<{ relationship: Relationship }>(),
);

export const updateRelationshipFailed = createAction(RelationshipActions.UpdateRelationshipFailed);

export const getRelationshipRequest = createAction(
    RelationshipActions.GetRelationshipRequest,
    props<{ requestId: number }>(),
);

export const getRelationshipRequestSuccess = createAction(
    RelationshipActions.GetRelationshipRequestSuccess,
    props<{ relationship: Relationship }>(),
);

export const getRelationshipRequestFailed = createAction(
    RelationshipActions.GetRelationshipRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getRequestTags = createAction(RelationshipActions.GetRequestTags, props<{ requestId: number }>());

export const getRequestTagsSuccess = createAction(RelationshipActions.GetRequestTagsSuccess, props<{ tags: Tag[] }>());

export const getRequestTagsFailed = createAction(RelationshipActions.GetRequestTagsFailed);

export const calculateInherentRisk = createAction(
    RelationshipActions.CalculateInherentRisk,
    props<{ dataTypes: number[]; contextTypes: number[] }>(),
);

export const calculateInherentRiskSuccess = createAction(
    RelationshipActions.CalculateInherentRiskSuccess,
    props<{ data: Risk }>(),
);

export const calculateInherentRiskFailed = createAction(RelationshipActions.CalculateInherentRiskFailed);
