import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RelationshipService } from '@entities/relationship';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { RelationshipOnboardingModalComponent } from '../../relationship-tab/relationship-onboarding-modal/relationship-onboarding-modal.component';
import {
    offboardRelationshipRequest,
    offboardRelationshipRequestFailed,
    offboardRelationshipRequestSuccess,
    onboardModalCancelled,
    onboardRelationshipFailed,
    onboardRelationshipRequest,
    onboardRelationshipSuccess,
    openOnboardModal,
} from '../actions/onboard.actions';
import { getRelationshipPopulatedLatestAssessment } from '../selectors/assessment.selectors';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';

@Injectable()
export class OnboardEffects {
    openOnboardModal$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openOnboardModal),
            withLatestFrom(this._store$.select(getRelationshipPopulatedLatestAssessment)),
            map(([onboardModalInputs, latestAssessment]) => ({
                ...onboardModalInputs,
                latestCompletedAssessmentDate: latestAssessment?.completedDate,
            })),
            switchMap(({ relationship, vendorName, dateOfNextArtifactExpiration, latestCompletedAssessmentDate }) =>
                this._dialogService
                    .open(RelationshipOnboardingModalComponent, {
                        inputs: {
                            relationship,
                            vendorName,
                            dateOfNextArtifactExpiration,
                            latestCompletedAssessmentDate,
                        },
                        config: {
                            maxWidth: 900,
                        },
                        metadata: { modalName: 'Onboard Relationship' },
                    })
                    .afterClosed()
                    .pipe(
                        map((onboardRequest) =>
                            !!onboardRequest
                                ? onboardRelationshipRequest({
                                      relationshipId: relationship.id,
                                      onboardRequest,
                                  })
                                : onboardModalCancelled(),
                        ),
                    ),
            ),
        ),
    );

    onboardRelationship$ = createEffect(() =>
        this._actions$.pipe(
            ofType(onboardRelationshipRequest),
            switchMap(({ relationshipId, onboardRequest }) =>
                this._relationshipService.onboard(relationshipId, onboardRequest).pipe(
                    tap(({ vendorName }) => {
                        const snackbarMessage = `<strong>${vendorName}</strong> has been onboarded.`;
                        this._snackbarService.success(snackbarMessage);
                    }),
                    map((relationshipOnboardedView) => onboardRelationshipSuccess({ relationshipOnboardedView })),
                    catchError(() => of(onboardRelationshipFailed())),
                ),
            ),
        ),
    );

    showOnboardedMessage$ = createEffect(() => this._actions$.pipe(ofType(onboardRelationshipSuccess)), {
        dispatch: false,
    });

    offboardRelationshipRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(offboardRelationshipRequest),
            switchMap(({ relationshipId }) => {
                return this._confirmService
                    .confirm({
                        title: 'Offboard Relationship',
                        message: `Are you sure you want to offboard this relationship? Any assessments in progress will be cancelled and Lifecycle Management will be disabled until this relationship is onboarded.`,
                        confirmLabel: 'Yes, offboard',
                    })
                    .pipe(
                        filter(Boolean),
                        switchMap(() =>
                            this._relationshipService.offboard(relationshipId).pipe(
                                tap(({ vendorName }) => {
                                    const snackbarMessage = `<strong>${vendorName}</strong> has been mark as not onboarded.`;
                                    this._snackbarService.success(snackbarMessage);
                                }),
                                map(() => offboardRelationshipRequestSuccess()),
                                catchError(() => of(offboardRelationshipRequestFailed())),
                            ),
                        ),
                    );
            }),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _relationshipService: RelationshipService,
        private _snackbarService: SnackbarService,
        private _dialogService: MatDialogWrapperService,
        private _confirmService: MatConfirmDialogService,
    ) {}
}
