import { GuidedTour, Orientation } from 'ngx-guided-tour';

const scrollToElementWithResize = (selector: string) => {
    const element = document.querySelector(selector);
    element?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    // Hack to fix the highlighted element not being visible after scrolling
    setTimeout(() => window.dispatchEvent(new Event('resize')), 700);
};

export const instantAnalysisRDPTour = (): GuidedTour => {
    return {
        tourId: 'instant-analysis-rlp',
        steps: [
            {
                title: 'Welcome to your instant analysis!',
                content:
                    'Get instantly predicted inherent and residual risk to help your team move securely at the speed of business.',
            },
            {
                title: "We'll predict inherent and residual risk based on what we know about the vendor.",
                content: '',
                orientation: Orientation.Top,
                selector: '[data-spotlight-tour="vendor-prediction"]',
            },
            {
                title: 'Get information on domain assurance, artifacts, and compliance.',
                content: '',
                orientation: Orientation.Top,
                selector: '[data-spotlight-tour="domain-artifacts-compliance-information"]',
                action: () =>
                    scrollToElementWithResize('[data-spotlight-tour="domain-artifacts-compliance-information"]'),
            },
            {
                title: 'Predicted context outlines an accurate, customized threat surface for the vendor.',
                content: '',
                orientation: Orientation.Top,
                selector: '[data-spotlight-tour="vendor-context"]',
                action: () => scrollToElementWithResize('[data-spotlight-tour="vendor-context"]'),
            },
            {
                title: 'Real-time monitoring keeps your team ahead of exposure. Onboard the vendor to get notifications.',
                content: '',
                orientation: Orientation.Top,
                selector: '[data-spotlight-tour="vendor-monitoring"]',
                scrollAdjustment: 400,
                action: () => scrollToElementWithResize('[data-spotlight-tour="vendor-monitoring"]'),
            },
        ],
    };
};
