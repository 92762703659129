<form [formGroup]="businessOwnerFormGroup">
    <ng-content select="[businessPurposeControl]"></ng-content>

    <ng-container *ngIf="containerType === 'assignBusinessOwnermodal'; else customFormTitle">
        <legend class="mb-3 mt-0">Business Owner</legend>
    </ng-container>
    <ng-template #customFormTitle>
        <h2 class="mt-4 text-bold">Business Owner</h2>
    </ng-template>

    <p class="mb-4">The business owner is the internal point of contact and is typically the buyer or requester.</p>

    <app-radiobutton-group
        [type]="'toggle'"
        [direction]="'row'"
        [radiobuttonGroupName]="'selectContact'"
        [formControl]="selectContactFormControl"
    >
        <ng-template radiobutton-group-item [value]="true" let-forItem="forItem">
            <label [for]="forItem">Assign an existing contact</label>
        </ng-template>

        <ng-template radiobutton-group-item [value]="false" let-forItem="forItem">
            <label [for]="forItem">Create a new contact and assign</label>
        </ng-template>
    </app-radiobutton-group>

    <div class="mt-4" *ngIf="containerType === 'assignBusinessOwnermodal'">
        <hr />
    </div>

    <div class="form-group required my-4" *ngIf="showContactList">
        <label>Contact</label>
        <ngx-select
            optionValueField="ref"
            optionTextField="name"
            formControlName="contact"
            [items]="contacts"
            [isFocused]="false"
            [autoClearSearch]="true"
            placeholder="Select an existing contact"
        >
            <ng-template ngx-select-option let-option>
                <div [ngClass]="{ 'border-bottom pb-1': option.value.email === currentUser?.email }">
                    <span>{{ option.value.firstName }} {{ option.value.lastName }} • {{ option.value.email }}</span>
                    <span *ngIf="option.value.email === currentUser?.email"> • (you)</span>
                    <span class="text-muted d-block">{{ option.value.businessUnitName }}</span>
                </div>
            </ng-template>
        </ngx-select>
    </div>

    <div class="form-group required my-4" *ngIf="!showContactList">
        <div class="row">
            <div class="col-xl-12">
                <div class="form-group required">
                    <label for="field_first_name">First Name</label>
                    <input
                        type="text"
                        class="form-control"
                        name="firstName"
                        id="field_first_name"
                        data-pw="firstNameInput"
                        formControlName="firstName"
                        placeholder="Enter first name"
                        required
                    />
                    <span class="text-error" *ngIf="showFirstNameLengthError">Maximum Length: 50</span>
                </div>
                <div class="form-group required">
                    <label for="field_last_name">Last Name</label>
                    <input
                        type="text"
                        class="form-control"
                        name="lastName"
                        id="field_last_name"
                        data-pw="lastNameInput"
                        formControlName="lastName"
                        placeholder="Enter last name"
                        required
                    />
                </div>
                <span class="text-error" *ngIf="showLastNameLengthError">Maximum Length: 50</span>
                <div class="form-group required">
                    <label for="field_email">Email Address</label>
                    <input
                        type="text"
                        class="form-control"
                        name="email"
                        id="field_email"
                        data-pw="emailInput"
                        formControlName="email"
                        placeholder="Enter email"
                        required
                    />
                    <span class="text-error" *ngIf="showEmailLengthError">Maximum Length: 100<br /></span>
                    <span class="text-error" *ngIf="showContactAlreadyExistsError"
                        >A contact with this email address already exists.</span
                    >
                    <span class="text-error" *ngIf="showInvalidEmailDomainError"
                        >The contacts domain is invalid. Valid domains: <span>{{ orgDomains.join(',') }}</span></span
                    >
                    <span class="text-error" *ngIf="showInvalidEmailSyntaxError">
                        The username length is invalid. Maximum size for username 64 characters.
                    </span>
                </div>
                <div class="form-group required mb-0">
                    <label for="field_businessUnit">Business Unit</label>
                    <ngx-select
                        optionValueField="ref"
                        optionTextField="name"
                        formControlName="businessUnit"
                        [items]="businessUnits"
                        [isFocused]="false"
                        placeholder="Select business unit"
                        data-pw="selectBusinessUnitInput"
                    >
                    </ngx-select>
                </div>
            </div>
        </div>
    </div>
</form>
