import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { CSRFService } from '@shared/auth/csrf.service';
import { createRequestOption } from '@shared/model/request-util';
import { DateUtilsService } from '@shared/utils/date-utils.service';
import {
    Artifact,
    ArtifactService,
    ArtifactType,
    ArtifactUploadResponse,
    isArtifactExpired,
    isArtifactExpiringSoon,
    QuestionnaireArtifact,
    UpdateQuestionnaireRequest,
    UploadFileArtifactRequest,
} from '@entities/artifact';
import { AssessmentRecommendation } from '@entities/recommendation';
import { isRTPArtifactAvScanned, isRTPArtifactClassified, isRTPArtifactPublishDateSet, RTPEvent } from '@entities/rtp';
import { RelationshipControlDomainAssessments } from '@entities/relationship/models/security-control-domain';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';
import { AssessmentSubmission, PublicAssessment } from './assessment.model';
import { OrgBranding } from '../../branding';

@Injectable({ providedIn: 'root' })
export class PublicAssessmentService {
    private _assessmentSubmissionResourceUrl = 'api/assessment-submission';
    private _assessmentRequestResourceUrl = 'api/assessment-request';

    constructor(
        private _http: HttpClient,
        private _csrfService: CSRFService,
        private _dateUtilsService: DateUtilsService,
        private _apollo: Apollo,
        private _artifactService: ArtifactService,
    ) {}

    submit(assessment: AssessmentSubmission): Observable<void> {
        return this._http.post<void>(this._assessmentSubmissionResourceUrl, assessment);
    }

    getClientOrgBranding(token: string): Observable<OrgBranding> {
        return this._http.get<OrgBranding>(`${this._assessmentRequestResourceUrl}/${token}/client/branding`, {
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
        });
    }

    find(token: string, secret: string): Observable<PublicAssessment> {
        return this._http
            .post<PublicAssessment>(`${this._assessmentRequestResourceUrl}/${token}`, null, {
                headers: new HttpHeaders({ secret: `"${secret}"` }),
                context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
            })
            .pipe(map((res) => this.convertResponse(res)));
    }

    deleteFile(token: string, secret: string, artifactId: number): Observable<void> {
        return this._http.delete<void>(`${this._assessmentSubmissionResourceUrl}/${token}/files`, {
            headers: new HttpHeaders({ secret: `"${secret}"` }),
            params: createRequestOption({ artifactId }),
        });
    }

    deleteAllFiles(token: string, secret: string): Observable<void> {
        return this._http.delete<void>(`${this._assessmentSubmissionResourceUrl}/${token}/files/all`, {
            headers: new HttpHeaders({ secret: `"${secret}"` }),
        });
    }

    uploadFiles(
        requests: UploadFileArtifactRequest[],
        secret: string,
        token: string,
    ): Observable<ArtifactUploadResponse> {
        const formData = new FormData();
        const filesMetaData = [];

        requests.forEach((r) => {
            formData.append('files', r.file);
            filesMetaData.push(r.fileArtifactMetaData);
        });

        formData.append(
            'fileArtifactMetaData',
            new Blob([JSON.stringify(filesMetaData)], {
                type: 'application/json',
            }),
        );

        return this._http.post<ArtifactUploadResponse>(
            `${this._assessmentSubmissionResourceUrl}/${token}/files`,
            formData,
            {
                headers: new HttpHeaders({
                    name: 'X-XSRF-TOKEN',
                    value: this._csrfService.getCSRF(),
                    secret: `"${secret}"`,
                }),
            },
        );
    }

    savePasswordForArtifact(artifactId: number, password: string, secret: string, token: string): Observable<void> {
        const artifactPasswordBody = {
            artifactId,
            password,
        };

        return this._http.put<void>(
            `${this._assessmentSubmissionResourceUrl}/${token}/artifact-password`,
            artifactPasswordBody,
            {
                headers: new HttpHeaders({ secret: `"${secret}"` }),
            },
        );
    }

    createQuestionnaireArtifact(token: string, secret: string): Observable<QuestionnaireArtifact> {
        return this._http.post<QuestionnaireArtifact>(
            `${this._assessmentSubmissionResourceUrl}/${token}/questionnaire`,
            null,
            {
                headers: new HttpHeaders({ secret: `"${secret}"` }),
            },
        );
    }

    updateQuestionnaire(
        token: string,
        secret: string,
        updateQuestionnaireRequest: UpdateQuestionnaireRequest,
    ): Observable<QuestionnaireArtifact> {
        return this._http.put<QuestionnaireArtifact>(
            `${this._assessmentSubmissionResourceUrl}/${token}/questionnaire`,
            updateQuestionnaireRequest,
            {
                headers: new HttpHeaders({ secret: `"${secret}"` }),
            },
        );
    }

    extendAssessmentExpiration(token: string, secret: string): Observable<void> {
        return this._http.post<void>(`${this._assessmentSubmissionResourceUrl}/${token}/extend-expiration`, null, {
            headers: new HttpHeaders({ secret }),
        });
    }

    forwardAssessmentToNewContact(
        token: string,
        secret: string,
        newContactDetails: { firstName: string; lastName: string; email: string },
    ): Observable<void> {
        return this._http.post<void>(
            `${this._assessmentSubmissionResourceUrl}/${token}/update-recipient`,
            newContactDetails,
            {
                headers: new HttpHeaders({ secret }),
            },
        );
    }

    declineRemediation(
        token: string,
        secret: string,
        vendorDetails: { firstName: string; lastName: string; email: string },
    ): Observable<void> {
        return this._http.post<void>(
            `${this._assessmentSubmissionResourceUrl}/${token}/decline-remediation`,
            vendorDetails,
            {
                headers: new HttpHeaders({ secret }),
            },
        );
    }

    cancelAssessmentDueToClientVendorNoLongerDoingBusiness(
        token: string,
        secret: string,
        vendorDetails: { firstName: string; lastName: string; email: string },
    ): Observable<void> {
        return this._http.post<void>(`${this._assessmentSubmissionResourceUrl}/${token}/cancel`, vendorDetails, {
            headers: new HttpHeaders({ secret }),
        });
    }

    getRecommendations(token: string, secret: string): Observable<AssessmentRecommendation[]> {
        return this._http.post<AssessmentRecommendation[]>(
            `${this._assessmentRequestResourceUrl}/${token}/recommendations`,
            null,
            {
                headers: new HttpHeaders({ secret: `"${secret}"` }),
            },
        );
    }

    listenToPublicAssessmentRTP(token: string, secret: string): Observable<RTPEvent> {
        const eventName = 'onAssessmentRtpEventUnauthenticated';
        const ON_ASSESSMENT_RTP_EVENT_UNAUTHENTICATED = gql`
            subscription onAssessmentRtpEventUnauthenticated($secret: String!, $token: String!) {
                ${eventName}(secret: $secret, token: $token) {
                    id
                    source
                    specversion
                    type
                    datacontenttype
                    dataschema
                    subject
                    time
                    data
                    additionalProperties
                }
            }
        `;

        return this._apollo
            .subscribe<{ [eventName]: { data: RTPEvent } }>({
                query: ON_ASSESSMENT_RTP_EVENT_UNAUTHENTICATED,
                variables: {
                    token,
                    secret,
                },
            })
            .pipe(
                map((response) => response.data?.[eventName].data),
                map((rtpEvent) => {
                    if (
                        isRTPArtifactAvScanned(rtpEvent) ||
                        isRTPArtifactClassified(rtpEvent) ||
                        isRTPArtifactPublishDateSet(rtpEvent)
                    ) {
                        return {
                            ...rtpEvent,
                            artifact: this._artifactService.convertItemFromServer(rtpEvent.artifact),
                        };
                    }
                    return rtpEvent;
                }),
            );
    }

    getSecurityControlDomain(token: string, secret: string): Observable<RelationshipControlDomainAssessments> {
        return this._http.get<RelationshipControlDomainAssessments>(
            `${this._assessmentSubmissionResourceUrl}/${token}/security-control-domain`,
            {
                headers: new HttpHeaders({ secret: `"${secret}"` }),
            },
        );
    }

    private convertResponse(assessment: PublicAssessment): PublicAssessment {
        const copy = this.convertItemFromServer(assessment);
        return copy;
    }

    private convertItemFromServer(assessment: PublicAssessment): PublicAssessment {
        const copy: PublicAssessment = { ...assessment };
        copy.createdDate = this._dateUtilsService.convertDateTimeFromServer(assessment.createdDate);

        copy.artifacts.map((artifact: Artifact) => {
            if (artifact.type === ArtifactType.QUESTIONNAIRE_ARTIFACT) {
                const questionnaireArtifact: QuestionnaireArtifact = artifact;
                questionnaireArtifact.artifactTypeName = 'Questionnaire';
                questionnaireArtifact.fileName = `Questionnaire Artifact ${this._dateUtilsService.formatDate(
                    questionnaireArtifact.createdDate,
                    'yyyy-MM-dd hh:mm a',
                )}`;
            }

            if (artifact.validation) {
                artifact.validation.createdDate =
                    artifact.validation.createdDate &&
                    this._dateUtilsService.convertDateTimeFromServer(artifact.validation.createdDate);
                artifact.validation.expirationDate =
                    artifact.validation.expirationDate &&
                    this._dateUtilsService.convertLocalDateFromServer(artifact.validation.expirationDate);
                artifact.validation.publishedDate =
                    artifact.validation.publishedDate &&
                    this._dateUtilsService.convertLocalDateFromServer(artifact.validation.publishedDate);
                artifact.validation.expiringSoon = isArtifactExpiringSoon(artifact);
                artifact.validation.expired = isArtifactExpired(artifact);
            }
        });

        return copy;
    }
}
