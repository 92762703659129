<div class="row stat-boxes-wrapper">
    <div class="col-sm-6 col-lg-3 position-relative">
        <div class="card risk-card" [ngClass]="inherentRiskBoxCssClasses()">
            <div class="card-body">
                <div class="card-title text-muted mb-0">Inherent Risk</div>
                <p class="h4 text-bold mb-0">{{ inherentRiskLabel() | riskLevel | async }}</p>
            </div>
            <div class="card-footer py-0"></div>
        </div>
    </div>
    <div class="col-sm-6 col-lg-3">
        <div class="card risk-card" [ngClass]="residualRiskBoxCssClasses()">
            <div class="card-body">
                <div class="d-flex justify-content-between">
                    <div class="card-title text-muted mb-0">
                        Residual Risk {{ riskIsOverridden() ? ' (overridden)' : '' }}
                    </div>
                    <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin]">
                        <a exportRemovableAction class="text-primary cursor-pointer" (click)="editRiskReview.emit()">
                            Edit
                        </a>
                    </ng-container>
                </div>
                <div class="d-flex">
                    <p
                        class="h4 text-bold mb-0"
                        [ngClass]="{
                            'fst-italic text-muted': isResidualRiskTransitionalAndNotOverridden(),
                        }"
                    >
                        {{ residualRiskLabel() | riskLevel | async }}
                        <span *ngIf="isResidualRiskTransitionalAndNotOverridden()">*</span>
                    </p>
                </div>
            </div>
            <div class="card-footer py-0">
                <small class="text-muted">{{ residualRiskHelpText() }}</small>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="card-title text-muted mb-0">Lifecycle Status</div>
                <p
                    class="h4 text-bold mb-0 d-flex justify-content-between"
                    [ngClass]="{
                        'text-muted': isNotOnboarded() || isOnboardedAndRecertificationIsDisabled(),
                    }"
                >
                    {{ lifecycleStatus() }}
                    <em *ngIf="isUpdatingArtifactsOrRecertifying()" class="fa fa-sync text-muted ms-auto"></em>
                </p>
            </div>
            <div class="card-footer py-0">
                <ng-container *ngIf="isOnboardedAndRecertificationIsDisabled()">
                    <small class="text-muted">Not enabled</small>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-lg-3">
        <div class="card" [ngClass]="{ 'border-danger': isRecertificationOverdue() && !isAssessmentInProgress() }">
            <div class="card-body">
                <div class="card-title text-muted mb-0">Recertification Date</div>
                <p
                    class="h4 text-bold mb-0"
                    [ngClass]="{
                        'text-error': isRecertificationOverdue() && !isAssessmentInProgress(),
                        'text-muted':
                            isNotOnboarded() || isAssessmentInProgress() || isOnboardedAndRecertificationIsDisabled(),
                    }"
                >
                    <ng-container *ngIf="isNotOnboarded(); else checkRecertificationInProgress">
                        {{ lifecycleStatus() }}
                    </ng-container>
                    <ng-template #checkRecertificationInProgress>
                        <ng-container *ngIf="isAssessmentInProgress() && isUpForRecertification(); else recertDate">
                            In Progress
                        </ng-container>
                    </ng-template>
                    <ng-template #recertDate>
                        {{ isOnboardedAndRecertificationIsDisabled() ? 'N/A' : (recertificationDate() | date) }}
                    </ng-template>
                </p>
            </div>
            <div class="card-footer py-0">
                <small
                    *ngIf="isOnboardedAndRecertificationIsDisabled(); else recertificationEnabled"
                    class="text-muted"
                >
                    Not enabled
                </small>
                <ng-template #recertificationEnabled>
                    <small
                        *ngIf="
                            isUpForRecertification() &&
                            !isAssessmentInProgress() &&
                            (recertificationDueDaysLeft() || isRecertificationOverdue())
                        "
                        class="text-error"
                    >
                        <ng-container
                            *ngIf="!!recertificationDueDaysLeft() && !isRecertificationOverdue(); else overdue"
                        >
                            Due in {{ recertificationDueDaysLeft() }} days
                        </ng-container>
                        <ng-template #overdue> Overdue </ng-template>
                    </small>
                </ng-template>
            </div>
        </div>
    </div>
</div>
