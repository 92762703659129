import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { ArtifactFilePageLink, ArtifactType, ControlValidationDetectionType } from '@entities/artifact';
import { Detection } from '@shared/artifact-intelligence/models/detection.model';
import { ArtifactPageLinkViewType } from '../artifact-page-link/artifact-page-link.component';
import { FrameworkMappingType, FrameworkMappingTypeLabels } from '@entities/framework/models/framework-mapping.model';

@Component({
    selector: 'app-detection-details',
    templateUrl: './detection-details.component.html',
    styleUrls: ['./detection-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectionDetailsComponent implements OnInit {
    @Input() detection: Detection;
    @Input() controlName: string;
    @Input() domainControlName: string;
    @Input() frameworkType: FrameworkMappingType;
    @Output() close = new EventEmitter<void>();

    public ArtifactType = ArtifactType;
    public ArtifactPageLinkViewType = ArtifactPageLinkViewType;
    public DetectionType = ControlValidationDetectionType;
    public subservicerNames: string;

    constructor() {}

    ngOnInit() {
        this.subservicerNames = this.detection.subservicers.map((s) => s.name).join(', ');
    }

    public onClose() {
        this.close.emit();
    }

    public getPages(pageList: string): ArtifactFilePageLink[] {
        if (!pageList) {
            return null;
        }
        const pageNumbers = pageList.replace(/, +/g, ',').split(',');

        return pageNumbers.map((page) => {
            if (isNaN(Number(page)) && page.includes('-')) {
                const pageRange = page.split('-');
                return {
                    initialPage: +pageRange[0],
                    finalPage: +pageRange[1],
                };
            } else if (typeof page === 'number') {
                return page;
            } else {
                return page;
            }
        });
    }

    public getFrameworkTypeLabel(frameworkType: string) {
        return FrameworkMappingTypeLabels[frameworkType];
    }
}
