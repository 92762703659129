<div class="modal-header">
    <h4 class="modal-title">Edit Relationship Details</h4>
</div>
<div class="modal-body">
    <form id="businessOwnerForm" [formGroup]="relationshipForm" role="form" (ngSubmit)="update()">
        <div class="form-group required">
            <label for="name">Third Party Name</label>
            <input
                id="name"
                type="text"
                class="form-control"
                formControlName="name"
                placeholder="Enter the third party name"
                [readonly]="!isVendorManagedByCurrentOrg"
            />
            <span class="text-error" *ngIf="showVendorNameError">Maximum Length: 255</span>
        </div>
        <div class="form-group">
            <label for="url">Third Party Website</label>
            <input
                id="url"
                type="text"
                class="form-control"
                formControlName="url"
                placeholder="Enter the URL of the third party's website"
                [readonly]="!isVendorManagedByCurrentOrg"
            />
            <span class="text-error" *ngIf="showVendorURLError">Please provide a valid URL</span>
        </div>
        <div class="form-group">
            <label for="businessPurpose">Business Purpose</label>
            <span class="form-text">Tell us how you will do business with this third party in 1-3 sentences.</span>
            <small class="form-text text-muted mb-2">This will be visible to the third party.</small>
            <textarea
                id="businessPurpose"
                class="form-control business-purpose"
                formControlName="businessPurpose"
                placeholder="e.g. “Provider of SaaS loan servicing software for the loan servicing operations department.”"
            ></textarea>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-button visoButton="secondary" class="me-2" type="button" (click)="close()">Cancel</button>
    <button
        toggleDisableOnActions
        [enablingActions]="[
            RelationshipActions.UpdateRelationshipSuccess,
            RelationshipActions.UpdateRelationshipFailed,
        ]"
        [disablingActions]="[RelationshipActions.UpdateRelationship]"
        [disabled]="relationshipForm.invalid"
        mat-button
        visoButton="primary"
        type="submit"
        form="businessOwnerForm"
    >
        Save
    </button>
</div>
