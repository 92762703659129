<mat-card>
    <mat-card-header class="mb-4 d-block">
        <h4>Artifacts</h4>
        <p>
            {{ vendorDetails?.name }} is likely to provide the following artifacts for audit. Check out
            <a [routerLink]="['/artifact-types']" class="text-primary text-decoration-underline"
                >artifact types in the glossary</a
            >
            for more information.
        </p>
    </mat-card-header>
    <mat-card-content>
        <div class="d-flex column-gap-2 flex-wrap">
            @for (auditReportType of relationshipVendorDetailsStats?.auditReportTypes; track auditReportType) {
                <app-chip class="mb-2" type="basic-inverted" [disableRipple]="true">
                    <div class="d-flex align-items-center">
                        <span>{{ AuditReportTypeName[auditReportType] }}</span>
                    </div>
                </app-chip>
            } @empty {
                <p class="text-muted">No artifact insights available.</p>
            }
        </div>
    </mat-card-content>
</mat-card>
