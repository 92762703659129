import { createAction, props } from '@ngrx/store';

export enum MarkActions {
    markRequestAsArchived = '[Request] Mark Request As Archived',
    markRequestAsArchivedCancel = '[Request] Mark Request As Archived Cancel',
    markRequestAsArchivedRequest = '[Request] Mark Request As Archived Request',
    markRequestAsArchivedRequestSuccess = '[Request] Mark Request As Archived Request Success',
    markRequestAsArchivedRequestFailed = '[Request] Mark Request As Archived Request Failed',
}

export const markRequestAsArchived = createAction(MarkActions.markRequestAsArchived, props<{ requestId: number }>());

export const markRequestAsArchivedCancel = createAction(MarkActions.markRequestAsArchivedCancel);

export const markRequestAsArchivedRequest = createAction(
    MarkActions.markRequestAsArchivedRequest,
    props<{ requestId: number }>(),
);

export const markRequestAsArchivedRequestSuccess = createAction(MarkActions.markRequestAsArchivedRequestSuccess);

export const markRequestAsArchivedRequestFailed = createAction(MarkActions.markRequestAsArchivedRequestFailed);
