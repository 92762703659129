<mat-card>
    <mat-card-header class="mb-3 d-block">
        <div class="d-flex align-items-center">
            <h4 class="mb-0">Context</h4>
            <mat-icon
                class="ms-2 text-primary"
                svgIcon="viso"
                matTooltip="VISO TRUST analyzes vendor data to generate the most accurate outcomes for this relationship. Individual relationship values may vary based on assessment results."
            ></mat-icon>
        </div>
        <p class="mt-2">
            Your relationship with {{ vendorDetails()?.name }} involves the following business case and data types.
        </p>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col">
                <h4>
                    {{ relevantRiskDimensions().length }} risk dimension{{
                        relevantRiskDimensions().length === 1 ? ' is ' : 's are '
                    }}
                    in scope during an assessment of {{ vendorDetails()?.name }}.
                </h4>
                <div class="d-flex column-gap-2 mb-4">
                    <div *ngFor="let controlDomainType of allRiskDimensions">
                        <app-chip
                            type="basic-inverted"
                            [disableRipple]="true"
                            [disabled]="
                                isControlDomainTypeDisabled(controlDomainType) ||
                                isControlDomainTypeOutOfScope(controlDomainType)
                            "
                        >
                            <div class="d-flex align-items-center">
                                <mat-icon
                                    class="me-2"
                                    inline="true"
                                    *ngIf="isControlDomainTypeDisabled(controlDomainType)"
                                    >lock
                                </mat-icon>
                                <span>{{ ControlDomainTypeLabels[controlDomainType] }}</span>
                            </div>
                        </app-chip>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <strong>Business case</strong>
                <p class="text-muted">
                    The business case determines the attack surface profile and the relevant security controls required
                    to reduce the likelihood of data loss.
                </p>
                <div *ngFor="let contextType of contextTypes()">
                    <span
                        matTooltip="{{ contextType.description }}"
                        matTooltipPosition="right"
                        class="text-primary hoverable"
                    >
                        {{ contextType.name }}
                    </span>
                </div>
            </div>
            <div class="col">
                <strong>Data types</strong>
                <p class="text-muted">
                    The overall sensitivity of data types in scope, along with the attack surface, determines the
                    inherent risk of this relationship.
                </p>
                <div *ngFor="let dataType of dataTypes()">
                    <span
                        matTooltip="{{ dataType.description }}"
                        matTooltipPosition="right"
                        class="text-primary hoverable"
                    >
                        {{ dataType.name }}
                    </span>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
