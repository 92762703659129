import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    LeadershipContactView,
    VendorDetailsResponse,
} from '../../../../../routes/request/models/vendor-details.model';

@Component({
    selector: 'app-vendor-leadership',
    templateUrl: './vendor-leadership.component.html',
    styleUrl: './vendor-leadership.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorLeadershipComponent {
    @Input({ required: true })
    vendorDetails: VendorDetailsResponse;

    get leadership(): LeadershipContactView[] {
        return this.vendorDetails?.leadership;
    }
}
