import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrgUrlType } from '@entities/org';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    OrgTrustCenter,
    TrustCenterType,
    VendorDetailsResponse,
    VendorDetailsUrl,
} from '../../../../../routes/request/models/vendor-details.model';

@Component({
    selector: 'app-vendor-links',
    templateUrl: './vendor-links.component.html',
    styleUrl: './vendor-links.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorLinksComponent {
    @Input({ required: true })
    set vendorDetails(value: VendorDetailsResponse) {
        this.vendorDetails$.next(value);
    }

    vendorDetails$ = new BehaviorSubject<VendorDetailsResponse>(null);
    readonly trustCenterLinkName: string = 'Trust Center';
    protected readonly OrgUrlType = OrgUrlType;

    links$: Observable<VendorDetailsUrl[]> = this.vendorDetails$.pipe(
        map((vendorDetails) => {
            if (!vendorDetails || !vendorDetails.urls) {
                return [];
            }
            return [
                { name: 'Website', url: vendorDetails?.homepage, type: null },
                {
                    name: 'Privacy Policy',
                    url: vendorDetails?.urls[OrgUrlType.PRIVACY_POLICY],
                    type: OrgUrlType.PRIVACY_POLICY,
                },
                {
                    name: 'Status Page',
                    url: vendorDetails?.urls[OrgUrlType.STATUS_PAGE],
                    type: OrgUrlType.STATUS_PAGE,
                },
                {
                    name: this.trustCenterLinkName,
                    url: vendorDetails?.trustCenter?.url,
                    type: null,
                },
                {
                    name: 'Testimonials',
                    url: vendorDetails?.urls[OrgUrlType.CUSTOMER_LIST],
                    type: OrgUrlType.CUSTOMER_LIST,
                },
                {
                    name: 'Incident Contact',
                    url: vendorDetails?.urls[OrgUrlType.INCIDENT_RESPONSE],
                    type: OrgUrlType.INCIDENT_RESPONSE,
                },
            ];
        }),
    );

    stripLinkProtocol(url: string): string {
        return url.replace(/(^\w+:|^)\/\//, '');
    }

    urlIsFromSafeBase(trustCenter: OrgTrustCenter | undefined): boolean {
        return trustCenter?.type === TrustCenterType.SAFEBASE;
    }
}
