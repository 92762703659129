<div class="row">
    <div class="col">
        <div class="form-group text-end">
            <button
                [disabled]="isSecurityControlDomainType"
                (click)="enableAllCurrentControls()"
                type="button"
                mat-button
                visoButton="secondary"
                class="me-2"
            >
                Enable All
            </button>
            <button
                [disabled]="isSecurityControlDomainType"
                (click)="disableAllCurrentControls()"
                type="button"
                mat-button
                visoButton="secondary"
                class="me-2"
            >
                Disable All
            </button>
        </div>
    </div>
</div>

<ngx-datatable
    class="bootstrap"
    [rows]="controlDomains"
    [columnMode]="'force'"
    [headerHeight]="$any('auto')"
    [rowHeight]="$any('auto')"
    [loadingIndicator]="loadingControls"
    [count]="controlDomains?.length"
>
    <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
    <ngx-datatable-column name="Description" prop="longDescription"></ngx-datatable-column>
    <ngx-datatable-column name="Business Case" prop="contextTypes">
        <ng-template let-contextTypes="value" ngx-datatable-cell-template>
            <ul>
                <span *ngFor="let contextType of contextTypes | orderBy: 'name'">
                    <li>{{ contextType.name }}</li>
                </span>
            </ul>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Sensitivity" prop="influence">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <ngx-slider
                class="viso-slider slider-content"
                [options]="sliderOptions"
                [(value)]="row.influence"
            ></ngx-slider>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Status" prop="disabled">
        <ng-template let-disabled="value" ngx-datatable-cell-template>
            <app-badge [type]="getBadgeCssClass(disabled)" [noBorderRadius]="true">
                {{ disabled ? 'Disabled' : 'Enabled' }}
            </app-badge>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [width]="40" [resizable]="false" [canAutoResize]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="additional-actions" placement="bottom left" dropdown container="body">
                <button
                    id="button-container"
                    dropdownToggle
                    type="button"
                    class="btn-icon float-end"
                    aria-controls="dropdown-container"
                >
                    <em class="fa fa-ellipsis-v text-primary"></em>
                </button>
                <ul
                    id="dropdown-container"
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-start translate-x"
                    aria-labelledby="button-container"
                    style="--data-translate-value: -160px"
                >
                    <li>
                        <a
                            class="dropdown-item"
                            [ngClass]="{ disabled: isSecurityControlDomainType && !row.disabled }"
                            (click)="toggleControlStatus(row)"
                            >{{ row.disabled ? 'Enable' : 'Disable' }}</a
                        >
                    </li>
                </ul>
            </div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
