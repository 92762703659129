import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatus, VisoUserRole } from '@entities/viso-user';
import { Actions, ofType } from '@ngrx/effects';
import {
    createSupplementalQuestionnaireRequestSuccess,
    deleteSupplementalQuestionnaireRequest,
    deleteSupplementalQuestionnaireRequestSuccess,
    getSupplementalQuestionnairesRequest,
    getSupplementalQuestionnairesRequestFailed,
    getSupplementalQuestionnairesRequestSuccess,
    openCreateSupplementalQuestionnaireDialog,
    updateSupplementalQuestionnaireRequestSuccess,
    uploadSupplementalQuestionnairesRequestSuccess,
    downloadSupplementalQuestionnaireRequest,
} from './redux/questionnaire-management.actions';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '@shared/redux/state';
import { HttpHeaders } from '@angular/common/http';
import { BREADCRUMB_CONTAINER_TOKEN } from '@shared/dynamic-content/dynamic-content-injector';
import { ArtifactType } from '@entities/artifact';
import { TableSortItem } from '@shared/ngx-datables/table-sort-item';
import { getUserAccount, getUserAuthority } from '../../session/redux/session.selectors';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { getSupplementalQuestionnaires } from './redux/questionnaire-management.selectors';
import { SupplementalQuestionnaireDialogStep } from './components/supplemental-questionnaire-dialog/supplemental-questionnaire-dialog.component';
import { IMPORT_QUESTIONNAIRE_TEMPLATE_URL } from '@shared/constants/url.constants';
import { SupplementalQuestionnaireResponse } from '@entities/supplemental-questionnaire';
import { getServerSessionRequest } from '../../session/redux/session.actions';

@Component({
    selector: 'app-questionnaire-management',
    templateUrl: './questionnaire-management.component.html',
    styleUrls: ['./questionnaire-management.component.scss'],
})
export class QuestionnaireManagementComponent implements OnInit, AfterViewInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;

    Roles = VisoUserRole;
    UserStatus = UserStatus;

    questionnairesLoading: boolean;

    queryCount: number;
    itemsPerPage: number;
    totalCurrentItems: number;
    page: number;
    predicate: any;
    reverse: any;
    maxSupplementalQuestionnaireQuestions: number = -1;
    questionCount: number = 0;
    doesOrgHaveAvailableQuestions: boolean = false;
    supplementalQuestionnaires$: Observable<SupplementalQuestionnaireResponse[]>;

    @ViewChild(MatSort) matSort: MatSort;
    dataSource = new MatTableDataSource<SupplementalQuestionnaireResponse>();

    sortByColumnName: string = 'createdDate';
    sortDirection: SortDirection = 'desc';
    columnDefinitions = ['questionnaireName', 'createdDate', 'questions', 'enabled', 'actions'];

    tableSort$: BehaviorSubject<TableSortItem[]> = new BehaviorSubject<TableSortItem[]>([
        {
            prop: this.sortByColumnName,
            dir: this.sortDirection,
        },
    ]);

    columnDefinitions$: Observable<string[]>;

    readonly IMPORT_QUESTIONNAIRE_TEMPLATE_URL = IMPORT_QUESTIONNAIRE_TEMPLATE_URL;
    readonly ArtifactType = ArtifactType;

    private _unsub$ = new Subject<void>();

    isCurrentUserOrgAdmin$: Observable<boolean>;

    constructor(
        private _actions$: Actions,
        private _store$: Store<AppState>,
        private _route: ActivatedRoute,
        private _cdr: ChangeDetectorRef,
        private _router: Router,
    ) {
        this.itemsPerPage = 10;
        this.page = 0;
    }

    ngOnInit() {
        this.columnDefinitions$ = this._store$.pipe(
            select(getUserAuthority(VisoUserRole.OrgAdmin)),
            map((isCurrentUserOrgAdmin) => {
                let filteredColumns = this.columnDefinitions;
                if (!isCurrentUserOrgAdmin) {
                    filteredColumns = filteredColumns.filter((col) => col !== 'actions');
                }
                return filteredColumns;
            }),
            takeUntil(this._unsub$),
        );

        this._store$.pipe(select(getUserAccount), takeUntil(this._unsub$)).subscribe((currentUser) => {
            this.maxSupplementalQuestionnaireQuestions =
                currentUser?.clientLicense?.maxSupplementalQuestionnaireQuestions;
        });

        this._actions$
            .pipe(
                ofType(
                    updateSupplementalQuestionnaireRequestSuccess,
                    uploadSupplementalQuestionnairesRequestSuccess,
                    deleteSupplementalQuestionnaireRequestSuccess,
                    createSupplementalQuestionnaireRequestSuccess,
                ),
                tap(() => {
                    this._store$.dispatch(getServerSessionRequest());
                    this.loadAll();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getSupplementalQuestionnairesRequestSuccess, getSupplementalQuestionnairesRequestFailed),
                tap(() => {
                    this.questionnairesLoading = false;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getSupplementalQuestionnairesRequestSuccess),
                filter(({ headers }) => !!headers.get('x-total-count')),
                takeUntil(this._unsub$),
            )
            .subscribe(({ questionnaires, headers }) => {
                this.onSuccess(questionnaires, headers);
            });

        this.tableSort$
            .pipe(
                map((sortData) => sortData && sortData[0]),
                takeUntil(this._unsub$),
            )
            .subscribe((sortItem) => {
                this.predicate = sortItem.prop;
                this.reverse = sortItem.dir !== 'desc';
            });

        this._route.queryParams.pipe(take(1)).subscribe(({ sort, page }) => {
            const sortPredicateFromUrl: string = !!sort ? (Array.isArray(sort) ? sort[0] : sort) : undefined;

            if (sortPredicateFromUrl) {
                const [prop, dir] = sortPredicateFromUrl.split(',');
                this.tableSort$.next([{ prop, dir }]);
            }

            this.page = +page || this.page;
        });

        this.supplementalQuestionnaires$ = this._store$.pipe(
            select(getSupplementalQuestionnaires),
            tap((questionnaires: SupplementalQuestionnaireResponse[]) => {
                this.questionCount = !questionnaires
                    ? 0
                    : questionnaires.reduce((count, questionnaire) => count + questionnaire.questionCount, 0);
                this.doesOrgHaveAvailableQuestions =
                    this.maxSupplementalQuestionnaireQuestions === -1 ||
                    this.maxSupplementalQuestionnaireQuestions - this.questionCount > 0;
            }),
        );

        this.isCurrentUserOrgAdmin$ = this._store$.pipe(
            select(getUserAuthority(VisoUserRole.OrgAdmin)),
            takeUntil(this._unsub$),
        );
        this.loadAll();
    }

    ngAfterViewInit() {
        this.supplementalQuestionnaires$.pipe(takeUntil(this._unsub$)).subscribe((data) => {
            this.dataSource.data = data;
        });

        this.matSort.sortChange.pipe(takeUntil(this._unsub$)).subscribe((sort: Sort) => this.onSort(sort));
    }

    loadAll() {
        this.questionnairesLoading = true;
        this._store$.dispatch(
            getSupplementalQuestionnairesRequest({
                pageable: {
                    page: this.page,
                    size: this.itemsPerPage,
                    sort: this.sort(),
                },
            }),
        );
    }

    setCurrentPage(pageEvent: PageEvent) {
        this.page = +pageEvent.pageIndex;
        this.itemsPerPage = pageEvent.pageSize;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {
                page: this.page,
                size: this.itemsPerPage,
                sort: `${this.sortByColumnName},${this.sortDirection}`,
            },
            queryParamsHandling: 'merge',
        });
        this.loadAll();
    }

    onSort(sort: Sort) {
        this.sortByColumnName = sort.active;
        this.sortDirection = sort.direction;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {
                page: this.page,
                size: this.itemsPerPage,
                sort: `${this.sortByColumnName},${this.sortDirection}`,
            },
            queryParamsHandling: 'merge',
        });
        this.loadAll();
    }

    deleteQuestionnaire(questionnaireToDelete: SupplementalQuestionnaireResponse) {
        this._store$.dispatch(deleteSupplementalQuestionnaireRequest({ questionnaireId: questionnaireToDelete.id }));
    }

    downloadQuestionnaire(questionnaireToDownload: SupplementalQuestionnaireResponse) {
        this._store$.dispatch(
            downloadSupplementalQuestionnaireRequest({ questionnaireId: questionnaireToDownload.id }),
        );
    }

    createQuestionnaire() {
        this._store$.dispatch(
            openCreateSupplementalQuestionnaireDialog({
                questionnaireStep: SupplementalQuestionnaireDialogStep.CREATE,
            }),
        );
    }

    importQuestionnaire() {
        this._store$.dispatch(
            openCreateSupplementalQuestionnaireDialog({
                questionnaireStep: SupplementalQuestionnaireDialogStep.IMPORT,
            }),
        );
    }

    getQuestionnaireEditUrl(questionnaire: SupplementalQuestionnaireResponse) {
        return [
            '/',
            {
                outlets: {
                    popup: `questionnaire/${questionnaire.id}/edit`,
                },
            },
        ];
    }

    private onSuccess(data: SupplementalQuestionnaireResponse[], headers: HttpHeaders): void {
        this.totalCurrentItems = +headers.get('x-total-count');
        this.queryCount = data.length;

        this._cdr.detectChanges();
    }

    private sort() {
        const result = [this.sortByColumnName + ',' + this.sortDirection];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
