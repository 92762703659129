import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LongRunningTaskName } from '../enums/long-running-task';

@Injectable({ providedIn: 'root' })
export class LongRunningTaskService {
    private resourceUrl = 'api/tasks';

    constructor(private _http: HttpClient) {}

    getAllRunningTasks(): Observable<LongRunningTaskName[]> {
        return this._http.get<LongRunningTaskName[]>(`${this.resourceUrl}/running/org`);
    }
}
