export const RelationshipProps = {
    vendorOrgId: 'vendorOrgId',
    vendorOrgName: 'vendorOrgName',
    vendorFaviconUrl: 'vendorFaviconUrl',
    drStatus: 'drStatus',
    residualRisk: 'residualRisk',
    inherentRisk: 'inherentRisk',
    businessPurpose: 'businessPurpose',
    updatedDate: 'updatedDate',
    assessmentStatus: 'assessmentStatus',
    assessmentStatusDate: 'assessmentStatusDate',
    assessmentCreatedDate: 'assessmentCreatedDate',
    assessmentCompletedDate: 'assessmentCompletedDate',
    startDate: 'startDate',
    recertificationDate: 'recertificationDate',
    recertificationType: 'recertificationType',
    artifactUpdateType: 'artifactUpdateType',
    businessUnitId: 'businessUnitId',
    businessUnitName: 'businessUnitName',
    creatorId: 'creatorId',
    creatorFirstName: 'creatorFirstName',
    creatorLastName: 'creatorLastName',
    businessOwnerId: 'businessOwnerId',
    businessOwnerFirstName: 'businessOwnerFirstName',
    businessOwnerLastName: 'businessOwnerLastName',
    businessOwnerEmail: 'businessOwnerEmail',
    primaryContactId: 'primaryContactId',
    primaryContactFirstName: 'primaryContactFirstName',
    primaryContactLastName: 'primaryContactLastName',
    primaryContactLastEmail: 'primaryContactEmail',
    createdDate: 'createdDate',
    customerOrgId: 'customerOrgId',
    customerOrgName: 'customerOrgName',
    tags: 'tags.name',
    contextTypes: 'contextTypes.id',
    hasCompleted: 'hasCompleted',
    assessed: 'assessed',
    overdueRecertification: 'overdueRecertification',
    upcomingRecertification: 'upcomingRecertification',
    dataTypes: 'dataTypes.id',
    licenseType: 'licenseType',
    score: 'score',
    inherentRiskScore: 'inherentRiskScore',
    nthParties: 'riskNetworkDetections.orgId',
    recommendationCount: 'recommendationCount',
    remediationRequested: 'remediationRequested',
    riskAccepted: 'riskAccepted',
    vendorTierId: 'vendorTierId',
};

export const ColumnNames = {
    relationship: 'Relationship',
    client: 'Client',
    status: 'Status',
    risk: 'Risk',
    inherentRisk: 'Inherent Risk',
    residualRisk: 'Residual Risk',
    lastUpdated: 'Last Updated',
    assessmentPhase: 'Assessment Phase',
    assessmentCreatedDate: 'Requested',
    phaseDate: 'Phase Date',
    recertificationDate: 'Recertification Date',
    recertificationType: 'Recertification Type',
    artifactUpdateType: 'Artifact Update Type',
    businessUnit: 'Business Unit',
    createdBy: 'Created By',
    createdOn: 'Created On',
    businessOwnerName: 'Business Owner Name',
    businessOwnerEmail: 'Business Owner Email',
    primaryContactName: 'Third Party Contact Name',
    primaryContactEmail: 'Third Party Contact Email',
    tags: 'Tags',
    score: 'Residual Risk Score',
    inherentRiskScore: 'Inherent Risk Score',
    recommendationCount: 'Recommendations',
    remediationRequested: 'Remediation Requested',
    tier: 'Tier',
};

export const AdditionalFilters = {
    startPhaseDate: 'startPhaseDate',
    endPhaseDate: 'endPhaseDate',
    startRecertificationDate: 'startRecertificationDate',
    endRecertificationDate: 'endRecertificationDate',
    businessUnit: 'businessUnit',
    createdBy: 'createdBy',
    businessOwner: 'businessOwner',
    startLastUpdated: 'startLastUpdated',
    endLastUpdated: 'endLastUpdated',
    startCreatedOn: 'startCreatedOn',
    endCreatedOn: 'endCreatedOn',
    client: 'client',
    contextTypes: 'contextTypes',
    dataTypes: 'dataTypes',
    tags: 'tags',
    hasCompleted: 'hasCompleted',
    assessed: 'assessed',
    overdueRecertification: 'overdueRecertification',
    upcomingRecertification: 'upcomingRecertification',
    licenseType: 'licenseType',
    artifactUpdateType: 'artifactUpdateType',
    nthParties: 'nthParties',
    remediationRequested: 'remediationRequested',
    riskAccepted: 'riskAccepted',
};
