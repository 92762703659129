import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    inject,
    OnInit,
} from '@angular/core';
import {
    FrameworkMapping,
    FrameworkMappingType,
    FrameworkMappingTypeLabels,
} from '@entities/framework/models/framework-mapping.model';
import { GroupedControlDomains } from '../models/grouped-control-domain';
import { Relationship } from '@entities/relationship';
import {
    ControlDomainAssessmentStatus,
    ControlDomainAssessmentValidationStatus,
    RelationshipControlDomainAssessments,
    SecurityControlDomainGroupStatus,
} from '@entities/relationship/models/security-control-domain';
import { ControlDomainType } from '@entities/control-domain';
import { AssessmentStatus } from '@entities/assessment';
import {
    RiskAnalysisNavigationMenuGroup,
    RiskAnalysisNavigationMenuItem,
} from './risk-analysis-navigation-menu/risk-analysis-navigation-menu.component';
import { Risk } from '@entities/risk-assessment';
import { first, merge, Observable, of } from 'rxjs';
import { ControlValidationStatus } from '@entities/artifact';
import { isAssessmentComplete, isAssessmentInProgress } from '@shared/utils/assessment-utils.service';
import { select, Store } from '@ngrx/store';
import { getFrameworkMappings, getFrameworkMappingsByRelationshipId } from '../redux/request.selectors';
import { getFrameworkMappingsRequest } from '../redux/actions/framework-mapping.actions';
import { getRelationshipPopulatedLatestAssessment } from '../redux/selectors/assessment.selectors';
import { VisoUser } from '@entities/viso-user';
import { getUserProfile } from '../../session/redux/session.selectors';
import { updateUserProfileRequest } from '../../session/redux/session.actions';
import { startSpotlightTour } from '../../../layout/redux/layout.actions';
import { SpotlightTourType } from '@shared/spotlight-tour/steps';
import { SuppQReprocessingState } from '@entities/supplemental-questionnaire';

@Component({
    selector: 'app-risk-analysis',
    templateUrl: './risk-analysis.component.html',
    styleUrls: ['./risk-analysis.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskAnalysisComponent implements OnInit, OnChanges {
    @Input() groupedControls: GroupedControlDomains[];
    @Input() request: Relationship;
    @Input() relationshipControlDomainAssessments: RelationshipControlDomainAssessments;
    @Input() latestAssessmentStatus: AssessmentStatus;
    @Input() isLatestAssessmentNonDocumentsOnly: boolean; // Prop drilled from request to security-control-domains
    @Input({ required: true }) artifactSupersession: Map<number, number>; // Prop drilled from request to security-control-domains
    @Input() latestAssessmentFollowUpQuestionnaireCreatedDate: Date; // Prop drilled from request to security-control-domains
    @Input() missingInformationWasRequested: boolean; // Prop drilled from request to security-control-domains
    @Input() supplementalQuestionnaireReprocessingState: Map<string, SuppQReprocessingState>; // Prop drilled from request to security-control-domains
    @Input() businessOwner: VisoUser;

    private _store$ = inject(Store);
    private _cdr = inject(ChangeDetectorRef);

    public riskAnalysisNavigationMenu: RiskAnalysisNavigationMenuGroup[];
    public selectedControlGroup: GroupedControlDomains;
    public selectedNavigationItem: RiskAnalysisNavigationMenuItem;
    public selectedFramework: FrameworkMappingType;
    public frameworkMappings$: Observable<FrameworkMapping>;
    public latestAssessment$ = this._store$.pipe(select(getRelationshipPopulatedLatestAssessment));

    readonly FrameworkMappingType = FrameworkMappingType;
    readonly FrameworkMappingTypeLabels = FrameworkMappingTypeLabels;
    readonly iconMap: Record<Risk, string> = {
        [Risk.NO_CONTEXT]: 'not_interested',
        [Risk.LOW]: 'circle',
        [Risk.MEDIUM]: 'square',
        [Risk.HIGH]: 'warning',
        [Risk.EXTREME]: 'report',
    };

    constructor() {}

    ngOnInit(): void {
        this._store$
            .select(getFrameworkMappingsByRelationshipId(this.request.id))
            .pipe(first())
            .subscribe((frameworkMappingType) => {
                this.selectedFramework = frameworkMappingType;
            });

        this.frameworkMappings$ = merge(of(this.selectedFramework), this._store$.select(getFrameworkMappings));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.relationshipControlDomainAssessments?.currentValue || changes.groupedControls?.currentValue) {
            if (!this.groupedControls || !this.relationshipControlDomainAssessments) {
                return;
            }

            // Store current selections before rebuilding menu
            const currentControlGroup = this.selectedControlGroup;
            const currentNavigationItem = this.selectedNavigationItem;

            this.riskAnalysisNavigationMenu = this.buildRiskAnalysisNavigationMenu();
            this.selectedControlGroup = this.groupedControls[0];
            this.selectedNavigationItem = this.riskAnalysisNavigationMenu[0]?.items?.[0];

            // Restore previous selections if they exist, otherwise set defaults
            if (currentControlGroup && currentNavigationItem) {
                this.selectedControlGroup = this.groupedControls.find(
                    (group) => group.controlDomainTypeLabel === currentControlGroup.controlDomainTypeLabel,
                );
                this.selectedNavigationItem = this.riskAnalysisNavigationMenu
                    .flatMap((group) => group.items)
                    .find((item) => item.title === currentNavigationItem.title);
            } else {
                this.selectedControlGroup = this.groupedControls[0];
                this.selectedNavigationItem = this.riskAnalysisNavigationMenu[0]?.items?.[0];
            }

            this._store$
                .select(getUserProfile)
                .pipe(first())
                .subscribe((user) => {
                    if (user.showRiskAnalysisWalkthrough) {
                        this._store$.dispatch(startSpotlightTour({ tourType: SpotlightTourType.RISK_ANALYSIS_PAGE }));
                        this._store$.dispatch(
                            updateUserProfileRequest({
                                userProfile: { ...user, showRiskAnalysisWalkthrough: false },
                            }),
                        );
                    }
                });
        }
    }

    private buildRiskAnalysisNavigationMenu(): RiskAnalysisNavigationMenuGroup[] {
        const riskDimensions = [];
        const supplementalQuestionnaires: GroupedControlDomains[] = [];

        // Separate the risk dimensions from the supplemental questionnaires
        this.groupedControls.forEach((item) => {
            if (item.supplementalQuestionnaireId) {
                supplementalQuestionnaires.push(item);
            } else {
                riskDimensions.push(item);
            }
        });

        return [
            {
                title: 'Risk Dimensions',
                items: riskDimensions.map((riskDimension) => {
                    let item: RiskAnalysisNavigationMenuItem = {
                        prefixIcon: '',
                        prefixIconClass: '',
                        title: riskDimension.controlDomainTypeLabel,
                        suffixText: '',
                        suffixTextClass: '',
                        disabled: false,
                    };

                    const areAllControlsValid = this.areAllControlDomainsValid(riskDimension);

                    const controlDomainsInScope = riskDimension.controlDomains.filter(
                        (controlDomain) => controlDomain.relevant,
                    );
                    const controlDomainsPresent =
                        this.relationshipControlDomainAssessments.controlDomainAssessments.filter(
                            (controlDomainAssessment) =>
                                controlDomainAssessment.status === ControlValidationStatus.PRESENT &&
                                controlDomainsInScope.find(
                                    (controlDomain) => controlDomain.id === controlDomainAssessment.controlDomainId,
                                ),
                        );
                    const presentControlDomainsPercentage =
                        controlDomainsInScope.length === 0
                            ? 0
                            : Math.round((controlDomainsPresent.length / controlDomainsInScope.length) * 100);

                    let itemDisabled = false;
                    riskDimension.featureFlagEnabled$
                        .pipe(first())
                        .subscribe((enabled) => (itemDisabled = !enabled || riskDimension.allControlsDisabled));

                    if (itemDisabled) {
                        item.disabled = true;
                        item.prefixIcon = 'lock';
                        item.prefixIconClass = 'color-grey';
                    } else if (riskDimension.allControlsOutOfScope) {
                        item.prefixIcon = 'not_interested';
                        item.prefixIconClass = 'color-grey';
                    } else if (riskDimension.isStatusRiskBased) {
                        const riskIcon = this.iconMap[this.request.latestRiskAssessment?.risk];
                        item.prefixIcon = item.prefixIconClass = item.suffixTextClass = riskIcon;
                        item.suffixText = this.request.latestRiskAssessment?.risk;
                    } else if (riskDimension.isStatusCompliantBased || riskDimension.isStatusInformationBased) {
                        // StatusCompliant and StatusInformationBased are threaded the same way
                        item.prefixIcon = areAllControlsValid ? 'circle' : 'warning';
                        item.prefixIconClass = `small-icon ${areAllControlsValid ? 'color-green' : 'color-orange'}`;
                        item.suffixText = `${presentControlDomainsPercentage}%`;
                        item.suffixTextClass = 'color-grey';
                    }

                    return item;
                }),
            },
            {
                title: 'Custom Controls',
                items: supplementalQuestionnaires.map((questionnaire) => {
                    // ControlDomains here is the number of questions inside the questionnaire
                    const answeredQuestionsInQuestionnaire = questionnaire.controlDomains?.filter(
                        (cd) => cd.hasDetections,
                    );

                    const answeredQuestionsPercentage =
                        questionnaire.controlDomains?.length === 0
                            ? 0
                            : Math.round(
                                  (answeredQuestionsInQuestionnaire.length / questionnaire.controlDomains?.length) *
                                      100,
                              );

                    return {
                        prefixIcon: answeredQuestionsPercentage === 100 ? 'circle' : 'warning',
                        prefixIconClass: `small-icon ${answeredQuestionsPercentage === 100 ? 'color-green' : 'color-orange'}`,
                        title: questionnaire.controlDomainTypeLabel,
                        suffixText: `${answeredQuestionsPercentage}%`,
                        suffixTextClass: 'color-grey',
                        disabled: false,
                    };
                }),
            },
        ];
    }

    private getSecurityControlDomainGroupStatus(
        controlDomainAssessmentValidationStatus: ControlDomainAssessmentValidationStatus,
        isLatestAssessmentCompleted: boolean,
        isLatestAssessmentInProgress: boolean,
        detectionsTotalCount: number,
    ): SecurityControlDomainGroupStatus {
        switch (controlDomainAssessmentValidationStatus) {
            case ControlValidationStatus.PRESENT:
            case ControlValidationStatus.DESCRIPTION_ONLY:
                return SecurityControlDomainGroupStatus.PRESENT;
            case ControlValidationStatus.NOT_PRESENT:
                return SecurityControlDomainGroupStatus.INSUFFICIENT;
            case ControlValidationStatus.NOT_APPLICABLE:
                return SecurityControlDomainGroupStatus.NOT_APPLICABLE;
            case ControlValidationStatus.MISSING_INFORMATION:
                return isLatestAssessmentCompleted
                    ? SecurityControlDomainGroupStatus.NO_INFORMATION
                    : SecurityControlDomainGroupStatus.MISSING_INFORMATION;
            case ControlValidationStatus.UNVALIDATED:
                return isLatestAssessmentInProgress && detectionsTotalCount === 0
                    ? SecurityControlDomainGroupStatus.UNVALIDATED
                    : SecurityControlDomainGroupStatus.NO_INFORMATION;
            case ControlDomainAssessmentStatus.OUT_OF_SCOPE:
                return SecurityControlDomainGroupStatus.OUT_OF_SCOPE;
            case ControlDomainAssessmentStatus.DISABLED:
                return SecurityControlDomainGroupStatus.DISABLED;
            default:
                return;
        }
    }

    private areAllControlDomainsValid(groupedControl: GroupedControlDomains) {
        const controlDomains = this.relationshipControlDomainAssessments?.controlDomainAssessments
            .map((controlDomainAssessment) =>
                !controlDomainAssessment.relevant
                    ? { ...controlDomainAssessment, status: ControlDomainAssessmentStatus.OUT_OF_SCOPE }
                    : { ...controlDomainAssessment },
            )
            .concat(
                ...groupedControl.controlDomains
                    .filter(
                        (controlDomain) =>
                            !this.relationshipControlDomainAssessments.controlDomainAssessments.find(
                                (controlDomainAssessment) =>
                                    controlDomainAssessment.controlDomainId === controlDomain.id,
                            ),
                    )
                    .map<any>((control) => {
                        return {
                            status:
                                control.controlDomainType === ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE
                                    ? ControlValidationStatus.MISSING_INFORMATION
                                    : ControlDomainAssessmentStatus.DISABLED,
                            controlDomainId: control.id,
                            longDescription: control.longDescription,
                        };
                    }),
            )
            .filter(
                (controlDomainAssessment) =>
                    !!groupedControl.controlDomains.find(
                        (controlDomain) => controlDomain.id === controlDomainAssessment.controlDomainId,
                    ),
            );

        return controlDomains.every((control) => {
            const status = this.getSecurityControlDomainGroupStatus(
                control.status,
                isAssessmentComplete(this.latestAssessmentStatus),
                isAssessmentInProgress(this.latestAssessmentStatus),
                0,
            );

            return [
                SecurityControlDomainGroupStatus.PRESENT,
                SecurityControlDomainGroupStatus.OUT_OF_SCOPE,
                SecurityControlDomainGroupStatus.DISABLED,
                SecurityControlDomainGroupStatus.NOT_APPLICABLE,
            ].includes(status);
        });
    }

    public onFrameworkChange(event) {
        this._store$.dispatch(
            getFrameworkMappingsRequest({
                relationshipId: this.request.id,
                framework: event.value,
            }),
        );
    }

    onNavigationChange(navigationMenuItem: RiskAnalysisNavigationMenuItem) {
        this.selectedControlGroup = null;
        this._cdr.detectChanges();

        this.selectedControlGroup = this.groupedControls.find(
            (groupedControl) => groupedControl.controlDomainTypeLabel === navigationMenuItem.title,
        );
        this.selectedNavigationItem = navigationMenuItem;
        this._cdr.markForCheck();
    }
}
