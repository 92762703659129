import { Routes } from '@angular/router';

import { AuthGuard } from '../../shared';
import { ControlsComponent } from './controls.component';
import { VisoUserRole } from '../../entities/viso-user';

export const controlsRoute: Routes = [
    {
        path: 'controls',
        component: ControlsComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.ReadOnly, VisoUserRole.Auditor],
            pageTitle: 'Controls',
        },
        canActivate: [AuthGuard],
    },
];
