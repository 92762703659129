<header header>
    <div class="d-flex align-items-center">
        <h2>{{ controlDomainTypeLabel }}</h2>
        <button
            mat-icon-button
            class="download-button"
            (click)="downloadSupplementalQuestionnaire()"
            *ngIf="supplementalQuestionnaireId"
        >
            <mat-icon fontSet="material-symbols-outlined" class="color-primary">download</mat-icon>
        </button>
        <ng-content *ngTemplateOutlet="statusTemplate$ | async"></ng-content>
    </div>

    @if (showSuppQReprocessingControls) {
        <div class="col-12 mb-3">
            @switch (supplementalQuestionnaireReprocessingState.get(supplementalQuestionnaireId)) {
                @case (SuppQReprocessingState.CAN_REPROCESS) {
                    @if (!(isCurrentUserReadonly$ | async)) {
                        <ng-container *hideIfOrgUserIsNotBusinessOwner="relationship?.businessOwner">
                            <div class="suppq-get-answers-container">
                                <div class="row">
                                    <div class="col d-flex flex-column">
                                        <span class="text-bold">Answer with Artifact Intelligence?</span>
                                        <span
                                            >Answer this questionnaire with the artifacts that already exist on this
                                            relationship.</span
                                        >
                                    </div>
                                    <div class="col-auto align-content-center">
                                        <button
                                            mat-flat-button
                                            class="mat-elevation-z3"
                                            (click)="reprocessSupplementalQuestionnaire(supplementalQuestionnaireId)"
                                            toggleDisableOnActions
                                            [disablingActions]="[
                                                CommonAssessmentDetailsActions.ReprocessSupplementalQuestionnaireRequest,
                                            ]"
                                            [enablingActions]="[
                                                CommonAssessmentDetailsActions.ReprocessSupplementalQuestionnaireRequestSuccess,
                                                CommonAssessmentDetailsActions.ReprocessSupplementalQuestionnaireRequestFailed,
                                            ]"
                                        >
                                            <mat-icon>auto_awesome</mat-icon>
                                            <span>Get answers now</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    }
                }
                @case (SuppQReprocessingState.PROCESSING) {
                    <app-disclaimer type="info" title="Your questionnaire answers are processing!" icon="autorenew">
                        <span> We'll notify you when our analysis is complete. </span>
                    </app-disclaimer>
                }
            }
        </div>
    }
    <ng-content select="[framework-selection-dropdown]"></ng-content>
</header>

<ng-template #riskBasedStatus>
    <app-mat-risk-chip [disableRipple]="true" [risk]="latestRiskLevel" />
</ng-template>
<ng-template #compliantBasedStatus>
    <app-chip *ngIf="isCompliantStatus$ | async; else noInformation" type="success">Compliant</app-chip>
    <ng-template #noInformation>
        <app-chip [disableRipple]="true" type="danger">Non-Compliant</app-chip>
    </ng-template>
</ng-template>
<ng-template #informationBasedStatus>
    <app-chip [disableRipple]="true" *ngIf="isInformationStatus$ | async; else noInformation" type="success"
        >Collected
    </app-chip>
    <ng-template #noInformation>
        <app-chip [disableRipple]="true" type="danger">No Information</app-chip>
    </ng-template>
</ng-template>
<ng-template #outOfScopeStatus>
    <app-chip [disableRipple]="true" type="basic">Out of Scope</app-chip>
</ng-template>
<ng-template #notEnabledStatus>
    <app-chip [disableRipple]="true" type="basic">N/A</app-chip>
</ng-template>

<mat-tab-group dynamicHeight>
    @for (group of groupedSecurityControlDomain$ | async; track trackGroupedSecurityControlDomain; let i = $index) {
        <mat-tab
            label="{{
                (supplementalQuestionnaireId ? group.supplementalQuestionnaireGroupName : group.groupName) +
                    ' (' +
                    group.controlDomainAssessments.length +
                    ')'
            }}"
        >
            <app-control-group
                [groupIndex]="i"
                [group]="group"
                [supplementalQuestionnaireId]="supplementalQuestionnaireId"
                [noAssessment]="noAssessment$ | async"
                [latestAssessmentInProgress]="latestAssessmentInProgress$ | async"
                [latestAssessmentCompleted]="latestAssessmentCompleted$ | async"
                [assessmentSentToEmail]="assessmentSentToEmail$ | async"
                [isLatestAssessmentNonDocumentsOnly]="isLatestAssessmentNonDocumentsOnly$ | async"
                [isDisabled]="group.controlValidationStatus === SecurityControlDomainGroupStatus.DISABLED"
                [isOutOfScope]="group.controlValidationStatus === SecurityControlDomainGroupStatus.OUT_OF_SCOPE"
                [onExport]="onExport"
                (accordionToggled)="accordionToggled($event)"
                (downloadSupplementalQuestionnaire)="downloadSupplementalQuestionnaire()"
                [frameworkType]="selectedFramework"
            >
            </app-control-group>
        </mat-tab>
    }
</mat-tab-group>
