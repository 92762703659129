import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
    assignRelationshipsToTierRequest,
    assignRelationshipsToTierRequestFailed,
    assignRelationshipsToTierRequestSuccess,
    bulkArchiveRelationshipsRequestAccepted,
    bulkArchiveRelationshipsRequestSuccess,
    bulkCancelAssessmentsRequestAccepted,
    bulkCancelAssessmentsRequestSuccess,
    getRiskThresholdsForVendorTierStats,
    getVendorTierStatsRequest,
    getVendorTierStatsRequestFailed,
    getVendorTierStatsRequestSuccess,
    bulkOffboardRelationshipsRequestAccepted,
    openAssignRelationshipsToTierDialog,
    openManageTiersDialog,
    openVendorTierDescriptionDialog,
    relationshipsQuerySuccess,
    bulkStartAssessmentsRequestAccepted,
    bulkStartAssessmentsRequestSuccess,
    tryShowVendorTierWalkthrough,
    updateVendorTierDescriptionRequest,
    updateVendorTierDescriptionRequestFailed,
    updateVendorTierDescriptionRequestSuccess,
    updateVendorTiersCountRequest,
    updateVendorTiersCountRequestFailed,
    updateVendorTiersCountRequestSuccess,
    bulkOffboardRelationshipsRequestSuccess,
    clearVendorTierStats,
} from '../relationships.actions';
import { combineLatest, first, of } from 'rxjs';
import { getUserAuthority, getUserProfile, getVendorTiers } from '../../../session/redux/session.selectors';
import { getSpotlightTourStatus } from '../../../../layout/redux/layout.selectors';
import {
    VendorTierWalkthroughDialogComponent,
    VendorTierWalkthroughDialogResult,
} from '../../vendor-tier-walkthrough-dialog/vendor-tier-walkthrough-dialog.component';
import { startSpotlightTour } from '../../../../layout/redux/layout.actions';
import { SpotlightTourType } from '@shared/spotlight-tour/steps';
import { updateUserProfileRequest } from '../../../session/redux/session.actions';
import { VendorTierManagementDialogComponent } from '../../vendor-tier-management-dialog/vendor-tier-management-dialog.component';
import { VendorTierDescriptionDialogComponent } from '../../vendor-tier-description-dialog/vendor-tier-description-dialog.component';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { RelationshipService } from '@entities/relationship';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import { VendorTierService } from '@entities/vendor-tier';
import { Store } from '@ngrx/store';
import { VisoUserRole } from '@entities/viso-user';
import { getRiskToleranceThresholdsRequest } from '../../../risk-model-settings/redux/risk-model-settings.actions';
import { getSelectedTierId } from '../relationships.selectors';

@Injectable()
export class VendorTierEffects {
    refreshVendorTierStats$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                bulkArchiveRelationshipsRequestSuccess,
                bulkStartAssessmentsRequestSuccess,
                bulkCancelAssessmentsRequestSuccess,
                bulkOffboardRelationshipsRequestSuccess,
                bulkStartAssessmentsRequestAccepted,
                bulkCancelAssessmentsRequestAccepted,
                bulkArchiveRelationshipsRequestAccepted,
                bulkOffboardRelationshipsRequestAccepted,
            ),
            withLatestFrom(this._store$.select(getSelectedTierId)),
            mergeMap(([, selectedTierId]) =>
                of(clearVendorTierStats(), getVendorTierStatsRequest({ vendorTierId: selectedTierId })),
            ),
        ),
    );

    getVendorTierStatsRequest = createEffect(() =>
        this._actions$.pipe(
            ofType(getVendorTierStatsRequest),
            switchMap(({ vendorTierId }) =>
                this._relationshipService.getStatsByVendorTierId(vendorTierId).pipe(
                    map((vendorTierStats) => getVendorTierStatsRequestSuccess({ vendorTierStats })),
                    catchError(() => of(getVendorTierStatsRequestFailed())),
                ),
            ),
        ),
    );

    // (Perhaps?) Temporary - for showing off the new tiering feature!
    // (Skip if they don't have any relationships.)
    triggerVendorTierWalkthroughOnRelationshipsLoaded$ = createEffect(() =>
        this._actions$.pipe(
            ofType(relationshipsQuerySuccess),
            withLatestFrom(this._store$.select(getUserProfile)),
            filter(([{ results }, userProfile]) => userProfile.showVendorTierWalkthrough && !!results.length),
            map(() => tryShowVendorTierWalkthrough()),
        ),
    );

    tryShowVendorTierWalkthrough$ = createEffect(() =>
        combineLatest([
            this._actions$.pipe(ofType(tryShowVendorTierWalkthrough)),
            this._store$.select(getUserAuthority(VisoUserRole.OrgAdmin)),
            this._store$.select(getUserProfile),
            this._store$.select(getSpotlightTourStatus),
            this._store$.select(getVendorTiers),
        ]).pipe(
            filter(([, , userProfile, , vendorTiers]) => !!userProfile && !!vendorTiers),
            filter(
                ([, , userProfile, tourInProgress]) =>
                    // If the user is going through either first-time org/name setup, the first-time spotlight tour,
                    // or already has vendor tiers, don't show them the walkthrough.
                    !tourInProgress &&
                    userProfile.showVendorTierWalkthrough &&
                    !userProfile.showWelcomeMessage &&
                    !userProfile.showOrganizationNameForm &&
                    !userProfile.showFirstLastNameForm,
            ),
            exhaustMap(([, isOrgAdmin, userProfile, , vendorTiers]) =>
                this._matDialogService
                    .open<VendorTierWalkthroughDialogComponent, VendorTierWalkthroughDialogResult>(
                        VendorTierWalkthroughDialogComponent,
                        {
                            inputs: {
                                isOrgAdmin,
                                orgAlreadyHasTiers: !!vendorTiers.length,
                            },
                            config: {
                                maxWidth: 500,
                                disableClose: true,
                            },
                        },
                    )
                    .afterClosed()
                    .pipe(
                        first(),
                        mergeMap(({ skipSpotlightTour, tiersToCreate }) => {
                            const actionsToDispatch = [];

                            if (!skipSpotlightTour) {
                                actionsToDispatch.push(
                                    startSpotlightTour({ tourType: SpotlightTourType.VENDOR_TIERING }),
                                );
                            }

                            if (tiersToCreate !== undefined) {
                                actionsToDispatch.push(updateVendorTiersCountRequest({ numberOfTiers: tiersToCreate }));
                            }

                            actionsToDispatch.push(
                                updateUserProfileRequest({
                                    userProfile: { ...userProfile, showVendorTierWalkthrough: false },
                                }),
                            );

                            return actionsToDispatch;
                        }),
                    ),
            ),
        ),
    );

    openManageTiersDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openManageTiersDialog),
            withLatestFrom(this._store$.select(getVendorTiers)),
            switchMap(([, tiers]) =>
                this._matDialogService
                    .open<VendorTierManagementDialogComponent, number>(VendorTierManagementDialogComponent, {
                        inputs: {
                            currentNumberOfTiers: tiers.length,
                        },
                        config: {
                            maxWidth: 500,
                        },
                    })
                    .afterClosed()
                    .pipe(
                        first(),
                        filter((tiersToCreate) => tiersToCreate !== undefined),
                        map((tiersToCreate) => updateVendorTiersCountRequest({ numberOfTiers: tiersToCreate })),
                    ),
            ),
        ),
    );

    updateVendorTiersCount$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateVendorTiersCountRequest),
            withLatestFrom(this._store$.select(getVendorTiers)),
            filter(([{ numberOfTiers }, tiers]) => tiers.length !== numberOfTiers),
            switchMap(([{ numberOfTiers }]) =>
                this._vendorTierService.updateVendorTiersCount(numberOfTiers).pipe(
                    map((vendorTiers) => updateVendorTiersCountRequestSuccess({ vendorTiers })),
                    catchError(() => of(updateVendorTiersCountRequestFailed())),
                ),
            ),
        ),
    );

    openAssignRelationshipsToTier$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openAssignRelationshipsToTierDialog),
            switchMap(({ relationshipIds, tier, relationshipsAlreadyAssignedToTiers, forUnpaginatedRelationships }) => {
                const showDialog = relationshipsAlreadyAssignedToTiers || forUnpaginatedRelationships;
                let result = of(true);
                if (showDialog) {
                    const message = forUnpaginatedRelationships
                        ? `Any relationships currently assigned to a tier will be ${!!tier ? 'moved to ' + tier.name : 'marked unassigned'}. Continue?`
                        : 'One or more selected relationship(s) are already assigned to a tier. Continue?';
                    result = this._matConfirmDialogService.confirm({
                        title: !!tier
                            ? `Assign ${relationshipIds.length} relationship(s) to ${tier.name}?`
                            : `Unassign ${relationshipIds.length} relationship(s) from their tiers?`,
                        message,
                        confirmLabel: !!tier ? `Assign to ${tier.name}` : 'Unassign',
                    });
                }
                return result.pipe(
                    first(),
                    filter(Boolean),
                    map(() =>
                        assignRelationshipsToTierRequest({
                            relationshipIds,
                            tier,
                        }),
                    ),
                );
            }),
        ),
    );

    assignRelationshipsToTierRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(assignRelationshipsToTierRequest),
            switchMap(({ relationshipIds, tier }) =>
                this._relationshipService.assignAllToTier(relationshipIds, tier?.id).pipe(
                    tap(() =>
                        this._snackbarService.success(
                            !!tier
                                ? `${relationshipIds.length} relationship(s) successfully assigned to ${tier.name}.`
                                : `${relationshipIds.length} relationship(s) successfully unassigned from their tiers.`,
                        ),
                    ),
                    map(() => assignRelationshipsToTierRequestSuccess()),
                    catchError(() => of(assignRelationshipsToTierRequestFailed())),
                ),
            ),
        ),
    );

    openVendorTierDescriptionDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openVendorTierDescriptionDialog),
            withLatestFrom(this._store$.select(getVendorTiers), this._store$.select(getSelectedTierId)),
            map(([, tiers, vendorTierId]) => tiers.find((t) => t.id === vendorTierId)),
            switchMap((vendorTier) =>
                this._matDialogService
                    .open<VendorTierDescriptionDialogComponent>(VendorTierDescriptionDialogComponent, {
                        inputs: {
                            tierName: vendorTier.name,
                            currentDescription: vendorTier.description,
                        },
                        config: {
                            maxWidth: 500,
                        },
                    })
                    .afterClosed()
                    .pipe(
                        first(),
                        filter(
                            (newDescription) =>
                                // Empty string means clear description, while undefined means the user cancelled the dialog.
                                newDescription !== undefined && newDescription !== vendorTier.description,
                        ),
                        map((newDescription) =>
                            updateVendorTierDescriptionRequest({
                                vendorTierId: vendorTier.id,
                                description: newDescription,
                            }),
                        ),
                    ),
            ),
        ),
    );

    updateVendorTierDescriptionRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateVendorTierDescriptionRequest),
            switchMap(({ vendorTierId, description }) =>
                this._vendorTierService.updateVendorTierDescription(vendorTierId, description).pipe(
                    map((vendorTier) => updateVendorTierDescriptionRequestSuccess({ vendorTier })),
                    catchError(() => of(updateVendorTierDescriptionRequestFailed())),
                ),
            ),
        ),
    );

    getRiskThresholdsForVendorTierStats = createEffect(() =>
        this._actions$.pipe(
            ofType(getRiskThresholdsForVendorTierStats),
            withLatestFrom(this._store$.select(getUserAuthority([VisoUserRole.Auditor, VisoUserRole.Support]))),
            filter(([, isAuditorOrSupport]) => !isAuditorOrSupport),
            map(() => getRiskToleranceThresholdsRequest()),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _snackbarService: SnackbarService,
        private _relationshipService: RelationshipService,
        private _matConfirmDialogService: MatConfirmDialogService,
        private _matDialogService: MatDialogWrapperService,
        private _vendorTierService: VendorTierService,
        private _store$: Store,
    ) {}
}
