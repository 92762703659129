import { GuidedTour, Orientation } from 'ngx-guided-tour';

export const welcomeTour = (): GuidedTour => {
    return {
        tourId: 'welcome-tour',
        skipCallback: () => document.getElementById('help-button').click(),
        steps: [
            {
                selector: '#help-button',
                orientation: Orientation.Left,
                title: 'Help',
                content:
                    'A quick interruption before you begin exploring! Various tools are available to help you as you navigate the platform.',
                action: () => {
                    document.getElementById('help-button').click();
                },
            },
            {
                selector: '#mat-menu-panel-0 > div > button:nth-child(1)',
                orientation: Orientation.Left,
                title: 'Page walkthrough',
                content: 'Activate a spotlight tour for a breakdown of any page you find yourself on.',
            },
            {
                selector: '#mat-menu-panel-0 > div > button:nth-child(2)',
                orientation: Orientation.Left,
                title: 'Help center',
                content: 'Find useful documentation in our help center.',
            },
            {
                selector: '#mat-menu-panel-0 > div > a:nth-child(3)',
                orientation: Orientation.Left,
                title: 'Support',
                content: "Reach out to our support team at any time. We're here to help.",
            },
            {
                selector: '#mat-menu-panel-0 > div > a:nth-child(4)',
                orientation: Orientation.Left,
                title: 'Share feedback',
                content: 'How can we do better? Share your thoughts with the team.',
                closeAction: () => document.getElementById('help-button').click(),
            },
        ],
    };
};
