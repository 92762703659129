import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    AdminUpdateVendorDetailsRequest,
    UpdateVendorDetailsRequest,
    VendorDetailsResponse,
} from '../../models/vendor-details.model';
import { PaginationParams } from '../../../../shared';
import { NetworkExposureRiskAdvisory, RiskAdvisory } from '@entities/risk-advisory';
import { RelationshipVendorDetailsStatsResponse } from '@entities/relationship';

export enum VendorDetailsTabActions {
    GetVendorDetailsRequest = '[Request] Get Vendor Details Request',
    GetVendorDetailsRequestSuccess = '[Request] Get Vendor Details Request Success',
    GetVendorDetailsRequestFailed = '[Request] Get Vendor Details Request Failed',

    UpdateVendorDetailsRequest = '[Request] Update Vendor Details Request',
    UpdateVendorDetailsRequestSuccess = '[Request] Update Vendor Details Request Success',
    UpdateVendorDetailsRequestFailed = '[Request] Update Vendor Details Request Failed',

    AddDomainToVendor = '[Request] Add Domain To Vendor',
    AddDomainToVendorRequest = '[Request] Add Domain To Vendor Request',
    AddDomainToVendorRequestSuccess = '[Request] Add Domain To Vendor Request Success',
    AddDomainToVendorRequestFailed = '[Request] Add Domain To Vendor Request Failed',

    GetVendorDetailsTabRiskAdvisoriesRequest = '[Request] Get Vendor Details Tab Risk Advisories Request',
    GetVendorDetailsTabRiskAdvisoriesRequestSuccess = '[Request] Get Vendor Details Tab Risk Advisories Request Success',
    GetVendorDetailsTabRiskAdvisoriesRequestFailed = '[Request] Get Vendor Details Tab Risk Advisories Request Failed',

    RefreshVendorDetailsVendorDetailsTabRequest = '[Request] Refresh Vendor Details Vendor Details Tab Request',
    RefreshVendorDetailsVendorDetailsTabRequestSuccess = '[Request] Refresh Vendor Details Vendor Details Tab Request Success',
    RefreshVendorDetailsVendorDetailsTabRequestFailed = '[Request] Refresh Vendor Details Vendor Details Tab Request Failed',

    DeleteVendorDetailsTabRiskAdvisory = '[Request] Delete Vendor Details Tab Risk Advisory',
    DeleteVendorDetailsTabRiskAdvisoryRequest = '[Request] Delete Vendor Details Tab Risk Advisory Request',
    DeleteVendorDetailsTabRiskAdvisoryRequestSuccess = '[Request] Delete Vendor Details Tab Risk Advisory Request Success',
    DeleteVendorDetailsTabRiskAdvisoryRequestFailed = '[Request] Delete Vendor Details Tab Risk Advisory Request Failed',
    DeleteVendorDetailsTabRiskAdvisoryCancel = '[Request] Delete Vendor Details Tab Risk Advisory Cancel',

    GetVendorDetailsStatsRequest = '[Request] Get Vendor Details Stats Request',
    GetVendorDetailsStatsRequestSuccess = '[Request] Get Vendor Details Stats Request Success',
    GetVendorDetailsStatsRequestFailed = '[Request] Get Vendor Details Stats Request Failed',
}

export const getVendorDetailsRequest = createAction(
    VendorDetailsTabActions.GetVendorDetailsRequest,
    props<{
        orgId: number;
    }>(),
);

export const getVendorDetailsRequestSuccess = createAction(
    VendorDetailsTabActions.GetVendorDetailsRequestSuccess,
    props<{ vendorDetails: VendorDetailsResponse }>(),
);

export const getVendorDetailsRequestFailed = createAction(
    VendorDetailsTabActions.GetVendorDetailsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const updateVendorDetailsRequest = createAction(
    VendorDetailsTabActions.UpdateVendorDetailsRequest,
    props<{
        updateRequest: AdminUpdateVendorDetailsRequest | UpdateVendorDetailsRequest;
        isCurrentUserAdmin: boolean;
    }>(),
);

export const updateVendorDetailsRequestSuccess = createAction(
    VendorDetailsTabActions.UpdateVendorDetailsRequestSuccess,
);

export const updateVendorDetailsRequestFailed = createAction(
    VendorDetailsTabActions.UpdateVendorDetailsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const addDomainToVendor = createAction(
    VendorDetailsTabActions.AddDomainToVendor,
    props<{
        vendorDetails: VendorDetailsResponse;
        newDomain: string;
    }>(),
);

export const addDomainToVendorRequest = createAction(
    VendorDetailsTabActions.AddDomainToVendorRequest,
    props<{
        vendorDetails: VendorDetailsResponse;
        newDomain: string;
    }>(),
);

export const addDomainToVendorRequestSuccess = createAction(
    VendorDetailsTabActions.AddDomainToVendorRequestSuccess,
    props<{
        orgName: string;
    }>(),
);

export const addDomainToVendorRequestFailed = createAction(
    VendorDetailsTabActions.AddDomainToVendorRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getVendorDetailsTabRiskAdvisoriesRequest = createAction(
    VendorDetailsTabActions.GetVendorDetailsTabRiskAdvisoriesRequest,
    props<{
        params?: PaginationParams;
        vendorId: number;
    }>(),
);

export const getVendorDetailsTabRiskAdvisoriesRequestSuccess = createAction(
    VendorDetailsTabActions.GetVendorDetailsTabRiskAdvisoriesRequestSuccess,
    props<{
        riskAdvisories: NetworkExposureRiskAdvisory[];
        httpHeaders: HttpHeaders;
    }>(),
);

export const getVendorDetailsTabRiskAdvisoriesRequestFailed = createAction(
    VendorDetailsTabActions.GetVendorDetailsTabRiskAdvisoriesRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const refreshVendorDetailsVendorDetailsTabRequest = createAction(
    VendorDetailsTabActions.RefreshVendorDetailsVendorDetailsTabRequest,
    props<{
        vendorId: number;
        relationshipId?: number;
    }>(),
);

export const refreshVendorDetailsVendorDetailsTabRequestSuccess = createAction(
    VendorDetailsTabActions.RefreshVendorDetailsVendorDetailsTabRequestSuccess,
);

export const refreshVendorDetailsVendorDetailsTabRequestFailed = createAction(
    VendorDetailsTabActions.RefreshVendorDetailsVendorDetailsTabRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteVendorDetailsTabRiskAdvisory = createAction(
    VendorDetailsTabActions.DeleteVendorDetailsTabRiskAdvisory,
    props<{ riskAdvisory: RiskAdvisory }>(),
);

export const deleteVendorDetailsTabRiskAdvisoryRequest = createAction(
    VendorDetailsTabActions.DeleteVendorDetailsTabRiskAdvisoryRequest,
    props<{ riskAdvisory: RiskAdvisory }>(),
);

export const deleteVendorDetailsTabRiskAdvisoryRequestSuccess = createAction(
    VendorDetailsTabActions.DeleteVendorDetailsTabRiskAdvisoryRequestSuccess,
);

export const deleteVendorDetailsTabRiskAdvisoryRequestFailed = createAction(
    VendorDetailsTabActions.DeleteVendorDetailsTabRiskAdvisoryRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteVendorDetailsTabRiskAdvisoryCancel = createAction(
    VendorDetailsTabActions.DeleteVendorDetailsTabRiskAdvisoryCancel,
);

export const getVendorDetailsStatsRequest = createAction(
    VendorDetailsTabActions.GetVendorDetailsStatsRequest,
    props<{
        relationshipId: number;
    }>(),
);

export const getVendorDetailsStatsRequestSuccess = createAction(
    VendorDetailsTabActions.GetVendorDetailsStatsRequestSuccess,
    props<{ relationshipVendorDetailsStats: RelationshipVendorDetailsStatsResponse }>(),
);

export const getVendorDetailsStatsRequestFailed = createAction(
    VendorDetailsTabActions.GetVendorDetailsStatsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
