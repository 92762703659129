import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { Risk, RiskLabels } from '@entities/risk-assessment';
import { ColorUtilsService } from '@shared/utils/color-utils.service';
import { OrgRiskConfig } from '../../../../admin/client-profile/client-profile.model';
import { getRiskLevelFromScore, RelationshipRiskToleranceView, RiskTolerance } from '@entities/risk-tolerance';
import { RiskThresholdData } from '../../redux/risk-model-settings.selectors';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'app-risk-distribution',
    templateUrl: './risk-distribution.component.html',
    styleUrls: ['./risk-distribution.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskDistributionComponent implements OnChanges {
    @Input({ required: true })
    relationshipRiskDistribution: Map<Risk, RelationshipRiskToleranceView[]>;

    @Input({ required: true })
    riskLevelDisplayNames: OrgRiskConfig[];

    @Input({ required: true })
    activeRiskTolerance: RiskTolerance;

    @Input({ required: true })
    riskToleranceThresholds: Map<RiskTolerance, Map<Risk, RiskThresholdData>>;

    @ViewChild(BaseChartDirective)
    chart: BaseChartDirective;

    barChartPlugins = [];

    barChartOptions: ChartConfiguration<'bar'>['options'] = {
        responsive: true,
        scales: {
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Residual risk',
                    color: '#9E9E9E',
                    font: {
                        weight: 'lighter',
                    },
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    callback: function (value) {
                        const lbl = this.getLabelForValue(value as number);
                        if (typeof lbl === 'string' && lbl.length > 22) {
                            return `${lbl.substring(0, 22)}...`;
                        }
                        return lbl;
                    },
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Num. Relationships',
                    color: '#9E9E9E',
                    font: {
                        weight: 'lighter',
                    },
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    maxTicksLimit: 3,
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
            },
        },
        elements: {
            bar: {
                borderRadius: 10,
                borderSkipped: false,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.parsed.y} relationship(s)`;
                    },
                },
            },
        },
    };

    riskOrder = [Risk.NO_CONTEXT, Risk.LOW, Risk.MEDIUM, Risk.HIGH, Risk.EXTREME];

    barChartData: ChartConfiguration<'bar'>['data'] = {
        labels: this.riskOrder.map(
            (riskLevel) =>
                this.riskLevelDisplayNames?.find((riskConfig) => riskConfig.riskLevel === riskLevel)?.displayName ||
                RiskLabels[riskLevel],
        ),
        datasets: [
            {
                data: [],
                backgroundColor: this.riskOrder.map((risk) => this._colorUtilsService.getRiskColor(risk)),
                borderColor: this.riskOrder.map((risk) => this._colorUtilsService.getRiskColor(risk)),
                borderWidth: 1,
                hoverBackgroundColor: this._colorUtilsService.graphColors.skyBlue,
                hoverBorderColor: this._colorUtilsService.graphColors.skyBlue,
            },
        ],
    };

    constructor(private _colorUtilsService: ColorUtilsService) {}

    generateRiskDistributionPreview(selectedTolerance: RiskTolerance): void {
        let relationshipDistributionForSelectedRiskTolerance: Map<Risk, RelationshipRiskToleranceView[]> = new Map();

        Array.from(this.relationshipRiskDistribution?.values()).forEach((relationshipRiskToleranceViews) => {
            relationshipRiskToleranceViews.forEach((view) => {
                let newRiskLevel =
                    view.residualRisk === Risk.NO_CONTEXT
                        ? Risk.NO_CONTEXT
                        : getRiskLevelFromScore(
                              view.residualRiskScore,
                              selectedTolerance,
                              this.riskToleranceThresholds,
                              view.residualRisk,
                          );
                if (relationshipDistributionForSelectedRiskTolerance.has(newRiskLevel)) {
                    relationshipDistributionForSelectedRiskTolerance.get(newRiskLevel).push(view);
                } else {
                    relationshipDistributionForSelectedRiskTolerance.set(newRiskLevel, [view]);
                }
            });
        });

        if (relationshipDistributionForSelectedRiskTolerance.size > 0) {
            this.barChartData.datasets[0].data = this.riskOrder.map(
                (risk) => relationshipDistributionForSelectedRiskTolerance.get(risk)?.length || 0,
            );
            this.barChartData.labels = this.riskOrder.map(
                (riskLevel) =>
                    this.riskLevelDisplayNames?.find((riskConfig) => riskConfig.riskLevel === riskLevel)?.displayName ||
                    RiskLabels[riskLevel],
            );
            this.chart?.update();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.generateRiskDistributionPreview(this.activeRiskTolerance);
    }
}
