import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LongRunningTaskEffects } from './redux/long-running-task.effects';
import { longRunningTaskFeature } from './redux/long-running-task.reducer';

@NgModule({
    imports: [StoreModule.forFeature(longRunningTaskFeature), EffectsModule.forFeature([LongRunningTaskEffects])],
})
export class LongRunningTaskModule {}
