<div class="row">
    <div class="col-md-12">
        <mat-progress-bar *ngIf="loadingRiskAdvisories" mode="indeterminate"></mat-progress-bar>
        <mat-table
            [dataSource]="dataSource"
            matSort
            matSortDisableClear
            matSortActive="publishedDate"
            matSortDirection="desc"
            matSortStart="desc"
            class="viso-table"
            [trackBy]="trackByRiskAdvisoryId"
            (matSortChange)="onSortData($event)"
        >
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header="title">Title</mat-header-cell>
                <mat-cell *matCellDef="let row" class="d-flex align-items-center">
                    <button color="warn" mat-icon-button *ngIf="canEdit" (click)="onDeleteRiskAdvisory(row)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    <div class="d-flex flex-column">
                        <a
                            type="button"
                            class="mat-text-primary text-bold"
                            (click)="canEdit ? openAdvisoryDialogEdit(row) : openDetailsDialog(row)"
                        >
                            {{ row.title }}
                        </a>

                        <small>{{ RiskAdvisoryTypeLabels[row.type] }}</small>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="material">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header="material"></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    @if (row.material) {
                        <div
                            class="text-bold d-flex align-items-center flex-column"
                            matTooltipPosition="right"
                            matTooltip="This advisory is a material event. Material events qualify as any cybersecurity advisory a reasonable shareholder would consider important for investment decisions, taking into account the incident’s impact on reputation, customer relationships, competitiveness, and potential litigation or regulatory actions."
                        >
                            <mat-icon color="warn" fontSet="material-symbols-outlined">warning</mat-icon>
                            <span class="mat-text-warn">Material</span>
                        </div>
                    }
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="date">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header="publishedDate"
                    >Date
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{ row.publishedDate | date: 'mediumDate' }}
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
            (page)="setCurrentPage($event)"
            [pageIndex]="currentPage"
            [length]="totalRiskAdvisories"
            [pageSize]="itemsPerPage"
            [pageSizeOptions]="[10, 20, 30]"
            [showFirstLastButtons]="true"
            aria-label="Select page of Risk Advisories"
        ></mat-paginator>
    </div>
</div>
