import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NetworkExposureRiskAdvisory, RiskAdvisory } from '@entities/risk-advisory';
import { SortDirection } from '@angular/material/sort';
import {
    VendorDetailsResponse,
    VendorDetailsStatsResponse,
} from '../../../../../routes/request/models/vendor-details.model';

@Component({
    selector: 'app-vendor-risk-events',
    templateUrl: './vendor-risk-events.component.html',
    styleUrl: './vendor-risk-events.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorRiskEventsComponent {
    @Input({ required: true })
    riskAdvisories!: NetworkExposureRiskAdvisory[];

    @Input({ required: true })
    vendorDetails: VendorDetailsResponse;

    @Input({ required: true })
    vendorDetailsStats: VendorDetailsStatsResponse;

    @Input({ required: true })
    totalRiskAdvisories!: number;

    @Input({ required: true })
    userCanEdit!: boolean;

    @Output() onRiskAdvisorySortChange: EventEmitter<{ column: string; direction: SortDirection }> = new EventEmitter();

    @Output() onRiskAdvisoryPageChange: EventEmitter<{ page: number; pageSize: number }> = new EventEmitter();

    @Output() onRiskAdvisorySearchTextChange: EventEmitter<string> = new EventEmitter();

    @Output() deleteRiskAdvisory: EventEmitter<{ riskAdvisory: RiskAdvisory }> = new EventEmitter();
}
