import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    deletePreferredEmailDomainRequest,
    deletePreferredEmailDomainRequestFailure,
    deletePreferredEmailDomainRequestSuccess,
    getDomainVerificationRequest,
    getDomainVerificationRequestFailure,
    getDomainVerificationRequestSuccess,
    getDomainVerificationsRequest,
    getDomainVerificationsRequestFailure,
    getDomainVerificationsRequestSuccess,
    getPreferredEmailDomainRequest,
    getPreferredEmailDomainRequestFailure,
    getPreferredEmailDomainRequestSuccess,
    openConfirmDeletePreferredEmailDomainModal,
    openCustomizeEmailModal,
    openDkimConfigurationModal,
    setPreferredEmailDomainRequest,
    setPreferredEmailDomainRequestFailure,
    setPreferredEmailDomainRequestSuccess,
} from './domain-verification.actions';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ViewDkimConfigurationDialogComponent } from '../dkim-configuration-dialog/view-dkim-configuration-dialog.component';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import { DomainVerificationService } from '@entities/domain-verification';
import { OrgService } from '@entities/org';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { getDomainVerifications, getPreferredDomainVerification } from './domain-verification.selectors';
import { CustomizeEmailDialogComponent } from '../customize-email-dialog/customize-email-dialog.component';
import { Store } from '@ngrx/store';

@Injectable()
export class DomainVerificationEffects {
    getDomainVerifications$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getDomainVerificationsRequest),
            switchMap(() =>
                this._domainVerificationService.getVerifications().pipe(
                    map((verifications) => getDomainVerificationsRequestSuccess({ verifications })),
                    catchError(() => of(getDomainVerificationsRequestFailure())),
                ),
            ),
        ),
    );

    getPreferredDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getPreferredEmailDomainRequest),
            switchMap(() =>
                this._orgService.getPreferredEmailDomain().pipe(
                    map(({ body }) => getPreferredEmailDomainRequestSuccess({ verification: body })),
                    catchError(() => of(getPreferredEmailDomainRequestFailure())),
                ),
            ),
        ),
    );

    setPreferredDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(setPreferredEmailDomainRequest),
            switchMap(({ config }) =>
                this._orgService.updatePreferredEmailDomain(config).pipe(
                    map(() => setPreferredEmailDomainRequestSuccess()),
                    catchError(() => of(setPreferredEmailDomainRequestFailure())),
                ),
            ),
        ),
    );

    openConfirmDeletePreferredEmailDomainModal$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openConfirmDeletePreferredEmailDomainModal),
            switchMap(() =>
                this._matConfirmDialogService
                    .confirm({
                        title: 'Reset to default domain',
                        message:
                            '<strong class="text-error">Are you sure you want to reset to the default domain?</strong>\n' +
                            'Automated communications and assessment communications will be sent from ' +
                            '<strong>the default email address</strong>.',
                        confirmLabel: 'Reset to default',
                    })
                    .pipe(
                        filter(Boolean),
                        map(() => deletePreferredEmailDomainRequest()),
                    ),
            ),
        ),
    );

    deletePreferredDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deletePreferredEmailDomainRequest),
            switchMap(() =>
                this._orgService.deletePreferredEmailDomain().pipe(
                    map(() => deletePreferredEmailDomainRequestSuccess()),
                    catchError(() => of(deletePreferredEmailDomainRequestFailure())),
                ),
            ),
        ),
    );

    refreshPreferredDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(setPreferredEmailDomainRequestSuccess),
            map(() => getPreferredEmailDomainRequest()),
        ),
    );

    getDomainVerification$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getDomainVerificationRequest),
            switchMap(({ domain }) =>
                this._domainVerificationService.getVerification(domain).pipe(
                    map((verification) => getDomainVerificationRequestSuccess({ verification })),
                    catchError(() => of(getDomainVerificationRequestFailure())),
                ),
            ),
        ),
    );

    openDkimConfigurationModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openDkimConfigurationModal),
                switchMap(({ domain }) =>
                    this._domainVerificationService.getVerification(domain).pipe(
                        map((verification) =>
                            this._dialogService.open(ViewDkimConfigurationDialogComponent, {
                                inputs: { verification },
                            }),
                        ),
                    ),
                ),
            ),
        { dispatch: false },
    );

    openCustomizeEmailModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openCustomizeEmailModal),
                withLatestFrom(
                    this._store$.select(getPreferredDomainVerification),
                    this._store$.select(getDomainVerifications),
                ),
                tap(([, preference, verifications]) =>
                    this._dialogService.open<CustomizeEmailDialogComponent>(CustomizeEmailDialogComponent, {
                        inputs: { preference, verifications: verifications.filter((v) => v.verified) },
                    }),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _orgService: OrgService,
        private _store$: Store,
        private _dialogService: MatDialogWrapperService,
        private _domainVerificationService: DomainVerificationService,
        private _matConfirmDialogService: MatConfirmDialogService,
    ) {}
}
