<app-custom-stepper #stepper>
    <cdk-step>
        <div mat-dialog-title>
            Manage tiers
            <button mat-icon-button [mat-dialog-close]="undefined">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <mat-dialog-content>
            <p>
                Adjust the number of tiers in your program. Remember, you can also group vendors with tags within a
                tier.
            </p>
            <div class="d-flex justify-content-center mt-4 mb-4">
                <app-vendor-tier-selection
                    [initialSelection]="currentNumberOfTiers"
                    [allowNone]="true"
                    (numberOfTiersSelected)="selectedTiers = $event"
                />
            </div>
            @if (reducingTiers) {
                <span class="text-danger fw-bold">
                    {{ generateUnassignedWarning() }}
                </span>
            }
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-flat-button type="button" [mat-dialog-close]="undefined">Cancel</button>
            <button
                mat-flat-button
                color="primary"
                type="button"
                (click)="reducingTiers ? stepper.next() : saveChanges()"
            >
                Save changes
            </button>
        </mat-dialog-actions>
    </cdk-step>
    <cdk-step>
        <div mat-dialog-title>
            Reduce your tiers?
            <button mat-icon-button [mat-dialog-close]="undefined">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <mat-dialog-content>
            <p>
                You are reducing the number of tiers from <strong>{{ currentNumberOfTiers }}</strong> to
                <strong>{{ selectedTiers }}</strong
                >. Any vendors assigned to these deleted tiers will be unassigned.
            </p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-flat-button type="button" [mat-dialog-close]="undefined">Cancel</button>
            <button mat-flat-button color="primary" type="button" (click)="saveChanges()">Save changes</button>
        </mat-dialog-actions>
    </cdk-step>
</app-custom-stepper>
