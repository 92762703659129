<div class="card comment-card avoid-break-inside" *ngFor="let comment of comments$ | async">
    <div class="card-header d-flex justify-content-between">
        <ng-container *ngIf="comment.authorFirstName && comment.authorLastName; else elseBlock">
            <strong
                >{{ comment.authorFirstName + ' ' + comment.authorLastName | titlecase }}
                {{ comment.isAuthorInactive ? ' (Inactive)' : '' }}
            </strong>
        </ng-container>
        <ng-template #elseBlock
            ><strong>{{ comment.authorEmail }}</strong></ng-template
        >
        <div>
            <span class="text-muted">
                {{ comment.createdDate | date: 'medium' }}
            </span>
            <button
                exportRemovableAction
                class="btn-icon"
                *ngIf="comment.canCurrentUserDelete"
                (click)="deleteComment.emit(comment.id)"
            >
                <em class="fa fa-trash ms-2 text-error"></em>
            </button>
        </div>
    </div>
    <div class="card-body">
        <quill-view [content]="comment.safeHtml" format="html" theme="snow" [sanitize]="true" class="p-0"></quill-view>
    </div>
</div>
