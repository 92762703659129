import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    VendorDetailsResponse,
    VendorDetailsStatsResponse,
} from '../../../../../../routes/request/models/vendor-details.model';
import { OrgSizeLabels } from '@entities/org';
import { VendorSearchUtils } from '@entities/org/vendor-search.utils';
import { Risk, RiskAssessment } from '@entities/risk-assessment';

@Component({
    selector: 'app-vendor-risk-details',
    templateUrl: './vendor-risk-details.component.html',
    styleUrl: './vendor-risk-details.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorRiskDetailsComponent {
    @Input({ required: true })
    vendorDetails: VendorDetailsResponse;

    @Input({ required: true })
    vendorDetailsStats: VendorDetailsStatsResponse;

    @Input()
    latestRiskAssessment?: RiskAssessment;

    @Input({ required: true })
    isRelationshipDetails: boolean;

    protected readonly Math = Math;

    get companySize(): string {
        return OrgSizeLabels[this.vendorDetails?.orgSize];
    }

    get isPubliclyTraded(): boolean {
        return this.vendorDetails?.publiclyTraded;
    }

    get vendorAddress(): string {
        return VendorSearchUtils.getVendorFormattedAddress(this.vendorDetails);
    }

    riskColors(riskLevel: Risk): string {
        const normalizedRiskLevelName = riskLevel?.toLowerCase().replace('_', '-');
        return `${normalizedRiskLevelName}-risk`;
    }

    roundRiskScore(riskScore: number): number {
        return Math.round(riskScore * 100) / 100;
    }
}
