import { createAction, props } from '@ngrx/store';
import {
    Artifact,
    ArtifactIntelligence,
    ArtifactMetadataFile,
    ControlValidation,
    OpenArtifactFileParams,
} from '../../../../entities/artifact';
import { VisoUser } from '../../../../entities/viso-user';
import { UploadArtifactsRequest } from '../../../../shared/upload-artifacts';
import {
    ArtifactControl,
    ArtifactControlDomain,
    ArtifactViewAllControlDetectionsToggleTrack,
    ArtifactViewControlDetectionToggleTrack,
    BaseRelationshipDetailPageEventTrack,
} from '../../models';

export enum ArtifactActions {
    GetRequestArtifactsRequest = '[Request] Get  Request Artifacts Request',
    GetRequestArtifactsRequestSuccess = '[Request] Get  Request Artifacts Request Success',
    GetRequestArtifactsRequestFailed = '[Request] Get  Request Artifacts Request Failed',
    DeleteRequestArtifactRequest = '[Request] Delete Request Artifact Request',
    DeleteRequestArtifactRequestSuccess = '[Request] Delete Request Artifact Request Success',
    DeleteRequestArtifactRequestFailed = '[Request] Delete Request Artifact Request Failed',
    CreateAllArtifactControlValidationsRequest = '[Request] Create all Artifact Control Validations Request',
    CreateAllArtifactControlValidationsRequestSuccess = '[Request] Create all Artifact Control Validations Request Success',
    DeleteAllArtifactControlValidationsRequest = '[Request] Delete all Artifact Control Validations Request',
    DeleteAllArtifactControlValidationsRequestFailed = '[Request] Delete all Artifact Control Validations Request Failed',
    DeleteAllArtifactControlValidationsRequestSuccess = '[Request] Delete all Artifact Control Validations Request Success',
    DeleteArtifactControlDomainValidationsRequest = '[Request] Delete Artifact Control Domain Validations Request',
    DeleteArtifactControlDomainValidationsRequestSuccess = '[Request] Delete Artifact Control Domain Validations Request Success',
    DeleteArtifactControlDomainValidationsRequestFailed = '[Request] Delete Artifact Control Domain Validations Request Failed',
    OpenAddControlModal = '[Request] Open Add Control Modal',
    DownloadArtifact = '[Request] Download Artifact',
    DownloadArtifactFailed = '[Request] Download Artifact Failed',
    DownloadRelationshipArtifactsAsZip = '[Request] Download Relationship Artifacts As Zip',
    DownloadRelationshipArtifactsAsZipFailed = '[Request] Download Relationship Artifacts As Zip Failed',
    DownloadArtifactMetadataFile = '[Request] Download Artifact Metadata File',
    DownloadArtifactMetadataFileFailed = '[Request] Download Artifact Metadata File Failed',
    GetArtifactMetadataRequest = '[Request] Get Artifact Metadata Request',
    GetArtifactMetadataRequestSuccess = '[Request] Get Artifact Metadata Request Success',
    GetArtifactMetadataRequestFailed = '[Request] Get Artifact Metadata Request Failed',
    UploadRequestArtifactRequest = '[Request] Upload  Request Artifact Request',
    UploadRequestArtifactRequestSuccess = '[Request] Upload  Request Artifact Request Success',
    UploadRequestArtifactRequestFailed = '[Request] Upload  Request Artifact Request Failed',
    GetArtifactIntelligenceRequest = '[Request] Get Artifact Intelligence Request',
    GetArtifactIntelligenceRequestSuccess = '[Request] Get Artifact Intelligence Request Success',
    GetArtifactIntelligenceRequestFailed = '[Request] Get Artifact Intelligence Request Failed',
    UpdateArtifactValidationStatusRequest = '[Request] Update Artifact Validation Status Request',
    UpdateArtifactValidationStatusRequestSuccess = '[Request] Update Artifact Validation Status Request Success',
    UpdateArtifactValidationStatusRequestFailed = '[Request] Update Artifact Validation Status Request Failed',
    GetArtifactPasswordRequest = '[Request] Get Artifact Password Request',
    GetArtifactPasswordRequestSuccess = '[Request] Get Artifact Password Request Success',
    GetArtifactPasswordRequestFailed = '[Request] Get Artifact Password Request Failed',
    OpenArtifactFile = '[Request] Open Artifact File',
    TrackArtifactDetailControlDetectionToggle = '[Request] Track Artifact Detail Control Detection Toggle',
    TrackArtifactDetailAllControlDetectionsToggle = '[Request] Track Artifact Detail All Controls Detections Toggle',
    GetArtifactRequest = '[Request] Get Artifact Request',
    GetArtifactRequestSuccess = '[Request] Get Artifact Request Success',
    GetArtifactRequestFailed = '[Request] Get Artifact Request Failed',
    GetRelationshipArtifactSupersessionRequest = '[Request] Get Relationship Artifact Supersession Request',
    GetRelationshipArtifactSupersessionRequestSuccess = '[Request] Get Relationship Artifact Supersession Request Success',
    GetRelationshipArtifactSupersessionRequestFailed = '[Request] Get Relationship Artifact Supersession Request Failed',
}

export const getArtifactIntelligenceRequest = createAction(
    ArtifactActions.GetArtifactIntelligenceRequest,
    props<{ artifactId: number }>(),
);

export const getArtifactIntelligenceRequestSuccess = createAction(
    ArtifactActions.GetArtifactIntelligenceRequestSuccess,
    props<{ artifactIntelligence: ArtifactIntelligence }>(),
);

export const getArtifactIntelligenceRequestFailed = createAction(ArtifactActions.GetArtifactIntelligenceRequestFailed);

export const getRequestArtifactsRequest = createAction(
    ArtifactActions.GetRequestArtifactsRequest,
    props<{ requestId: number }>(),
);

export const getRequestArtifactsRequestSuccess = createAction(
    ArtifactActions.GetRequestArtifactsRequestSuccess,
    props<{ artifacts: Artifact[] }>(),
);

export const getRequestArtifactsRequestFailed = createAction(ArtifactActions.GetRequestArtifactsRequestFailed);

export const deleteRequestArtifactRequest = createAction(
    ArtifactActions.DeleteRequestArtifactRequest,
    props<{ requestId: number; artifactId: number }>(),
);

export const deleteRequestArtifactRequestSuccess = createAction(
    ArtifactActions.DeleteRequestArtifactRequestSuccess,
    props<{ requestId: number }>(),
);

export const deleteRequestArtifactRequestFailed = createAction(ArtifactActions.DeleteRequestArtifactRequestFailed);

export const openAddControlModal = createAction(
    ArtifactActions.OpenAddControlModal,
    props<{
        artifact: Artifact;
        artifactControl: ArtifactControl;
        allArtifactControlDomains?: ArtifactControlDomain[];
        currentAccount: VisoUser;
        controlValidation?: ControlValidation;
    }>(),
);

export const downloadArtifact = createAction(ArtifactActions.DownloadArtifact, props<{ artifactId: number }>());

export const downloadArtifactFailed = createAction(ArtifactActions.DownloadArtifactFailed);

export const downloadRelationshipArtifactsAsZip = createAction(
    ArtifactActions.DownloadRelationshipArtifactsAsZip,
    props<{ requestId: number; artifactIds: number[] }>(),
);

export const downloadRelationshipArtifactsAsZipFailed = createAction(
    ArtifactActions.DownloadRelationshipArtifactsAsZipFailed,
);

export const downloadArtifactMetadataFile = createAction(
    ArtifactActions.DownloadArtifactMetadataFile,
    props<{ artifactId: number; artifactMetadataFile: ArtifactMetadataFile }>(),
);

export const downloadArtifactMetadataFileFailed = createAction(ArtifactActions.DownloadArtifactMetadataFileFailed);

export const getArtifactMetadataRequest = createAction(
    ArtifactActions.GetArtifactMetadataRequest,
    props<BaseRelationshipDetailPageEventTrack & { artifactId: number }>(),
);

export const getArtifactMetadataRequestSuccess = createAction(
    ArtifactActions.GetArtifactMetadataRequestSuccess,
    props<
        BaseRelationshipDetailPageEventTrack & {
            artifactId: number;
            artifactMetadata: Map<ArtifactMetadataFile, boolean>;
        }
    >(),
);

export const getArtifactMetadataRequestFailed = createAction(ArtifactActions.GetArtifactMetadataRequestFailed);

export const deleteAllArtifactControlValidationsRequest = createAction(
    ArtifactActions.DeleteAllArtifactControlValidationsRequest,
    props<{ artifact: Artifact }>(),
);

export const deleteAllArtifactControlValidationsRequestSuccess = createAction(
    ArtifactActions.DeleteAllArtifactControlValidationsRequestSuccess,
    props<{ artifactId: number }>(),
);

export const deleteAllArtifactControlValidationsRequestFailed = createAction(
    ArtifactActions.DeleteAllArtifactControlValidationsRequestFailed,
);

export const deleteArtifactControlDomainValidationsRequest = createAction(
    ArtifactActions.DeleteArtifactControlDomainValidationsRequest,
    props<{ artifact: Artifact; controlDomain: ArtifactControlDomain }>(),
);

export const deleteArtifactControlDomainValidationsRequestSuccess = createAction(
    ArtifactActions.DeleteArtifactControlDomainValidationsRequestSuccess,
    props<{ artifactId: number }>(),
);

export const deleteArtifactControlDomainValidationsRequestFailed = createAction(
    ArtifactActions.DeleteArtifactControlDomainValidationsRequestFailed,
);

export const uploadRequestArtifactRequest = createAction(
    ArtifactActions.UploadRequestArtifactRequest,
    props<{ requestId: number } & UploadArtifactsRequest>(),
);

export const uploadRequestArtifactRequestSuccess = createAction(
    ArtifactActions.UploadRequestArtifactRequestSuccess,
    props<{ requestId: number; duplicateArtifactFileNames: string[] }>(),
);

export const uploadRequestArtifactRequestFailed = createAction(ArtifactActions.UploadRequestArtifactRequestFailed);

export const getArtifactPasswordRequest = createAction(
    ArtifactActions.GetArtifactPasswordRequest,
    props<{ artifactId: number }>(),
);

export const getArtifactPasswordRequestSuccess = createAction(
    ArtifactActions.GetArtifactPasswordRequestSuccess,
    props<{ password: string }>(),
);

export const getArtifactPasswordRequestFailed = createAction(ArtifactActions.GetArtifactPasswordRequestFailed);

export const openArtifactFile = createAction(ArtifactActions.OpenArtifactFile, props<OpenArtifactFileParams>());

export const trackArtifactDetailControlDetectionToggle = createAction(
    ArtifactActions.TrackArtifactDetailControlDetectionToggle,
    props<ArtifactViewControlDetectionToggleTrack>(),
);

export const trackArtifactDetailAllControlDetectionsToggle = createAction(
    ArtifactActions.TrackArtifactDetailAllControlDetectionsToggle,
    props<ArtifactViewAllControlDetectionsToggleTrack>(),
);

export const getArtifactRequest = createAction(ArtifactActions.GetArtifactRequest, props<{ artifactId: number }>());

export const getArtifactRequestSuccess = createAction(
    ArtifactActions.GetArtifactRequestSuccess,
    props<{ artifact: Artifact }>(),
);

export const getArtifactRequestFailed = createAction(ArtifactActions.GetArtifactRequestFailed);

export const getRelationshipArtifactSupersessionRequest = createAction(
    ArtifactActions.GetRelationshipArtifactSupersessionRequest,
    props<{ relationshipId: number }>(),
);

export const getRelationshipArtifactSupersessionRequestSuccess = createAction(
    ArtifactActions.GetRelationshipArtifactSupersessionRequestSuccess,
    props<{ artifactSupersession: Map<number, number> }>(),
);

export const getRelationshipArtifactSupersessionRequestFailed = createAction(
    ArtifactActions.GetRelationshipArtifactSupersessionRequestFailed,
);
