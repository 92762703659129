import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ControlDomain, ControlDomainType } from '../../../entities/control-domain';
import { AppState } from '../../../shared/redux/state';
import { ControlsState, controlsStateKey, initialState } from './controls.state';

export const getControlsState: MemoizedSelector<AppState, ControlsState> =
    createFeatureSelector<ControlsState>(controlsStateKey);

export const getControls: MemoizedSelector<AppState, ControlDomain[]> = createSelector(
    getControlsState,
    (state) => state.controls || initialState.controls,
);

export const getOrgControls: MemoizedSelector<AppState, ControlDomain[]> = createSelector(
    getControlsState,
    (state) => state.orgControls || initialState.orgControls,
);

export const getEnabledControlDomainTypes: MemoizedSelector<AppState, ControlDomainType[]> = createSelector(
    getOrgControls,
    (orgControlDomains) => {
        const withDisabledFlag = orgControlDomains.map((controlDomain) => ({
            ...controlDomain,
            isDisabled: !controlDomain.controls,
        }));
        const disabledDomainTypes = withDisabledFlag
            .filter((group) => !group.isDisabled)
            .map((group) => group.controlDomainType);
        return Array.from(new Set(disabledDomainTypes));
    },
);
