<mat-card>
    <mat-card-header class="mb-4 d-block">
        <h4>Monitoring</h4>
        <p>
            VISO TRUST monitors public data for risk advisories and filings about this organization. Create a
            relationship with this vendor to receive notifications about their risk advisories.
        </p>

        @if (riskAdvisories?.length > 0) {
            <div class="row mt-4">
                <div class="col d-flex column-gap-5">
                    <div>
                        <h1 class="text-normal mb-0">
                            {{
                                !!vendorDetailsStats?.lastRiskAdvisoryPublishedDate
                                    ? (vendorDetailsStats?.lastRiskAdvisoryPublishedDate | timeAgo)
                                    : '-'
                            }}
                        </h1>
                        <p>Last advisory</p>
                    </div>
                    <div>
                        <h1 class="text-normal mb-0">
                            {{
                                !!vendorDetailsStats?.lastRiskAdvisoryPublishedDate
                                    ? vendorDetailsStats?.materialRiskAdvisoriesCount
                                    : '-'
                            }}
                        </h1>
                        <p>Material risk advisories</p>
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <mat-form-field class="keyword-filter">
                        <input
                            matInput
                            #keywordInputFilter
                            type="text"
                            id="keyword-search"
                            placeholder="Search keywords"
                            autocomplete="off"
                            (input)="onRiskAdvisorySearchTextChange.emit(keywordInputFilter.value)"
                        />
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
        }
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col">
                <ng-container *ngIf="riskAdvisories?.length > 0; else noRiskAdvisories">
                    <app-vendor-risk-advisory-table
                        [data]="riskAdvisories"
                        [totalRiskAdvisories]="totalRiskAdvisories"
                        [canEdit]="userCanEdit"
                        (pageChange)="onRiskAdvisoryPageChange.emit($event)"
                        (sortChange)="onRiskAdvisorySortChange.emit($event)"
                        (deleteRiskAdvisory)="deleteRiskAdvisory.emit($event)"
                    />
                </ng-container>
                <ng-template #noRiskAdvisories>
                    <div class="no-risk-advisories-container">
                        <h3 class="text-normal">There are no risk advisories for {{ vendorDetails?.name }}.</h3>
                        <p>When you onboard a vendor, you’ll receive risk advisories for material advisories</p>
                    </div>
                </ng-template>
            </div>
        </div>
    </mat-card-content>
</mat-card>
