import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, map, switchMap, filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ControlDomain, ControlDomainType, ControlDomainTypeLabels } from '../../../entities/control-domain';
import { getControls } from '../../../routes/controls/redux/controls.selectors';
import { OrgControlDomainConfig } from '../client-profile.model';

interface GroupedControls {
    label: string;
    controls: ControlDomain[];
}

@Component({
    selector: 'app-controls-config',
    templateUrl: './controls-config.component.html',
    styleUrls: ['./controls-config.component.scss'],
})
export class ControlsConfigComponent implements OnInit {
    @Input() clientProfileId: string;
    @Input() orgId: number;
    @Input() set profileControls(value: OrgControlDomainConfig[]) {
        this._profileControls$.next(value);
    }

    @Output() controlStatusChange = new EventEmitter<void>();

    groupedControls$: Observable<GroupedControls[]>;
    private _profileControls$ = new BehaviorSubject<OrgControlDomainConfig[]>([]);

    constructor(private _store$: Store) {}

    ngOnInit(): void {
        this.groupedControls$ = this._profileControls$.pipe(
            filter(Boolean),
            map((profileControls) => Object.values(profileControls)),
            switchMap((profileControls) =>
                this._store$.pipe(
                    select(getControls),
                    map((controls) => ({ profileControls, controls })),
                    take(1),
                ),
            ),
            map(({ profileControls, controls }) => {
                const profiledControls = controls
                    .map((c) => {
                        c.disabled = !profileControls?.find((pc) => pc.controlDomainId === c.id);
                        return c;
                    })
                    .sort((a, b) => (a.name > b.name ? 1 : -1));
                return [
                    ControlDomainType.SECURITY,
                    ControlDomainType.PRIVACY,
                    ControlDomainType.ARTIFICIAL_INTELLIGENCE,
                    ControlDomainType.CYBER_INSURANCE,
                    ControlDomainType.RESILIENCE,
                    ControlDomainType.PRODUCT_SECURITY,
                    ControlDomainType.SERVICE_LOCATIONS,
                    ControlDomainType.SUBSERVICERS,
                ].map<GroupedControls>((controlDomainType) => ({
                    label: `${ControlDomainTypeLabels[controlDomainType]} Controls`,
                    controls: profiledControls.filter((c) => c.controlDomainType === controlDomainType),
                }));
            }),
        );
    }

    trackGroupById(index: number, group: GroupedControls) {
        return group.label;
    }

    resizeWindow(): void {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 50);
    }
}
