import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Tag, TagPagination } from './tag.model';
import { createRequestOption } from '../../shared';
import { map } from 'rxjs/operators';
import { BulkLinkTagResponse } from './tag.model';

@Injectable({ providedIn: 'root' })
export class TagService {
    private resourceUrl = 'api/tags';
    constructor(private _httpClient: HttpClient) {}

    getAll(pagination?: TagPagination): Observable<{ items: Tag[]; xTotalCount: number }> {
        let params = createRequestOption(pagination || null); // TODO: without this param it doesn't work
        params = params.append('sort', 'id');
        return this._httpClient
            .get<HttpResponse<Tag[]>>(this.resourceUrl, { params, observe: 'response' })
            .pipe(
                map((res) => ({ items: (res?.body || []) as Tag[], xTotalCount: +res.headers?.get('x-total-count') })),
            );
    }

    getById(tagId: string): Observable<Tag> {
        return this._httpClient.get<Tag>(`${this.resourceUrl}/${tagId}`);
    }

    save(newTag: Tag): Observable<Tag> {
        return this._httpClient.post<Tag>(this.resourceUrl, newTag);
    }

    update(updatedTag: Tag): Observable<Tag> {
        return this._httpClient.put<Tag>(this.resourceUrl, updatedTag);
    }

    delete(tagId: string): Observable<void> {
        return this._httpClient.delete<void>(`${this.resourceUrl}/${tagId}`);
    }

    bulkLinkToRelationships(payload: { [tagId: string]: string[] }): Observable<BulkLinkTagResponse> {
        return this._httpClient.post<BulkLinkTagResponse>(`${this.resourceUrl}/link`, payload);
    }

    linkToRelationship(tagId: string, relationshipId: number): Observable<void> {
        return this._httpClient.post<void>(`${this.resourceUrl}/link/${tagId}/${relationshipId}`, {});
    }

    unlinkFromRelationship(tagId: string, relationshipId: number): Observable<void> {
        return this._httpClient.delete<void>(`${this.resourceUrl}/link/${tagId}/${relationshipId}`);
    }
}
