<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item">
            <a [routerLink]="['/']">Home</a>
        </li>
        <li class="breadcrumb-item active">Relationships</li>
    </ol>
</div>
@if (
    {
        selectedTierId: selectedTierId$ | async,
        tieringVm: vendorTiersVM$ | async,
        relationshipCounts: relationshipCounts$ | async,
        selectedRelationshipsOnPage: selectedRelationshipsOnCurrentPage$ | async,
        selectedRelationshipIds: selectedRelationshipIds$ | async,
        unpaginatedRelationshipIdsForCurrentFilter: unpaginatedRelationshipIdsForCurrentFilter$ | async,
        allUnpaginatedRelationshipsAreSelected:
            (unpaginatedRelationshipIdsForCurrentFilter$ | async).length === (selectedRelationshipIds$ | async).length,
    };
    as ctx
) {
    <div class="content-heading d-flex justify-content-between">
        <div>Relationships</div>
        <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]">
            <button
                id="new-relationship-btn"
                *disableAddRelationshipOnRules
                class="pull-right"
                mat-flat-button
                color="primary"
                [routerLink]="['/', { outlets: { popup: [addRelationshipRoute] } }]"
                [replaceUrl]="true"
                queryParamsHandling="merge"
                data-pw="newRelationshipButton"
            >
                <mat-icon>add</mat-icon>
                Add relationship
            </button>
        </ng-container>
    </div>
    <div class="d-flex justify-content-between align-items-center border-bottom overflow-x-auto mb-3">
        <nav id="vendor-tiers-nav" mat-tab-nav-bar [tabPanel]="tabPanel" [mat-stretch-tabs]="false">
            @for (tier of ctx.tieringVm.allVendorTiers; track tier.id) {
                <a
                    mat-tab-link
                    (click)="setSelectedTierId(tier.id)"
                    [active]="ctx.selectedTierId === tier.id"
                    aria-label="Filter relationships to only those in this tier"
                >
                    {{ tier.name }} ({{ ctx.relationshipCounts.byTier.get(tier.id) ?? 0 }})
                </a>
            }
            @if (!!ctx.tieringVm.allVendorTiers?.length) {
                <mat-divider [vertical]="true"></mat-divider>
            }
            <a
                id="all-relationships-button"
                mat-tab-link
                (click)="setSelectedTierId(null)"
                [active]="ctx.selectedTierId == null"
                aria-label="Remove tier filter from relationships"
            >
                All relationships ({{ ctx.relationshipCounts.total ?? 0 }})
            </a>
        </nav>
        <mat-tab-nav-panel #tabPanel>
            <!-- NOOP panel to prevent console errors since we're using the nav menu above. -->
        </mat-tab-nav-panel>
        <ng-container *jhiHasAnyAuthority="Roles.OrgAdmin">
            <button id="manage-tiers-button" mat-button color="primary mb-0 fw-bold" (click)="manageTiers()">
                Manage tiers
            </button>
        </ng-container>
    </div>
    @if (!(isGraphExpanded$ | async)) {
        <div class="row" *jhiHasNotAuthority="[Roles.Auditor, Roles.Support]">
            <div class="col">
                <app-vendor-tier-stats
                    [vendorTierStats]="ctx.tieringVm.vendorTierStats"
                    [riskToleranceThresholds]="ctx.tieringVm.riskToleranceThresholds"
                    [riskTolerance]="ctx.tieringVm.riskTolerance"
                    [description]="ctx.tieringVm.description"
                    [editingTierDescription]="editingTierDescription$ | async"
                    (updateDescription)="updateVendorTierDescription()"
                    (applyAssessedFilter)="additionalFiltersPanel.setFiltersAndApply('assessed', true)"
                    (applyOnboardedFilter)="setStatusFilter(RequestStatus.ONBOARDED)"
                />
            </div>
        </div>
    }
    <div class="row mt-3">
        <div class="col-lg-12">
            <section class="relationships-loading" *ngIf="relationshipsLoading && !relationshipsLoaded">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="sk-spinner sk-spinner-pulse"></div>
                </div>
            </section>

            <section
                class="relationships-header"
                *ngIf="ctx.selectedRelationshipIds.length === 0 && relationshipsLoaded"
            >
                <div
                    *ngIf="!(isGraphExpanded$ | async)"
                    class="d-flex justify-content-between align-items-center gap-3"
                >
                    <mat-form-field class="keyword-filter">
                        <input
                            matInput
                            #keywordInputFilter
                            type="text"
                            id="keyword-search"
                            (input)="keywordFilter($event)"
                            placeholder="Search keywords"
                            autocomplete="off"
                        />
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>

                    <button
                        id="extra-options-button"
                        mat-icon-button
                        color="primary"
                        type="button"
                        [matMenuTriggerFor]="extraOptionsMenu"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #extraOptionsMenu>
                        <button mat-menu-item (click)="openEditColumnsModal()">Edit columns</button>
                        <button
                            mat-menu-item
                            *jhiHasNotAuthority="[Roles.Auditor, Roles.Admin, Roles.Support]"
                            (click)="exportToCsv()"
                        >
                            Export .csv
                        </button>
                    </mat-menu>
                </div>
                <div class="d-flex justify-content-between align-items-center gap-3 py-3">
                    <div *ngIf="!(isGraphExpanded$ | async)" id="relationship-filters" class="d-flex gap-2">
                        <div class="btn-group" dropdown [insideClick]="true">
                            <button
                                mat-stroked-button
                                color="neutral"
                                class="dropdown-toggle dropdown-filter"
                                [ngClass]="areStatusFiltersApplied ? 'filters-applied' : 'no-filter'"
                                type="button"
                                dropdownToggle
                            >
                                <mat-icon *ngIf="areStatusFiltersApplied">check</mat-icon>
                                Status
                                <span class="caret"></span>
                            </button>
                            <div *dropdownMenu class="quick-filter dropdown-menu" role="menu">
                                <button class="quick-filter-actions dropdown-item" (click)="toggleAllStatuses()">
                                    All
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="drStatusFilter.includes(RequestStatus.NOT_ONBOARDED)"
                                                (click)="changeStatusFilter($event)"
                                                [value]="RequestStatus.NOT_ONBOARDED"
                                            />
                                            <span class="fa fa-check"></span> Not Onboarded
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="drStatusFilter.includes(RequestStatus.ONBOARDED)"
                                                (click)="changeStatusFilter($event)"
                                                [value]="RequestStatus.ONBOARDED"
                                            />
                                            <span class="fa fa-check"></span> Onboarded
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="drStatusFilter.includes(RequestStatus.ARCHIVED)"
                                                (click)="changeStatusFilter($event)"
                                                [value]="RequestStatus.ARCHIVED"
                                            />
                                            <span class="fa fa-check"></span> Archived
                                        </label>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div class="btn-group" dropdown [insideClick]="true">
                            <button
                                mat-stroked-button
                                color="neutral"
                                class="dropdown-toggle"
                                [ngClass]="{ 'filters-applied': areAssessmentPhaseFiltersApplied }"
                                dropdownToggle
                            >
                                <mat-icon *ngIf="areAssessmentPhaseFiltersApplied">check</mat-icon>
                                {{ 'Assessment phase' }}
                                <span class="caret"></span>
                            </button>
                            <div *dropdownMenu class="quick-filter dropdown-menu" role="menu">
                                <button
                                    class="quick-filter-actions dropdown-item"
                                    (click)="toggleAllAssessmentPhases()"
                                >
                                    All
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="assessmentPhaseFilter.includes('')"
                                                (click)="changeAssessmentPhaseFilter($event)"
                                                value=""
                                            />
                                            <span class="fa fa-check"></span> Not Assessed
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="assessmentPhaseFilter.includes(AssessmentStatus.STARTED)"
                                                (click)="changeAssessmentPhaseFilter($event)"
                                                [value]="AssessmentStatus.STARTED"
                                            />
                                            <span class="fa fa-check"></span> Started
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="
                                                    assessmentPhaseFilter.includes(
                                                        AssessmentStatus.COLLECTING_INFORMATION
                                                    )
                                                "
                                                (click)="changeAssessmentPhaseFilter($event)"
                                                [value]="AssessmentStatus.COLLECTING_INFORMATION"
                                            />
                                            <span class="fa fa-check"></span> Collecting Information
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="
                                                    assessmentPhaseFilter.includes(AssessmentStatus.REVIEW_STARTED)
                                                "
                                                (click)="changeAssessmentPhaseFilter($event)"
                                                [value]="AssessmentStatus.REVIEW_STARTED"
                                            />
                                            <span class="fa fa-check"></span> Review Started
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="
                                                    assessmentPhaseFilter.includes(AssessmentStatus.AUDIT_COMPLETED)
                                                "
                                                (click)="changeAssessmentPhaseFilter($event)"
                                                [value]="AssessmentStatus.AUDIT_COMPLETED"
                                            />
                                            <span class="fa fa-check"></span> Follow-up recommended
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="assessmentPhaseFilter.includes(AssessmentStatus.COMPLETED)"
                                                (click)="changeAssessmentPhaseFilter($event)"
                                                [value]="AssessmentStatus.COMPLETED"
                                            />
                                            <span class="fa fa-check"></span> Completed
                                        </label>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div class="btn-group" dropdown [insideClick]="true">
                            <button
                                mat-stroked-button
                                color="neutral"
                                class="dropdown-toggle dropdown-filter"
                                [ngClass]="areRiskLevelFiltersApplied ? 'filters-applied' : 'no-filter'"
                                type="button"
                                dropdownToggle
                            >
                                <mat-icon *ngIf="areRiskLevelFiltersApplied">check</mat-icon>
                                Risk levels
                                <span class="caret"></span>
                            </button>
                            <div *dropdownMenu class="quick-filter risk-filters dropdown-menu" role="menu">
                                <button class="quick-filter-actions dropdown-item" (click)="toggleAllRiskLevels()">
                                    All
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="riskLevelFilter.includes('NO_CONTEXT')"
                                                (click)="changeRiskLevelFilter($event)"
                                                value="NO_CONTEXT"
                                            />
                                            <span class="fa fa-check"></span>
                                            <app-risk-chip [risk]="Risk.NO_CONTEXT" [condensed]="true"> </app-risk-chip>
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="riskLevelFilter.includes('LOW')"
                                                (click)="changeRiskLevelFilter($event)"
                                                value="LOW"
                                            />
                                            <span class="fa fa-check"></span>
                                            <app-risk-chip [risk]="Risk.LOW" [condensed]="true"> </app-risk-chip>
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="riskLevelFilter.includes('MEDIUM')"
                                                (click)="changeRiskLevelFilter($event)"
                                                value="MEDIUM"
                                            />
                                            <span class="fa fa-check"></span>
                                            <app-risk-chip [risk]="Risk.MEDIUM" [condensed]="true"> </app-risk-chip>
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="riskLevelFilter.includes('HIGH')"
                                                (click)="changeRiskLevelFilter($event)"
                                                value="HIGH"
                                            />
                                            <span class="fa fa-check"></span>
                                            <app-risk-chip [risk]="Risk.HIGH" [condensed]="true"> </app-risk-chip>
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="riskLevelFilter.includes('EXTREME')"
                                                (click)="changeRiskLevelFilter($event)"
                                                value="EXTREME"
                                            />
                                            <span class="fa fa-check"></span>
                                            <app-risk-chip [risk]="Risk.EXTREME" [condensed]="true"> </app-risk-chip>
                                        </label>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div class="btn-group" dropdown [insideClick]="true" placement="bottom right">
                            <button
                                *jhiHasNotAuthority="[Roles.Auditor]"
                                mat-stroked-button
                                color="neutral"
                                class="dropdown-toggle dropdown-filter"
                                [ngClass]="areTagFiltersApplied ? 'filters-applied' : 'no-filter'"
                                dropdownToggle
                            >
                                Filter tags
                                <mat-icon *ngIf="areTagFiltersApplied">check</mat-icon>
                                <span class="caret"></span>
                            </button>
                            <div
                                *dropdownMenu
                                class="quick-filter tags-filters dropdown-menu dropdown-menu-end"
                                role="menu"
                            >
                                <app-tag-select
                                    [formControl]="selectedTagNamesControl"
                                    [createTagDisabled]="true"
                                ></app-tag-select>
                            </div>
                        </div>

                        <div class="btn-group" dropdown [insideClick]="true">
                            @if (isInAllRelationshipsTab()) {
                                <button
                                    mat-stroked-button
                                    color="neutral"
                                    class="dropdown-toggle dropdown-filter"
                                    [ngClass]="areTierFiltersApplied ? 'filters-applied' : 'no-filter'"
                                    type="button"
                                    dropdownToggle
                                >
                                    <mat-icon *ngIf="areTierFiltersApplied">check</mat-icon>
                                    Tier
                                    <span class="caret"></span>
                                </button>
                            }
                            <div *dropdownMenu class="quick-filter dropdown-menu" role="menu">
                                <button class="quick-filter-actions dropdown-item" (click)="toggleAllTiers()">
                                    All
                                </button>
                                <button
                                    class="dropdown-item quick-filter-option"
                                    *ngFor="let tier of (vendorTiersVM$ | async)?.allVendorTiers"
                                >
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="tierFilter.includes(tier.id.toString())"
                                                (click)="changeTierFilter($event)"
                                                value="{{ tier.id }}"
                                            />
                                            <span class="fa fa-check"></span> {{ tier.name }}
                                        </label>
                                    </div>
                                </button>
                                <button class="dropdown-item quick-filter-option">
                                    <div class="checkbox c-checkbox">
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="tierFilter.includes('null')"
                                                (click)="changeTierFilter($event)"
                                                value="null"
                                            />
                                            <span class="fa fa-check"></span> Unassigned
                                        </label>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div class="btn-group">
                            <button
                                id="additional-filters-button"
                                class="text-nowrap"
                                #additionalFiltersButton
                                mat-stroked-button
                                color="neutral"
                                [ngClass]="additionalFiltersApplied ? 'filters-applied' : 'no-filter'"
                                type="button"
                                (click)="toggleFilterPanel()"
                            >
                                <mat-icon *ngIf="additionalFiltersApplied">check</mat-icon>
                                More filters
                            </button>

                            <relationships-additional-filters-panel
                                #additionalFiltersPanel
                                [openPanelButton]="additionalFiltersButton"
                                (filtersApplied)="applyAdditionalFilters($event)"
                            />
                        </div>

                        <button
                            *ngIf="areNonTieringFiltersApplied || !!networkExposureOrgId"
                            class="ms-2 text-primary"
                            type="button"
                            mat-button
                            disableRipple
                            (click)="clearAllFilters()"
                        >
                            Clear all
                        </button>
                    </div>
                    <div class="radio-button-container">
                        <mat-button-toggle-group
                            *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser, Roles.ReadOnly]"
                            id="relationships-view-toggle"
                            class="pilled-button-toggle"
                            [formControl]="listOrGraphRadioFormControl"
                            aria-label="Relationship list view"
                        >
                            <mat-button-toggle
                                id="list-view-toggle"
                                [value]="ListOrGraphOptions.List"
                                matTooltip="Show list view"
                                matTooltipShowDelay="1000"
                            >
                                <mat-icon>list</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle
                                id="graph-view-toggle"
                                [value]="ListOrGraphOptions.Graph"
                                matTooltip="Show graph view"
                                matTooltipShowDelay="1000"
                            >
                                <mat-icon>bubble_chart</mat-icon>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </section>
        </div>
    </div>

    @if (
        {
            bulkActionInProgress: bulkRelationshipActionInProgress$ | async,
            bulkActionTooltipMessage: bulkActionInProgressMessage$ | async,
        };
        as bulkVm
    ) {
        <div class="row">
            <div class="col-lg-12" *jhiHasNotAuthority="[Roles.Auditor, Roles.Support, Roles.ReadOnly]">
                <section class="relationship-bulk-actions" *ngIf="ctx.selectedRelationshipIds.length >= 1">
                    <div class="fw-bold d-flex align-items-center justify-content-between">
                        <div>
                            {{ ctx.selectedRelationshipIds.length }}
                            selected
                        </div>
                        @if (displaySelectAllRelationshipsButton$ | async) {
                            <div
                                class="text-primary ms-2"
                                role="button"
                                tabindex="0"
                                (click)="onSelectAllPages()"
                                (keydown.enter)="onSelectAllPages()"
                            >
                                <span
                                    >Select all
                                    {{ ctx.unpaginatedRelationshipIdsForCurrentFilter.length }} relationships</span
                                >
                            </div>
                            @if (filtersIncludeArchived) {
                                <mat-icon
                                    class="ms-1 cursor-info"
                                    color="primary"
                                    matTooltip="Excludes archived relationships"
                                    [inline]="true"
                                    >info</mat-icon
                                >
                            }
                        }
                    </div>
                    <div>
                        <button
                            id="assign-vendor-tier-button"
                            mat-stroked-button
                            class="me-2"
                            color="neutral"
                            type="button"
                            [matMenuTriggerFor]="assignTierMenu"
                        >
                            <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
                            Assign to
                        </button>
                        <mat-menu #assignTierMenu>
                            <button
                                mat-menu-item
                                (click)="
                                    assignSelectedRelationshipsToTier(null, ctx.allUnpaginatedRelationshipsAreSelected)
                                "
                            >
                                Unassigned
                            </button>
                            @for (tier of ctx.tieringVm.allVendorTiers; track tier.id) {
                                <button
                                    mat-menu-item
                                    (click)="
                                        assignSelectedRelationshipsToTier(
                                            tier,
                                            ctx.allUnpaginatedRelationshipsAreSelected
                                        )
                                    "
                                >
                                    {{ tier.name }}
                                </button>
                            } @empty {
                                <button
                                    matTooltip="You need to first create one or more vendor tiers via 'Manage tiers' before you can assign relationships to them."
                                    matTooltipPosition="right"
                                    disabled
                                    mat-menu-item
                                >
                                    No tiers configured
                                </button>
                            }
                        </mat-menu>
                        <div class="btn-group me-2" dropdown [insideClick]="true" #tagsDropdown="bs-dropdown">
                            <button mat-stroked-button color="neutral" class="mat-button-dropdown" dropdownToggle>
                                <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
                                Add tags
                            </button>
                            <div *dropdownMenu class="quick-filter tags-filters dropdown-menu pb-0" role="menu">
                                <app-tag-select ngModel #selectedTagsControl="ngModel">
                                    <ng-template tag-actions-content>
                                        <div class="d-flex justify-content-end p-1">
                                            <button
                                                mat-stroked-button
                                                color="primary"
                                                [disabled]="!selectedTagsControl.value?.length"
                                                (click)="
                                                    addTagsToRelationships($event, selectedTagsControl);
                                                    tagsDropdown.toggle()
                                                "
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </ng-template>
                                </app-tag-select>
                            </div>
                        </div>
                        <button
                            mat-stroked-button
                            class="me-2"
                            color="neutral"
                            type="button"
                            [matMenuTriggerFor]="changeStatusMenu"
                        >
                            <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
                            Change status
                        </button>
                        <mat-menu #changeStatusMenu>
                            <button
                                mat-menu-item
                                (click)="offboardSelectedRelationships()"
                                [disabled]="bulkVm.bulkActionInProgress"
                                [matTooltip]="bulkVm.bulkActionTooltipMessage"
                                matTooltipPosition="left"
                            >
                                Offboard
                            </button>
                            <button
                                mat-menu-item
                                (click)="archiveRelationships()"
                                [disabled]="bulkVm.bulkActionInProgress"
                                [matTooltip]="bulkVm.bulkActionTooltipMessage"
                                matTooltipPosition="left"
                            >
                                Archive
                            </button>
                        </mat-menu>
                        <button
                            mat-stroked-button
                            class="me-2"
                            color="neutral"
                            type="button"
                            [matMenuTriggerFor]="manageAssessmentsMenu"
                        >
                            <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
                            Manage assessments
                        </button>
                        <mat-menu #manageAssessmentsMenu>
                            <button
                                mat-menu-item
                                [matTooltip]="startAssessmentsButtonTooltipMessage(bulkVm.bulkActionInProgress) | async"
                                matTooltipPosition="left"
                                [disabled]="!doesCurrentUserHaveAvailableAssessments || bulkVm.bulkActionInProgress"
                                (click)="startAssessments()"
                            >
                                Start assessment
                            </button>
                            <button
                                mat-menu-item
                                (click)="cancelAssessments()"
                                [disabled]="bulkVm.bulkActionInProgress"
                                [matTooltip]="bulkVm.bulkActionTooltipMessage"
                                matTooltipPosition="left"
                            >
                                Cancel assessment
                            </button>
                            <mat-divider />
                            <button mat-menu-item (click)="enableArtifactUpdates()">Enable artifact updates</button>
                            <button mat-menu-item (click)="disableArtifactUpdates()">Disable artifact updates</button>
                        </mat-menu>
                    </div>
                </section>
            </div>
        </div>
    }

    <mat-tab-group class="list-graph-tab-group" #listGraphTabGroup>
        <mat-tab class="overflow-auto">
            @if (relationshipsLoaded) {
                @if (!!relationships.length) {
                    <div class="table-responsive">
                        <ngx-datatable
                            id="relationships-table"
                            #relationshipsTable
                            class="bootstrap"
                            [rows]="relationships"
                            [loadingIndicator]="relationshipsLoading"
                            [columnMode]="'force'"
                            [footerHeight]="40"
                            [rowHeight]="35"
                            [headerHeight]="30"
                            [rowClass]="getRowInactiveClass"
                            [scrollbarV]="true"
                            [scrollbarH]="true"
                            [selected]="ctx.selectedRelationshipsOnPage"
                            [selectionType]="SelectionType.checkbox"
                            [externalSorting]="true"
                            (select)="onSelect($event)"
                            (sort)="onSort($event)"
                            [sorts]="tableSort$ | async"
                            [externalPaging]="true"
                            [virtualization]="false"
                            [count]="totalCurrentItems"
                            [offset]="page"
                            [limit]="itemsPerPage"
                            [messages]="customMessages"
                            [observeDims]="true"
                            (resize)="tableResized($event)"
                        >
                            <ngx-datatable-column
                                id="relationship-checkbox-container"
                                *jhiHasAnyAuthority="Roles.OrgAdmin"
                                [width]="40"
                                [sortable]="false"
                                [canAutoResize]="false"
                                [draggable]="false"
                                [resizeable]="false"
                                [frozenLeft]="true"
                            >
                                <ng-template
                                    ngx-datatable-header-template
                                    let-value="value"
                                    let-allRowsSelected="allRowsSelected"
                                    let-selectFn="selectFn"
                                >
                                    <div
                                        class="checkbox c-checkbox float-start select-all"
                                        *ngIf="
                                            ctx.selectedRelationshipsOnPage.length === 0 ||
                                            ctx.selectedRelationshipsOnPage.length ===
                                                unarchivedRelationshipsOnPage.length
                                        "
                                    >
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="allRowsSelected"
                                                (change)="selectFn(!allRowsSelected)"
                                            />
                                            <span class="fa fa-check"></span>
                                        </label>
                                    </div>

                                    <div
                                        class="checkbox c-checkbox float-start select-all"
                                        *ngIf="
                                            ctx.selectedRelationshipsOnPage.length >= 1 &&
                                            ctx.selectedRelationshipsOnPage.length <
                                                unarchivedRelationshipsOnPage.length
                                        "
                                    >
                                        <label>
                                            <input type="checkbox" checked (change)="clearSelection()" />
                                            <span class="fa fa-minus"></span>
                                        </label>
                                    </div>
                                </ng-template>
                                <ng-template
                                    ngx-datatable-cell-template
                                    let-value="value"
                                    let-row="row"
                                    let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn"
                                >
                                    @if (row.drStatus !== relationshipArchivedStatus) {
                                        <div class="checkbox c-checkbox float-start mt0">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    id="relationship-checkbox"
                                                    [checked]="isSelected"
                                                    (change)="onCheckboxChangeFn($event)"
                                                />
                                                <span class="fa fa-check"></span>
                                            </label>
                                        </div>
                                    } @else {
                                        <ng-container
                                            *jhiHasNotAuthority="[
                                                Roles.Auditor,
                                                Roles.Support,
                                                Roles.OrgUser,
                                                Roles.ReadOnly,
                                            ]"
                                        >
                                            <mat-icon
                                                class="recycle-button"
                                                role="button"
                                                aria-description="Restore archived relationship"
                                                [inline]="true"
                                                matTooltip="Restore relationship"
                                                matTooltipShowDelay="1000"
                                                (click)="restoreRelationship(row.id)"
                                            >
                                                restore_from_trash
                                            </mat-icon>
                                        </ng-container>
                                    }
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Data Columns start -->

                            <ngx-datatable-column
                                [name]="'Relationship (' + (totalCurrentItems ?? 0) + ')'"
                                [frozenLeft]="true"
                                cellClass="cell-padding"
                                prop="vendorOrgName.keyword"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <ng-template #vendorPopover let-orgData>
                                        <div class="popover-header">
                                            <div
                                                class="popover-icon"
                                                [ngClass]="{ 'white-bg': orgData.vendorFaviconUrl }"
                                                [style.background-image]="
                                                    orgData.vendorFaviconUrl
                                                        ? 'url(' + orgData.vendorFaviconUrl + ')'
                                                        : ''
                                                "
                                                [style.background-size]="'cover'"
                                            ></div>
                                            <span class="popover-title">
                                                {{ orgData.vendorOrgName }}
                                            </span>
                                        </div>
                                        <div
                                            class="popover-detail"
                                            *ngIf="
                                                orgData.vendorDescription ||
                                                orgData.vendorUrl ||
                                                orgData.businessPurpose
                                            "
                                        >
                                            <div class="popover-detail-label mb-2" *ngIf="orgData.description">
                                                Description
                                            </div>
                                            <div class="popover-detail-text mb-2" *ngIf="orgData.description">
                                                {{ orgData.description }}
                                            </div>
                                            <div class="popover-link mb-2" *ngIf="orgData.vendorUrl">
                                                <a [externalLink]="orgData.vendorUrl">{{ orgData.vendorUrl }}</a>
                                            </div>
                                            <div class="popover-detail-label mb-2" *ngIf="orgData.businessPurpose">
                                                Business Purpose
                                            </div>
                                            <div class="popover-detail-text" *ngIf="orgData.businessPurpose">
                                                {{ orgData.businessPurpose }}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <div
                                        *ngIf="row.vendorOrgName"
                                        class="vendor-name-text"
                                        [appPopover]="vendorPopover"
                                        [popoverHostSelector]="'.datatable-body-cell'"
                                        [popoverContext]="row"
                                        [popoverMaxWidth]="300"
                                    >
                                        <div
                                            class="vendor-logo-icon"
                                            [ngClass]="{ 'white-bg': row.vendorFaviconUrl }"
                                            [style.background-image]="
                                                row.vendorFaviconUrl ? 'url(' + row.vendorFaviconUrl + ')' : ''
                                            "
                                            [style.background-size]="'cover'"
                                        ></div>
                                        <ng-container *ngIf="row.drStatus !== RequestStatus.ARCHIVED; else archived">
                                            <a
                                                [routerLink]="['', 'requests', row.id]"
                                                data-pw="relationshipListItemVendorLink"
                                                >{{ row.vendorOrgName }}</a
                                            >
                                        </ng-container>
                                        <ng-template #archived>
                                            <a>{{ row.vendorOrgName }}</a>
                                        </ng-template>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ng-container *jhiHasAnyAuthority="[Roles.Auditor, Roles.Support]">
                                <ngx-datatable-column
                                    name="Client"
                                    prop="customerOrgName"
                                    [width]="90"
                                    [cellClass]="getColumnClass('Client')"
                                    [headerClass]="getColumnClass('Client')"
                                    *ngIf="getColumnVisibility('Client')"
                                >
                                    <ng-template let-row="row" ngx-datatable-cell-template
                                        >{{ row.customerOrgName }}
                                    </ng-template>
                                </ngx-datatable-column>
                            </ng-container>
                            <ngx-datatable-column
                                name="Status"
                                prop="drStatus"
                                [width]="90"
                                [cellClass]="getDrStatusPhaseClass"
                                [headerClass]="getColumnClass('Status')"
                                *ngIf="getColumnVisibility('Status')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{ row.drStatus | pascalCase }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Risk"
                                prop="score"
                                [width]="90"
                                [cellClass]="'cell-centered ' + getColumnClass('Risk')"
                                [headerClass]="getColumnClass('Risk')"
                                *ngIf="getColumnVisibility('Risk')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <app-risk-chip
                                        placement="right"
                                        container="body"
                                        [ngbTooltip]="
                                            !row.latestRiskAssessmentLegacy && row.transitional
                                                ? 'The final risk is still being calculated since the assessment is still in progress'
                                                : ''
                                        "
                                        [risk]="row.residualRisk"
                                        [riskIsTransitional]="!row.latestRiskAssessmentLegacy && row.transitional"
                                        [condensed]="true"
                                    >
                                    </app-risk-chip>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Residual Risk Score"
                                [width]="90"
                                prop="score"
                                [cellClass]="getColumnClass('Residual Risk Score')"
                                [headerClass]="getColumnClass('Residual Risk Score')"
                                *ngIf="getColumnVisibility('Residual Risk Score')"
                            >
                                <ng-template let-score="value" ngx-datatable-cell-template>
                                    {{ score * 100 | number: '1.2-2' }}%
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Inherent Risk"
                                prop="inherentRiskScore"
                                [width]="90"
                                [cellClass]="'cell-centered ' + getColumnClass('Inherent Risk')"
                                [headerClass]="getColumnClass('Inherent Risk')"
                                *ngIf="getColumnVisibility('Inherent Risk')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <app-risk-chip
                                        placement="right"
                                        container="body"
                                        [ngbTooltip]="getInherentRiskTooltipText(row)"
                                        [risk]="row.inherentRisk"
                                        [riskIsTransitional]="false"
                                        [condensed]="true"
                                    >
                                    </app-risk-chip>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Inherent Risk Score"
                                [width]="90"
                                prop="inherentRiskScore"
                                [cellClass]="getColumnClass('Inherent Risk Score')"
                                [headerClass]="getColumnClass('Inherent Risk Score')"
                                *ngIf="getColumnVisibility('Inherent Risk Score')"
                            >
                                <ng-template let-inherentRiskScore="value" ngx-datatable-cell-template>
                                    {{ inherentRiskScore * 100 | number: '1.2-2' }}%
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Residual Risk"
                                prop="score"
                                [width]="90"
                                [cellClass]="'cell-centered ' + getColumnClass('Residual Risk')"
                                [headerClass]="getColumnClass('Residual Risk')"
                                *ngIf="getColumnVisibility('Residual Risk')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <app-risk-chip
                                        *ngIf="
                                            !!row.latestNonTransitionalRiskAssessmentResidualRisk;
                                            else noNonTransitionalRiskAssessment
                                        "
                                        placement="right"
                                        container="body"
                                        [ngbTooltip]="
                                            getResidualRiskTooltipText(
                                                row,
                                                row.latestNonTransitionalRiskAssessmentResidualRisk
                                                    | riskLevel
                                                    | async
                                                    | titlecase
                                            )
                                        "
                                        [risk]="row.latestNonTransitionalRiskAssessmentResidualRisk"
                                        [riskIsTransitional]="!row.latestRiskAssessmentLegacy && row.transitional"
                                        [condensed]="true"
                                    >
                                    </app-risk-chip>
                                    <ng-template #noNonTransitionalRiskAssessment>-</ng-template>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Last Updated"
                                [width]="100"
                                prop="updatedDate"
                                [cellClass]="getColumnClass('Last Updated')"
                                [headerClass]="getColumnClass('Last Updated')"
                                *ngIf="getColumnVisibility('Last Updated')"
                            >
                                <ng-template let-value="value" ngx-datatable-cell-template
                                    >{{ value | date: 'mediumDate' }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Assessment Phase"
                                [width]="120"
                                prop="assessmentStatusOrdinal"
                                [cellClass]="getAssessmentPhaseCellClass"
                                [headerClass]="getColumnClass('Assessment Phase')"
                                *ngIf="getColumnVisibility('Assessment Phase')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div
                                        class="tooltip-wrapper"
                                        placement="right"
                                        container="body"
                                        [ngbTooltip]="row.assessmentPhaseTooltipMessage"
                                    >
                                        <div [ngClass]="getAssessmentPhaseTextClass(row.assessmentStatus)">
                                            {{ row.assessmentStatus }}
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Recommendations"
                                [width]="135"
                                prop="recommendationCount"
                                [cellClass]="getColumnClass('Recommendations')"
                                [headerClass]="getColumnClass('Recommendations')"
                                *ngIf="getColumnVisibility('Recommendations')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{ row.recommendationCount }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Remediation Requested"
                                [width]="135"
                                prop="remediationRequested"
                                [cellClass]="getColumnClass('Remediation Requested')"
                                [headerClass]="getColumnClass('Remediation Requested')"
                                *ngIf="getColumnVisibility('Remediation Requested')"
                            >
                                <ng-template let-remediationRequested="value" ngx-datatable-cell-template>
                                    {{ remediationRequested ? 'Yes' : 'No' }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Requested"
                                [width]="90"
                                prop="assessmentCreatedDate"
                                [cellClass]="getColumnClass('Requested')"
                                [headerClass]="getColumnClass('Requested')"
                                *ngIf="getColumnVisibility('Requested')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{
                                        row.assessmentCreatedDate
                                            ? (row.assessmentCreatedDate | date: 'mediumDate')
                                            : '-'
                                    }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Phase Date"
                                [width]="90"
                                prop="assessmentStatusDate"
                                [cellClass]="getColumnClass('Phase Date')"
                                [headerClass]="getColumnClass('Phase Date')"
                                *ngIf="getColumnVisibility('Phase Date')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{
                                        row.assessmentStatusDate ? (row.assessmentStatusDate | date: 'mediumDate') : '-'
                                    }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Recertification Date"
                                [width]="130"
                                prop="recertificationDate"
                                [cellClass]="getColumnClass('Recertification Date')"
                                [headerClass]="getColumnClass('Recertification Date')"
                                *ngIf="getColumnVisibility('Recertification Date')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span
                                        [ngbTooltip]="getRelationshipRecertificationTooltipMessage(row)"
                                        placement="right"
                                        class="pe-1"
                                    >
                                        <em
                                            *ngIf="isRelationshipUpForRecertification(row)"
                                            [ngClass]="
                                                doesRelationshipHaveInProgressAssessment(row)
                                                    ? 'fa fa-sync text-muted me-2'
                                                    : 'fa fa-exclamation-circle text-error me-2'
                                            "
                                        ></em>
                                        {{
                                            row.recertificationDate && row.drStatus == RequestStatus.ONBOARDED
                                                ? (row.recertificationDate | date: 'mediumDate')
                                                : '-'
                                        }}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                name="Recertification Type"
                                [width]="130"
                                prop="recertificationType"
                                [cellClass]="getColumnClass('Recertification Type')"
                                [headerClass]="getColumnClass('Recertification Type')"
                                *ngIf="getColumnVisibility('Recertification Type')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{
                                        row.recertificationType && row.drStatus == RequestStatus.ONBOARDED
                                            ? getRecertificationType(row.recertificationType)
                                            : '-'
                                    }}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                name="Artifact Update Type"
                                [width]="130"
                                prop="artifactUpdateType"
                                [cellClass]="getArtifactUpdateClass"
                                [headerClass]="getColumnClass('Artifact Update Type')"
                                *ngIf="getColumnVisibility('Artifact Update Type')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{
                                        row.recertificationType && row.drStatus == RequestStatus.ONBOARDED
                                            ? getArtifactUpdateType(row.artifactUpdateType)
                                            : '-'
                                    }}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                name="Business Unit"
                                [width]="110"
                                prop="businessUnitName"
                                [cellClass]="getColumnClass('Business Unit')"
                                [headerClass]="getColumnClass('Business Unit')"
                                *ngIf="getColumnVisibility('Business Unit')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template
                                    >{{ row.businessUnitName }}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                name="Business Owner Email"
                                [width]="110"
                                prop="businessOwnerEmail"
                                [cellClass]="getColumnClass('Business Owner Email')"
                                [headerClass]="getColumnClass('Business Owner Email')"
                                *ngIf="getColumnVisibility('Business Owner Email')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div *ngIf="row.businessOwnerId" class="creator-name-text">
                                        {{ row.businessOwnerEmail }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                name="Business Owner Name"
                                [width]="110"
                                prop="businessOwnerFirstName,businessOwnerLastName"
                                [cellClass]="getColumnClass('Business Owner Name')"
                                [headerClass]="getColumnClass('Business Owner Name')"
                                *ngIf="getColumnVisibility('Business Owner Name')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div *ngIf="row.businessOwnerId" class="creator-name-text">
                                        {{ row.businessOwnerFirstName }} {{ row.businessOwnerLastName }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                name="Third Party Contact Name"
                                [width]="110"
                                prop="primaryContactFirstName,primaryContactLastName"
                                [cellClass]="getColumnClass('Third Party Contact Name')"
                                [headerClass]="getColumnClass('Third Party Contact Name')"
                                *ngIf="getColumnVisibility('Third Party Contact Name')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{
                                        row.primaryContactId
                                            ? row.primaryContactFirstName + ' ' + row.primaryContactLastName
                                            : '-'
                                    }}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                name="Third Party Contact Email"
                                [width]="110"
                                prop="primaryContactEmail"
                                [cellClass]="getColumnClass('Third Party Contact Email')"
                                [headerClass]="getColumnClass('Third Party Contact Email')"
                                *ngIf="getColumnVisibility('Third Party Contact Email')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="primary-contact-text">
                                        {{ row.primaryContactId ? row.primaryContactEmail : '-' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                name="Created By"
                                [width]="110"
                                prop="creatorFirstName"
                                [cellClass]="getColumnClass('Created By')"
                                [headerClass]="getColumnClass('Created By')"
                                *ngIf="getColumnVisibility('Created By')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div *ngIf="row.creatorId" class="creator-name-text">
                                        {{ row.creatorFirstName }} {{ row.creatorLastName }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Created On"
                                [width]="90"
                                prop="createdDate"
                                [cellClass]="getColumnClass('Created On')"
                                [headerClass]="getColumnClass('Created On')"
                                *ngIf="getColumnVisibility('Created On')"
                            >
                                <ng-template let-value="value" ngx-datatable-cell-template
                                    >{{ value | date: 'mediumDate' }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                name="Tags"
                                [width]="150"
                                prop="tags"
                                [cellClass]="'tags-cell ' + getColumnClass('Tags')"
                                [headerClass]="getColumnClass('Tags')"
                                *ngIf="getColumnVisibility('Tags')"
                            >
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    <div *ngIf="value.length === 0">-</div>
                                    <ng-container *collapseCellItems="value; width: tagsColumnWidth; let currentTag">
                                        <span [tagColor]="currentTag?.color">{{ currentTag?.name }}</span>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                name="Tier"
                                [width]="110"
                                prop="vendorTierId"
                                [cellClass]="getColumnClass('Tier')"
                                [headerClass]="getColumnClass('Tier')"
                                *ngIf="getColumnVisibility('Tier')"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{
                                        !!row.vendorTierId
                                            ? getTierName(ctx.tieringVm.allVendorTiers, row.vendorTierId)
                                            : 'Unassigned'
                                    }}
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Data Columns end -->

                            <ngx-datatable-footer>
                                <ng-template
                                    ngx-datatable-footer-template
                                    let-page="page"
                                    let-rowCount="rowCount"
                                    let-pageSize="pageSize"
                                    let-selectedCount="selectedCount"
                                    let-curPage="curPage"
                                    let-offset="offset"
                                >
                                    <datatable-pager
                                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'"
                                        [page]="curPage"
                                        [size]="pageSize"
                                        [count]="rowCount"
                                        [hidden]="!(rowCount / pageSize > 1)"
                                        (change)="setCurrentPage($event)"
                                    ></datatable-pager>
                                    <div
                                        ngbDropdown
                                        class="d-inline-block ms-auto"
                                        placement="top-end"
                                        [hidden]="rowCount < 20"
                                    >
                                        <button mat-button visoButton="secondary" ngbDropdownToggle>
                                            {{ rowLabel }}
                                        </button>
                                        <div ngbDropdownMenu>
                                            <div *ngFor="let op of rowOptions">
                                                <button ngbDropdownItem (click)="setPagination(op.value)">
                                                    {{ op.value }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                } @else if (!relationshipsLoading) {
                    <div class="no-relationships">
                        <img src="content/img/viso_navbar_brand.png" alt="VISO TRUST Logo" />
                        <h3 class="fw-normal mb-0">There's nothing here!</h3>
                        <p class="mb-0">
                            {{
                                noRelationshipsHintText(
                                    !!ctx.selectedTierId
                                        ? ctx.relationshipCounts.byTier.get(ctx.selectedTierId)
                                        : ctx.relationshipCounts.total
                                ) | async
                            }}
                        </p>
                        @if (!!ctx.selectedTierId) {
                            <button
                                class="text-center"
                                mat-flat-button
                                color="primary"
                                (click)="setSelectedTierId(null)"
                            >
                                All Relationships
                            </button>
                        } @else if (ctx.relationshipCounts.total === 0) {
                            <button
                                mat-flat-button
                                color="primary"
                                [routerLink]="['/', { outlets: { popup: [addRelationshipRoute] } }]"
                                [replaceUrl]="true"
                            >
                                <mat-icon>add</mat-icon>
                                Add relationship
                            </button>
                        }
                    </div>
                }
            }
        </mat-tab>
        <mat-tab>
            <app-risk-network
                [graphNodes]="riskNetworkGraphNodes$ | async"
                [currentAccount]="currentAccount$ | async"
                [networkExposureOrgId]="networkExposureOrgId"
                [triggerShow4thParties]="isNthPartiesFilterApplied || !!networkExposureOrgId"
                [isGraphExpanded]="isGraphExpanded$ | async"
                (selectedVendorId)="applyVendorIdFilter($event)"
            ></app-risk-network>
        </mat-tab>
    </mat-tab-group>
}
