<div ng2FileDrop class="dropzone" [uploader]="uploader" [ngClass]="{ 'on-drag': (onDrag$ | async) }">
    <div class="on-drag-area border border-primary text-primary rounded justify-content-center align-items-center">
        <p>Drop files here.</p>
    </div>
    <div mat-dialog-title>
        {{ titleLabel }}
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <p class="full-flex-row" [ngClass]="!!trustCenter ? 'mb-3' : 'mb-0'">
            Select how you would like us to collect information for this assessment.<br />
            Every assessment includes collection and analysis of publicly available information.
        </p>
        <app-disclaimer type="info" *ngIf="!!trustCenter">
            {{ thirdPartyOrgName }} has a trust portal, which may contain private artifacts for business partners.
            Access the artifacts at:
            <a
                class="mat-text-primary text-decoration-underline trust-portal-url"
                [externalLink]="trustCenter.url"
                appMiddleTruncate
                [truncateShowTooltip]="true"
            >
                {{ trustCenter.url }}
            </a>
            @if (trustCenterIsFromSafeBase) {
                <div class="safebase-disclaimer d-flex align-items-center pt-2">
                    <span>SafeBase</span>
                    <div class="ps-2 d-flex align-items-center">
                        <mat-icon svgIcon="safebase" [inline]="true"></mat-icon>
                    </div>
                </div>
            }
        </app-disclaimer>
        <ng-container *ngIf="currentUserAvailableAssessments$ | async as availableAssessments">
            <p *ngIf="doesCurrentUserHaveTrialLicense$ | async" class="full-flex-row trial-info-container p-3 mt-3">
                You have
                <strong
                    >{{
                        availableAssessments === 1
                            ? availableAssessments + ' assessment'
                            : availableAssessments + ' assessments'
                    }}
                    left</strong
                >
                as part of your trial account. Please contact sales&#64;visotrust.com for more information.
            </p>
        </ng-container>

        <form [formGroup]="startAssessmentFormGroup" class="d-flex flex-column mt-3">
            <mat-checkbox
                [formControl]="assessmentInfoTypeFormGroup.controls[AssessmentInfoTypeValue.THIRD_PARTY_INFO]"
                data-pw="thirdPartyAssessmentCheckbox"
                color="primary"
            >
                Designate a third party contact to reach out to
            </mat-checkbox>
            <ng-container *ngIf="assessmentInfoTypeValue[AssessmentInfoTypeValue.THIRD_PARTY_INFO]">
                <div class="selected-option-content">
                    <mat-radio-group
                        class="contact-radio-group"
                        color="primary"
                        [formControl]="useThirdPartyOrOtherRecipientFormControl"
                    >
                        @if (this.hasPrimaryVendorContact) {
                            <mat-radio-button
                                #thirdPartyRadio
                                id="primaryContactRadio"
                                [value]="ThirdPartyOptions.PrimaryVendorContact"
                            >
                                Primary third party contact
                            </mat-radio-button>
                            @if (thirdPartyRadio.checked) {
                                <div class="primary-contact-name">
                                    <div>{{ primaryContactName }}</div>
                                    <div>{{ primaryContactEmail }}</div>
                                </div>
                            }
                        }

                        <!--
                            TODO: After upgrading to Angular 18 we can do this with:
                            @let suggestedContacts = suggestedContacts$ | async
                            @if (suggestedContacts.length) {
                                <ngx-select
                                    [items]="suggestedContacts"
                                    ...
                        -->
                        @if (!!(suggestedContacts$ | async).length) {
                            <mat-radio-button
                                id="vendorDirectoryRadio"
                                data-pw="vendorDirectoryRadio"
                                [value]="ThirdPartyOptions.VendorDirectoryContact"
                                #directoryProfileRadio
                                >Directory profile contact
                            </mat-radio-button>
                            @if (directoryProfileRadio.checked) {
                                <ngx-select
                                    required
                                    class="ps-4 pe-4 ms-md-4"
                                    [formControl]="suggestedContactFormControl"
                                    [items]="suggestedContacts$ | async"
                                    [isFocused]="false"
                                    data-pw="suggestedContactsSelect"
                                    placeholder="Select an existing contact"
                                />
                            }
                        }

                        @if (!this.hasPrimaryVendorContact && !(suggestedContacts$ | async).length) {
                            <ng-container [ngTemplateOutlet]="contactDetailEntry"></ng-container>
                        } @else {
                            <mat-radio-button
                                id="otherRecipientRadio"
                                [value]="ThirdPartyOptions.OtherRecipient"
                                #otherRecipientRadio
                            >
                                Another recipient
                            </mat-radio-button>
                            @if (otherRecipientRadio.checked) {
                                <ng-container [ngTemplateOutlet]="contactDetailEntry"></ng-container>
                            }
                        }

                        <ng-template #contactDetailEntry>
                            <form class="mt-2 pe-4" formGroupName="otherRecipient">
                                <div class="form-group required">
                                    <input
                                        id="firstName"
                                        type="text"
                                        class="form-control"
                                        formControlName="firstName"
                                        required
                                        minlength="2"
                                        placeholder="First Name"
                                        data-pw="firstNameInput"
                                    />
                                    <span class="text-error" *ngIf="showRecipientFirstNameRequiredError"
                                        >This field is required</span
                                    >
                                    <span class="text-error" *ngIf="showRecipientFirstNameMinLengthError"
                                        >Given name must be at least 2 characters long</span
                                    >
                                </div>
                                <div class="form-group required">
                                    <input
                                        id="lastName"
                                        type="text"
                                        class="form-control"
                                        formControlName="lastName"
                                        required
                                        minlength="2"
                                        placeholder="Last Name"
                                        data-pw="lastNameInput"
                                    />
                                    <span class="text-error" *ngIf="showRecipientLastNameRequiredError"
                                        >This field is required</span
                                    >
                                    <span class="text-error" *ngIf="showRecipientLastNameMinLengthError"
                                        >Family name must be at least 2 characters long</span
                                    >
                                </div>
                                <div class="form-group required">
                                    <input
                                        id="email"
                                        type="email"
                                        class="form-control"
                                        formControlName="email"
                                        required
                                        placeholder="Email Address"
                                        data-pw="emailInput"
                                    />
                                    <span class="text-error" *ngIf="showRecipientEmailRequiredError"
                                        >This field is required</span
                                    >
                                    <span class="text-error" *ngIf="showRecipientEmailFormatError"
                                        >Must be a properly formatted email address</span
                                    >
                                </div>
                            </form>
                        </ng-template>
                    </mat-radio-group>

                    <div class="mt-3">
                        <app-followup-type-selection
                            [followupTypeControl]="followupTypeFormControl"
                            [followupRiskThreshold]="followupRiskThresholdControl"
                            [conciergeEnabled]="conciergeAssessmentsEnabled$ | async"
                            [remainingConciergeAssessments]="currentUserRemainingConciergeAssessments$ | async"
                        />
                    </div>

                    <div class="form-group mt-3">
                        <label class="text-bold" for="optionalMessage">Add an optional message</label>
                        <p class="text-normal">
                            If you want to include a personal message in the email we send to the contact, add it below.
                        </p>
                        <textarea
                            rows="4"
                            id="optionalMessage"
                            class="form-control"
                            [formControl]="optionalMessageFormControl"
                            placeholder="Add an optional message"
                            data-pw="optionalMessageInput"
                        ></textarea>
                    </div>
                </div>
            </ng-container>

            <mat-checkbox
                [formControl]="assessmentInfoTypeFormGroup.controls[AssessmentInfoTypeValue.UPLOAD_ARTIFACTS]"
                data-pw="uploadArtifactsCheckbox"
                color="primary"
            >
                Upload artifacts on behalf of {{ thirdPartyOrgName }}
            </mat-checkbox>
            <ng-container *ngIf="assessmentInfoTypeValue[AssessmentInfoTypeValue.UPLOAD_ARTIFACTS]">
                <div class="mt-2 selected-option-content">
                    <p class="d-block mb-2 text-normal">
                        Third party audit artifacts provide a higher level of assurance and are preferred. Certificates
                        alone are insufficient.
                    </p>
                    <div class="mb-2 d-flex align-items-center" [appPopover]="uploadExamples" type="button">
                        <a href="javascript:void(0)" class="me-1 mat-text-primary"
                            >View additional guidance and examples</a
                        >
                        <mat-icon class="mat-text-primary" [inline]="true">info</mat-icon>
                    </div>

                    <ng-template #uploadExamples>
                        <div class="upload-examples">
                            <div class="p-3">
                                <p class="text-bold mb-1">Provide recent third party audit documents such as:</p>
                                <ul class="ps-3 mb-0">
                                    <li>
                                        CSA STAR Level 2 Certification/Attestation Report
                                        <p class="text-muted m-0">
                                            Include associated CAIQ and below mentioned ISO/SOC documents
                                        </p>
                                    </li>
                                    <li>
                                        ISO 27001 Cert and Statement of Applicability
                                        <p class="text-muted m-0">
                                            Provide a surveillance audit if certificates have been issued over 365 days
                                            ago
                                        </p>
                                    </li>
                                    <li>
                                        SOC 2 Type 1/2 Report
                                        <p class="text-muted m-0">
                                            Include a bridge letter if this was issued over 365 days ago
                                        </p>
                                    </li>
                                    <li>PCI DSS Cert and Attestation of Compliance (On-site)</li>
                                    <li>HITRUST Certification/Validation Report</li>
                                </ul>
                            </div>
                            <mat-divider class="my-0" />
                            <div class="p-3">
                                <p class="text-bold">Provide recent third party penetration tests:</p>
                                <ul class="ps-3 mb-0">
                                    <li>External, Internal and Application Penetration Test Reports</li>
                                    <li>Application Security Assessment Reports</li>
                                    <li>Executive summaries or redacted reports are acceptable</li>
                                </ul>
                            </div>
                            <mat-divider class="my-0" />
                            <div class="p-3">
                                <p class="text-bold">Provide alternative attestations:</p>
                                <ul class="ps-3 mb-0">
                                    <li>Published security policies/standards and table of contents</li>
                                    <li>CSA STAR, Shared Assessments, PCI DSS, HITRUST or VSA self assessment</li>
                                    <li>Security Overview Document</li>
                                </ul>
                            </div>
                        </div>
                    </ng-template>
                    <div class="mb-2">
                        <button
                            mat-button
                            visoButton="text"
                            class="full-width p-3 mb-2 drag-drop-message-container"
                            (click)="fileInput.click()"
                        >
                            <span class="text-dark">
                                Drag and Drop or <span class="text-bold text-primary">Browse</span>
                            </span>
                        </button>
                        <input
                            #fileInput
                            type="file"
                            ng2FileSelect
                            multiple
                            class="d-none"
                            [uploader]="uploader"
                            data-pw="uploadArtifactsFileInput"
                        />
                    </div>

                    <div class="artifact-files-container">
                        <ng-container *ngFor="let item of uploader.queue">
                            <app-document-file
                                class="mb-2"
                                [disabled]="uploadingFiles$ | async"
                                [documentFileName]="item?.file?.name"
                                [includePassword]="true"
                                (onPasswordSaved)="setArtifactPassword($event, item)"
                                (onFileRemoved)="removeItem(item)"
                            ></app-document-file>
                        </ng-container>

                        <small class="text-muted text-center d-block" *ngIf="uploader.queue.length === 0">
                            No artifacts uploaded yet
                        </small>
                    </div>
                </div>
            </ng-container>

            <mat-checkbox
                [formControl]="assessmentInfoTypeFormGroup.controls[AssessmentInfoTypeValue.PUBLIC_URLS]"
                data-pw="publicUrlsAssessmentCheckbox"
                color="primary"
            >
                Share one or more URLs that were provided to you by {{ thirdPartyOrgName }}
            </mat-checkbox>
            <ng-container *ngIf="assessmentInfoTypeValue[AssessmentInfoTypeValue.PUBLIC_URLS]">
                <div class="form-group mt-3 selected-option-content">
                    <div class="d-flex">
                        <textarea
                            [formControl]="publicDocUrlFormControl"
                            class="form-control me-3 mw-80"
                            placeholder="Enter one or more URLs"
                            data-pw="publicDocsUrlsInput"
                        >
                        </textarea>
                        <button
                            mat-stroked-button
                            color="primary"
                            class="btn-md align-self-center add-url-button"
                            type="button"
                            [disabled]="addUrlButtonDisabled"
                            (click)="addUrl()"
                            data-pw="addUrlPublicDocsAssessmentButton"
                        >
                            Add URL
                        </button>
                    </div>
                    <span class="text-error full-flex-row" *ngIf="showPublicDocUrlError"
                        >Please provide a valid url</span
                    >
                    <div class="d-flex flew-wrap mt-2">
                        <app-badge
                            *ngFor="let url of publicDocsUrlsFormArray.value; let i = index"
                            [type]="'light-primary'"
                            extraCssClasses="lead me-2"
                        >
                            {{ url }}
                            <em class="fa fa-times ms-1 cursor-pointer" (click)="removeUrl(i)"></em>
                        </app-badge>
                    </div>
                </div>
            </ng-container>

            <div class="assessment-type-separator">
                <hr />
                <span>OR</span>
            </div>

            <mat-checkbox
                [formControl]="assessmentInfoTypeFormGroup.controls[AssessmentInfoTypeValue.CONTINUE_WITH_PUBLIC_INFO]"
                data-pw="otherArtifactAttestationOption"
                color="primary"
            >
                Assess {{ thirdPartyOrgName }} with publicly available information
            </mat-checkbox>

            <div class="mt-3" *jhiHasAnyAuthority="VisoUserRole.Auditor">
                <label class="text-bold">Select an Assessment Creator from Client Org</label>
                <mat-select
                    required
                    class="pl-4 pr-4 ml-md-4"
                    [formControl]="assessmentCreatorFormControl"
                    data-pw="assessmentCreatorSelect"
                    placeholder="Select an assessment creator"
                >
                    @for (potentialCreator of potentialAssessmentCreators$ | async; track potentialCreator.ref.id) {
                        <mat-option [value]="potentialCreator.ref">
                            {{ potentialCreator.name }}
                        </mat-option>
                    }
                </mat-select>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <small
            *ngIf="showSelectAtLeastOneMethodError && startAssessmentFormGroup.dirty"
            class="text-error mb-1 full-flex-row text-end"
        >
            Please select one method in order to start an assessment.
        </small>
        <button mat-stroked-button color="primary" class="me-2" (click)="close()">Cancel</button>
        <button
            mat-flat-button
            color="primary"
            toggleDisableOnActions
            [disabled]="startAssessmentFormGroup.invalid"
            [disablingActions]="[AssessmentActions.StartAssessmentRequest]"
            [enablingActions]="[
                AssessmentActions.StartAssessmentRequestSuccess,
                AssessmentActions.StartAssessmentRequestFailed,
            ]"
            (click)="submit()"
        >
            {{ submitButtonLabel }}
        </button>
    </mat-dialog-actions>
</div>
