<section class="detection" [class.selected]="selected">
    <header class="detection-header">
        <div class="d-flex justify-content-between">
            <h5>
                {{
                    detection.artifactType === ArtifactType.QUESTIONNAIRE_ARTIFACT
                        ? 'Questionnaire response'
                        : 'Artifact detection'
                }}
            </h5>
            <div class="d-flex align-items-center gap-2">
                @if (detection.testResult) {
                    <mat-icon fontSet="material-symbols-outlined" class="test-icon" matTooltip="Tested"
                        >science</mat-icon
                    >
                }
                @if (detection.auditReportAssuranceLevel) {
                    <app-assurance-level-meter
                        [assuranceLevel]="detection.auditReportAssuranceLevel"
                        [hideLabel]="true"
                    />
                }
            </div>
        </div>
        <h4>{{ individualControlName }}</h4>
    </header>

    @if (detection.notes) {
        <article class="detection-body">
            <p [class.multiline]="detection.artifactType === ArtifactType.QUESTIONNAIRE_ARTIFACT">
                "{{ detection.notes }}"
            </p>
        </article>
    }

    <footer class="detection-footer">
        <article class="type">
            <h5>Type</h5>
            @switch (detection.detectionType) {
                @case (DetectionType.NORMAL) {
                    <h4>General</h4>
                }
                @case (DetectionType.EXCEPTION) {
                    <h4>Exception</h4>
                }
                @case (DetectionType.SUBSERVICE) {
                    <h4>Subservicer</h4>
                }
                @case (DetectionType.SHARED_RESPONSIBILITY_MODEL) {
                    <h4>Shared Responsibility</h4>
                }
                @case (DetectionType.CUEC) {
                    <h4>CUEC</h4>
                }
            }
        </article>

        <article class="location">
            <h5>Location</h5>
            <div class="d-flex align-items-center">
                @for (page of detection.viewerPage | callback: getPages; track page; let isLast = $last) {
                    <app-artifact-page-link
                        [detection]="detection"
                        [viewType]="ArtifactPageLinkViewType.locationLink"
                        [viewerPage]="page"
                        [isLast]="isLast"
                        (click)="$event.stopPropagation()"
                    />
                    @if (!isLast) {
                        <span>, </span>
                    }
                }
                @if (detection.sectionName) {
                    <span>; {{ detection.sectionName }}</span>
                }
                @if (!detection.viewerPage && !detection.sectionName) {
                    <span>–</span>
                }
            </div>
        </article>

        <article class="artifact-type">
            <h5>Artifact Type</h5>

            @if (
                detection.artifactType === ArtifactType.FILE_ARTIFACT ||
                detection.artifactType === ArtifactType.URL_ARTIFACT
            ) {
                <h4>{{ AuditReportType[detection.auditReportType] || '–' }}</h4>
            } @else if (detection.artifactType === ArtifactType.QUESTIONNAIRE_ARTIFACT) {
                <h4>Questionnaire</h4>
            }
        </article>

        <article class="artifact">
            <h5>
                Artifact
                <em *ngIf="detection.artifactExpired" class="fa fa-exclamation-circle text-danger me-1"></em>
            </h5>
            <app-artifact-page-link
                [detection]="detection"
                [viewType]="ArtifactPageLinkViewType.artifactLink"
                (click)="$event.stopPropagation()"
            />
        </article>
    </footer>
</section>
