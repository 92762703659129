/**
 *  To hide a menu item for a particular role any role name can be added to the item.role array
 *  with a "!" before the name. For example roles: ['!VisoUserRole.OrgAdmin'] will hide the item even
 *  if other user roles are included in the array.
 */

import { VisoUserRole } from '../../entities/viso-user';
import { FeatureFlags } from '../../shared/enums/feature-flags';

export enum Environments {
    LOCAL = 'local',
    DEV = 'dev',
    DEMO = 'demo',
    PROD = 'prod',
}

export interface BaseMenu {
    text: string;
    icon: string;
    heading: boolean;
    link: string;
    target: string;
    elink: string;
    submenu: Partial<Submenu>[];
    isBeta?: boolean;
    isCollapsed: boolean;
    button: boolean;
    buttonColor: string;
    popup: boolean;
    roles?: (VisoUserRole | string)[];
    disableOnTrialOptions?: DisableOnTrialOptions;
    environments?: Environments[];
    featureFlag?: string;
}

export interface Submenu {
    text: string;
    link: string;
    disableOnTrialOptions?: DisableOnTrialOptions;
    featureFlag?: FeatureFlags;
    isBeta?: boolean;
    environments?: Environments[];
}

interface DisableOnTrialOptions {
    popoverTitle: string;
    popoverBody: string;
    popoverIcon?: string;
    popoverImg?: string;
    popoverClass?: string;
}

export interface HeadingMenu extends Pick<BaseMenu, 'text' | 'heading' | 'roles'> {
    heading: true;
}

export interface LinkMenu
    extends Pick<BaseMenu, 'text' | 'icon' | 'link' | 'roles' | 'disableOnTrialOptions' | 'environments'> {}

export interface MenuWithSubmenus
    extends Pick<
        BaseMenu,
        'text' | 'icon' | 'submenu' | 'isBeta' | 'isCollapsed' | 'roles' | 'environments' | 'featureFlag'
    > {}

export interface ExternalLinkMenu
    extends Pick<BaseMenu, 'text' | 'icon' | 'roles' | 'disableOnTrialOptions' | 'environments' | 'elink'> {}

export interface PopupButtonMenu
    extends Pick<BaseMenu, 'text' | 'icon' | 'link' | 'roles' | 'button' | 'buttonColor' | 'popup'> {
    popup: true;
}

export type Menu = HeadingMenu | LinkMenu | PopupButtonMenu | MenuWithSubmenus | ExternalLinkMenu;

const ThirdParties: MenuWithSubmenus = {
    text: 'Third Parties',
    icon: 'view_list',
    isCollapsed: false,
    submenu: [
        {
            text: 'Relationships',
            link: '/relationships',
        },
        {
            text: 'Assessments',
            link: '/assessments',
        },
        {
            text: 'Directory',
            link: '/directory',
        },
    ],
    roles: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.ReadOnly],
};

const RiskAdvisory: LinkMenu = {
    text: 'Risk Advisories',
    link: '/risk-advisories',
    icon: 'gpp_bad',
    roles: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.ReadOnly, VisoUserRole.Auditor],
};

const Relationships: LinkMenu = {
    text: 'Relationships',
    link: '/relationships',
    icon: 'view_list',
    roles: [
        VisoUserRole.Auditor,
        VisoUserRole.Support,
        `!${VisoUserRole.OrgAdmin}`,
        `!${VisoUserRole.Admin}`,
        `!${VisoUserRole.OrgUser}`,
        `!${VisoUserRole.ReadOnly}`,
    ],
};

const Directory: LinkMenu = {
    text: 'Directory',
    link: '/directory',
    icon: 'manage_search',
    roles: [
        VisoUserRole.Auditor,
        VisoUserRole.Support,
        VisoUserRole.Admin,
        `!${VisoUserRole.OrgAdmin}`,
        `!${VisoUserRole.OrgUser}`,
        `!${VisoUserRole.ReadOnly}`,
    ],
};

const MetabaseDashboard: LinkMenu = {
    text: 'Risk Insights',
    link: '/insights',
    icon: 'insights',
    roles: [VisoUserRole.OrgAdmin, VisoUserRole.ReadOnly, VisoUserRole.OrgUser],
    disableOnTrialOptions: {
        popoverTitle: 'Risk Insights is a premium feature.',
        popoverIcon: 'fas fa-chart-bar',
        popoverBody:
            'Analyze and compare the risk exposure across your vendor ecosystem over time using our data-driven insights dashboard.',
        popoverImg: 'content/img/risk-insights-locked-feature.png',
    },
};

const Trust: MenuWithSubmenus = {
    text: 'Trust',
    icon: 'verified_user',
    isBeta: false,
    isCollapsed: true,
    submenu: [
        {
            text: 'Artifact Intelligence',
            link: 'artifact-intelligence',
            featureFlag: FeatureFlags.TRUST,
        },
        {
            text: 'Questionnaires',
            link: 'intelligent-questionnaire-response',
            featureFlag: FeatureFlags.TRUST,
        },
        {
            text: 'AI Q&A',
            link: 'ai-question-and-answer',
            featureFlag: FeatureFlags.TRUST,
        },
    ],
    roles: [VisoUserRole.OrgAdmin],
    featureFlag: FeatureFlags.TRUST,
};

const Settings: MenuWithSubmenus = {
    text: 'Settings',
    icon: 'settings',
    isCollapsed: true,
    submenu: [
        {
            text: 'Org Profile',
            link: '/your-organization',
        },
        {
            text: 'Questionnaires',
            link: '/questionnaires',
            isBeta: false,
            featureFlag: FeatureFlags.SUPPLEMENTAL_QUESTIONNAIRES,
        },
        {
            text: 'Assessment Summary',
            link: '/assessment-summary',
        },
        {
            text: 'Business Units',
            link: '/business-units',
        },
        {
            text: 'Users',
            link: '/users',
        },
        {
            text: 'Tags',
            link: '/tags',
        },
        {
            text: 'Risk Model',
            link: '/risk-model-settings',
            featureFlag: FeatureFlags.RISK_TOLERANCE,
        },
        {
            text: 'Import Relationships',
            link: '/import-relationships',
            disableOnTrialOptions: {
                popoverTitle: 'Importing relationships is a premium feature.',
                popoverIcon: 'fa fa-file-import',
                popoverBody:
                    'Import new or existing relationships in one go using our easy-to-use template or Client API.',
            },
        },
        {
            text: 'Integrations',
            link: '/integrations',
        },
    ],
    roles: [
        VisoUserRole.OrgAdmin,
        `!${VisoUserRole.Auditor}`,
        `!${VisoUserRole.Admin}`,
        `!${VisoUserRole.Support}`,
        `!${VisoUserRole.OrgUser}`,
        `!${VisoUserRole.ReadOnly}`,
    ],
};

const OrgUserSettings: MenuWithSubmenus = {
    text: 'Settings',
    icon: 'settings',
    isCollapsed: true,
    submenu: [
        {
            text: 'Org Profile',
            link: '/your-organization',
        },
        {
            text: 'Questionnaires',
            link: '/questionnaires',
            isBeta: true,
            featureFlag: FeatureFlags.SUPPLEMENTAL_QUESTIONNAIRES,
        },
        {
            text: 'Assessment Summary',
            link: '/assessment-summary',
        },
        {
            text: 'Business Units',
            link: '/business-units',
        },
        {
            text: 'Users',
            link: '/users',
        },
        {
            text: 'Tags',
            link: '/tags',
        },
        {
            text: 'Risk Model',
            link: '/risk-model-settings',
            featureFlag: FeatureFlags.RISK_TOLERANCE,
        },
    ],
    roles: [VisoUserRole.OrgUser, VisoUserRole.ReadOnly],
};

const Glossary: MenuWithSubmenus = {
    text: 'Glossary',
    icon: 'menu_book',
    isCollapsed: true,
    submenu: [
        {
            text: 'Artifact Types',
            link: '/artifact-types',
        },
        {
            text: 'Control Domains',
            link: '/controls',
        },
        {
            text: 'Data Types',
            link: '/data-types',
        },
    ],
    roles: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.ReadOnly, VisoUserRole.Auditor],
};

const headingMain: HeadingMenu = {
    text: ' ',
    heading: true,
};

const headingAdmin: HeadingMenu = {
    text: 'VISO Admin',
    heading: true,
    roles: [VisoUserRole.Admin, VisoUserRole.Support],
};

const ClientAdmin: MenuWithSubmenus = {
    text: 'Client Admin',
    icon: 'rocket',
    isCollapsed: false,
    roles: [VisoUserRole.Admin],
    submenu: [
        {
            text: 'Client Profile',
            link: 'admin/client-profile',
        },
        {
            text: 'Client Offboarding',
            link: 'admin/client-offboarding',
        },
        {
            text: 'Demo Tools',
            link: 'admin/demo-tools',
        },
        {
            text: 'License Management',
            link: 'admin/license-management',
        },
        {
            text: 'Risk Model',
            link: 'admin/risk-model',
        },
        {
            text: 'Recommendations',
            link: 'admin/assessment-recommendation',
        },
        {
            text: 'Webhooks',
            link: 'admin/webhooks',
        },
        {
            text: 'Jwt Management',
            link: 'admin/jwt-management',
        },
    ],
};

const SupportAdmin: MenuWithSubmenus = {
    text: 'Support Admin',
    icon: 'rocket',
    isCollapsed: false,
    roles: [VisoUserRole.Support],
    submenu: [
        {
            text: 'Client Profile',
            link: 'admin/client-profile',
        },
        {
            text: 'Client Offboarding',
            link: 'admin/client-offboarding',
        },
        {
            text: 'License Management',
            link: 'admin/license-management',
        },
        {
            text: 'Webhooks',
            link: 'admin/webhooks',
        },
        {
            text: 'Feature Flags',
            link: 'admin/feature-flags',
        },
        {
            text: 'Link Notifications',
            link: 'admin/notifications',
        },
    ],
};

const SiteAdmin: MenuWithSubmenus = {
    text: 'Site Admin',
    icon: 'settings_applications',
    isCollapsed: false,
    roles: [VisoUserRole.Admin],
    submenu: [
        {
            text: 'Link Notifications',
            link: 'admin/notifications',
        },
        {
            text: 'Announcements',
            link: 'admin/announcements',
        },
        {
            text: 'ES Index Management',
            link: 'admin/es-index',
        },
        {
            text: 'Artifact Management',
            link: 'admin/artifact-management',
        },
        {
            text: 'Organization Import',
            link: 'admin/org-import',
        },
        {
            text: 'Public Info Search',
            link: 'admin/public-search',
        },
        {
            text: 'Logs',
            link: 'admin/logs',
        },
        {
            text: 'Feature Flags',
            link: 'admin/feature-flags',
        },
        {
            text: 'Hubspot Management',
            link: 'admin/hubspot-management',
        },
    ],
};

export const menu: Menu[] = [
    headingMain,
    ThirdParties,
    RiskAdvisory,
    Relationships,
    Directory,
    MetabaseDashboard,
    Trust,
    Settings,
    OrgUserSettings,
    Glossary,
    headingAdmin,
    ClientAdmin,
    SiteAdmin,
    SupportAdmin,
];
