import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AuditReport } from '@entities/audit-report';
import { AuditorCancelReason, CollectingInformationReason } from '@entities/assessment';

export enum CommonAssessmentDetailsActions {
    LoadAssessment = '[Common Assessment Details] Load Assessment',
    LoadEmails = '[Common Assessment Details] Load Emails',
    LoadArtifacts = '[Common Assessment Details] Load Artifacts',
    LoadControls = '[Common Assessment Details] Load Controls',
    LoadSecurityControlDomains = '[Common Assessment Details] Load Security Control Domains',
    LoadArtifactSupersession = '[Common Assessment Details] Load Artifact Supersession',
    LoadPreSummary = '[Common Assessment Details] Load Pre Summary',
    LoadRecommendations = '[Common Assessment Details] Load Recommendations',
    LoadPrimaryContact = '[Common Assessment Details] Load Primary Contact',
    MarkAssessmentAsReviewStarted = '[Common Assessment Details] Mark Assessment As Review Started',
    MarkAssessmentAsCancelled = '[Common Assessment Details] Mark Assessment As Cancelled',
    MarkAssessmentAsCollectingInformation = '[Common Assessment Details] Mark Assessment As Collecting Information',
    CancelAssessment = '[Common Assessment Details] Cancel Assessment',
    CompleteAssessmentAudit = '[Common Assessment Details] Complete Assessment Audit',
    UndoCancel = '[Common Assessment Details] Undo Cancel',
    SendReminderEmail = '[Common Assessment Details] Send Reminder Email',
    DeleteArtifact = '[Common Assessment Details] Delete Artifact',
    UpdateArtifactSupersession = '[Common Assessment Details] Update Artifact Supersession',
    DownloadArtifacts = '[Common Assessment Details] Download Artifacts',
    DeleteAssessment = '[Common Assessment Details] Delete Assessment',
    BypassFollowupAndComplete = '[Common Assessment Details] Bypass Followup And Complete',

    GetAuditReportsRequest = '[Common Assessment Details] Get Audit Reports Request',
    GetAuditReportsRequestSuccess = '[Common Assessment Details] Get Audit Reports Request Success',
    GetAuditReportsRequestFailed = '[Common Assessment Details] Get Audit Reports Request Failed',

    DownloadSupplementalQuestionnaireRequest = '[Common Assessment Details] Download Supplemental Questionnaire Request',
    DownloadSupplementalQuestionnaireRequestFailed = '[Common Assessment Details] Download Supplemental Questionnaire Request Failed',

    ReprocessSupplementalQuestionnaireRequest = '[Common Assessment Details] Reprocess Supplemental Questionnaire Request',
    ReprocessSupplementalQuestionnaireRequestSuccess = '[Common Assessment Details] Reprocess Supplemental Questionnaire Request Success',
    ReprocessSupplementalQuestionnaireRequestFailed = '[Common Assessment Details] Reprocess Supplemental Questionnaire Request Failed',

    GetRiskOutput = '[Common Assessment Details] Get Risk Output',
}

export const loadAssessment = createAction(CommonAssessmentDetailsActions.LoadAssessment);

export const loadEmails = createAction(CommonAssessmentDetailsActions.LoadEmails);

export const loadArtifacts = createAction(CommonAssessmentDetailsActions.LoadArtifacts);

export const loadControls = createAction(CommonAssessmentDetailsActions.LoadControls);

export const loadSecurityControlDomain = createAction(CommonAssessmentDetailsActions.LoadSecurityControlDomains);

export const loadArtifactSupersession = createAction(CommonAssessmentDetailsActions.LoadArtifactSupersession);

export const loadPreSummary = createAction(CommonAssessmentDetailsActions.LoadPreSummary);

export const loadRecommendations = createAction(CommonAssessmentDetailsActions.LoadRecommendations);

export const loadPrimaryContact = createAction(CommonAssessmentDetailsActions.LoadPrimaryContact);

export const markAssessmentAsReviewStarted = createAction(CommonAssessmentDetailsActions.MarkAssessmentAsReviewStarted);

export const bypassFollowupAndComplete = createAction(CommonAssessmentDetailsActions.BypassFollowupAndComplete);

export const markAssessmentAsCancelled = createAction(
    CommonAssessmentDetailsActions.MarkAssessmentAsCancelled,
    props<{ reason: AuditorCancelReason }>(),
);

export const markAssessmentAsCollectingInformation = createAction(
    CommonAssessmentDetailsActions.MarkAssessmentAsCollectingInformation,
    props<{ reason: CollectingInformationReason }>(),
);

export const cancelAssessment = createAction(CommonAssessmentDetailsActions.CancelAssessment);

export const completeAssessmentAudit = createAction(CommonAssessmentDetailsActions.CompleteAssessmentAudit);

export const undoCancel = createAction(CommonAssessmentDetailsActions.UndoCancel);

export const sendReminderEmail = createAction(CommonAssessmentDetailsActions.SendReminderEmail);

export const deleteArtifact = createAction(
    CommonAssessmentDetailsActions.DeleteArtifact,
    props<{ artifactId: number }>(),
);

export const updateArtifactSupersession = createAction(
    CommonAssessmentDetailsActions.UpdateArtifactSupersession,
    props<{ artifactId: number; artifactIdToSupersede: number }>(),
);

export const downloadArtifacts = createAction(CommonAssessmentDetailsActions.DownloadArtifacts);

export const getAuditReportsRequest = createAction(CommonAssessmentDetailsActions.GetAuditReportsRequest);

export const getAuditReportsRequestSuccess = createAction(
    CommonAssessmentDetailsActions.GetAuditReportsRequestSuccess,
    props<{ auditReports: AuditReport[] }>(),
);

export const getAuditReportsRequestFailed = createAction(
    CommonAssessmentDetailsActions.GetAuditReportsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteAssessment = createAction(CommonAssessmentDetailsActions.DeleteAssessment);

export const getRiskOutput = createAction(CommonAssessmentDetailsActions.GetRiskOutput);

export const downloadSupplementalQuestionnaireRequest = createAction(
    CommonAssessmentDetailsActions.DownloadSupplementalQuestionnaireRequest,
    props<{ relationshipId: number; suppQId: string }>(),
);

export const downloadSupplementalQuestionnaireRequestFailed = createAction(
    CommonAssessmentDetailsActions.DownloadSupplementalQuestionnaireRequestFailed,
);

export const reprocessSupplementalQuestionnaireRequest = createAction(
    CommonAssessmentDetailsActions.ReprocessSupplementalQuestionnaireRequest,
    props<{ relationshipId: number; suppQId: string }>(),
);

export const reprocessSupplementalQuestionnaireRequestSuccess = createAction(
    CommonAssessmentDetailsActions.ReprocessSupplementalQuestionnaireRequestSuccess,
    props<{ artifactsToReprocess: number }>(),
);

export const reprocessSupplementalQuestionnaireRequestFailed = createAction(
    CommonAssessmentDetailsActions.ReprocessSupplementalQuestionnaireRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
