<mat-card class="card-container">
    <mat-card-header class="mb-4 d-block">
        <h4>Compliance</h4>
        <p>{{ vendorName }} has provided evidence that they adhere to the following compliance standards.</p>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col">
                <div class="compliance-container">
                    <mat-card
                        class="compliance-card d-flex flex-row align-items-center mb-3 me-3"
                        [appearance]="'outlined'"
                        *ngFor="let standard of complianceStandards"
                    >
                        <mat-card-content class="w-100">
                            <img
                                mat-card-image
                                [ngClass]="{
                                    'hitrust-badge': standard === ComplianceStandard.HITRUST,
                                    'compliance-full-size-badge': complianceStandardsFullSize.includes(standard),
                                }"
                                class="compliance-icon me-3"
                                [appImageFallback]="badgeImageFallback"
                                onImageErrorCssClass="no-compliance-badge"
                                [src]="standard | callback: getComplianceIcon"
                                alt="Compliance Badge"
                                height="60"
                                width="60"
                            />
                            <span>{{ ComplianceStandardLabels[standard] }}</span>
                        </mat-card-content>
                    </mat-card>

                    @if (showLinks) {
                        @for (link of links; track $index) {
                            @if (!!link.url) {
                                <mat-card
                                    class="compliance-card d-flex flex-row align-items-center mb-3 me-3"
                                    [appearance]="'outlined'"
                                >
                                    <mat-card-content
                                        class="d-flex justify-content-between align-items-center w-100 text-primary"
                                    >
                                        <strong>{{ link.name }}</strong>
                                        <a [externalLink]="link.url" target="_blank" class="d-flex align-items-center">
                                            <mat-icon>open_in_new</mat-icon>
                                        </a>
                                    </mat-card-content>
                                </mat-card>
                            }
                        }
                    }
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
