import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { INgxSelectOption, NgxSelectComponent } from 'ngx-select-ex';
import {
    getBusinessUnitsRequest,
    getBusinessUnitsRequestSuccess,
} from '../../business-unit-management/redux/business-unit-management.actions';
import { AppState } from '../../../shared/redux/state';
import {
    clearAllFilters,
    orgClientsQueryRequest,
    orgClientsQuerySuccess,
    setAdditionalFiltersState,
    visoUsersQueryRequest,
    visoUsersQuerySuccess,
} from '../redux/relationships.actions';
import { VisoUser, VisoUserRole } from '../../../entities/viso-user';
import { AdditionalFilters } from '../relationships.constants';
import { getRelationshipsAdditionalFiltersState } from '../redux/relationships.selectors';
import { RelationshipAdditionalFilters } from '../models/relationship-additional-filters.model';
import { Org } from '../../../entities/org';
import { getUserAccount } from '../../session/redux/session.selectors';
import { DateUtilsService } from '../../../shared/utils/date-utils.service';
import { BusinessUnit } from '../../../entities/business-unit';
import {
    getContextTypesQueryRequest,
    getContextTypesQueryRequestSuccess,
} from '../../context-types/redux/context-types.actions';
import { getDataTypesQueryRequest, getDataTypesQueryRequestSuccess } from '../../data-types/redux/data-types.actions';
import { ContextType } from '../../../entities/context-type';
import { DataType } from '../../../entities/data-type';
import {
    searchOrganizationsRequest,
    searchOrganizationsRequestSuccess,
} from '../../request/redux/actions/organization.actions';
import { Keycodes } from '../../../shared/model/key-codes';
import { TagColorEnum } from '../../../entities/tag';
import { CompleteVendorSearchResult } from '../../../shared/vendor-components/models/vendor-search-result';
import moment from 'moment';
import { IMAGE_FALLBACK_BASE64 } from '../../../shared/image-fallback/image-fallback.constants';

@Component({
    selector: 'relationships-additional-filters-panel',
    templateUrl: './additional-filters-panel.component.html',
    styleUrls: ['./additional-filters-panel.component.scss'],
})
export class RelationshipsAdditionalFiltersPanelComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() openPanelButton: MatButton;
    @Output() filtersApplied = new EventEmitter<any>();

    @ViewChild('filterPanel') filterPanel: ElementRef;
    @ViewChild('thirdPartySearch') thirdPartySelectComponent: NgxSelectComponent;
    @ViewChild('nthPartySearch') nthPartySelectComponent: NgxSelectComponent;

    Roles = VisoUserRole;
    TagColor = TagColorEnum;

    readonly vendorImageFallback: string = IMAGE_FALLBACK_BASE64;

    additionalFiltersForm: UntypedFormGroup;
    showFilterPanel: boolean;
    todayDate: string;

    phaseDateValid: boolean = true;
    recertificationDateValid: boolean = true;
    lastUpdatedValid: boolean = true;
    createdOnValid: boolean = true;
    validationMessage = 'Start date can not be greater than end date.';

    businessUnits$: Observable<BusinessUnit[]>;
    contextTypes$: Observable<ContextType[]>;
    dataTypes$: Observable<DataType[]>;
    visoUsers$: Observable<VisoUser[]>;
    clients$: Observable<Org[]>;
    vendorOrgs$: Observable<CompleteVendorSearchResult[]>;
    nthParties$: Observable<CompleteVendorSearchResult[]>;

    thirdPartiesComponentId = 'thirdPartyComponentId';
    nthPartiesComponentId = 'nthPartyComponentId';

    datePickerOpen = false;

    private _unsub: Subject<void> = new Subject<void>();
    private _dateFormat = 'yyyy-MM-dd';

    recertificationTypesList = [
        { label: 'Automatic', value: 'AUTOMATIC' },
        { label: 'Reminders Only', value: 'MANUAL' },
        { label: 'None', value: 'NONE' },
    ];

    licenseTypesList = [
        { label: 'Enterprise', value: 'ENTERPRISE' },
        { label: 'Trial', value: 'TRIAL' },
        { label: 'Prod Trial', value: 'PROD_TRIAL' },
        { label: 'Starter', value: 'STARTER' },
    ];

    constructor(
        private _dateUtilsService: DateUtilsService,
        private _formBuilder: UntypedFormBuilder,
        private _store$: Store<AppState>,
        private _actions$: Actions,
    ) {}

    get areAdditionalFiltersApplied(): boolean {
        return (
            this.additionalFiltersForm.controls.startPhaseDate.value ||
            this.additionalFiltersForm.controls.endPhaseDate.value ||
            this.additionalFiltersForm.controls.startRecertificationDate.value ||
            this.additionalFiltersForm.controls.endRecertificationDate.value ||
            this.additionalFiltersForm.controls.businessUnit.value?.length ||
            this.additionalFiltersForm.controls.createdBy.value?.length ||
            this.additionalFiltersForm.controls.recertificationType.value?.length ||
            this.additionalFiltersForm.controls.businessOwner.value?.length ||
            this.additionalFiltersForm.controls.startLastUpdated.value ||
            this.additionalFiltersForm.controls.endLastUpdated.value ||
            this.additionalFiltersForm.controls.startCreatedOn.value ||
            this.additionalFiltersForm.controls.endCreatedOn.value ||
            this.additionalFiltersForm.controls.client.value?.length ||
            this.additionalFiltersForm.controls.contextTypes.value?.length ||
            this.additionalFiltersForm.controls.dataTypes.value?.length ||
            this.additionalFiltersForm.controls.hasCompleted.value ||
            this.additionalFiltersForm.controls.assessed.value ||
            this.additionalFiltersForm.controls.recertificationState.value ||
            this.additionalFiltersForm.controls.vendorOrgs.value?.length ||
            this.additionalFiltersForm.controls.licenseType.value?.length ||
            this.additionalFiltersForm.controls.artifactUpdateType.value ||
            this.additionalFiltersForm.controls.remediationRequested.value ||
            this.additionalFiltersForm.controls.riskAccepted.value
        );
    }

    ngOnInit(): void {
        this.todayDate = this._dateUtilsService.formatDate(new Date(), this._dateFormat);

        this.additionalFiltersForm = this._formBuilder.group({
            startPhaseDate: [],
            endPhaseDate: [],
            startRecertificationDate: [],
            endRecertificationDate: [],
            businessUnit: [[]],
            createdBy: [[]],
            recertificationType: [],
            businessOwner: [[]],
            startLastUpdated: [],
            endLastUpdated: [],
            startCreatedOn: [],
            endCreatedOn: [],
            client: [[]],
            contextTypes: [[]],
            dataTypes: [[]],
            hasCompleted: [],
            assessed: [],
            recertificationState: [],
            vendorOrgs: [[]],
            nthParties: [[]],
            licenseType: [],
            artifactUpdateType: [],
            remediationRequested: [],
            riskAccepted: [],
        });

        this._store$.pipe(select(getUserAccount), takeUntil(this._unsub)).subscribe((account) => {
            if (
                account?.authorities?.includes(this.Roles.Auditor) ||
                account?.authorities?.includes(this.Roles.Support)
            )
                this._store$.dispatch(orgClientsQueryRequest());
        });
        this._store$.dispatch(getBusinessUnitsRequest(null));
        this._store$.dispatch(getContextTypesQueryRequest());
        this._store$.dispatch(getDataTypesQueryRequest({ enabled: true }));
        this._store$.dispatch(visoUsersQueryRequest());

        this.businessUnits$ = this._actions$.pipe(
            ofType(getBusinessUnitsRequestSuccess),
            map((r) =>
                r.businessUnits.sort((a: BusinessUnit, b: BusinessUnit) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }),
            ),
        );

        this.contextTypes$ = this._actions$.pipe(
            ofType(getContextTypesQueryRequestSuccess),
            map((r) =>
                r.contextTypes.sort((a: ContextType, b: ContextType) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }),
            ),
        );

        this.dataTypes$ = this._actions$.pipe(
            ofType(getDataTypesQueryRequestSuccess),
            map((r) =>
                r.results.sort((a: DataType, b: DataType) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }),
            ),
        );

        this.visoUsers$ = this._actions$.pipe(
            ofType(visoUsersQuerySuccess),
            map((r) =>
                r.results.sort((a: VisoUser, b: VisoUser) => {
                    if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }),
            ),
        );
        this.clients$ = this._actions$.pipe(
            ofType(orgClientsQuerySuccess),
            map((r) => r.results),
        );

        this._actions$.pipe(ofType(clearAllFilters), takeUntil(this._unsub)).subscribe(() => {
            this.clearFilters();
            this.applyFilters(true);
        });

        this._store$
            .pipe(select(getRelationshipsAdditionalFiltersState), takeUntil(this._unsub))
            .subscribe((filters) => {
                if (filters) this.setAdditionalFilters(filters);
            });

        this.vendorOrgs$ = this._actions$.pipe(
            ofType(searchOrganizationsRequestSuccess),
            filter(({ componentId }) => componentId === this.thirdPartiesComponentId),
            map(({ organizations }) => organizations),
        );

        this.nthParties$ = this._actions$.pipe(
            ofType(searchOrganizationsRequestSuccess),
            filter(({ componentId }) => componentId === this.nthPartiesComponentId),
            map(({ organizations }) => organizations),
        );
    }

    ngAfterViewInit(): void {
        fromEvent(window, 'click')
            .pipe(
                map((e: MouseEvent) => {
                    let isClickInside = this.filterPanel?.nativeElement.contains(e.target);
                    let isFilterButton = this.openPanelButton
                        ? this.openPanelButton._elementRef.nativeElement.contains(e.target as Node)
                        : false;
                    if (!isClickInside && !isFilterButton && this.showFilterPanel && !this.datePickerOpen) {
                        this.showFilterPanel = false;
                    }
                }),
                takeUntil(this._unsub),
            )
            .subscribe();
    }

    setFiltersAndApply(filterName: keyof RelationshipAdditionalFilters, value: any): void {
        this.additionalFiltersForm.controls[filterName].setValue(value);
        this.applyFilters();
    }

    searchForVendor(searchText: string, componentId: string) {
        this._store$.dispatch(searchOrganizationsRequest({ searchText, componentId }));
    }

    onVendorNameEntered(event: any, componentId: string): void {
        if (![Keycodes.LeftArrow, Keycodes.RightArrow, Keycodes.UpArrow, Keycodes.DownArrow].includes(event.keyCode)) {
            const value = event.target.value;

            if (value) {
                this.searchForVendor(value, componentId);
            }
        }
    }

    toggleFilterPanel(): void {
        this.showFilterPanel = !this.showFilterPanel;
    }

    clearFilters(): void {
        this.thirdPartySelectComponent.optionsSelected.forEach((r) =>
            this.thirdPartySelectComponent.optionRemove(r, new Event('clear')),
        );
        this.nthPartySelectComponent.optionsSelected.forEach((r) =>
            this.nthPartySelectComponent.optionRemove(r, new Event('clear')),
        );
        this.additionalFiltersForm.setValue({
            startPhaseDate: '',
            endPhaseDate: '',
            startRecertificationDate: '',
            endRecertificationDate: '',
            recertificationType: '',
            businessUnit: [],
            createdBy: [],
            businessOwner: [],
            startLastUpdated: '',
            endLastUpdated: '',
            startCreatedOn: '',
            endCreatedOn: '',
            client: [],
            contextTypes: [],
            dataTypes: [],
            hasCompleted: null,
            assessed: null,
            recertificationState: null,
            vendorOrgs: [],
            nthParties: [],
            licenseType: [],
            artifactUpdateType: '',
            remediationRequested: null,
            riskAccepted: null,
        });
    }

    validateFilters() {
        this.phaseDateValid = this.validateDates(AdditionalFilters.startPhaseDate, AdditionalFilters.endPhaseDate);
        this.recertificationDateValid = this.validateDates(
            AdditionalFilters.startRecertificationDate,
            AdditionalFilters.endRecertificationDate,
        );
        this.lastUpdatedValid = this.validateDates(
            AdditionalFilters.startLastUpdated,
            AdditionalFilters.endLastUpdated,
        );
        this.createdOnValid = this.validateDates(AdditionalFilters.startCreatedOn, AdditionalFilters.endCreatedOn);

        return this.phaseDateValid && this.recertificationDateValid && this.lastUpdatedValid && this.createdOnValid;
    }

    applyFilters(clearFilters = false) {
        if (!this.validateFilters()) return;

        const filterToBeApplied = {
            filters: {
                assessmentCompletedDate: this.getFormDateValues(
                    AdditionalFilters.startPhaseDate,
                    AdditionalFilters.endPhaseDate,
                ),
                recertificationDate: this.getFormDateValues(
                    AdditionalFilters.startRecertificationDate,
                    AdditionalFilters.endRecertificationDate,
                ),
                recertificationType: this.additionalFiltersForm.controls.recertificationType.value,
                businessUnitId: this.additionalFiltersForm.controls.businessUnit.value,
                creatorId: this.additionalFiltersForm.controls.createdBy.value,
                businessOwnerId: this.additionalFiltersForm.controls.businessOwner.value,
                updatedDate: this.getFormDateValues(
                    AdditionalFilters.startLastUpdated,
                    AdditionalFilters.endLastUpdated,
                ),
                createdDate: this.getFormDateValues(AdditionalFilters.startCreatedOn, AdditionalFilters.endCreatedOn),
                customerOrgId: this.additionalFiltersForm.controls.client.value,
                contextTypes: this.additionalFiltersForm.controls.contextTypes.value,
                dataTypes: this.additionalFiltersForm.controls.dataTypes.value,
                hasCompleted: this.additionalFiltersForm.controls.hasCompleted.value,
                assessed: this.additionalFiltersForm.controls.assessed.value,
                overdueRecertification:
                    this.additionalFiltersForm.controls.recertificationState.value === 'overdueRecertification' || null,
                upcomingRecertification:
                    this.additionalFiltersForm.controls.recertificationState.value === 'upcomingRecertification' ||
                    null,
                artifactUpdateType: this.additionalFiltersForm.controls.artifactUpdateType.value,
                vendorOrgId: this.additionalFiltersForm.controls.vendorOrgs.value,
                nthParties: this.additionalFiltersForm.controls.nthParties.value,
                licenseType: this.additionalFiltersForm.controls.licenseType.value,
                remediationRequested: this.additionalFiltersForm.controls.remediationRequested.value,
                riskAccepted: this.additionalFiltersForm.controls.riskAccepted.value,
            },
            additionalFiltersApplied: !!this.areAdditionalFiltersApplied,
            clearFilters,
        };

        this.filtersApplied.emit(filterToBeApplied);
        this.setAdditionalFiltersToState(filterToBeApplied);
        this.toggleFilterPanel();
    }

    setAdditionalFilters(filters: RelationshipAdditionalFilters) {
        let recertificationState = null;
        if (filters.overdueRecertification || filters.upcomingRecertification) {
            recertificationState = filters.overdueRecertification
                ? 'overdueRecertification'
                : 'upcomingRecertification';
        }
        this.additionalFiltersForm.setValue({
            startPhaseDate: filters.startPhaseDate ? moment(filters.startPhaseDate) : '',
            endPhaseDate: filters.endPhaseDate ? moment(filters.endPhaseDate) : '',
            startRecertificationDate: filters.startRecertificationDate ? moment(filters.startRecertificationDate) : '',
            endRecertificationDate: filters.endRecertificationDate ? moment(filters.endRecertificationDate) : '',
            recertificationType: filters.recertificationType || '',
            businessUnit: filters.businessUnit || [],
            createdBy: filters.createdBy || [],
            businessOwner: filters.businessOwner || [],
            startLastUpdated: filters.startLastUpdated ? moment(filters.startLastUpdated) : '',
            endLastUpdated: filters.endLastUpdated ? moment(filters.endLastUpdated) : '',
            startCreatedOn: filters.startCreatedOn ? moment(filters.startCreatedOn) : '',
            endCreatedOn: filters.endCreatedOn ? moment(filters.endCreatedOn) : '',
            client: filters.client || [],
            contextTypes: filters.contextTypes || [],
            dataTypes: filters.dataTypes || [],
            hasCompleted: filters.hasCompleted || null,
            assessed: filters.assessed || null,
            recertificationState,
            vendorOrgs: filters.vendorOrgs || [],
            nthParties: filters.nthParties || [],
            licenseType: filters.licenseType || [],
            artifactUpdateType: filters.artifactUpdateType || '',
            remediationRequested: filters.remediationRequested || null,
            riskAccepted: filters.riskAccepted || null,
        });
    }

    clearDates(startDate: string, endDate: string) {
        this.additionalFiltersForm.controls[startDate].setValue('');
        this.additionalFiltersForm.controls[endDate].setValue('');
        this.validateFilters();
    }

    searchCallbackIgnoreFilter(search: string, item: INgxSelectOption): boolean {
        return true;
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }

    private setAdditionalFiltersToState({ filters, additionalFiltersApplied }): void {
        this._store$.dispatch(
            setAdditionalFiltersState(
                !additionalFiltersApplied
                    ? null
                    : {
                          filters: {
                              startPhaseDate: filters.assessmentCompletedDate[0],
                              endPhaseDate: filters.assessmentCompletedDate[1],
                              startRecertificationDate: filters.recertificationDate[0],
                              endRecertificationDate: filters.recertificationDate[1],
                              recertificationType: filters.recertificationType,
                              businessUnit: filters.businessUnitId,
                              createdBy: filters.creatorId,
                              businessOwner: filters.businessOwnerId,
                              startLastUpdated: filters.updatedDate[0],
                              endLastUpdated: filters.updatedDate[1],
                              startCreatedOn: filters.createdDate[0],
                              endCreatedOn: filters.createdDate[1],
                              client: filters.customerOrgId,
                              contextTypes: filters.contextTypes,
                              upcomingRecertification: filters.upcomingRecertification,
                              overdueRecertification: filters.overdueRecertification,
                              dataTypes: filters.dataTypes,
                              hasCompleted: filters.hasCompleted,
                              assessed: filters.assessed,
                              vendorOrgs: filters.vendorOrgId,
                              nthParties: filters.nthParties,
                              licenseType: filters.licenseType,
                              artifactUpdateType: filters.artifactUpdateType,
                              remediationRequested: filters.remediationRequested,
                              riskAccepted: filters.riskAccepted,
                          },
                      },
            ),
        );
    }

    private validateDates(startDate: string, endDate: string) {
        const startDateFormValue = this.additionalFiltersForm.controls[startDate].value as moment.Moment;
        const endDateFormValue = this.additionalFiltersForm.controls[endDate].value as moment.Moment;
        if (!startDateFormValue || !endDateFormValue) return true;
        return startDateFormValue.isSameOrBefore(endDateFormValue);
    }

    private getFormDateValues(startDate: string, endDate: string) {
        const startDateFormValue = this.additionalFiltersForm.controls[startDate].value as moment.Moment;
        const endDateFormValue = this.additionalFiltersForm.controls[endDate].value as moment.Moment;
        if (startDateFormValue || endDateFormValue) {
            return [
                startDateFormValue ? startDateFormValue.format('YYYY-MM-DD') : '',
                endDateFormValue ? endDateFormValue.format('YYYY-MM-DD') : '',
            ];
        } else {
            return [];
        }
    }

    public selectRecertificationDateRange(rangeSelected: number) {
        const startDate = moment();
        const endDate = moment();
        endDate.add(rangeSelected, 'days');
        this.additionalFiltersForm.patchValue({
            startRecertificationDate: startDate,
            endRecertificationDate: endDate,
        });
    }

    public clearRadio(control: string) {
        this.additionalFiltersForm.get(control).setValue(null);
    }
}
