import { Action, createReducer, on } from '@ngrx/store';
import {
    getServerSessionCompleted,
    getUserProfileRequestSuccess,
    loadClientProfile,
    loadSession,
    logoutSuccess,
    updateUserProfileRequestSuccess,
} from './session.actions';
import { initialState, SessionState } from './session.state';
import { LicenseType } from '@entities/viso-user';
import {
    updateVendorTierDescriptionRequestSuccess,
    updateVendorTiersCountRequestSuccess,
} from '../../relationships/redux/relationships.actions';

const reducer = createReducer(
    initialState,
    on(loadSession, (state, { account }) => ({
        ...state,
        isAuthenticated: !!account,
        account,
        userIsTrial: account.clientLicense.licenseType === LicenseType.TRIAL,
    })),
    on(logoutSuccess, (state) => ({
        ...state,
        isAuthenticated: false,
        account: null,
    })),
    on(getServerSessionCompleted, (state) => ({
        ...state,
        sessionLoaded: true,
    })),
    on(loadClientProfile, (state, { profile }) => ({
        ...state,
        clientProfile: profile,
    })),
    on(getUserProfileRequestSuccess, (state, { userProfile }) => ({
        ...state,
        userProfile,
    })),
    on(updateUserProfileRequestSuccess, (state, { userProfile }) => ({
        ...state,
        userProfile: {
            ...state.userProfile,
            ...userProfile,
        },
    })),
    on(updateVendorTiersCountRequestSuccess, (state, { vendorTiers }) => ({
        ...state,
        clientProfile: {
            ...state.clientProfile,
            vendorTiers,
        },
    })),
    on(updateVendorTierDescriptionRequestSuccess, (state, { vendorTier }) => ({
        ...state,
        clientProfile: {
            ...state.clientProfile,
            vendorTiers: state.clientProfile.vendorTiers.map((tier) =>
                tier.id === vendorTier.id ? { ...vendorTier } : tier,
            ),
        },
    })),
);

export const sessionReducer = (state: SessionState = initialState, action: Action): SessionState =>
    reducer(state, action);
