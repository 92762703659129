import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { VendorTier } from '@entities/vendor-tier';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-move-tier-dialog',
    templateUrl: './move-tier-dialog.component.html',
    styleUrl: './move-tier-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveTierDialogComponent implements OnInit {
    @Input({ required: true })
    currentTier: VendorTier;

    @Input({ required: true })
    allVendorTiers: VendorTier[];

    newTierIdControl: FormControl<number>;

    constructor(private _activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.newTierIdControl = new FormControl<number>(this.currentTier?.id || -1);
    }

    close(): void {
        this._activeModal.close(undefined);
    }

    save(): void {
        this._activeModal.close(this.newTierIdControl.value === -1 ? null : this.newTierIdControl.value);
    }
}
