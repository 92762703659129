import { createAction, props } from '@ngrx/store';
import { Relationship, RelationshipStatusUpdatedResponse } from '@entities/relationship';
import { Risk } from '@entities/risk-assessment';
import { OnboardRequest } from '../../models/onboarding.model';

export enum OnboardActions {
    OpenOnboardModal = '[Onboard] Open Onboard Modal',
    OnboardModalCancelled = '[Onboard] Onboard Modal Cancelled',

    OnboardRelationshipRequest = '[Onboard] Onboard Relationship Request',
    OnboardRelationshipRequestSuccess = '[Onboard] Onboard Relationship Request Success',
    OnboardRelationshipRequestFailed = '[Onboard] Onboard Relationship Request Failed',

    OffboardRelationshipRequest = '[Onboard] Offboard Relationship Request',
    OffboardRelationshipRequestSuccess = '[Onboard] Offboard Relationship Request Success',
    OffboardRelationshipRequestFailed = '[Onboard] Offboard Relationship Request Failed',
}

export const openOnboardModal = createAction(
    OnboardActions.OpenOnboardModal,
    props<{
        relationship: Relationship;
        vendorName: string;
        risk: Risk;
        riskIsTransitional: boolean;
        dateOfNextArtifactExpiration?: Date;
    }>(),
);

export const onboardModalCancelled = createAction(OnboardActions.OnboardModalCancelled);

export const onboardRelationshipRequest = createAction(
    OnboardActions.OnboardRelationshipRequest,
    props<{ relationshipId: number; onboardRequest?: OnboardRequest }>(),
);

export const onboardRelationshipSuccess = createAction(
    OnboardActions.OnboardRelationshipRequestSuccess,
    props<{
        relationshipOnboardedView: RelationshipStatusUpdatedResponse;
    }>(),
);

export const onboardRelationshipFailed = createAction(OnboardActions.OnboardRelationshipRequestFailed);

export const offboardRelationshipRequest = createAction(
    OnboardActions.OffboardRelationshipRequest,
    props<{ relationshipId: number }>(),
);

export const offboardRelationshipRequestSuccess = createAction(OnboardActions.OffboardRelationshipRequestSuccess);

export const offboardRelationshipRequestFailed = createAction(OnboardActions.OffboardRelationshipRequestFailed);
