import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { VisoUserRole } from '@entities/viso-user';
import {
    ComplianceStandard,
    VendorDetailsResponse,
    VendorDetailsStatsResponse,
} from '../../../../../routes/request/models/vendor-details.model';
import { OrgUrlType } from '@entities/org';
import { IMAGE_FALLBACK_BASE64 } from '@shared/image-fallback/image-fallback.constants';
import { RiskAssessment } from '@entities/risk-assessment';

@Component({
    selector: 'app-vendor-overview',
    templateUrl: './vendor-overview.component.html',
    styleUrl: './vendor-overview.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorOverviewComponent {
    @Input({ required: true })
    vendorDetails: VendorDetailsResponse;

    @Input({ required: true })
    vendorDetailsStats: VendorDetailsStatsResponse;

    @Input({ required: true })
    isRelationshipDetails!: boolean;

    @Input()
    latestRiskAssessment?: RiskAssessment;

    @Output()
    onAddRelationshipClicked = new EventEmitter<void>();

    readonly Roles = VisoUserRole;
    readonly ComplianceStandard = ComplianceStandard;
    readonly OrgUrlType = OrgUrlType;
    readonly vendorImageFallback = IMAGE_FALLBACK_BASE64;

    get vendorName(): string {
        return this.vendorDetails?.name;
    }

    get vendorFaviconUrl(): string {
        return this.vendorDetails?.faviconUrl;
    }

    get homepage(): string {
        return this.vendorDetails?.homepage;
    }

    get industry(): string {
        return this.vendorDetails?.industry;
    }

    get description(): string {
        return this.vendorDetails?.description;
    }

    get foundedDate(): Date {
        return this.vendorDetails?.foundedDate;
    }

    get complianceStandards(): ComplianceStandard[] {
        return this.vendorDetails?.complianceStandards?.sort((a, b) => {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
        });
    }
}
