import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Relationship } from '@entities/relationship';
import { VisoUser, VisoUserRole } from '@entities/viso-user';
import { Risk } from '@entities/risk-assessment';

export interface RiskAnalysisNavigationMenuGroup {
    title: string;
    items: RiskAnalysisNavigationMenuItem[];
}

export interface RiskAnalysisNavigationMenuItem {
    prefixIcon?: string;
    prefixIconClass?: string;
    title: string;
    suffixText?: string;
    suffixTextClass?: string;
    disabled?: boolean;
}

@Component({
    selector: 'app-risk-analysis-navigation-menu',
    templateUrl: './risk-analysis-navigation-menu.component.html',
    styleUrls: ['./risk-analysis-navigation-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskAnalysisNavigationMenuComponent {
    @Input()
    riskAnalysisNavigationMenu: RiskAnalysisNavigationMenuGroup[];

    @Input()
    selectedNavigationItem: RiskAnalysisNavigationMenuItem;

    @Input()
    relationship: Relationship;

    @Input()
    businessOwner: VisoUser;

    @Output()
    onSelectedItem = new EventEmitter<RiskAnalysisNavigationMenuItem>();

    Roles = VisoUserRole;

    isRiskLevel(value: string): boolean {
        return Object.keys(Risk).includes(value);
    }
}
