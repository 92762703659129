import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ControlDomainService } from '../../../entities/control-domain';
import { VisoUserRole } from '../../../entities/viso-user';
import { getServerSessionCompleted } from '../../session/redux/session.actions';
import { getUserAccount, getUserAuthority } from '../../session/redux/session.selectors';
import {
    getControlsByOrgIdRequest,
    getControlsByOrgIdRequestFailed,
    getControlsByOrgIdRequestSuccess,
    getControlsRequest,
    getControlsRequestFailed,
    getControlsRequestSuccess,
    getCurrentAccountControlsRequest,
} from './controls.actions';

@Injectable()
export class ControlEffects {
    getCurrentAccountControlsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getCurrentAccountControlsRequest),
            withLatestFrom(this._store$.pipe(select(getUserAccount))),
            map(([, account]) => ({ orgId: account.orgId, isCurrentOrg: true })),
            map(({ orgId, isCurrentOrg }) => getControlsByOrgIdRequest({ orgId, isCurrentOrg })),
        ),
    );

    getControlsByOrgIdRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getControlsByOrgIdRequest),
            mergeMap(({ orgId, isCurrentOrg }) =>
                this._controlService.query({ orgId, unpaged: true }).pipe(
                    map((response) => response.body),
                    map((controls) => getControlsByOrgIdRequestSuccess({ orgId, controls, isCurrentOrg })),
                    catchError(() => of(getControlsByOrgIdRequestFailed())),
                ),
            ),
        ),
    );

    getControlsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getControlsRequest),
            mergeMap(() =>
                this._controlService.query().pipe(
                    map((response) => response.body),
                    map((controls) => getControlsRequestSuccess({ controls })),
                    catchError(() => of(getControlsRequestFailed())),
                ),
            ),
        ),
    );

    triggerControlRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getServerSessionCompleted),
            filter(({ error }) => !error),
            withLatestFrom(this._store$.pipe(select(getUserAuthority([VisoUserRole.Admin, VisoUserRole.Support])))),
            mergeMap(([, isAdminOrSupport]) =>
                [!isAdminOrSupport && getCurrentAccountControlsRequest(), getControlsRequest()].filter((x) => !!x),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _controlService: ControlDomainService,
    ) {}
}
