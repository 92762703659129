import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientLicenseDefaultView, ClientLicenseView } from '../../entities/viso-user';

@Injectable({
    providedIn: 'root',
})
export class LicenseManagementService {
    private _resourceUrl = 'api/client-license';

    constructor(private _http: HttpClient) {}

    getClientLicenseForOrg(orgId: number): Observable<ClientLicenseView> {
        return this._http.get<ClientLicenseView>(`${this._resourceUrl}/${orgId}`);
    }

    updateClientLicenseForOrg(orgId: number, request: ClientLicenseView): Observable<any> {
        return this._http.put(`${this._resourceUrl}/${orgId}`, request);
    }

    getClientLicenseDefaults(): Observable<ClientLicenseDefaultView[]> {
        return this._http.get<ClientLicenseDefaultView[]>(`${this._resourceUrl}/defaults`);
    }

    offboardAllTrialClients(): Observable<void> {
        return this._http.post<void>(`${this._resourceUrl}/offboard-trials`, null);
    }
}
