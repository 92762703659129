import { Action, createReducer, on } from '@ngrx/store';
import { ArtifactIntelligenceActions } from './artifact-intelligence.actions';
import { ArtifactIntelligenceState, initialState } from './artifact-intelligence.state';

const reducer = createReducer(
    initialState,
    on(
        ArtifactIntelligenceActions.openDetectionDetailsDrawer,
        (state, { detection, controlName, domainControlName, frameworkType }) => ({
            ...state,
            isDetectionDetailsDrawerOpen: true,
            detectionDetailsDrawerData: {
                detection,
                controlName,
                domainControlName,
                frameworkType,
            },
        }),
    ),
    on(ArtifactIntelligenceActions.closeDetectionDetailsDrawer, (state, _) => ({
        ...state,
        isDetectionDetailsDrawerOpen: false,
        detectionDetailsDrawerData: null,
    })),
);

export const artifactIntelligenceReducer = (
    state: ArtifactIntelligenceState = initialState,
    action: Action,
): ArtifactIntelligenceState => reducer(state, action);
