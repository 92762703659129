import { Routes } from '@angular/router';
import { RequestComponent } from './request.component';
import { AuthGuard } from '../../shared';
import { VisoUserRole } from '../../entities/viso-user';
import { RequestResolver } from './request.resolver';
import { RelationshipResolver } from './relationship.resolver';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ArtifactIntelligenceActions } from '@shared/artifact-intelligence/redux/artifact-intelligence.actions';

const canDeactivateArtifactIntelligenceDetails = () => {
    const store = inject(Store);
    store.dispatch(ArtifactIntelligenceActions.closeDetectionDetailsDrawer());
    return true;
};

export const requestRoutes: Routes = [
    {
        path: 'requests/:id',
        component: RequestComponent,
        data: {
            authorities: [
                VisoUserRole.OrgAdmin,
                VisoUserRole.Auditor,
                VisoUserRole.Support,
                VisoUserRole.OrgUser,
                VisoUserRole.ReadOnly,
            ],
        },
        canActivate: [AuthGuard],
        canDeactivate: [canDeactivateArtifactIntelligenceDetails],
        resolve: {
            relationship: RelationshipResolver,
            clientProfile: RequestResolver,
        },
    },
];

export const requestPopupRoutes: Routes = [
    {
        path: 'relationships/:requestId/add-artifact',
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.Auditor, VisoUserRole.OrgUser],
        },
        children: [],
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'relationships/:requestId/add-attachment',
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.Auditor, VisoUserRole.OrgUser],
        },
        children: [],
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'relationships/:requestId/business-owner',
        data: {
            authorities: [VisoUserRole.Auditor, VisoUserRole.Support, VisoUserRole.OrgAdmin],
        },
        children: [],
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'relationships/:requestId/subscriber',
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
        },
        children: [],
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'relationships/:requestId/third-party-contact',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.Auditor, VisoUserRole.Support],
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'relationships/:relationshipId/context',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'relationships/:relationshipId/edit-risk',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'relationships/:relationshipId/assessment-summary-configuration',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'relationships/:relationshipId/supplemental-questionnaires',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'relationships/:relationshipId/vendor-tier',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
];
