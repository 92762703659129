<div
    #filterPanel
    class="relationships-additional-filter-panel"
    [ngClass]="showFilterPanel ? 'show-panel' : 'hide-panel'"
>
    <div class="filter-panel-close-icon" (click)="toggleFilterPanel()">
        <i class="fa fa-times"></i>
    </div>

    <div class="filter-panel-title">More Filters</div>

    <div>
        <form [formGroup]="additionalFiltersForm" name="filtersForm" role="form" novalidate>
            <fieldset>
                <div class="form-group">
                    <div class="filter-header">
                        <label for="field_phaseDate" class="filter-label"> Phase Date: </label>
                        <button
                            mat-button
                            visoButton="link"
                            class="filter-action"
                            color="primary"
                            (click)="clearDates('startPhaseDate', 'endPhaseDate')"
                        >
                            Clear dates
                        </button>
                    </div>
                    <div class="filter-date-range">
                        <div class="date-form-group">
                            <input
                                id="field_phaseDate_from"
                                matInput
                                [matDatepicker]="startPhaseDatePicker"
                                class="form-control filter-start-date"
                                [ngClass]="{ 'filter-no-value': !additionalFiltersForm.controls.startPhaseDate.value }"
                                placeholder="yyyy-mm-dd"
                                formControlName="startPhaseDate"
                            />
                            <mat-datepicker-toggle [for]="startPhaseDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker
                                #startPhaseDatePicker
                                (opened)="datePickerOpen = true"
                                (closed)="datePickerOpen = false"
                            ></mat-datepicker>
                        </div>

                        <span class="filter-separator">to</span>

                        <div class="date-form-group">
                            <input
                                id="field_phaseDate_to"
                                matInput
                                [matDatepicker]="endPhaseDatePicker"
                                class="form-control filter-end-date"
                                [ngClass]="{ 'filter-no-value': !additionalFiltersForm.controls.endPhaseDate.value }"
                                placeholder="yyyy-mm-dd"
                                formControlName="endPhaseDate"
                            />
                            <mat-datepicker-toggle [for]="endPhaseDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker
                                #endPhaseDatePicker
                                (opened)="datePickerOpen = true"
                                (closed)="datePickerOpen = false"
                            ></mat-datepicker>
                        </div>
                    </div>
                </div>
                <small class="text-error" *ngIf="!phaseDateValid">
                    {{ validationMessage }}
                </small>
            </fieldset>
            <fieldset>
                <div class="form-group">
                    <div class="filter-header">
                        <label for="field_recertificationDate" class="filter-label">Recertification Date:</label>
                        <button
                            mat-button
                            visoButton="link"
                            class="filter-action"
                            color="primary"
                            (click)="clearDates('startRecertificationDate', 'endRecertificationDate')"
                        >
                            Clear dates
                        </button>
                    </div>
                    <div class="filter-date-range">
                        <div class="date-form-group">
                            <input
                                id="field_recertificationDate_from"
                                matInput
                                [matDatepicker]="startRecertificationDatePicker"
                                class="form-control filter-start-date"
                                placeholder="yyyy-mm-dd"
                                [ngClass]="{
                                    'filter-no-value': !additionalFiltersForm.controls.startRecertificationDate.value,
                                }"
                                formControlName="startRecertificationDate"
                            />
                            <mat-datepicker-toggle [for]="startRecertificationDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker
                                #startRecertificationDatePicker
                                (opened)="datePickerOpen = true"
                                (closed)="datePickerOpen = false"
                            ></mat-datepicker>
                        </div>

                        <span class="filter-separator">to</span>

                        <div class="date-form-group">
                            <input
                                id="field_recertificationDate_to"
                                matInput
                                [matDatepicker]="endRecertificationDatePicker"
                                class="form-control filter-end-date"
                                placeholder="yyyy-mm-dd"
                                [ngClass]="{
                                    'filter-no-value': !additionalFiltersForm.controls.endRecertificationDate.value,
                                }"
                                formControlName="endRecertificationDate"
                            />
                            <mat-datepicker-toggle [for]="endRecertificationDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker
                                #endRecertificationDatePicker
                                (opened)="datePickerOpen = true"
                                (closed)="datePickerOpen = false"
                            ></mat-datepicker>
                        </div>
                    </div>

                    <div class="mt-4">
                        <label for="field_recertificationDate" class="filter-label">Select Range:</label>
                        <mat-button-toggle-group
                            [hideSingleSelectionIndicator]="true"
                            name="date-range"
                            aria-label="Date Range buttons"
                        >
                            <mat-button-toggle (click)="selectRecertificationDateRange(30)">30</mat-button-toggle>
                            <mat-button-toggle (click)="selectRecertificationDateRange(60)">60</mat-button-toggle>
                            <mat-button-toggle (click)="selectRecertificationDateRange(90)">90</mat-button-toggle>
                        </mat-button-toggle-group>
                        <span class="ms-2">Days</span>
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <label class="filter-label">Recertification Type:</label>
                    <ngx-select
                        name="Recertification Type"
                        id="recertificationType"
                        class="filter-control"
                        [items]="recertificationTypesList"
                        [multiple]="true"
                        optionTextField="label"
                        optionValueField="value"
                        formControlName="recertificationType"
                        placeholder="Unfiltered"
                        [autoClearSearch]="true"
                    >
                        <ng-template ngx-select-option let-option>
                            {{ option.data.label }}
                        </ng-template>
                    </ngx-select>

                    <div class="filter-header mt-4">
                        <label class="filter-label">Recertification State:</label>
                        <button
                            mat-button
                            visoButton="link"
                            class="filter-action"
                            color="primary"
                            (click)="clearRadio('recertificationState')"
                            type="button"
                        >
                            Clear selection
                        </button>
                    </div>

                    <app-radiobutton-group
                        [type]="'toggle'"
                        [direction]="'row'"
                        [radiobuttonGroupName]="'selectContactRadiobuttonGroup'"
                        [defaultValue]="null"
                        formControlName="recertificationState"
                    >
                        <ng-template radiobutton-group-item [value]="'overdueRecertification'" let-forItem="forItem">
                            <label class="text-normal" [for]="forItem">Overdue</label>
                        </ng-template>

                        <ng-template radiobutton-group-item [value]="'upcomingRecertification'" let-forItem="forItem">
                            <label class="text-normal" [for]="forItem">Upcoming</label>
                        </ng-template>
                    </app-radiobutton-group>

                    <div class="form-group mt-4">
                        <label class="filter-label">Artifact Update Type:</label>
                        <ngx-select
                            name="Artifact Update Type"
                            id="artifactUpdateType"
                            class="filter-control"
                            [items]="recertificationTypesList"
                            [multiple]="true"
                            optionTextField="label"
                            optionValueField="value"
                            formControlName="artifactUpdateType"
                            placeholder="Unfiltered"
                            [autoClearSearch]="true"
                        >
                            <ng-template ngx-select-option let-option>
                                {{ option.data.label }}
                            </ng-template>
                        </ngx-select>
                    </div>

                    <div class="filter-header mt-4">
                        <label for="field_remediationRequested" class="filter-label">Remediation Requested:</label>
                        <button
                            id="field_remediationRequested"
                            mat-button
                            visoButton="link"
                            class="filter-action"
                            color="primary"
                            (click)="clearRadio('remediationRequested')"
                            type="button"
                        >
                            Clear selection
                        </button>
                    </div>

                    <app-radiobutton-group
                        [type]="'toggle'"
                        [direction]="'row'"
                        [radiobuttonGroupName]="'selectRemediationRequestedRadiobuttonGroup'"
                        [defaultValue]="null"
                        formControlName="remediationRequested"
                    >
                        <ng-template radiobutton-group-item [value]="'true'" let-forItem="forItem">
                            <label class="text-normal" [for]="forItem">Yes</label>
                        </ng-template>

                        <ng-template radiobutton-group-item [value]="'false'" let-forItem="forItem">
                            <label class="text-normal" [for]="forItem">No</label>
                        </ng-template>
                    </app-radiobutton-group>
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <label for="field_hasCompleted" class="filter-label">Assessment:</label>
                    <div>
                        <mat-checkbox
                            formControlName="hasCompleted"
                            id="hasCompleted"
                            class="start-aligned"
                            color="primary"
                            >Completed Assessment</mat-checkbox
                        >
                    </div>
                    <div>
                        <mat-checkbox
                            formControlName="assessed"
                            id="assessed"
                            class="start-aligned mt-2"
                            color="primary"
                            >Previously Assessed</mat-checkbox
                        >
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <label for="field_riskAccepted" class="filter-label">Risk Acceptance:</label>
                    <mat-checkbox formControlName="riskAccepted" id="riskAccepted" class="start-aligned" color="primary"
                        >Risk Accepted</mat-checkbox
                    >
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <label for="field_businessUnit" class="filter-label">Business Units:</label>
                    <ngx-select
                        name="Business Units"
                        id="businessUnits"
                        class="filter-control"
                        [items]="businessUnits$ | async"
                        [multiple]="true"
                        optionTextField="name"
                        optionValueField="id"
                        formControlName="businessUnit"
                        placeholder="Unfiltered"
                        [autoClearSearch]="true"
                    >
                    </ngx-select>
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <label for="field_businessCase" class="filter-label">Business Cases:</label>
                    <ngx-select
                        name="Business Case"
                        id="businessCases"
                        class="filter-control"
                        [items]="contextTypes$ | async"
                        [multiple]="true"
                        optionTextField="name"
                        optionValueField="id"
                        formControlName="contextTypes"
                        placeholder="Unfiltered"
                        [autoClearSearch]="true"
                    >
                    </ngx-select>
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <label for="field_dataType" class="filter-label">Data Types:</label>
                    <ngx-select
                        name="Data Types"
                        id="dataTypes"
                        class="filter-control"
                        [items]="dataTypes$ | async"
                        [multiple]="true"
                        optionTextField="name"
                        optionValueField="id"
                        formControlName="dataTypes"
                        placeholder="Unfiltered"
                        [autoClearSearch]="true"
                    >
                    </ngx-select>
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <label for="field_createdBy" class="filter-label">Created By:</label>
                    <ngx-select
                        name="Created By"
                        id="visoUsers"
                        class="filter-control"
                        [items]="visoUsers$ | async"
                        [multiple]="true"
                        optionTextField="firstName"
                        optionValueField="id"
                        formControlName="createdBy"
                        placeholder="Unfiltered"
                        [autoClearSearch]="true"
                    >
                        <ng-template ngx-select-option let-option>
                            {{ option.data.firstName }}
                            {{ option.data.lastName }}
                        </ng-template>

                        <ng-template ngx-select-option-selected let-option>
                            {{ option.data.firstName }}
                            {{ option.data.lastName }}
                        </ng-template>
                    </ngx-select>
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <label for="field_businessOwner" class="filter-label">Business Owner:</label>
                    <ngx-select
                        name="Business Owner"
                        id="businessOwners"
                        class="filter-control"
                        [items]="visoUsers$ | async"
                        [multiple]="true"
                        optionTextField="firstName"
                        optionValueField="id"
                        formControlName="businessOwner"
                        placeholder="Unfiltered"
                        [autoClearSearch]="true"
                    >
                        <ng-template ngx-select-option let-option>
                            {{ option.data.firstName }}
                            {{ option.data.lastName }}
                        </ng-template>

                        <ng-template ngx-select-option-selected let-option>
                            {{ option.data.firstName }}
                            {{ option.data.lastName }}
                        </ng-template>
                    </ngx-select>
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <label for="field_thirdParty" class="filter-label">Third Party:</label>
                    <ngx-select
                        #thirdPartySearch
                        name="Third Party"
                        id="thirdParty"
                        class="filter-control"
                        [items]="vendorOrgs$ | async"
                        [multiple]="true"
                        optionTextField="name"
                        optionValueField="id"
                        formControlName="vendorOrgs"
                        [keepSelectedItems]="true"
                        placeholder="Search by name or website"
                        [autoClearSearch]="true"
                        [noAutoComplete]="true"
                        [autoClearSearch]="true"
                        [searchCallback]="searchCallbackIgnoreFilter"
                        (keyup)="onVendorNameEntered($event, thirdPartiesComponentId)"
                    >
                        <ng-template ngx-select-option let-option>
                            <div class="row row-height">
                                <div class="col col-icon">
                                    <img
                                        class="organization-icon"
                                        *ngIf="option.data?.faviconUrl"
                                        [src]="option.data?.faviconUrl"
                                        [appImageFallback]="vendorImageFallback"
                                        onImageErrorCssClass="no-organization-icon"
                                    />
                                    <div class="no-organization-icon" *ngIf="!option.data?.faviconUrl"></div>
                                </div>

                                <div class="col ps-0">
                                    <div class="row name-industry-spacing">
                                        <div class="col">
                                            <span>{{ option.data?.name }}</span>
                                            <span
                                                *ngIf="!!option.data?.managedById"
                                                [tagColor]="TagColor.COLOR_01"
                                                class="custom-tag"
                                                >Custom</span
                                            >
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <span class="text-muted d-block">{{ option.data?.homepage }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template ngx-select-option-selected let-option>
                            {{ option.data.name }}
                        </ng-template>
                    </ngx-select>
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <label for="nthParties" class="filter-label">Associated Nth Party:</label>
                    <ngx-select
                        #nthPartySearch
                        name="Nth Party"
                        id="nthParty"
                        class="filter-control"
                        [items]="nthParties$ | async"
                        [multiple]="true"
                        optionTextField="name"
                        optionValueField="id"
                        formControlName="nthParties"
                        [keepSelectedItems]="true"
                        placeholder="Search by name or website"
                        [autoClearSearch]="true"
                        [noAutoComplete]="false"
                        [searchCallback]="searchCallbackIgnoreFilter"
                        (keyup)="onVendorNameEntered($event, nthPartiesComponentId)"
                    >
                        <ng-template ngx-select-option let-option>
                            <div class="row row-height">
                                <div class="col col-icon">
                                    <img
                                        class="organization-icon"
                                        *ngIf="option.data?.faviconUrl"
                                        [src]="option.data?.faviconUrl"
                                        [appImageFallback]="vendorImageFallback"
                                        onImageErrorCssClass="no-organization-icon"
                                    />
                                    <div class="no-organization-icon" *ngIf="!option.data?.faviconUrl"></div>
                                </div>

                                <div class="col ps-0">
                                    <div class="row name-industry-spacing">
                                        <div class="col">
                                            <span>{{ option.data?.name }}</span>
                                            <span
                                                *ngIf="!!option.data?.managedById"
                                                [tagColor]="TagColor.COLOR_01"
                                                class="custom-tag"
                                                >Custom</span
                                            >
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <span class="text-muted d-block">{{ option.data?.homepage }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template ngx-select-option-selected let-option>
                            {{ option.data.name }}
                        </ng-template>
                    </ngx-select>
                </div>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <div class="filter-header">
                        <label for="field_lastUpdated" class="filter-label">Last Updated: </label>
                        <button
                            mat-button
                            visoButton="link"
                            class="filter-action"
                            color="primary"
                            (click)="clearDates('startLastUpdated', 'endLastUpdated')"
                        >
                            Clear dates
                        </button>
                    </div>
                    <div class="filter-date-range">
                        <div class="date-form-group">
                            <input
                                id="field_lastUpdated_from"
                                matInput
                                [matDatepicker]="startLastUpdatedPicker"
                                class="form-control filter-start-date"
                                [ngClass]="{
                                    'filter-no-value': !additionalFiltersForm.controls.startLastUpdated.value,
                                }"
                                placeholder="yyyy-mm-dd"
                                formControlName="startLastUpdated"
                            />
                            <mat-datepicker-toggle [for]="startLastUpdatedPicker"></mat-datepicker-toggle>
                            <mat-datepicker
                                #startLastUpdatedPicker
                                (opened)="datePickerOpen = true"
                                (closed)="datePickerOpen = false"
                            ></mat-datepicker>
                        </div>

                        <span class="filter-separator">to</span>

                        <div class="date-form-group">
                            <input
                                id="field_lastUpdated_to"
                                matInput
                                [matDatepicker]="endLastUpdatedPicker"
                                class="form-control filter-end-date"
                                [ngClass]="{ 'filter-no-value': !additionalFiltersForm.controls.endLastUpdated.value }"
                                placeholder="yyyy-mm-dd"
                                formControlName="endLastUpdated"
                            />
                            <mat-datepicker-toggle [for]="endLastUpdatedPicker"></mat-datepicker-toggle>
                            <mat-datepicker
                                #endLastUpdatedPicker
                                (opened)="datePickerOpen = true"
                                (closed)="datePickerOpen = false"
                            ></mat-datepicker>
                        </div>
                    </div>
                </div>
                <small class="text-error" *ngIf="!lastUpdatedValid">
                    {{ validationMessage }}
                </small>
            </fieldset>

            <fieldset>
                <div class="form-group">
                    <div class="filter-header">
                        <label for="field_createdOn" class="filter-label">Created On:</label>
                        <button
                            mat-button
                            visoButton="link"
                            class="filter-action"
                            color="primary"
                            (click)="clearDates('startCreatedOn', 'endCreatedOn')"
                        >
                            Clear dates
                        </button>
                    </div>
                    <div class="filter-date-range">
                        <div class="date-form-group">
                            <input
                                id="field_createdOn_from"
                                matInput
                                [matDatepicker]="startCreatedOnPicker"
                                class="form-control filter-start-date"
                                [ngClass]="{ 'filter-no-value': !additionalFiltersForm.controls.startCreatedOn.value }"
                                placeholder="yyyy-mm-dd"
                                formControlName="startCreatedOn"
                            />
                            <mat-datepicker-toggle [for]="startCreatedOnPicker"></mat-datepicker-toggle>
                            <mat-datepicker
                                #startCreatedOnPicker
                                (opened)="datePickerOpen = true"
                                (closed)="datePickerOpen = false"
                            ></mat-datepicker>
                        </div>

                        <span class="filter-separator">to</span>

                        <div class="date-form-group">
                            <input
                                id="field_createdOn_to"
                                matInput
                                [matDatepicker]="endCreatedOnPicker"
                                class="form-control filter-end-date"
                                [ngClass]="{ 'filter-no-value': !additionalFiltersForm.controls.endCreatedOn.value }"
                                placeholder="yyyy-mm-dd"
                                formControlName="endCreatedOn"
                            />
                            <mat-datepicker-toggle [for]="endCreatedOnPicker"></mat-datepicker-toggle>
                            <mat-datepicker
                                #endCreatedOnPicker
                                (opened)="datePickerOpen = true"
                                (closed)="datePickerOpen = false"
                            ></mat-datepicker>
                        </div>
                    </div>
                </div>
                <small class="text-error" *ngIf="!createdOnValid">
                    {{ validationMessage }}
                </small>
            </fieldset>

            <fieldset *jhiHasAnyAuthority="[Roles.Auditor, Roles.Support]">
                <div class="form-group">
                    <label for="field_client" class="filter-label">Clients:</label>
                    <ngx-select
                        name="Clients"
                        id="clients"
                        class="filter-control"
                        [items]="clients$ | async"
                        [multiple]="true"
                        optionTextField="name"
                        optionValueField="id"
                        formControlName="client"
                        placeholder="Unfiltered"
                        [autoClearSearch]="true"
                    >
                    </ngx-select>
                </div>
            </fieldset>

            <fieldset *jhiHasAnyAuthority="[Roles.Auditor, Roles.Support]">
                <div class="form-group">
                    <label for="field_licenseType" class="filter-label">License Type:</label>
                    <ngx-select
                        name="License Type"
                        id="licenseType"
                        class="filter-control"
                        [items]="licenseTypesList"
                        [multiple]="true"
                        optionTextField="label"
                        optionValueField="value"
                        formControlName="licenseType"
                        placeholder="Unfiltered"
                    >
                        <ng-template ngx-select-option let-option>
                            {{ option.data.label }}
                        </ng-template>
                    </ngx-select>
                </div>
            </fieldset>

            <div class="filter-panel-footer">
                <button
                    type="button"
                    mat-button
                    visoButton="link"
                    class="me-auto text-primary"
                    (click)="toggleFilterPanel()"
                >
                    Cancel
                </button>
                <button type="button" mat-button visoButton="secondary" class="me-2" (click)="clearFilters()">
                    Reset
                </button>

                <button mat-button visoButton="primary" class="text-white" type="button" (click)="applyFilters()">
                    Apply
                </button>
            </div>
        </form>
    </div>
</div>
