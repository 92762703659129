import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ComplianceStandard,
    ComplianceStandardLabels,
    VendorDetailsResponse,
    VendorDetailsUrl,
} from '../../../../../routes/request/models/vendor-details.model';
import { IMAGE_FALLBACK_BASE64 } from '@shared/image-fallback/image-fallback.constants';
import { OrgUrlType } from '@entities/org';

@Component({
    selector: 'app-vendor-compliance',
    templateUrl: './vendor-compliance.component.html',
    styleUrl: './vendor-compliance.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorComplianceComponent {
    @Input({ required: true })
    vendorDetails: VendorDetailsResponse;

    readonly ComplianceStandardLabels = ComplianceStandardLabels;
    readonly ComplianceStandard = ComplianceStandard;
    readonly badgeImageFallback: string = IMAGE_FALLBACK_BASE64;

    complianceStandardsFullSize = [
        ComplianceStandard.ISO27001,
        ComplianceStandard.ISO27017,
        ComplianceStandard.ISO27018,
        ComplianceStandard.ISO27701,
        ComplianceStandard.GDPR,
    ];

    get showLinks(): boolean {
        return this.links.some((vendorDetailsUrl) => !!vendorDetailsUrl.url);
    }

    get links(): VendorDetailsUrl[] {
        return [
            {
                name: 'Compliance Standards (1)',
                url: this.vendorDetails?.urls[OrgUrlType.COMPLIANCE_STANDARDS_1],
                type: OrgUrlType.COMPLIANCE_STANDARDS_1,
            },
            {
                name: 'Compliance Standards (2)',
                url: this.vendorDetails?.urls[OrgUrlType.COMPLIANCE_STANDARDS_2],
                type: OrgUrlType.COMPLIANCE_STANDARDS_1,
            },
            {
                name: 'Regulatory Filings',
                url: this.vendorDetails?.urls[OrgUrlType.REGULATORY_FILINGS],
                type: OrgUrlType.REGULATORY_FILINGS,
            },
        ];
    }

    get complianceStandards(): ComplianceStandard[] {
        return this.vendorDetails?.complianceStandards?.sort((a, b) => {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
        });
    }

    get vendorName(): string {
        return this.vendorDetails?.name;
    }

    getComplianceIcon(standard: ComplianceStandard) {
        switch (standard) {
            case ComplianceStandard.CSA_STAR:
                return 'content/compliance/csa-star-1.svg';
            case ComplianceStandard.CSA_LEVEL2:
                return 'content/compliance/csa-star-2.svg';
            case ComplianceStandard.GDPR:
                return 'content/compliance/gdpr.png';
            case ComplianceStandard.ISO27001:
            case ComplianceStandard.ISO27017:
            case ComplianceStandard.ISO27018:
            case ComplianceStandard.ISO27701:
                return 'content/compliance/iso.png';
            case ComplianceStandard.PCIDSS:
                return 'content/compliance/pci.svg';
            case ComplianceStandard.SOC1:
            case ComplianceStandard.SOC2:
            case ComplianceStandard.SOC3:
                return 'content/compliance/soc2.png';
            case ComplianceStandard.HITRUST:
                return 'content/compliance/hitrust.png';
            case ComplianceStandard.HIPAA:
                return 'content/compliance/hipaa.svg';
            case ComplianceStandard.FEDRAMP:
                return 'content/compliance/fedramp.png';
            case ComplianceStandard.PRIVACY_SHIELD:
                return 'content/compliance/privacyshield.png';
            case ComplianceStandard.GLBA:
                return 'content/compliance/glba.png';
            case ComplianceStandard.CCPA:
                return 'content/compliance/ccpa.png';
        }
    }

    stripLinkProtocol(url: string): string {
        return url.replace(/(^\w+:|^)\/\//, '');
    }
}
