import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
    CheckDuplicateRiskAdvisoryRequest,
    CreateUpdateRiskAdvisoryRequest,
    RiskAdvisory,
    NetworkExposureRiskAdvisory,
    SummaryResponse,
} from './risk-advisory.model';
import { createRequestOption, PaginationParams } from '../../shared';

@Injectable({
    providedIn: 'root',
})
export class RiskAdvisoryService {
    private resourceUrl = 'api/risk-advisory';

    constructor(private http: HttpClient) {}

    getAllRiskAdvisories(req?: PaginationParams): Observable<HttpResponse<NetworkExposureRiskAdvisory[]>> {
        const options = createRequestOption(req);
        return this.http.get<NetworkExposureRiskAdvisory[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    getAllRiskAdvisoriesForCurrentClient(
        req?: PaginationParams,
    ): Observable<HttpResponse<NetworkExposureRiskAdvisory[]>> {
        const options = createRequestOption(req);
        return this.http.get<NetworkExposureRiskAdvisory[]>(`${this.resourceUrl}/client`, {
            params: options,
            observe: 'response',
        });
    }

    getAllRiskAdvisoriesForVendor(
        vendorId: number,
        req?: PaginationParams,
    ): Observable<HttpResponse<NetworkExposureRiskAdvisory[]>> {
        const options = createRequestOption(req);
        return this.http.get<NetworkExposureRiskAdvisory[]>(`${this.resourceUrl}/vendor/${vendorId}`, {
            params: options,
            observe: 'response',
        });
    }

    getProvisionalAdvisories(req?: PaginationParams): Observable<HttpResponse<NetworkExposureRiskAdvisory[]>> {
        const options = createRequestOption(req);
        return this.http.get<NetworkExposureRiskAdvisory[]>(`${this.resourceUrl}/unmatched`, {
            params: options,
            observe: 'response',
        });
    }

    createRiskAdvisory(request: CreateUpdateRiskAdvisoryRequest): Observable<void> {
        return this.http.post<void>(`${this.resourceUrl}`, request);
    }

    updateRiskAdvisory(request: CreateUpdateRiskAdvisoryRequest): Observable<void> {
        return this.http.put<void>(`${this.resourceUrl}/`, request);
    }

    deleteRiskAdvisory(riskAdvisoryId: string): Observable<void> {
        return this.http.delete<void>(`${this.resourceUrl}/${riskAdvisoryId}`);
    }

    startImportProcess(): Observable<string> {
        return this.http.post(
            `${this.resourceUrl}/import`,
            {},
            {
                responseType: 'text',
            },
        );
    }

    summarizeRiskAdvisoryLink(url: string): Observable<SummaryResponse> {
        return this.http.post<SummaryResponse>(`${this.resourceUrl}/summarize`, url);
    }

    findDuplicate(request: CheckDuplicateRiskAdvisoryRequest): Observable<RiskAdvisory> {
        return this.http.post<RiskAdvisory>(`${this.resourceUrl}/find-duplicate`, request);
    }
}
