import { Component } from '@angular/core';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { RiskAdvisoryService } from '@entities/risk-advisory/risk-advisory.service';
import { PublicSearchService } from './public-search.service';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
    selector: 'app-public-search',
    templateUrl: './public-search.component.html',
    styleUrls: ['./public-search.component.scss'],
})
export class PublicSearchComponent {
    importStarted: boolean;

    constructor(
        private _riskAdvisoryService: RiskAdvisoryService,
        private _publicSearchService: PublicSearchService,
        private _snackbarService: SnackbarService,
        private _matConfirmDialogService: MatConfirmDialogService,
    ) {}

    startSec8kImportProcess() {
        this.importStarted = true;
        this._riskAdvisoryService.startImportProcess().subscribe((message) => {
            this._snackbarService.info(message);
        });
    }

    startPublicInfoSearchProcess(): void {
        this._matConfirmDialogService
            .confirm({
                title: 'Public info search',
                message:
                    '<strong class="text-danger">Warning:</strong> You\'re about to unleash our AI minions to search through all vendor relationships!\n' +
                    'This will make our cloud servers work overtime and our finance team cry in the corner. 🏦\n' +
                    'Your mission, should you choose to accept it, will cost us <strong class="text-success">$$$$$$$$$$$</strong>.\n' +
                    'Are you ready to make our budget feel like a crypto crash?',
                confirmLabel: 'Run public info search anyway',
                confirmButtonStyle: 'warning',
            })
            .pipe(
                switchMap((confirmed) => {
                    if (confirmed) {
                        this.importStarted = true;
                        return this._publicSearchService.startPublicInfoSearchProcess();
                    }
                    return EMPTY;
                }),
            )
            .subscribe((message) => {
                this._snackbarService.info(message);
            });
    }
}
