import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MoveTierDialogComponent } from '../../relationship-tab/move-tier-dialog/move-tier-dialog.component';
import { getRelationshipVendorTier } from '../request.selectors';
import { NgbModalWrapperService } from '@shared/modal/ngb-modal-wrapper.service';
import { getVendorTiers } from '../../../session/redux/session.selectors';
import { from, of } from 'rxjs';
import {
    assignRelationshipToTierRequest,
    assignRelationshipToTierRequestFailed,
    assignRelationshipToTierRequestSuccess,
} from '../request.actions';
import { RelationshipService } from '@entities/relationship';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';

@Injectable()
export class VendorTierEffects {
    moveTierDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            map((action: RouterNavigationAction) =>
                action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
            ),
            filter((route) => !!route && route.routeConfig.path === 'relationships/:relationshipId/vendor-tier'),
            map((route) => ({ relationshipId: +route.params.relationshipId })),
            withLatestFrom(this._store$.select(getRelationshipVendorTier), this._store$.select(getVendorTiers)),
            switchMap(([{ relationshipId }, currentTier, vendorTiers]) =>
                from(
                    this._modalService.open<MoveTierDialogComponent>(
                        MoveTierDialogComponent,
                        {
                            currentTier,
                            allVendorTiers: vendorTiers,
                        },
                        { size: 'md' },
                    ).result,
                ).pipe(
                    filter((vendorTierId) => vendorTierId !== undefined && vendorTierId !== currentTier?.id),
                    map((vendorTierId) => assignRelationshipToTierRequest({ relationshipId, tierId: vendorTierId })),
                ),
            ),
        ),
    );

    assignRelationshipToTier$ = createEffect(() =>
        this._actions$.pipe(
            ofType(assignRelationshipToTierRequest),
            exhaustMap(({ relationshipId, tierId }) =>
                this._relationshipService.assignAllToTier([relationshipId], tierId).pipe(
                    tap(() => this._snackbarService.success('Relationship tier updated.')),
                    map(() => assignRelationshipToTierRequestSuccess()),
                    catchError(() => of(assignRelationshipToTierRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _modalService: NgbModalWrapperService,
        private _relationshipService: RelationshipService,
        private _snackbarService: SnackbarService,
    ) {}
}
