import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { merge, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AuditReportTypeName } from '@entities/audit-report';
import { ArtifactType, ControlValidationDetectionType } from '@entities/artifact';
import {
    ControlDomainControlValidationDetectionWithArtifact,
    ControlsWithDetections,
    GroupedSecurityControlDomain,
    PopulatedControlDomainControlAssessment,
    SecurityControlDomainGroupStatus,
} from '@entities/relationship/models/security-control-domain';
import { VisoUserRole } from '@entities/viso-user';
import { ControlDetectionToggle } from '../../../../../routes/request/models';
import { FrameworkMappingType } from '@entities/framework/models/framework-mapping.model';
import { MatDrawer } from '@angular/material/sidenav';
import { Detection } from '@shared/artifact-intelligence/models/detection.model';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/state';
import { ArtifactIntelligenceActions } from '@shared/artifact-intelligence/redux/artifact-intelligence.actions';
import * as FromArtifactIntelligenceSelectors from '@shared/artifact-intelligence/redux/artifact-intelligence.selectors';

function sortDetectionsByAssurance(groupedSecurityControlDomain: GroupedSecurityControlDomain) {
    const sortFn = (
        a: ControlDomainControlValidationDetectionWithArtifact,
        b: ControlDomainControlValidationDetectionWithArtifact,
    ) => {
        // sort desc
        if (a.artifact?.auditReportAssurance < b.artifact?.auditReportAssurance) return 1;
        if (a.artifact?.auditReportAssurance > b.artifact?.auditReportAssurance) return -1;
        return 0;
    };
    for (const cda of groupedSecurityControlDomain.controlDomainAssessments) {
        cda.detections.sort(sortFn);

        for (const individualControl of cda.individualControls) {
            individualControl.detections.sort(sortFn);
        }
    }
    return groupedSecurityControlDomain;
}

@Component({
    selector: 'app-control-group',
    templateUrl: './control-group.component.html',
    styleUrls: ['./control-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlGroupComponent implements AfterViewInit, OnDestroy {
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;

    @Input({ transform: sortDetectionsByAssurance })
    group: GroupedSecurityControlDomain;

    @Input()
    groupIndex: number;

    @Input()
    noAssessment: boolean;

    @Input()
    latestAssessmentInProgress: boolean;

    @Input()
    latestAssessmentCompleted: boolean;

    @Input()
    latestAssessmentFollowUpQuestionnaireCreatedDate: Date;

    @Input()
    assessmentSentToEmail: string;

    @Input()
    isLatestAssessmentNonDocumentsOnly: boolean;

    @Input()
    isDisabled: boolean;

    @Input()
    isOutOfScope: boolean;

    @Input()
    onExport: boolean;

    @Input()
    frameworkType: FrameworkMappingType;

    @Input()
    supplementalQuestionnaireId: string;

    @Output()
    accordionToggled = new EventEmitter<ControlDetectionToggle>();

    @ViewChildren(MatExpansionPanel)
    private _accordionComponents: QueryList<MatExpansionPanel>;

    AuditReportType = AuditReportTypeName;
    ArtifactType = ArtifactType;
    SecurityControlDomainGroupStatus = SecurityControlDomainGroupStatus;
    DetectionType = ControlValidationDetectionType;
    Roles = VisoUserRole;

    openedAccordions = new Map<number, boolean>();
    allOpened = false;

    private _store$: Store<AppState> = inject(Store);
    private _unsub$ = new Subject<void>();

    public getDetectionDetailsDrawerData$ = this._store$.select(
        FromArtifactIntelligenceSelectors.getDetectionDetailsDrawerData,
    );

    get groupControlValidationStatus(): SecurityControlDomainGroupStatus {
        return this.group?.controlValidationStatus;
    }

    get controlDomainAssessments(): PopulatedControlDomainControlAssessment[] {
        return this.group?.controlDomainAssessments || [];
    }

    ngAfterViewInit(): void {
        this._accordionComponents.changes
            .pipe(
                startWith(true),
                switchMap(() => merge(...this._accordionComponents.map((accordion) => accordion.expandedChange))),
                takeUntil(this._unsub$),
            )
            .subscribe(() => {
                this.allOpened = this._accordionComponents.toArray().every((accordion) => accordion.expanded);
            });
    }

    triggerAccordionToggled(
        opened: boolean,
        { controlDomainId: controlId, controlName }: PopulatedControlDomainControlAssessment,
    ): void {
        this.handleAccordionOpenedState(controlId, opened);
        this.accordionToggled.emit({
            opened,
            controlId,
            controlName,
        });
    }

    isDetectionAllowed(detection: ControlDomainControlValidationDetectionWithArtifact): boolean {
        return AuditReportTypeName[detection.auditReportType] !== AuditReportTypeName.SUPERSEDED;
    }

    toggleAll() {
        if (this.allOpened) {
            this.closeAll();
        } else {
            this.openAll();
        }
    }

    onDetectionClick(
        detection: Detection,
        controlName: string,
        frameworkType: FrameworkMappingType,
        domainControlName: string,
    ) {
        this._store$.dispatch(
            ArtifactIntelligenceActions.openDetectionDetailsDrawer({
                detection,
                controlName,
                domainControlName,
                frameworkType,
            }),
        );
    }

    isAccordionOpened(controlDomainId: number): boolean {
        if (this.onExport) {
            return true;
        }
        return !!this.openedAccordions.get(controlDomainId);
    }

    trackByControlId(index: number, item: ControlsWithDetections) {
        return item.controlId;
    }

    trackById(index: number, item: ControlDomainControlValidationDetectionWithArtifact) {
        return item.id;
    }

    trackByControlDomainId(index: number, controlDomainAssessment: PopulatedControlDomainControlAssessment) {
        return controlDomainAssessment.controlDomainId;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private openAll() {
        this._accordionComponents?.forEach((accordion) => (accordion.expanded = true));
    }

    private closeAll() {
        this._accordionComponents?.forEach((accordion) => (accordion.expanded = false));
    }

    private handleAccordionOpenedState(controlDomainId: number, openedState: boolean): void {
        this.openedAccordions.set(controlDomainId, openedState);
    }
}
