<div class="row mb-4" *jhiHasAnyAuthority="[Roles.Auditor, Roles.Support, Roles.Admin]">
    <div class="col">
        <div class="d-flex">
            <div class="ms-auto">
                <button class="me-2" mat-flat-button color="primary" (click)="onEditClicked.emit()">Edit</button>
                <ng-container *ngIf="showRefreshDetailsButton">
                    <button
                        mat-flat-button
                        color="primary"
                        (click)="onRefreshDetailsClicked.emit()"
                        toggleDisableOnActions
                        [disabled]="refreshingVendorDetails"
                    >
                        {{ refreshingVendorDetails ? 'Refreshing...' : 'Refresh details' }}
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        @if (isRelationshipDetails && latestRiskAssessment?.predicted) {
            <div class="row my-3">
                <div class="col">
                    <h2 class="text-normal mb-2">Instant analysis</h2>
                    <p class="mb-3">
                        Based on anonymized data from previous assessments, VISO TRUST predicts risk values and
                        aggregates risk-impacting data about this vendor.
                    </p>
                    <strong>Start an assessment or upload artifacts for a comprehensive risk assessment.</strong>
                </div>
            </div>
        }
        <div class="row mb-4">
            <div class="col">
                <app-vendor-overview
                    [isRelationshipDetails]="isRelationshipDetails"
                    [vendorDetails]="vendorDetails"
                    [vendorDetailsStats]="vendorDetailsStats"
                    [latestRiskAssessment]="latestRiskAssessment"
                    (onAddRelationshipClicked)="onAddRelationshipClicked.emit()"
                />
            </div>
        </div>
        @if (isRelationshipDetails) {
            <article data-spotlight-tour="domain-artifacts-compliance-information">
                <h2 class="text-normal mb-2">Artifacts & risk analysis</h2>
                <p class="mb-3">Start an assessment to get detailed information on control coverage.</p>
            </article>

            <div class="row mb-4">
                <div class="col">
                    <app-vendor-artifacts [vendorDetails]="vendorDetails" [vendorDetailsStats]="vendorDetailsStats" />
                </div>
            </div>
            @if (vendorDetails?.complianceStandards?.length > 0) {
                <div class="row mb-4">
                    <div class="col">
                        <app-vendor-compliance [vendorDetails]="vendorDetails" />
                    </div>
                </div>
            }
            <div class="row mb-4">
                <div class="col">
                    <app-vendor-context
                        [inScopeControlDomainTypes]="inScopeControlDomainTypes"
                        [disabledControlDomainTypes]="disabledControlDomainTypes"
                        [vendorDetails]="vendorDetails"
                        [contextTypes]="contextTypes"
                        [dataTypes]="dataTypes"
                        data-spotlight-tour="vendor-context"
                    />
                </div>
            </div>
            <div class="row mb-4">
                <div class="col">
                    <app-vendor-risk-domains
                        [vendorDetails]="vendorDetails"
                        [vendorDetailsStats]="vendorDetailsStats"
                    />
                </div>
            </div>
        }
        <div class="row mb-4">
            <div class="col">
                <app-vendor-risk-events
                    [vendorDetails]="vendorDetails"
                    [vendorDetailsStats]="vendorDetailsStats"
                    [riskAdvisories]="riskAdvisories"
                    [totalRiskAdvisories]="totalRiskAdvisories"
                    [userCanEdit]="userCanEdit"
                    (onRiskAdvisorySortChange)="onRiskAdvisorySortChange.emit($event)"
                    (onRiskAdvisoryPageChange)="onRiskAdvisoryPageChange.emit($event)"
                    (onRiskAdvisorySearchTextChange)="onRiskAdvisorySearchTextChange.emit($event)"
                    (deleteRiskAdvisory)="deleteRiskAdvisory.emit($event)"
                    data-spotlight-tour="vendor-monitoring"
                />
            </div>
        </div>
        @if (showVendorLeadership || showVendorLinks) {
            <div class="row my-3">
                <div class="col">
                    <h3 class="lead mb-2">Company details</h3>
                    <p class="mb-0">VISO TRUST monitors public data for risk-relevant information about the company.</p>
                    <p>Last updated {{ updatedDate | timeAgo }}</p>
                </div>
            </div>
            <div class="row">
                @if (showVendorLeadership) {
                    <div class="col mb-3">
                        <app-vendor-leadership [vendorDetails]="vendorDetails" />
                    </div>
                }
                @if (showVendorLinks) {
                    <div class="col mb-3">
                        <app-vendor-links [vendorDetails]="vendorDetails"></app-vendor-links>
                    </div>
                }
            </div>
        }
    </div>
</div>
