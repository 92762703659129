export enum FeatureFlags {
    TRUST = 'trust',
    PRIVACY_MODULE = 'privacy-module',
    SUPPLEMENTAL_QUESTIONNAIRES = 'supplemental-questionnaires',
    RDP_AI_QA = 'rdp-ai-qa',
    ORG_USER_CAN_ONBOARD = 'org-user-can-onboard',
    RISK_TOLERANCE = 'risk-tolerance',
    DOMAINS_BRANDING = 'domains-branding',
    CONCIERGE_ASSESSMENTS = 'concierge-assessments',
    ZOOMINFO_ORG_ENRICH = 'zoominfo-org-enrich',
    INSTANT_ANALYSIS = 'instant-analysis',
    LICENSE_INFO = 'license-info',
}
