import * as fromRoot from '@shared/redux/state';
import { PublicAssessment } from '@entities/assessment';
import { AssessmentRecommendation } from '@entities/recommendation';
import { PublicAuditReport } from '@entities/audit-report';
import { RTPEvent, RTPEventType } from '@entities/rtp';
import { RelationshipControlDomainAssessments } from '@entities/relationship/models/security-control-domain';
import {
    ArtifactUploadRecommendation,
    AssessmentSubmissionContext,
    AuthenticationFailedReasons,
    AdditionalInformationSubSteps,
    PublicAssessmentRTPFileArtifact,
    SlimControlDomain,
    StepIds,
} from '../models';

export const assessmentCollectionStateKey = 'assessment-collection';

export const initialState: AssessmentCollectionState = {
    passcode: '',
    publicAssessment: null,
    recommendations: [],
    auditReports: [],
    inScopeControlDomains: [],
    rtpFileArtifacts: [],
    rtpEventsMap: new Map(),
    artifactUploadRecommendations: [],
    collectArtifactsSubStep: AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS,
    canWaitingScreenMoveForward: false,
    securityControlDomain: null,
    submitted: false,
    isAuthenticating: false,
    authenticationFailedReason: null,
    currentStepId: StepIds.WELCOME,
    followupRequested: false,
    submissionContext: null,
    followupControlDomainIds: [],
    expiredArtifactIdsToReplace: {},
};

export interface AppState extends fromRoot.AppState {
    [assessmentCollectionStateKey]: AssessmentCollectionState;
}

export interface AssessmentCollectionState {
    passcode: string;
    publicAssessment: PublicAssessment;
    recommendations: AssessmentRecommendation[];
    auditReports: PublicAuditReport[];
    inScopeControlDomains: SlimControlDomain[];
    rtpFileArtifacts: PublicAssessmentRTPFileArtifact[];
    rtpEventsMap: Map<number, Map<RTPEventType, RTPEvent>>;
    artifactUploadRecommendations: ArtifactUploadRecommendation[];
    collectArtifactsSubStep: AdditionalInformationSubSteps;
    canWaitingScreenMoveForward: boolean;
    securityControlDomain: RelationshipControlDomainAssessments;
    submitted: boolean;
    isAuthenticating: boolean;
    authenticationFailedReason: AuthenticationFailedReasons;
    currentStepId: StepIds;
    justCancelled?: boolean;
    justForwarded?: boolean;
    remediationJustDeclined?: boolean;
    followupRequested?: boolean;
    submissionContext: AssessmentSubmissionContext;
    followupControlDomainIds: number[];
    expiredArtifactIdsToReplace: { [id: number]: number };
}
