export enum MainTabs {
    RELATIONSHIP = 'tab-request-relationship',
    DIRECTORY_RECORD = 'tab-request-directory-record',
    ASSESSMENT = 'tab-request-assessment',
    RISK_ANALYSIS = 'tab-request-risk-analysis',
    ARTIFACTS = 'tab-request-artifacts',
    AI_QA = 'tab-request-ai-qa',
    RISK_TIMELINE = 'tab-request-risk-timeline',
    ATTACHMENTS = 'tab-request-attachments',
    DETAILS = 'tab-request-details',
    COMMENTS = 'tab-request-comments',
}

export enum RiskAnalysisTabs {
    SECURITY = 'tab-request-riskAnalysis-security',
    PRIVACY = 'tab-request-riskAnalysis-privacy',
    RESILIENCE = 'tab-request-riskAnalysis-resilience',
    ARTIFICIAL_INTELLIGENCE = 'tab-request-riskAnalysis-artificial-intelligence',
    CYBER_INSURANCE = 'tab-request-riskAnalysis-cyber-insurance',
    PRODUCT_SECURITY = 'tab-request-riskAnalysis-product-security',
    SERVICE_LOCATIONS = 'tab-request-riskAnalysis-service-locations',
    SUPPLEMENTAL_QUESTIONNAIRES = 'tab-request-riskAnalysis-supplemental-questionnaires',
}
