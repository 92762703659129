import { GuidedTour, Orientation } from 'ngx-guided-tour';

export const relationshipsListTour = (): GuidedTour => {
    return {
        tourId: 'relationships-list',
        steps: [
            {
                title: 'Relationships',
                content:
                    'This is the relationships list. Here you can view, filter and interact with any relationships you have with your vendors.',
                action: () => {
                    document.getElementById('list-view-toggle-button').click();
                },
            },
            {
                title: 'Add relationship',
                content: 'Create a relationship to begin assessing a vendor.',
                selector: '#new-relationship-btn',
                orientation: Orientation.Left,
            },
            {
                title: 'List view',
                content: 'The default way to view your relationships is in a list.',
                selector: '#list-view-toggle',
                orientation: Orientation.Left,
            },
            {
                title: 'Graph view',
                content: 'Relationships can be viewed both in a list or a graph.',
                selector: '#graph-view-toggle-button',
                orientation: Orientation.Left,
                action: () => {
                    document.getElementById('graph-view-toggle-button').click();
                    // Hack to refresh the button highlight after graph loads.
                    setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
                },
            },
            {
                title: 'Graph view',
                orientation: Orientation.Center,
                selector: '#riskNetworkContainer',
                content:
                    'From the graph view, you have the power to not only track your third-parties, but also the organizations they do business with.' +
                    '<img class="mt-2 w-100" src="/content/animations/gif/spotlight-tours/relationships-list/risk_network.gif" />',
            },
            {
                title: 'Basic filtering',
                orientation: Orientation.Bottom,
                selector: '#relationship-filters',
                content:
                    'You can filter your list of relationships. Filters can be applied based on the status of the relationship, assessment phase, and more.',
            },
            {
                title: 'More filters',
                orientation: Orientation.Left,
                selector: '#additional-filters-button',
                content: 'Feeling specific? There are additional filters you can apply, too!',
            },
            {
                title: 'Extra options',
                orientation: Orientation.Left,
                selector: '#extra-options-button',
                content:
                    'From here you can customize the columns in the table and export your relationships to a .csv file.',
            },
            {
                title: 'Relationships',
                orientation: Orientation.Right,
                selector: '.sidebar-nav',
                content:
                    "That's it! Return to this page at any time by simply selecting 'Relationships' from the nav menu to the left.",
            },
        ],
    };
};
