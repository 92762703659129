import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompleteVendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';
import {
    AdminUpdateVendorDetailsRequest,
    UpdateVendorDetailsRequest,
    VendorDetailsResponse,
    VendorDetailsStatsResponse,
} from '../../request/models/vendor-details.model';
import { NetworkExposureRiskAdvisory, RiskAdvisory } from '@entities/risk-advisory';
import { PaginationParams } from '../../../shared';

export enum VendorDirectoryActions {
    GetVendorDirectoryListRequest = '[VendorDirectory] Get Vendor Directory List Request',
    GetVendorDirectoryListRequestSuccess = '[VendorDirectory] Get Vendor Directory List Request Success',
    GetVendorDirectoryListRequestFailed = '[VendorDirectory] Get Vendor Directory List Request Failed',

    AddRelationshipWithVendor = '[VendorDirectory] Add Relationship With Vendor',

    GetVendorDirectoryPredictedRiskDetailsRequest = '[VendorDirectory] Get Vendor Directory Predicted Risk Details Request',
    GetVendorDirectoryPredictedRiskDetailsRequestSuccess = '[VendorDirectory] Get Vendor Directory Predicted Risk Details Request Success',
    GetVendorDirectoryPredictedRiskDetailsRequestFailed = '[VendorDirectory] Get Vendor Directory Predicted Risk Details Request Failed',

    GetVendorDirectoryDetailsRequest = '[VendorDirectory] Get Vendor Directory Details Request',
    GetVendorDirectoryDetailsRequestSuccess = '[VendorDirectory] Get Vendor Directory Details Request Success',
    GetVendorDirectoryDetailsRequestFailed = '[VendorDirectory] Get Vendor Directory Details Request Failed',

    UpdateVendorDirectoryVendorDetailsRequest = '[VendorDirectory] Update Vendor Details Request',
    UpdateVendorDirectoryVendorDetailsRequestSuccess = '[VendorDirectory] Update Vendor Details Request Success',
    UpdateVendorDirectoryVendorDetailsRequestFailed = '[VendorDirectory] Update Vendor Details Request Failed',

    AddVendorDirectoryDomainToVendor = '[VendorDirectory] Add Domain To Vendor',
    AddVendorDirectoryDomainToVendorRequest = '[VendorDirectory] Add Domain To Vendor Request',
    AddVendorDirectoryDomainToVendorRequestSuccess = '[VendorDirectory] Add Domain To Vendor Request Success',
    AddVendorDirectoryDomainToVendorRequestFailed = '[VendorDirectory] Add Domain To Vendor Request Failed',

    GetVendorDirectoryRiskAdvisoriesRequest = '[VendorDirectory] Get Vendor Directory Risk Advisories Request',
    GetVendorDirectoryRiskAdvisoriesRequestSuccess = '[VendorDirectory] Get Vendor Directory Risk Advisories Request Success',
    GetVendorDirectoryRiskAdvisoriesRequestFailed = '[VendorDirectory] Get Vendor Directory Risk Advisories Request Failed',

    OpenCreateRiskAdvisoryDialog = '[VendorDirectory] Open Risk Advisory Dialog',

    RefreshVendorDirectoryVendorDetailsRequest = '[VendorDirectory] Refresh Vendor Directory Vendor Details Request',
    RefreshVendorDirectoryVendorDetailsRequestSuccess = '[VendorDirectory] Refresh Vendor Directory Vendor Details Request Success',
    RefreshVendorDirectoryVendorDetailsRequestFailed = '[VendorDirectory] Refresh Vendor Directory Vendor Details Request Failed',

    DeleteVendorDirectoryRiskAdvisory = '[VendorDirectory] Delete Vendor Directory Risk Advisory',
    DeleteVendorDirectoryRiskAdvisoryRequest = '[VendorDirectory] Delete Vendor Directory Risk Advisory Request',
    DeleteVendorDirectoryRiskAdvisoryRequestSuccess = '[VendorDirectory] Delete Vendor Directory Risk Advisory Request Success',
    DeleteVendorDirectoryRiskAdvisoryRequestFailed = '[VendorDirectory] Delete Vendor Directory Risk Advisory Request Failed',
    DeleteVendorDirectoryRiskAdvisoryCancel = '[VendorDirectory] Delete Vendor Directory Risk Advisory Cancel',

    GetVendorDirectoryDetailsStatsRequest = '[VendorDirectory] Get Vendor Directory Details Stats Request',
    GetVendorDirectoryDetailsStatsRequestSuccess = '[VendorDirectory] Get Vendor Directory Details Stats Request Success',
    GetVendorDirectoryDetailsStatsRequestFailed = '[VendorDirectory] Get Vendor Directory Details Stats Request Failed',
}

export const getVendorDirectoryListRequest = createAction(
    VendorDirectoryActions.GetVendorDirectoryListRequest,
    props<{ keyword?: string }>(),
);

export const getVendorDirectoryListRequestSuccess = createAction(
    VendorDirectoryActions.GetVendorDirectoryListRequestSuccess,
    props<{ vendorList: CompleteVendorSearchResult[] }>(),
);

export const getVendorDirectoryListRequestFailed = createAction(
    VendorDirectoryActions.GetVendorDirectoryListRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const addRelationshipWithVendor = createAction(
    VendorDirectoryActions.AddRelationshipWithVendor,
    props<{ vendor: CompleteVendorSearchResult }>(),
);

export const getVendorDirectoryDetailsRequest = createAction(
    VendorDirectoryActions.GetVendorDirectoryDetailsRequest,
    props<{
        orgId: number;
    }>(),
);

export const getVendorDirectoryDetailsRequestSuccess = createAction(
    VendorDirectoryActions.GetVendorDirectoryDetailsRequestSuccess,
    props<{ vendorDetails: VendorDetailsResponse }>(),
);

export const getVendorDirectoryDetailsRequestFailed = createAction(
    VendorDirectoryActions.GetVendorDirectoryDetailsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const updateVendorDirectoryVendorDetailsRequest = createAction(
    VendorDirectoryActions.UpdateVendorDirectoryVendorDetailsRequest,
    props<{
        updateRequest: AdminUpdateVendorDetailsRequest | UpdateVendorDetailsRequest;
        isCurrentUserAdmin: boolean;
    }>(),
);

export const updateVendorDirectoryVendorDetailsRequestSuccess = createAction(
    VendorDirectoryActions.UpdateVendorDirectoryVendorDetailsRequestSuccess,
);

export const updateVendorDirectoryVendorDetailsRequestFailed = createAction(
    VendorDirectoryActions.UpdateVendorDirectoryVendorDetailsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const addVendorDirectoryDomainToVendor = createAction(
    VendorDirectoryActions.AddVendorDirectoryDomainToVendor,
    props<{
        vendorDetails: VendorDetailsResponse;
        newDomain: string;
    }>(),
);

export const addVendorDirectoryDomainToVendorRequest = createAction(
    VendorDirectoryActions.AddVendorDirectoryDomainToVendorRequest,
    props<{
        vendorDetails: VendorDetailsResponse;
        newDomain: string;
    }>(),
);

export const addVendorDirectoryDomainToVendorRequestSuccess = createAction(
    VendorDirectoryActions.AddVendorDirectoryDomainToVendorRequestSuccess,
    props<{
        orgName: string;
    }>(),
);

export const addVendorDirectoryDomainToVendorRequestFailed = createAction(
    VendorDirectoryActions.AddVendorDirectoryDomainToVendorRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const openCreateRiskAdvisoryDialog = createAction(
    VendorDirectoryActions.OpenCreateRiskAdvisoryDialog,
    props<{
        orgId: number;
        name: string;
        isEditing: boolean;
    }>(),
);

export const getVendorDirectoryRiskAdvisoriesRequest = createAction(
    VendorDirectoryActions.GetVendorDirectoryRiskAdvisoriesRequest,
    props<{
        params?: PaginationParams;
        vendorId: number;
    }>(),
);

export const getVendorDirectoryRiskAdvisoriesRequestSuccess = createAction(
    VendorDirectoryActions.GetVendorDirectoryRiskAdvisoriesRequestSuccess,
    props<{ riskAdvisories: NetworkExposureRiskAdvisory[]; httpHeaders: HttpHeaders }>(),
);

export const getVendorDirectoryRiskAdvisoriesRequestFailed = createAction(
    VendorDirectoryActions.GetVendorDirectoryRiskAdvisoriesRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const refreshVendorDirectoryVendorDetailsRequest = createAction(
    VendorDirectoryActions.RefreshVendorDirectoryVendorDetailsRequest,
    props<{
        vendorId: number;
    }>(),
);

export const refreshVendorDirectoryVendorDetailsRequestSuccess = createAction(
    VendorDirectoryActions.RefreshVendorDirectoryVendorDetailsRequestSuccess,
);

export const refreshVendorDirectoryVendorDetailsRequestFailed = createAction(
    VendorDirectoryActions.RefreshVendorDirectoryVendorDetailsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteVendorDirectoryRiskAdvisory = createAction(
    VendorDirectoryActions.DeleteVendorDirectoryRiskAdvisory,
    props<{ riskAdvisory: RiskAdvisory }>(),
);

export const deleteVendorDirectoryRiskAdvisoryRequest = createAction(
    VendorDirectoryActions.DeleteVendorDirectoryRiskAdvisoryRequest,
    props<{ riskAdvisory: RiskAdvisory }>(),
);

export const deleteVendorDirectoryRiskAdvisoryRequestSuccess = createAction(
    VendorDirectoryActions.DeleteVendorDirectoryRiskAdvisoryRequestSuccess,
);

export const deleteVendorDirectoryRiskAdvisoryRequestFailed = createAction(
    VendorDirectoryActions.DeleteVendorDirectoryRiskAdvisoryRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteVendorDirectoryRiskAdvisoryCancel = createAction(
    VendorDirectoryActions.DeleteVendorDirectoryRiskAdvisoryCancel,
);

export const getVendorDirectoryDetailsStatsRequest = createAction(
    VendorDirectoryActions.GetVendorDirectoryDetailsStatsRequest,
    props<{
        orgId: number;
    }>(),
);

export const getVendorDirectoryDetailsStatsRequestSuccess = createAction(
    VendorDirectoryActions.GetVendorDirectoryDetailsStatsRequestSuccess,
    props<{ vendorDetailsStats: VendorDetailsStatsResponse }>(),
);

export const getVendorDirectoryDetailsStatsRequestFailed = createAction(
    VendorDirectoryActions.GetVendorDirectoryDetailsStatsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
