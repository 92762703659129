<ng-container *ngIf="notifications$ | async as notifications">
    <a class="nav-link dropdown-toggle dropdown-toggle-nocaret align-bottom" dropdownToggle>
        <ng-container *ngIf="unreadNotifications$ | async as unreadNotifications">
            <mat-icon
                class="text-accent notification-badge"
                [matBadge]="unreadNotifications.length"
                [matBadgeHidden]="unreadNotifications.length === 0"
                matBadgeSize="small"
                >notifications</mat-icon
            >
        </ng-container>
    </a>

    <div *dropdownMenu class="dropdown-menu dropdown-menu-end">
        <div class="dropdown-item">
            <div class="list-group notifications-container">
                <div class="list-group-item notifications-header">
                    <div class="list-item-header">
                        <span>
                            Notifications
                            {{
                                notifications.length
                                    ? '(' + notifications.length + (notificationsTotalCount > 20 ? '+' : '') + ')'
                                    : ''
                            }}
                        </span>
                    </div>
                    <span *ngIf="notifications.length" class="d-flex align-items-center list-global-action">
                        <button
                            type="button"
                            class="btn-sm clear-all"
                            mat-button
                            visoButton="link"
                            color="primary"
                            (click)="markAllAsDismissed($event)"
                        >
                            Clear all
                        </button>
                    </span>
                </div>
                <div class="notifications-items">
                    <div
                        @items
                        *ngFor="let notification of notifications; trackBy: trackNotification"
                        class="list-group-item list-group-item-action"
                    >
                        <ng-container [ngSwitch]="notification.notificationType">
                            <app-event-notification
                                *ngSwitchCase="NotificationType.EVENT_NOTIFICATION"
                                [eventNotification]="$any(notification)"
                                (markAsDismissed)="markAsDismissed(notification, $event)"
                            ></app-event-notification>
                            <app-tip-notification
                                *ngSwitchCase="NotificationType.TIP_NOTIFICATION"
                                [tipNotification]="$any(notification)"
                                (markAsDismissed)="markAsDismissed(notification, $event)"
                            ></app-tip-notification>
                            <app-link-notification
                                *ngSwitchCase="NotificationType.LINK_NOTIFICATION"
                                [linkNotification]="$any(notification)"
                                (markAsDismissed)="markAsDismissed(notification, null)"
                            ></app-link-notification>
                        </ng-container>
                    </div>
                    <div *ngIf="notifications.length === 0" class="list-group-item empty-notifications">
                        You have no notifications.
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
