import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientLicenseView, LicenseTypeLabel } from '@entities/viso-user';
import { ControlDomainType, ControlDomainTypeLabels } from '@entities/control-domain';

@Component({
    selector: 'app-license',
    templateUrl: './license.component.html',
    styleUrl: './license.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseComponent {
    @Input({ required: true }) clientLicense: ClientLicenseView;
    @Input({ required: true }) enabledRiskDimensions: ControlDomainType[];
    @Input({ required: true }) hasDomainsBrandingEnabled: boolean;

    protected readonly ControlDomainType = ControlDomainType;
    protected readonly LicenseTypeLabel = LicenseTypeLabel;
    protected readonly ControlDomainTypeLabels = ControlDomainTypeLabels;

    get licensedRiskDimensions(): ControlDomainType[] {
        const excludedTypes = [
            ControlDomainType.CYBER_INSURANCE,
            ControlDomainType.SERVICE_LOCATIONS,
            ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE,
            ControlDomainType.SUBSERVICERS,
        ];
        return Object.values(ControlDomainType).filter((type) => !excludedTypes.includes(type));
    }

    formatLicenseField(current: number, max: number): string {
        return max === -1 ? `${current} of Unlimited` : `${current} of ${max}`;
    }
}
