import { RequestStatus } from '@entities/relationship';
import { AssessmentStatus } from '@entities/assessment';
import { Tag } from '@entities/tag';

export interface VendorRelationship {
    id: number;
    vendorOrgId: number;
    vendorOrgName: string;
    vendorFaviconUrl?: string;
    vendorUrl?: string;
    vendorPopover?: boolean;
    customerOrgId: number;
    customerOrgName: string;
    drStatus: RequestStatus;
    residualRisk: string;
    inherentRisk: string;
    inherentRiskScore: number;
    residualRiskClass?: unknown;
    inherentRiskClass?: unknown;
    score?: number;
    transitional?: boolean;
    latestRiskAssessmentLegacy?: boolean;
    latestRiskAssessmentCreatedDate?: Date;
    latestNonTransitionalRiskAssessmentLegacy?: boolean;
    latestNonTransitionalRiskAssessmentResidualRisk?: string;
    businessPurpose?: string;
    description?: string;
    updatedDate: Date;
    assessmentStatus: string;
    assessmentStatusDate?: Date;
    assessmentCreatedDate?: Date;
    assessmentCompletedDate?: Date;
    startDate?: Date;
    recertificationDate: Date;
    recertificationType: string;
    upcomingRecertification: boolean;
    overdueRecertification: boolean;
    artifactUpdateType: string;
    businessUnitId: string;
    businessUnitName: string;
    creatorId: number;
    creatorFirstName: string;
    creatorLastName: string;
    businessOwnerId: number;
    businessOwnerFirstName: string;
    businessOwnerLastName: string;
    businessOwnerEmail: string;
    primaryContactId: number;
    primaryContactFirstName: string;
    primaryContactLastName: string;
    primaryContactEmail: string;
    createdDate: Date;
    assessmentPhaseTooltipMessage: string;
    recommendationCount: number;
    hasRemediation: boolean;
    tags?: Tag[];
    vendorTierId: number;

    // For frontend use only
    assessmentStatusCode: AssessmentStatus;
}

export enum RelationshipAssessmentPhases {
    STARTED = 'Started',
    REVIEW_STARTED = 'Review Started',
    COMPLETED = 'Completed',
    NOT_ASSESSED = 'Not Assessed',
    COLLECTING_INFORMATION = 'Collecting Information',

    // Assessment will only be AUDIT_COMPLETE if it's pending client's decision for a follow-up.
    AUDIT_COMPLETED = 'Follow-up recommended',
}

export enum RecertificationType {
    MANUAL = 'Manual',
    AUTOMATIC = 'Automatic',
    NONE = 'None',
}

export interface RelationshipCounts {
    total: number;
    byTier: Map<number, number>;
}
