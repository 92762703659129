import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { VisoUser } from '../../../entities/viso-user';
import {
    ActiveJwtView,
    CreateJwtPayload,
    JwtCreatedResponse,
} from '../../../admin/jwt-management/jwt-management.model';

export enum UserProfileActions {
    GetVisoUserRequest = '[User Profile] Get Viso User Request',
    GetVisoUserRequestSuccess = '[User Profile] Get Viso User Request Success',
    GetVisoUserRequestFailed = '[User Profile] Get Viso User Request Failed',

    GetApiTokenDetailsRequest = '[User Profile] Get Api Token Details Request',
    GetApiTokenDetailsRequestSuccess = '[User Profile] Get Api Token Details Request Success',
    GetApiTokenDetailsRequestFailed = '[User Profile] Get Api Token Details Request Failed',

    GenerateApiTokenRequest = '[User Profile] Generate Api Token Request',
    GenerateApiTokenRequestSuccess = '[User Profile] Generate Api Token Request Success',
    GenerateApiTokenRequestFailed = '[User Profile] Generate Api Token Request Failed',

    RevokeApiTokenRequest = '[User Profile] Revoke Api Token Request',
    RevokeApiTokenRequestSuccess = '[User Profile] Revoke Api Token Request Success',
    RevokeApiTokenRequestFailed = '[User Profile] Revoke Api Token Request Failed',

    OpenAccessTokenModal = '[User Profile] Open Access Token Modal',
    OpenRevokeAccessTokenModal = '[User Profile] Open Revoke Access Token Modal',
}

export const getVisoUserRequest = createAction(
    UserProfileActions.GetVisoUserRequest,
    props<{
        visoUserId: number;
    }>(),
);

export const getVisoUserRequestSuccess = createAction(
    UserProfileActions.GetVisoUserRequestSuccess,
    props<{ visoUser: VisoUser }>(),
);

export const getVisoUserRequestFailed = createAction(
    UserProfileActions.GetVisoUserRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getApiTokenDetailsRequest = createAction(UserProfileActions.GetApiTokenDetailsRequest);

export const getApiTokenDetailsRequestSuccess = createAction(
    UserProfileActions.GetApiTokenDetailsRequestSuccess,
    props<{ activeJwtDetails: ActiveJwtView }>(),
);

export const getApiTokenDetailsRequestFailed = createAction(
    UserProfileActions.GetApiTokenDetailsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const generateApiTokenRequest = createAction(
    UserProfileActions.GenerateApiTokenRequest,
    props<{ payload: CreateJwtPayload }>(),
);

export const generateApiTokenRequestSuccess = createAction(
    UserProfileActions.GenerateApiTokenRequestSuccess,
    props<{ apiTokenResponse: JwtCreatedResponse }>(),
);

export const generateApiTokenRequestFailed = createAction(
    UserProfileActions.GenerateApiTokenRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const revokeApiTokenRequest = createAction(UserProfileActions.RevokeApiTokenRequest);

export const revokeApiTokenRequestSuccess = createAction(UserProfileActions.RevokeApiTokenRequestSuccess);

export const revokeApiTokenRequestFailed = createAction(
    UserProfileActions.RevokeApiTokenRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const openAccessTokenModal = createAction(
    UserProfileActions.OpenAccessTokenModal,
    props<{
        accessToken: string;
    }>(),
);

export const openRevokeAccessTokenModal = createAction(UserProfileActions.OpenRevokeAccessTokenModal);
