import { Pipe, PipeTransform } from '@angular/core';
import { Detection } from '../models/detection.model';
import { ControlDomainControlValidationDetectionWithArtifact } from '../../../entities/relationship/models/security-control-domain';

@Pipe({
    name: 'asCommonDetection',
})
export class AsCommonDetectionPipe implements PipeTransform {
    transform(value: ControlDomainControlValidationDetectionWithArtifact): Detection {
        const {
            artifact,
            auditorComment,
            auditReportType,
            controlIdsText: controlIds,
            controlId: controlId,
            managementResponse,
            notes,
            sectionName,
            subservicers,
            type: detectionType,
            viewerPage,
            id,
            frameworkMapping,
            test,
            testResult,
            description,
            explanation,
        } = value;
        const {
            auditReportAssurance,
            auditReportAssuranceLevel,
            expirationDate: artifactExpirationDate,
            expired: artifactExpired,
            id: artifactId,
            fileName: artifactName,
            type: artifactType,
            validation,
        } = artifact ?? {};
        const { auditReportType: artifactValidationAuditReportType, expired: artifactValidationExpired } =
            validation ?? {};

        const transformed: Detection = {
            id,
            artifactExpirationDate,
            artifactExpired,
            artifactId,
            artifactName,
            artifactType,
            artifactValidationAuditReportType,
            artifactValidationExpired,
            auditorComment,
            auditReportAssurance,
            auditReportAssuranceLevel,
            auditReportType,
            confidence: undefined,
            controlIds,
            controlId,
            detectionType,
            managementResponse,
            notes,
            sectionName,
            subservicers: subservicers?.map((subservicer) => ({
                name: subservicer.subservicerName,
                orgId: subservicer.subservicerId,
            })),
            test,
            testResult,
            description,
            viewerPage,
            frameworkMapping,
            explanation,
        };
        return transformed;
    }
}
